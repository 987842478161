import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import moment from 'moment'
import XLSX from 'xlsx';

import * as firebase from 'firebase';
import BaseEditor from './BaseEditor';
import FormField from './FormField';
import HubStoreProduct from '../models/HubStoreProduct'
import ModalDialog from './ModalDialog';
import StateFieldInput from './inputs/StateFieldInput';
import StoreConfig from './StoreConfig';
import { isSuperAdmin, sort } from '../Helper';
import Header from './Header';
import City from '../models/City';


// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")
var db = firebase.firestore();

class StoreEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Store"
        this.dataRef = db.collection('stores')
        this.canCreate = false;
        this.canEdit = true;
        this.canDelete = true;
        this.defaultValue = { name: "", open_time: null, close_time: null, preorder_open_time: null, preorderClose_time: null, owner: [] };
        this.listFields = ['id', 'name', 'city', 'markup_ratio', 'link', 'sort_order', 'active', 'export_coupon']
        this.editFields = ['name', 'city', 'markup_ratio', 'sort_order']
        this.entityInfo = {
            name: 'String',
            city: { type: 'Choices', choices: [] },
            markup_ratio: 'Number',
            sort_order: 'Number',
        }
        this.customActions = [<button className="button is-info" onClick={() => this.exportByCoupon()}><i className="fa fa-file"></i>&nbsp;Export coupon use </button>, <button className="button is-primary" onClick={() => this.setState({ showCreateDialog: true })}><i className="fa fa-plus"></i>&nbsp;เพิ่มใหม่ </button>]
        this.state.showCreateDialog = false;
        this.state.showConfigDialog = false
        this.state.newEntity = { owner: [] }

        this.createStore = this.createStore.bind(this)

    }

    async componentDidMount() {
        super.componentDidMount();
        let cities = await (await City.getAll()).reduce((p, v) => {
            p[v.id] = v.name
            return p
        }, { '': '' })
        this.entityInfo.city.choices = cities
    }

    fieldData(item, name) {
        let value = super.fieldData(item, name)

        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }
        else if (name === 'export_coupon') {
            return <button className={"button is-small is-warning"} onClick={() => { this.exportByCoupon(item) }}>Export</button>

        }
        else if (name === 'link') {
            return <>
                <a href={`/${item.id}/admin`} className="button is-info is-small">Admin Panel</a>
                {item.markup_ratio && <button className="button is-warning is-small" onClick={async () => {
                    if (window.confirm("แน่ใจหรือไม่ว่าต้องการปรับราคาทั้งหมด?")) {
                        let products = await db.collection('stores').doc(item.id).collection('products').get()
                        if (!products.empty) {
                            this.setState({ loading: true })

                            for (let pi in products.docs) {
                                let p = products.docs[pi]
                                // p.ref.update(price:}
                                let pd = p.data()
                                if (pd.cost) {
                                    pd.price = Math.ceil(pd.cost * item.markup_ratio)

                                    // console.log(pd, pd.cost, pd.price, pd.cost * item.markup_ratio)
                                    pd.options.forEach(x => {
                                        if (x.cost) x.price = Math.ceil(x.cost * item.markup_ratio)
                                    })

                                    await p.ref.update({ price: pd.price, options: pd.options })
                                }

                                this.setState({ loading_message: `กำลังปรับราคา: ${pi}/${products.docs.length}` })
                            }

                            alert("ปรับราคาเรียบร้อยแล้ว")
                            this.setState({ loading: false })
                        }
                    }
                }}>ตั้งราคาใหม่</button>}
            </>
        }

        return value
    }


    async exportByCoupon(current_store) {

        var filename = "exportOrderByCoupon.xlsx";
        var data = []
        let reportItems = []
        this.setState({ loading: true })

        if (current_store) {
            let qs = await db.collection('stores').doc(current_store.id).collection('orders').where('status', '==', 'delivered').get()
            reportItems = qs.docs.map(x => { return { id: x.id, hub: x.ref.parent.parent.id, ...x.data() } })
        }
        else {
            let qs = await db.collectionGroup('orders').where('status', '==', 'delivered').get()
            reportItems = qs.docs.map(x => { return { id: x.id, hub: x.ref.parent.parent.id, ...x.data() } })
        }


        if (reportItems) {

            data = reportItems.filter(x => x.coupon_code).map((item, key) => [item.id, item.hub, item.coupon_code, item.customer.name, item.customer.tel, item.totalPrice, item.status.humanize, item.deliverAddress.address, item.paymentMethod, item.orderDate && item.orderDate.toString(), item.deliverDate && item.deliverDate.toString(),
            item.timestamp && item.timestamp.paid && item.timestamp.paid.toDate().toString(),
            item.timestamp && item.timestamp.paid && moment(item.timestamp.paid.toDate()).format("HH:mm"),
            item.timestamp && item.timestamp.ontheway && item.timestamp.ontheway.toDate().toString(),
            item.timestamp && item.timestamp.ontheway && moment(item.timestamp.ontheway.toDate()).format("HH:mm"),
            item.timestamp && item.timestamp.delivered && item.timestamp.delivered.toDate().toString(),
            item.timestamp && item.timestamp.delivered && moment(item.timestamp.delivered.toDate()).format("HH:mm")])
        }

        var exportdata = [["NO", "HUB", "Coupon", "Name", "Tel", "Discount", "Total", "Count", "Address", "Payment", "Submit Date", "Deliver Date", "TimePaid", "TimePaid[HH:mm]", "TimeOnTheWay", "TimeOnTheWay[HH:mm]", "TimeDelivered", "TimeDelivered[HH:mm]"]]

        let sortedData = sort(data, [{ 'coupon_code': 'asc' }], (item, field) => item[2])
        console.log(data)
        let currentStore = null
        let totalPrice = 0
        let totalDiscount = 0
        let count = 0
        for (let d in sortedData) {
            let store = sortedData[d][2]
            if (currentStore === null || currentStore !== store) {
                if (currentStore !== null) {
                    exportdata.push(['', '', '', '', totalDiscount, totalPrice, count])
                }

                totalPrice = 0
                totalDiscount = 0
                count = 0
                currentStore = store
            }
            totalDiscount += sortedData[d][5]
            totalPrice += sortedData[d][6]
            count++
            exportdata.push(sortedData[d])
        }

        if (currentStore !== null) {
            exportdata.push(['', '', '', '', totalDiscount, totalPrice, count])
        }


        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(exportdata);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());

        this.setState({ loading: false })
    }


    async createStore() {
        let { newEntity } = this.state
        // console.log(this, this.state.newEntity)
        if (newEntity && newEntity.name) {
            // newEntity.owner = [""]
            // console.log(newEntity)
            let doc = await this.dataRef.doc(newEntity.name).get()
            if (!doc.exists) {
                await this.dataRef.doc(newEntity.name).set(newEntity, { merge: true })
                this.setState({ showCreateDialog: false, showConfigDialog: newEntity.name, newEntity: { owner: [] } })
                window.open(`/${newEntity.name}/admin/settings`);
            }
            else {
                alert("ไม่สามารถสร้างร้านใหม่ได้ เนื่องจากมีร้านนี้แล้ว")
            }
            // window.location.href = `/${newEntity.name}/admin/settings`
        }
    }

    render() {
        let { current_user } = this.props
        // if (!current_user || !isSuperAdmin(current_user.email)) return <Header />

        let result = super.render()

        return [result,
            // <Header />,
            this.state.showCreateDialog && <ModalDialog title="สร้างร้านใหม่" onClose={() => this.setState({ showCreateDialog: false })} onOK={() => this.createStore()}>
                <label className="label is-small">Store ID</label>
                <StateFieldInput type="text" name="newEntity.name" stateComponent={this} filter={x => x.toLowerCase().replace(/[^\w_.]+/, '')} />
                <label className="label is-small">Email</label>
                <StateFieldInput type="text" name="newEntity.owner[0]" stateComponent={this} filter={x => x.toLowerCase()} />
            </ModalDialog>,]
    }
}


const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(StoreEditor))