import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import * as firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import * as AppConfig from '../AppConfig'
import * as actions from '../actions/index'
import { isAdmin, isSuperAdmin } from '../Helper';
import Store from '../models/Store';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { toggleBurger: false }
  }
  componentDidMount() {
    let { current_user } = this.props
    // Setup toggle nav
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });
    }

    // 
    if (this.props.location && this.props.location.pathname.includes('/admin') && !this.props.location.pathname.includes('/admin/tickets') && current_user === false) {
      this.signIn()
    }

    this.state.current_user = current_user
  }

  componentDidUpdate() {
    let { current_user } = this.props
    if (current_user !== this.state.current_user) {
      this.state.current_user = current_user

      if (this.props.location && this.props.location.pathname.includes('/admin') && !this.props.location.pathname.includes('/admin/tickets') && current_user === false) {
        this.signIn()
      }
    }
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        let hasClass = false;
        for (let c in el.classList) {
          if (el.classList[c] === "sc") {
            hasClass = true;
            break;
          }
        }

        if (!hasClass) {
          el.classList.add("sc");

          el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');

          });
        }
      });
    }
  }

  signIn() {
    alert('signIn')
    var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', {
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      signInSuccessUrl: window.location.href
    }
    );
  }
  signOut() {
    firebase.auth().signOut().then(function () {
      // Sign-out successful.
    }).catch(function (error) {
      // An error happened.
    });
  }

  render() {
    // debugger
    let { toggleBurger } = this.state
    let store_id = this.props.current_store
    let { current_store_data, current_user } = this.props

    if (!this.props.location || !this.props.location.pathname.includes('/admin') || current_user === false) return "";
    if (this.props.location.pathname.includes('/admin') && (!current_user)) return "";

    return (
      <div>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src="/logo512.png" />
            </a>

            <a role="button" className={`navbar-burger burger ${toggleBurger && 'is-active'}`} onClick={() => this.setState({ toggleBurger: !toggleBurger })} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className={`navbar-menu ${toggleBurger && 'is-active'}`}>
            <div className="navbar-end">
              {current_user === false && <div className="navbar-item">
                <div className="buttons">
                  <a className="button is-light" onClick={this.signIn}>
                    เข้าสู่ระบบ
                  </a>
                </div>
              </div>}

              {!this.props.current_store && <div className="navbar-start">
                <NavLink to={`/admin/stores`} activeClassName="is-active" className="navbar-item">HUB </NavLink>
                <NavLink to={`/admin/cities`} activeClassName="is-active" className="navbar-item">เมือง </NavLink>
                <NavLink to={`/admin/categories`} activeClassName="is-active" className="navbar-item">หมวดหมู่</NavLink>
                <div className="navbar-item has-dropdown is-hoverable">

                  <a className="navbar-link">โปรโมชั่น</a>
                  <div className="navbar-dropdown">
                    <NavLink to={`/admin/promotions`} activeClassName="is-active" className="navbar-item">โปรโมชั่น</NavLink>
                    <NavLink to={`/admin/coupons`} activeClassName="is-active" className="navbar-item">คูปองโค้ด</NavLink>
                  </div>
                </div>

              </div>}
              {// (this.props.user_roles && this.props.user_roles.includes('admin')) && 
                current_user && this.props.current_store_data && <>

                  <div className="navbar-start">
                    <NavLink to={`/${store_id}/admin`} activeClassName="is-active" className="navbar-item">แดชบอร์ด </NavLink>

                    <NavLink to={`/${store_id}/admin/orders`} activeClassName="is-active" className="navbar-item">ออเดอร์</NavLink>
                    {(isAdmin(current_store_data, current_user.email) || isSuperAdmin(current_user.email)) && <>
                      <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">สินค้า</a>
                        <div className="navbar-dropdown">
                          <NavLink to={`/${store_id}/admin/menus`} activeClassName="is-active" className="navbar-item">อาหาร</NavLink>
                          {((AppConfig.firebase.projectId === 'imperial-rine' && window.location.href.includes('demo')) || window.location.href.includes('locall.product')) && <NavLink to={`/${store_id}/admin/products`} activeClassName="is-active" className="navbar-item">สินค้า</NavLink>}
                          <NavLink to={`/${store_id}/admin/category`} activeClassName="is-active" className="navbar-item">หมวดหมู่</NavLink>
                        </div>
                      </div>

                      <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">โปรโมชั่น</a>
                        <div className="navbar-dropdown">
                          <NavLink to={`/${store_id}/admin/promotions`} activeClassName="is-active" className="navbar-item">โปรโมชั่น</NavLink>
                          <NavLink to={`/${store_id}/admin/coupons`} activeClassName="is-active" className="navbar-item">คูปองโค้ด</NavLink>
                        </div>
                      </div>
                    </>}


                    <div className="navbar-item has-dropdown is-hoverable">
                      <a className="navbar-link">Hub</a>
                      <div className="navbar-dropdown">
                        <NavLink to={`/${store_id}/admin/tickets`} activeClassName="is-active" className="navbar-item">ออเดอร์ร้านค้า</NavLink>
                        {(isAdmin(current_store_data, current_user.email) || isSuperAdmin(current_user.email)) && <>
                          <NavLink to={`/${store_id}/admin/hub_stores`} activeClassName="is-active" className="navbar-item">ร้านค้า</NavLink>
                          <NavLink to={`/${store_id}/admin/drivers`} activeClassName="is-active" className="navbar-item">คนขับ</NavLink>
                        </>}
                      </div>
                    </div>
                    {(isAdmin(current_store_data, current_user.email) || isSuperAdmin(current_user.email)) && <>
                      <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">ตั้งค่า</a>
                        <div className="navbar-dropdown">
                          <NavLink to={`/${store_id}/admin/banners`} activeClassName="is-active" className="navbar-item">แบนเนอร์</NavLink>
                          <NavLink to={`/${store_id}/admin/dayoff`} activeClassName="is-active" className="navbar-item">วันหยุด</NavLink>
                          {/* <NavLink to={`/${store_id}/admin/richmenus`} activeClassName="is-active" className="navbar-item">Rich Menus</NavLink> */}
                          <NavLink to={`/${store_id}/admin/delivery`} activeClassName="is-active" className="navbar-item">จัดการค่าส่ง</NavLink>
                          <NavLink to={`/${store_id}/admin/settings`} activeClassName="is-active" className="navbar-item">ตั้งค่า</NavLink>
                        </div>
                      </div>
                    </>}

                    <NavLink to={`/${store_id}/admin/billings`} activeClassName="is-active" className="navbar-item">ปริมาณการใช้งาน</NavLink>
                  </div>
                  <div className="navbar-item" key="logout">
                    <div className="buttons">
                      <a className="button is-light" onClick={this.signOut}>ออกจากระบบ</a>
                    </div>
                  </div></>}
            </div>
          </div>

        </nav>
        <div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = ({ current_user, user_roles, current_store, current_store_data }) => ({ current_user, user_roles, current_store, current_store_data });

export default withRouter(connect(mapStateToProps, actions)(Header))