import React from "react"
import moment from 'moment'
import { withRouter } from "react-router";

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import { withCookies } from 'react-cookie';
import Store from '../models/Store';
import DeliveryTierRow from "./DeliveryTierRow";
import { calculateDeliverPrice } from '../Helper'
import * as AppConfig from '../AppConfig.json';

class StoreConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.packages = {
            'FREE': { price: 0, order_count: 30, branch_count: 1, admin_count: 1 },
            'SEED': { price: 330, order_count: 1000, branch_count: 1, admin_count: 1 },
            'STANDARD': { price: 1600, order_count: 5000, branch_count: 2, admin_count: 5 },
            'PRO': { price: 4800, order_count: 15000, branch_count: 5, admin_count: 10 },
            'SME': { price: 12800, order_count: 30000, branch_count: 10, admin_count: 20 }
        }
    }

    async componentDidMount() {

        let { current_store } = this.props
        if (this.props.store) {
            current_store = this.props.store
        }

        if (current_store) {
            let store = await Store.get(current_store)
            if (store.exists) {
                console.log("Store data", store.data())
                let storeData = store.data()
                storeData.id = store.id
                if (!storeData.branches) {
                    storeData.branches = [{ name: "สาขาที่ 1", lat: "", lng: "" }]
                }
                if (!storeData.bank_accounts) {
                    storeData.bank_accounts = [{ bank: "", branch: "", account_name: "", account_no: "" }]
                }
                if (!storeData.package) {
                    storeData.package = [{ ...this.packages['FREE'], name: "FREE" }]
                }
                storeData.preorder_open_time = storeData.preorder_open_time || storeData.open_time;
                storeData.preorder_close_time = storeData.preorder_close_time || storeData.close_time;


                this.setState({ storeData })

                if (!storeData.delivery_tiers || storeData.delivery_tiers.length === 0) {
                    this.addDeliveryTier();
                }
            }

            else {
                this.setState({ storeData: false })
            }
        }
    }

    componentDidUpdate() {

    }


    handleDescriptionChange = (e) => {
        let updateState = this.state.storeData;
        if (e.target.type === "number") {
            eval(`updateState.${e.target.name} = Number.parseFloat(e.target.value)`)
        }
        else if (e.target.type === 'checkbox') {
            eval(`updateState.${e.target.name} = !updateState.${e.target.name}`)
        }
        else {
            eval(`updateState.${e.target.name} = e.target.value`)
        }
        this.setState({ storeData: updateState })
    }


    async submit() {
        let { storeData } = this.state
        let { current_store } = this.props
        if (this.props.store) {
            current_store = this.props.store
        }

        let permitted_params = ['delivery_tiers', 'delivery_calculate_mode', 'delivery_start_price', 'delivery_min_order_price', 'delivery_collecting_price', 'delivery_lm_markup_price', 'delivery_lm_markup_percent']
        let updataData = {}
        for (let p in permitted_params) {
            if (storeData[permitted_params[p]]) {
                updataData[permitted_params[p]] = storeData[permitted_params[p]]
            }
        }

        // console.log(updataData)

        await Store.update(current_store, updataData)
        alert("บันทึกการตั้งค่าเรียบร้อยแล้ว")


    }

    formValidate() {
        return true
        let state = this.state
        return state.store_name && state.store_id && state.firstname && state.lastname && state.email && state.tel && state.open_time && state.close_time && state.package
    }

    getDeliverTimes(date) {
        let dateList = []

        let currentTime = moment('00:00', "HH:mm")
        let currentEndTime = currentTime.clone().add(30, "minutes");
        let endTime = moment('23:59', "HH:mm")
        // console.log("getDeliverTime", currentTime.format(), endTime.format(), currentTime < endTime)
        while (currentTime < endTime) {
            // console.log(currentTime.format());
            dateList.push(`${currentTime.format("HH:mm")}`);
            currentTime = currentEndTime
            currentEndTime = currentTime.clone().add(30, "minutes");
        }

        return dateList.map(x => <option key={x} value={x}>{x}</option>)
    }

    addDeliveryTier() {
        let { storeData } = this.state
        let { delivery_tiers } = storeData
        if (!delivery_tiers) {
            delivery_tiers = storeData.delivery_tiers = []
        }
        delivery_tiers.push({ km: null, price: 0 }); this.forceUpdate()
    }



    renderPriceTable() {
        let { storeData } = this.state
        let { delivery_tiers } = storeData
        let tableRow = []
        let price = Number.parseFloat(storeData.delivery_start_price) || 0
        if (!delivery_tiers || delivery_tiers.length === 0) return false


        let currentDeliveryTier = 0
        for (let i = 0; i < delivery_tiers[delivery_tiers.length - 1].km; i++) {
            if (delivery_tiers[currentDeliveryTier].km < i) {
                if (currentDeliveryTier + 1 < delivery_tiers.length) {
                    currentDeliveryTier++
                }
            }

            tableRow.push(<tr>
                <td className="has-text-rigth">{i} - {i + 0.99}</td>
                <td className="has-text-right">{delivery_tiers[currentDeliveryTier].useLalamove ? 'Lalamove' : calculateDeliverPrice(storeData, i + 1)}</td>
            </tr>)
        }
        return <table className="table is-striped is-fullwidth delivery-price-table">
            <thead>
                <th className="has-text-left">ระยะทาง</th>
                <th className="has-text-right">ราคา</th>
            </thead>
            <tbody>
                {tableRow}
            </tbody>
        </table>
    }

    render() {
        let { current_store } = this.props

        if (this.props.store) {
            current_store = this.props.store
        }
        if (!this.props.current_user) return <div className="title p20 has-text-centered"></div>;
        // 
        if (this.props.current_user && this.props.owner === false) return <div className="title p20 has-text-centered">คุณไม่มีสิทธิในการเข้าถึงข้อมูลส่วนนี้!<br />({this.props.current_user.email})</div>;

        let { storeData } = this.state
        if (!storeData) return <div className="has-text-centered p20"> <img src="/loading.gif" width="80" /></div>
        let { delivery_tiers, delivery_start_price, delivery_calculate_mode, delivery_min_order_price, delivery_collecting_price, delivery_lm_markup_price, delivery_lm_markup_percent } = storeData

        if (!delivery_calculate_mode) {
            storeData.delivery_calculate_mode = 'dis-accurate'
        }


        let isSuperAdmin = ['mekku.nk@gmail.com'].includes(this.props.current_user.email)

        let isHubAdmin = isSuperAdmin || ['peangploy@satarana.com', 'danuchdech1@gmail.com', 'sanon@satarana.com', 'ping@satarana.com', 'mekku.nk@gmail.com', 'klinsanity@gmail.com'].includes(this.props.current_user.email)



        return <div className="store-config p15">
            <div className="container">
                <h1 className="title">ค่าจัดส่ง</h1>

                <hr />
                <div className="columns">
                    {isHubAdmin && <div className="column is-two-thirds">
                        <h3 className="subtitle is-5">วิธีการคำนวณค่าส่ง</h3>
                        <div className="select">
                            <select value={delivery_calculate_mode} onChange={e => this.setState({ storeData: { ...storeData, delivery_calculate_mode: e.currentTarget.value } })}>
                                <option value="dis-accurate">คิดตามระยะทางสะสม</option>
                                <option value="dis-muliply">คิดตามระยะทาง</option>
                                <option value="dis-fix">คงที่ตามระยะทาง</option>
                            </select>
                        </div>
                        <hr />
                        {storeData.enableLalamove && <>
                            <h3 className="subtitle is-5">Lalamove</h3>
                            <div className="columns">
                                <div className="column">ราคาบวกเพิ่ม (บาท) <input className="input" value={delivery_lm_markup_price} onChange={e => this.setState({ storeData: { ...storeData, delivery_lm_markup_price: e.currentTarget.value } })} /> บาท</div>
                                <div className="column">ราคาบวกเพิ่ม (%) <input className="input" value={delivery_lm_markup_percent} onChange={e => this.setState({ storeData: { ...storeData, delivery_lm_markup_percent: e.currentTarget.value } })} /> บาท</div>
                            </div>

                            <hr />
                        </>}
                        <h3 className="subtitle is-5">คำนวนค่าส่งตามระยะทาง</h3>
                        <div className="delivery-tiers">
                            <div className="columns">
                                <div className="column">
                                    <div>ยอดสั่งซื้อขั้นต่ำ <input className="input" value={delivery_min_order_price} onChange={e => this.setState({ storeData: { ...storeData, delivery_min_order_price: e.currentTarget.value } })} /> บาท</div>
                                </div>
                                <div className="column">
                                    <div>ค่ารวบรวมออเดอร์ <input className="input" value={delivery_collecting_price} onChange={e => this.setState({ storeData: { ...storeData, delivery_collecting_price: e.currentTarget.value } })} /> บาท</div>
                                </div>
                            </div>

                            <hr />
                            <div>ค่าส่งเริ่มต้น <input className="input" value={delivery_start_price} onChange={e => this.setState({ storeData: { ...storeData, delivery_start_price: e.currentTarget.value } })} /> บาท</div>
                            {delivery_tiers && delivery_tiers.map((delivery, index) =>
                                <div className="delivery-tier-row">
                                    <DeliveryTierRow data={delivery} mode={delivery_calculate_mode} startkm={index > 0 ? Number.parseInt(delivery_tiers[index - 1].km) : 0} onChange={(data) => { delivery_tiers[index] = data; this.forceUpdate() }} />
                                    {delivery_tiers.length > 1 && <button className="button is-warning is-small" style={{ marginLeft: 10 }} onClick={() => { storeData.delivery_tiers.splice(index, 1); this.forceUpdate() }}>ลบ</button>}
                                </div>
                            )}
                        </div>
                        <hr />
                        <div className="delivery-setting">

                            <div className="has-text-right">
                                <button className="button is-info is-fullwidth" onClick={() => { this.addDeliveryTier() }}><i className="fa fa-plus"></i>&nbsp;เพิ่ม</button>
                            </div>
                        </div>

                    </div>
                    }
                    <div className="column">
                        <h3 className="subtitle is-5">ตารางแสดงค่าส่ง</h3>
                        {this.renderPriceTable()}

                    </div>
                </div>




                <hr />

                {/* {JSON.stringify(storeData)} */}
                <button className="button is-success is-large is-fullwidth" onClick={() => this.submit()} disabled={!this.formValidate()}>บันทึกข้อมูล</button>
                {/* <Payment2c2pForm total_price={'3333'} order_no={'dddd'} order_id={'tttt'} email={'dddd'} description={'Rine Service Fee'} /> */}

            </div>
        </div >
    }
}

const mapStateToProps = ({ current_entity, current_store, current_user }) => ({ current_entity, current_store, current_user });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(StoreConfig)))
