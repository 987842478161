import React, { Component } from 'react';
import SanitizedHTML from 'react-sanitized-html';
import Humanize from 'humanize-plus'

import * as firebase from 'firebase';

import FieldInput from './inputs/FieldInput';
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import { SheetJSFT } from './types';

import ToggleButton from './forms/ToggleButton';
import { dateFormat, sort } from '../Helper';
import moment from 'moment'

// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")
var db = firebase.firestore();
var storageRef = firebase.storage().ref();

const defaultDesc = {};

class BaseEditor extends Component {
    constructor(props) {
        super(props);

        this.canCreate = false;
        this.canEdit = true;
        this.canDelete = true;
        this.defaultValue = defaultDesc;
        this.listFields = []
        this.viewFields = []
        this.editFields = []
        this.importFields = []
        this.entityInfo = {}
        this.scopeList = []

        this.state = {
            loading: false,
            loading_message: "",
            error_message: "",
            step: 0,
            item_desc: this.defaultValue,
            item_object: {},
            items: false,
            scope: null,

            // Import xlsx
            file: null,
            cols: [],
            import_queues: [],
            show_import: false,
            importing: false,

        }
        this.defaultImagePath = 'uploads/'

        this.handleEntityChange = this.handleEntityChange.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
        this.loadEditor = this.loadEditor.bind(this)
        this.loadViewer = this.loadViewer.bind(this)
        this.save = this.save.bind(this)
        this.upload = this.upload.bind(this)
        this.toggleActive = this.toggleActive.bind(this)
        this.delete = this.delete.bind(this)
        this.validate = this.validate.bind(this)
        this.renderFields = this.renderFields.bind(this);
        this.fieldData = this.fieldData.bind(this);
        this.applyFilter = this.applyFilter.bind(this);

        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.processImportList = this.processImportList.bind(this);
        this.renderViewer = this.renderViewer.bind(this)
        this.applyScope = this.applyScope.bind(this)
        this.applyListOrder = this.applyListOrder.bind(this)
    }

    applyScope(scope) {

        if (this.state.scope !== scope) {
            this.setState({ scope })
        }
        return this.state.items;
    }

    applyListOrder(data) {
        let { quickSearch } = this.state
        if (quickSearch) {
            let queries = quickSearch.replace("\w+").split(" ")
            data = data.filter((item) => {
                let result = true;

                for (let q in queries) {
                    if (!result) break;

                    queries[q] = queries[q].trim().toLowerCase()
                    for (let i in this.listFields) {
                        result = false;
                        if (item[this.listFields[i]] && item[this.listFields[i]].toString().trim().toLowerCase().includes(queries[q])) {
                            result = true;
                            break;
                        }
                    }

                    for (let i in this.searchFields) {
                        result = false;
                        if (item[this.searchFields[i]] && item[this.searchFields[i]].toString().trim().toLowerCase().includes(queries[q])) {
                            result = true;
                            break;
                        }
                        // if (!item[this.searchFields[i]]) {
                        // console.log(this.searchFields[i])
                        let value = this.fieldData(item, this.searchFields[i])
                        if (value && value.toString().trim().toLowerCase().includes(queries[q])) {
                            result = true;
                            break;
                        }
                        // }
                    }
                }
                return result;
            })
        }

        if (this.state.orders) {
            data = sort(data, this.state.orders, this.fieldData)
        }

        return data;
    }

    reloadList() {
        this.dataRef.get().then((querySnapshot) => {
            var children = [];
            querySnapshot.forEach((doc) => {

                var childKey = doc.id;
                var childData = doc.data();
                childData.id = childKey;
                children.push(childData)
            });

            this.setState({ items: children });
        });

    }

    toggleActive(item_id, active) {
        let $this = this;

        for (let e in this.state.items) {
            if (this.state.items[e].id === item_id) {
                this.state.items[e].is_active = active
                // console.log(this.state.items[e])
                this.forceUpdate()
            }
        }

        this.dataRef.doc(item_id).update({ is_active: active }).then(function () {
            // $this.reloadList();
            $this.setState({
                loading: false,
            })
        }).catch(function (error) {
            console.error("Error removing document: ", error);
            $this.setState({
                loading: false,
                error_message: error
            })
        });
    }

    handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0], });
        else this.setState({ file: null, });
    };

    handleFile() {
        this.setState({ importing: true })
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            if (data.length === 0) {
                this.setState({ importing: false })
            }
            /* Update state */
            let processedData = data.map(row => this.importFields.map(k => k in row ? { [k]: row[k] } : {})
                .reduce((res, o) => Object.assign(res, o), {}))

            this.setState({ import_queues: [...this.state.import_queues, ...processedData], cols: make_cols(ws['!ref']) }, () => {
                this.processImportList();
            });

        };

        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        };
    }

    addOrder(order) {
        let { orders } = this.state
        let o = {}
        let existed = false
        for (let i in orders) {

            if (Object.keys(orders[i])[0] === order) {
                if (orders[i][order] === 'asc') {
                    orders[i][order] = 'desc'
                }
                else {
                    orders[i][order] = 'asc'
                }

                existed = true
            }
        }
        if (!existed) {
            o[order] = 'asc'
            orders.push(o)
        }

        this.setState(orders)
    }

    getOrder(order) {
        let { orders } = this.state

        for (let i in orders) {
            if (Object.keys(orders[i])[0] === order) {
                return [orders[i][order], Number.parseInt(i) + 1]
            }
        }
        return [null, null]
    }


    removeOrder(order) {
        let { orders } = this.state

        for (let i in orders) {
            if (Object.keys(orders[i])[0] === order) {
                orders.splice(i, 1)
                break
            }
        }
        this.setState(orders)
    }

    processImportList() {
        let { import_queues } = this.state;
        let $this = this;
        let import_count = 0;
        let batch = db.batch();

        for (let i in import_queues) {
            let areaRef = this.dataRef.doc();
            batch.set(areaRef, this.state.import_queues[i]);
            import_count++;
            if (import_count >= 500) {
                break;
            }
        }

        import_queues.splice(0, import_count)


        // Commit the batch
        batch.commit().then(function () {
            $this.setState({ import_queues });
            if (import_queues.length > 0) {
                $this.processImportList();
            }
        });
    }

    delete(item_id) {
        if (!window.confirm("แน่ใจหรือไม่่ว่าจะลบรายการนี้")) return
        let $this = this;

        this.setState({
            loading: true,
            loading_message: "Delete item ...."
        })

        this.dataRef.doc(item_id).delete().then(() => {
            // $this.reloadList();
            let items = this.state.items
            let newItems = []

            for (let i in items) {
                if (items[i].id !== item_id) {
                    newItems.push(items[i])
                }
            }

            $this.setState({
                loading: false,
                items: newItems
            })
        }).catch(function (error) {
            console.error("Error removing document: ", error);
            $this.setState({
                loading: false,
                error_message: error
            })
        });
    }

    componentDidMount() {

        let { orders } = this.props
        let { current_entity } = this.props

        orders = orders || []
        this.setState({ orders })

        let $this = this;
        this.unsubscribe = this.dataRef
            .onSnapshot(querySnapshot => {
                var children = [];
                querySnapshot.forEach((doc) => {
                    var childKey = doc.id;
                    var childData = doc.data();
                    childData.id = childKey;
                    children.push(childData)
                });
                $this.setState({ items: children }, () => {
                    $this.applyFilter($this.state.filter)

                    if ($this.props.location.search) {
                        for (let c in children) {
                            if (!current_entity && children[c].id === $this.props.location.search.replace("?", "") && (!$this.state.currentItem || ($this.state.currentItem && $this.state.currentItem.id !== children[c].id))) {
                                $this.loadEditor(children[c])
                            }
                        }
                    }
                })

                // this.unsubscribe();
            });
    }

    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
    }


    applyFilter(filter) {
        let filteredItems = []


        this.setState({ filter, filteredItems })
    }

    fieldData(item, name) {
        let value = item[name];
        // console.log(name, value)
        if (value && typeof value !== 'string') {
            if (value.constructor.name === 'Timestamp' || (value.seconds && value.nanoseconds && value.toDate)) {
                return dateFormat(value)
            }
            return value.toString()
        }
        return value;
    }

    loadViewer(item) {
        this.setState({
            loading: true,
            loading_message: "Loading item....",
            file: null
        })

        let $this = this;


        if (item.image) {
            storageRef.child(item.image).getDownloadURL().then(function (url) {
                $this.setState({ fileObjURL: url, item_desc: item, step: 1, loading: false })

            }).catch(function (error) {
                console.error(error)
                // Handle any errors
                $this.setState({ loading: false })
            });
        }
        else {
            this.setState({ fileObjURL: null, item_desc: item, step: 1, loading: false })
        }

        this.props.setCurrentEntity(item)
        this.setState({ entity: item, show: 'viewer' })

    }

    loadEditor(item) {
        this.setState({
            loading: true,
            loading_message: "Loading item....",
            file: null
        })

        let $this = this;


        if (item.image) {
            storageRef.child(item.image).getDownloadURL().then(function (url) {
                $this.props.history.push(`${$this.props.location.pathname}?${item.id}`)
                $this.setState({ fileObjURL: url, item_desc: item, step: 1, loading: false })

            }).catch(function (error) {
                console.error(error)
                // Handle any errors
                $this.setState({ loading: false })
                $this.setState({ fileObjURL: null, item_desc: item, step: 1, loading: false })
            });
        }
        else {
            this.props.history.push(`${this.props.location.pathname}?${item.id}`)
            this.setState({ fileObjURL: null, item_desc: item, step: 1, loading: false })
        }

        this.props.setCurrentEntity(item)
        this.setState({ entity: item, show: 'editor' })

    }

    addOption() {
        let item = this.state.item_desc;
        item.options.push({})
        this.setState({ item_desc: item })
    }

    removeOption(i) {
        let item = this.state.item_desc;
        item.options.splice(i, 1)
        this.setState({ item_desc: item })
    }

    clone = (item) => {
        item.name += "-copy"
        this.dataRef.add(item)
    }

    save = (item_desc = null) => {
        // Save data to fire store
        let $this = this;

        if (item_desc === null) {
            item_desc = this.state.item_desc;
        }

        this.setState({ loading: true, loading_message: "Saving item...." })
        if (this.state.item_desc.id) {
            var itemRef = this.dataRef.doc(this.state.item_desc.id);
            itemRef.set(item_desc, { merge: true })
                .then(function () {
                    // 
                    if ($this.state.file) {
                        $this.upload($this.state.item_desc.id, $this.state.file.type, $this.state.base64Image)
                    }
                    else {
                        $this.setState({ loading: false, step: 0 })
                        // $this.reloadList();
                        $this.props.history.push(`${$this.props.location.pathname}`);
                    }
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                    $this.setState({ loading: true, error_message: error })
                });
        }
        else {
            this.dataRef.add(item_desc)
                .then(function (docRef) {
                    if ($this.state.file) {
                        $this.upload(docRef.id, $this.state.file.type, $this.state.base64Image)
                    }
                    else {

                        $this.setState({ loading: false, step: 0 })
                        $this.props.history.push(`${$this.props.location.pathname}`);
                        // $this.reloadList();
                    }
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                    $this.setState({ loading: false, error_message: error })
                });
        }


    };

    upload = (itemId, type, base64str) => {
        console.log("Start upload", itemId, type, base64str)
        let $this = this;
        var filename = this.defaultImagePath + itemId + '.' + type.replace('image/', '');
        var fileRef = storageRef.child(filename);
        this.setState({
            loading: true,
            loading_message: "Start upload item image to database...."
        })

        fileRef.putString(base64str, 'base64').then(function (snapshot) {
            var itemRef = $this.dataRef.doc(itemId);
            $this.setState({
                loading: true,
                loading_message: "Update item image infomation to database...."
            })

            itemRef.update({ image: filename }).then(function () {
                $this.setState({ loading: false, step: 0 })
                $this.props.history.push(`${$this.props.location.pathname}`);
                // $this.reloadList();
            })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                    $this.setState({ loading: false, error_message: error })
                });
        });
    };

    handleEntityChange(e, name, value) {
        let { current_entity } = this.props
        let $this = this
        if (typeof name === 'undefined' && e) {
            name = e.target.name;
        }
        if (typeof value === 'undefined' && e) {
            value = e.target.value;
        }

        if (e && e.target.type === "file") {

            var fr = new FileReader();
            fr.onload = function (e) {
                // let base64Image = this.result.replace(/^data:image\/(png|jpeg);base64,/, "");
                eval(`current_entity.${name} = this.result`)

            };
            fr.readAsDataURL(e.target.files[0]);

        }
        else {

            // entity[name] = value
            let np = ""
            if (name.includes(".")) {
                let part = name.split(".")
                for (var p in part) {

                    if (np !== "") {
                        np += "."
                    }

                    np += part[p]

                    if (typeof eval(`current_entity.${np}`) === 'undefined' || eval(`current_entity.${np}`) === null) {
                        eval(`current_entity.${np} = {}`)
                    }
                }
            }

            eval(`current_entity.${name} = value`)
        }

        this.setState({ entity: current_entity })
    }

    handleFileChange(event) {
        let $this = this;
        this.setState({
            file: event.target.files[0],
            fileObjURL: URL.createObjectURL(event.target.files[0]),
            loading: true
        })

        var fr = new FileReader();
        fr.onload = function (e) {
            let base64Image = this.result.replace(/^data:image\/(png|jpeg);base64,/, "");
            $this.setState({ 'base64Image': base64Image, loading: false })
        };
        fr.readAsDataURL(event.target.files[0]);
    }

    handleDescriptionChange(e) {
        let updateState = this.props.current_entity;

        let name = e.target.name
        try {
            if (e.target.type === "number") {
                eval(`updateState.${e.target.name} = Number.parseFloat(e.target.value)`)
            }
            else if (e.target.type === "checkbox") {
                eval(`updateState.${e.target.name} = e.target.checked`)
            }
            else {
                eval(`updateState.${e.target.name} = e.target.value`)
            }
        }
        catch (ex) {
            updateState[e.target.name] = e.target.value
        }

        this.setState({ item_desc: updateState })
        this.props.setCurrentEntity(updateState)
    }

    renderListHeader() {
        return <tr>
            {this.listFields.map((column) => {
                let order = this.getOrder(column)

                return <th key={column} className={`list-column list-header field-${column}`}>
                    <a className="column-name" onClick={() => this.addOrder(column)}>
                        {order[0] !== null && <span className="sort-order">{order[1]}</span>}
                        {Humanize.capitalize(column.replace(/_/g, ' '))}
                        {order[0] === 'asc' && <i className="fa fa-sort-up"></i>}
                        {order[0] === 'desc' && <i className="fa fa-sort-down"></i>}
                    </a>
                    {order[0] !== null && <i className="remove-order fa fa-times" onClick={() => this.removeOrder(column)}></i>}
                </th>
            })}
            <th>Action</th>
        </tr>;
        // return <tr>
        //     {this.listFields.map(x => <th key={x}>{Humanize.capitalize(x.replace(/_/g, ' '))}</th>)}
        //     <th>Action</th>
        // </tr>;
    }
    customRowAction(item) {
        return []
    }
    renderListRow() {
        let { items, scope } = this.state
        if (scope) {
            items = this.applyScope(scope)
        }

        items = this.applyListOrder(items)

        return items.map((item, key) =>
            <tr key={item.id}>
                {/* <td><CloudImage src={item.image} className="thumbnail" /></td> */}
                {this.listFields.map(x => <td key={x}>{this.fieldData(item, x)}</td>)}
                {/* <td><button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button></td> */}
                <td className="has-text-right">
                    {this.customRowAction(item)}
                    {/* <button className="button is-primary is-small" onClick={() => { this.clone(item) }} >DUPLICATE</button>&nbsp; */}
                    {/* <button className="button is-info is-small" onClick={() => { this.loadViewer(item) }} ><i className="fa fa-eye" /></button> */}
                    {this.canEdit && <button className="button is-warning is-small" onClick={() => { this.loadEditor(item) }} ><i className="fa fa-edit" /></button>}
                    {this.canDelete && <button className="button is-danger is-small" onClick={() => { this.delete(item.id) }} ><i className="fa fa-trash" /></button>}
                </td>
            </tr>
        )
    }

    renderFields(item, is_child = false, readonly = false) {
        let { current_entity } = this.props

        let defaultClass = "column"

        if (typeof item === 'object') {
            if (Array.isArray(item)) {
                return <div className="columns">{item.map(child => this.renderFields(child, true))}</div>
            }
            else {
                let label = item.label || item.name || null;
                let direction = item.direction || "column"
                return <div key={item.name} className={`field-group field-group-${item.name} ${is_child ? "column" : ""}`}>
                    {label && <label className="title is-4">{(label || "")}</label>}

                    {direction !== 'column' && item.fields.map(child => this.renderFields(child))}
                    {direction === 'column' && this.renderFields(item.fields)}

                </div>
            }
        }
        else if (typeof item === 'string') {

            let value = null
            let type = this.entityInfo[item]

            if (item.includes(".")) {
                // Sub object
                try {
                    type = eval('this.entityInfo.' + item)
                }
                catch (error) {
                    type = "String"
                }
            }

            if (type === 'Text' || type === 'ImageFile' || type === 'FileUrl' || type === 'ImageFileUrl') {
                defaultClass = "column"
            }

            try {
                value = eval('current_entity.' + item)
            } catch (error) {

            }

            let field = <div key={item + type} className={(this.props.editFieldsClass && this.props.editFieldsClass[item] ? this.props.editFieldsClass[item] : defaultClass) + " field " + type + " field-" + item}>
                <div className="control">
                    <label className="checkbox">
                        <FieldInput type={type} name={item} value={value} onChange={this.handleEntityChange} onFileChange={this.handleFileChange} />&nbsp;
                        {(item)}
                    </label>
                </div>
            </div>

            if (type && type !== 'Boolean' && type.type !== 'Boolean') {
                field = <div key={item + type} className={(this.props.editFieldsClass && this.props.editFieldsClass[item] ? this.props.editFieldsClass[item] : defaultClass) + " field " + type + " field-" + item}>
                    <label className="label">{Humanize.capitalizeAll(item.replace(/_/g, ' '))}</label>
                    <div className="control">
                        <FieldInput type={type} className="form-control" name={item} value={value} onChange={this.handleEntityChange} onFileChange={this.handleFileChange} dependences={this.props.dependences} />
                    </div>
                </div>
            }

            if (type && type.conditions) {
                let conditions = type.conditions;
                for (let c in conditions) {
                    let condition_value = eval('current_entity.' + c)
                    if (condition_value !== conditions[c]) {
                        field = false;
                    }
                }
            }

            if (is_child === false) {
                return <div className="columns" key={item + type}>{field}</div>
            }
            else {
                return field;
            }
        }
    }

    renderEditor(current_entity) {
        return <div>
            {this.editFields.map((item) => this.renderFields(item))}
            <div className="mt10">
                <button className="button is-primary" onClick={() => this.save(current_entity)} disabled={!this.validate(current_entity)}>SAVE</button>
            </div>
        </div>
    }


    renderViewer(current_entity) {
        if (this.viewFields.length === 0) {
            this.viewFields = this.editFields || this.listFields;
        }

        return <div>
            {this.viewFields.map((item) => this.renderViewerFields(item))}
        </div>

    }

    renderViewerFields(item, is_child = false) {
        let current_entity = this.state.entity
        let value = null
        let field = null

        try {
            value = this.fieldData(current_entity, item)
        } catch (error) {

        }

        if (typeof item === 'object') {
            if (Array.isArray(item)) {
                return <div className="columns">{item.map(child => this.renderViewerFields(child, true))}</div>
            }
            else {
                let label = item.label || item.name || null;
                let direction = item.direction || "column"
                return <div key={item.name} className={`field-group field-group-${item.name} ${is_child ? "column" : ""}`}>
                    {label && <label className="title is-4">{(label || "")}</label>}

                    {direction !== 'column' && item.fields.map(child => this.renderViewerFields(child))}
                    {direction === 'column' && this.renderViewerFields(item.fields)}

                </div>
            }
        }
        else if (typeof item === 'string') {

            field = <div key={item} className="column">
                <label className="label">{Humanize.capitalizeAll(item.replace(/_/g, ' '))}</label>
                <div className="control">
                    {value}
                </div>
            </div>


            if (is_child === false) {
                return <div className="columns" key={item}>{field}</div>
            }
            else {
                return field;
            }
        }
    }

    export() {
        let { items, scope } = this.state
        if (scope) {
            items = this.applyScope(scope)
        }

        items = this.applyListOrder(items)
        var filename = "export.xlsx";
        var data = this.exportFields
        if (items) {
            data = [[...data], ...items.map((row) => {
                let rowData = []
                this.exportFields.forEach(x => rowData.push(this.fieldData(row, x)))
                return rowData
            })]
        }
        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());
    }

    validate() {
        return true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true
    }

    render() {
        if (this.props.current_user === false && !this.props.location.pathname.includes('/admin/tickets')) return <div className="title p20 has-text-centered"></div>;
        // 
        if (this.props.current_user && this.props.owner === false && !this.props.location.pathname.includes('/admin/tickets')) return <div className="title p20 has-text-centered">คุณไม่มีสิทธิในการเข้าถึงข้อมูลส่วนนี้!<br />({this.props.current_user.email})</div>;
        // if (this.props.current_user === false  || !this.props.user_roles || !this.props.user_roles.includes('admin')) return <div className="title p20 has-text-centered">คุณไม่มีสิทธิในการเข้าถึงข้อมูลส่วนนี้</div>;

        return (
            <div className="item-editor m20">
                <h1 className="title mt20">{this.title}</h1>
                {this.state.loading && <div className="notification is-info">
                    <i className="fas fa-chevron-left"></i> Loading....<br />
                    <div className="loading_message">{this.state.loading_message}</div>
                </div>}

                {this.state.error_message && <div className="notification is-danger">
                    <div className="error_message"><SanitizedHTML allowedTags={['br', 'li', 'ul']} html={this.state.error_message} /></div>
                </div>}
                {(this.state.step) === 0 && <div className="step step0">
                    <div className="columns">
                        <div className="column">
                            <div className="buttons is-pulled-right">
                                {this.canCreate && <button className="button is-primary" onClick={() => { this.loadEditor(this.defaultValue) }}><i className="fa fa-plus"></i>&nbsp;เพิ่มใหม่ </button>}
                                {this.customActions}
                                {this.importFields && this.importFields.length > 0 && <button className="button is-primary" onClick={() => { this.setState({ show_import: !this.state.show_import }) }}>นำเข้าข้อมูล</button>}
                                {this.exportFields && this.exportFields.length > 0 && <button className="button is-info" onClick={() => { this.export() }}>ดาวน์โหลด</button>}
                            </div>
                        </div>
                    </div>
                    <div className="columns">

                        <div className="column">
                            <div className="field">
                                <p className="control has-icons-left has-icons-right">
                                    <input type="text" className="input" onChange={(e) => this.setState({ quickSearch: e.currentTarget.value })} />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-search"></i>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            {this.scopeList.length > 0 && <div>
                                <ToggleButton choices={this.scopeList} value={this.state.scope} onChange={scope => { this.setState({ scope }) }} />
                            </div>}
                        </div>
                    </div>

                    {this.state.show_import && !this.state.importing && <div className="import-file-dialog">
                        <label>Please select excel file to import : </label>
                        <input type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
                        {this.state.file !== null && <button className="button is-success is-small" onClick={this.handleFile}>Start</button>}
                        <br />
                        Available Columns: {JSON.stringify(this.importFields)}
                    </div>}
                    {this.state.items === false && <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /></div>}
                    {this.state.items.length === 0 && <div className="has-text-centered p20">
                        <h3 className="subtitle">ยังไม่มีรายการในส่วนนี้</h3>
                        {this.canCreate && <button className="button is-primary" onClick={() => { this.loadEditor(this.defaultValue) }}><i className="fa fa-plus"></i>&nbsp;เพิ่มรายการใหม่</button>}
                        <br /></div>}
                    {this.state.items !== false && this.state.items.length > 0 &&
                        <table className="table is-striped is-fullwidth">
                            <thead>
                                {this.renderListHeader()}
                            </thead>
                            <tbody>
                                {this.renderListRow()}
                            </tbody>

                        </table>
                    }
                </div>}
                {(!this.state.loading && this.state.step) === 1 &&
                    <div className="step step1">
                        <div className="pb10 has-text-left">
                            <button className="button is-warning " onClick={() => { this.props.history.push(`${this.props.location.pathname}`); this.setState({ step: 0 }) }}> <i className="fa fa-chevron-left" aria-hidden="true"></i> &nbsp;ย้อนกลับ </button>
                        </div>
                        {this.state.show === 'editor' && this.renderEditor(this.state.entity)}
                        {this.state.show === 'viewer' && this.renderViewer(this.state.entity)}
                    </div >

                }
            </div>
        )
    }
}

export default BaseEditor