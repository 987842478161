import * as Sentry from '@sentry/browser';
import React from "react"
import { withRouter } from "react-router";
import * as firebase from 'firebase';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as AppConfig from '../AppConfig.json';
import { withCookies } from 'react-cookie';
import { CloudImage } from './CloudImage';
import StoreDayoff from '../models/StoreDayoff';
import moment from 'moment'
import LineAddNotify from './LineAddNotify';
import { sort, isNative, sendRN, mpTrackEvent } from '../Helper';
import { data } from 'jquery';
import * as mixpanel from 'mixpanel-browser';

class StoreList extends React.Component {
    constructor() {
        super()
        this.state = { storeDayOff: {}, loading: true }
        let storeList = ['locall', 'locall.yaowarat', 'locall.nanglinchi', 'locall.talatphlu', 'locall.wangburapa', 'locall.ratchaphruek', 'locall.sutthisan', 'locall.khlongtoei', 'locall.bangsue', 'locall.watdansamrong']
        this.storeList = storeList

        this.updateCurrentLocation = this.updateCurrentLocation.bind(this)
        this.fail = this.fail.bind(this)
        this.sortByDistance = this.sortByDistance.bind(this)

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.updateCurrentLocation, this.fail);
        }
    }

    async componentDidMount() {

        let storeData = this.props.stores_map
        let { current_city } = this.props
        // alert(current_city)
        if (!storeData) {
            var db = firebase.firestore();
            let qs = await db.collection('stores').get();
            qs.forEach(x => {
                let data = x.data();
                data.id = x.id;
                storeData[x.id] = data
            })

        }

        this.setState({ storeData })
        this.storeList = Object.keys(storeData).filter(x => storeData[x].city === current_city)
        let storeDayOff = {}
        for (let s in this.storeList) {
            let store_id = this.storeList[s]
            let store = storeData[store_id]
            if (store.is_active) {
                let day = moment().isoWeekday() % 7;
                storeDayOff[store_id] = (store.openday && !store.openday[day])
                let dayoffSnap = await StoreDayoff.setStore(store_id).getCollection().get()
                let today = moment();
                dayoffSnap.forEach((doc) => {

                    var childKey = doc.id;
                    var childData = doc.data();
                    childData.id = childKey;

                    if (moment(childData.startDate).isSame(today, 'day')) {
                        storeDayOff[store_id] = true;
                    }
                });
            }

        }

        let storeRaining = {}
        // storeRaining['locall'] = true;
        // storeRaining['locall.yaowarat'] = true;

        this.setState({ storeDayOff, storeRaining, loading: false })

        firebase.analytics().logEvent('page_view', { page_title: `Hub List` })
    }

    updateCurrentLocation(currentLocation) {

        this.setState({ currentLocation })
    }

    fail(error) // 'error' can be named anything
    {
        // alert('ระบบไม่สามารถเข้าถึงตำแหน่งของคุณได้ เพื่อความสะดวกในการใช้งานกรุณาเปิด GPS และ ตั้งค่าอนุญาติการเข้าถึงตำแหน่งของคุณ')
        // console.log('failed location')
        // this.updateLocation({ coords: { latitude:13.7649084, longitude: 100.5382846 } })
        this.setState({ currentLocation: { coords: { latitude: 13.7649084, longitude: 100.5382846 } } })
    }

    loadStore(store) {
        if (AppConfig.build === 'locall') {
            mixpanel.track("Select restaurant hub", { "Restaurant Hub": store });
        }

        if (isNative()) {
            sendRN({ action: "loadStore", params: { store } })
        }
        else {
            this.props.setCurrentStore(store)
        }
    }


    sortByDistance(storeList) {
        if (!this.state.storeData || !this.state.currentLocation) return storeList

        for (let x in this.state.storeData) {
            let data = this.state.storeData[x]
            data.dist = 0;
            if (data.branches && data.branches.length > 0) {
                let lat = data.branches[0].lat;
                let lng = data.branches[0].lng;
                let mylat = this.state.currentLocation.coords.latitude
                let mylng = this.state.currentLocation.coords.longitude

                // data.dist = Math.sqrt(Math.pow(Math.abs(lat - mylat), 2) + Math.pow(Math.abs(lng - mylng), 2))
                data.dist = data.sort_order
            }
        }

        return storeList.sort((a, b) => this.state.storeData[a].dist - this.state.storeData[b].dist)

    }

    render() {
        let { storeDayOff, storeRaining, loading, storeData } = this.state
        if (loading || !storeData) return <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /><br />กำลังโหลด Hub</div>
        // this.storeList = this.storeList.sort((a, b) => { console.log(a, b, storeDayOff[a], storeDayOff[b], storeDayOff[a] > storeDayOff[b]); return storeDayOff[a] && storeDayOff[b] ? 0 : storeDayOff[a] ? 1 : -1 })
        // 

        this.storeList = this.sortByDistance(this.storeList)
        return <div>
            {<div className="header-nav">
                <div style={{ padding: '10px' }}><a onClick={() => { this.props.history.push('/'); this.props.setCurrentCity(null) }}><i className="fas fa-chevron-left"></i>&nbsp;เลือกเมืองอื่น</a></div>
            </div>}
            <div className="store-application store-list" style={{ padding: '70px 15px' }}>
                <LineAddNotify lineAccount={AppConfig.lineAccount} />

                <div>
                    <div className="quicksearch front-search" style={{ width: 'calc(100% - 80px)', display: 'inline-block' }}>
                        <input type="text" className="input" value={this.state.query} onChange={(e) => {
                            if (e.target.value.length >= 4) {
                                mpTrackEvent("Search", {
                                    "Search Term": e.target.value,
                                });
                            }

                            this.setState({ query: e.target.value })
                        }} placeholder="ค้นหาจากชื่อร้าน/ชื่ออาหาร" />
                        <i className="fa fa-search" />
                        {this.state.query && <button className="search-reset-button" onClick={(e) => { this.setState({ query: null }) }}><i className="fa fa-times" /></button>}
                    </div>
                    <button className="button is-small is-primary" style={{ width: 70, height: 30, borderRadius: 20, paddingLeft: 5 }} onClick={() => this.props.history.push(`/search/${this.state.query}`)}>ค้นหา</button>
                </div>


                {/* <div><a onClick={() => this.props.setCurrentStore('locall')}><CloudImage src="hubs/locall.jpg" /></a></div>
            <div><a onClick={() => this.props.setCurrentStore('locall.yaowarat')}><CloudImage src="hubs/locall.yaowarat.jpg" /></a></div> */}
                {this.storeList.filter(x => !(storeDayOff[x]) && storeData[x].is_active).map(x => <div key={x} className={`${(storeDayOff[x]) && 'closed'} store-${x} ${(storeRaining[x] || storeData[x].show_raining) && 'raining'}`}>
                    <a onClick={() => this.loadStore(x)}>
                        <CloudImage src={storeData[x].image || `hubs/${x}.jpg`} />
                    </a>
                </div>)}
                {this.storeList.filter(x => (storeDayOff[x]) && storeData[x].is_active).map(x => <div key={x} className={`${(storeDayOff[x]) && 'closed'} store-${x} ${(storeRaining[x] || storeData[x].show_raining) && 'raining'}`}>
                    <a onClick={() => this.loadStore(x)}>
                        <CloudImage src={storeData[x].image || `hubs/${x}.jpg`} />
                    </a>
                </div>)}
            </div>
        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store, app_params, stores_map, current_city }) => ({ current_entity, current_store, app_params, stores_map, current_city });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(StoreList)))
