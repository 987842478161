export default function current_entity(state = null, action) {
    switch (action.type) {
        case 'CURRENT_ENTITY_SET':
            // console.log('entity, set', action.payload)
            return action.payload
        case 'CURRENT_ENTITY_HANDLE_CHANGE':
            /// Don't know whey but you cannot just set state.name and return / It happen with only "name" property
            let newState = { ...state }
            if (state != null && action.payload.hasOwnProperty('target')) {
                let target = action.payload.target
                let name = target.name
                let value = target.value
                let type = target.type
                if (type === 'checkbox') {
                    value = eval(`!newState.${name}`)
                }
                eval(`newState.${name} = value`)
            }

            return newState
        default:
            return state
    }
} 