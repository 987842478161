import * as Sentry from '@sentry/browser';
import * as AppConfig from '../AppConfig.json';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SanitizedHTML from 'react-sanitized-html';

import moment from 'moment'
import XLSX from 'xlsx';

import * as firebase from 'firebase';

import * as actions from '../actions/index'
import { CloudImage } from './CloudImage';
import StoreProduct from '../models/StoreProduct'
import StoreOrder from '../models/StoreOrder'
import StoreTicket from '../models/StoreTicket'
import StoreDriver from '../models/StoreDriver'
import HubStoreProduct from '../models/HubStoreProduct'
import ModalDialog from './ModalDialog'
import Select from 'react-select'
import { addToCart, getCartItemPrice, getTotalPrice, sort } from '../Helper';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from 'date-fns/locale/th';
registerLocale('th', th)

// const databaseRef = firebase.database().ref();
// const richmenuRef = databaseRef.child("richmenus")
var db = firebase.firestore();
var storageRef = firebase.storage().ref();

const defaultDesc = { name: "New richmenu", chatBarText: "Tap to open", startDate: moment().format('YYYY-MM-DD'), endDate: null, startTime: null, endTime: null };

class OrderEditor extends Component {
    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            loading_message: "",
            error_message: "",
            step: 0,
            items: [],
            filteredItems: [],
            filter: "active",

            fullscreenImage: "",

            currentItem: defaultDesc,
            selectedItem: [],
            startDate: moment().subtract(10, 'days').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
        }

        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
        this.loadEditor = this.loadEditor.bind(this)
        this.applyFilter = this.applyFilter.bind(this)

        this.save = this.save.bind(this)
        this.upload = this.upload.bind(this)
        this.setItem = this.setItem.bind(this)
        this.toggleActive = this.toggleActive.bind(this)
        this.delete = this.delete.bind(this)
        this.export = this.export.bind(this);
        // this.saveToLine = this.saveToLine.bind(this)
        // this.uploadToLine = this.uploadToLine.bind(this)

        this.setPaid = this.setPaid.bind(this)

        this.showAssignDriver = this.showAssignDriver.bind(this)

        this.selectDriver = this.selectDriver.bind(this)
        this.saveDriver = this.saveDriver.bind(this)
        this.getDriverData = this.getDriverData.bind(this)
        this.createCartTicket = this.createCartTicket.bind(this)
        this.subscribeData = this.subscribeData.bind(this)
    }

    componentDidMount() {
        this.dataRef = StoreOrder.getCollection(this.props.current_store);
        this.ticketRef = StoreTicket.getCollection(this.props.current_store);

        StoreProduct.setStore(this.props.current_store).getAll().then(products => this.setState({ products }))
        StoreProduct.setStore(this.props.current_store).getAllById().then(productsById => this.setState({ productsById }))
        StoreDriver.setStore(this.props.current_store).getAll().then(drivers => this.setState({ drivers }))
        HubStoreProduct.setStore(this.props.current_store).getAllById().then(hubStoresById => this.setState({ hubStoresById }))

        this.subscribeData()

        // Later ...

        // Stop listening to changes



        // this.reloadList();
        // this.processRichMenuObject(this.state.layouts[0]);
        // this.setState({items: })


    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    subscribeData() {

        let { startDate, endDate } = this.state
        this.unsubscribe && this.unsubscribe();
        this.unsubscribe = this.dataRef//.where('status', 'in', ['submitted', 'paid', 'ontheway', 'delivered', 'cancel'])
            .where('deliverDate', '>=', moment(startDate).format('YYYY-MM-DD'))// moment().subtract(10, 'days').format('YYYY-MM-DD'))
            .where('deliverDate', '<=', moment(endDate).format('YYYY-MM-DD'))// moment().format('YYYY-MM-DD'))
            .onSnapshot((querySnapshot) => {
                var children = [];
                querySnapshot.forEach((doc) => {
                    var childKey = doc.id;
                    var childData = doc.data();
                    childData.id = childKey;
                    children.push(childData)
                });
                this.setState({ items: children }, () => {
                    this.applyFilter(this.state.filter)

                    if (this.props.location.search) {
                        for (let c in children) {
                            if (children[c].id === this.props.location.search.replace("?", "") && (!this.state.currentItem || this.state.currentItem && this.state.currentItem.id != children[c].id)) {
                                this.loadEditor(children[c])
                            }
                        }
                    }
                })
            });
    }

    reloadList() {
        let $this = this;

        this.dataRef.get().then((querySnapshot) => {
            var children = [];
            querySnapshot.forEach((doc) => {

                var childKey = doc.id;
                var childData = doc.data();
                childData.id = childKey;
                children.push(childData)
                console.log(doc.id, childData);
            });
            $this.setState({ items: children }, () =>
                $this.applyFilter($this.state.filter)
            )
        });
    }


    async exportCustomer() {
        this.setState({ loading: true })
        let qs = await this.dataRef.where("status", "==", "delivered").get()
        var list = []
        qs.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id
            list.push(data)
        });

        // console.log(list)

        list = list.reduce((val, row) => {
            let tel = row.customer.tel.replace(/[^0-9]/g, "")

            if (!val[tel]) val[tel] = row
            val[tel].sum = (val[tel].sum || 0) + row.totalPrice

            return val
        }, {})

        var filename = "exportCustomer.xlsx";
        var data = [["Name", "Tel", "Address", "Total"]];
        if (list) {
            for (let c in list) {
                let item = list[c]

                console.log("cc", item)
                data.push([item.customer.name, item.customer.tel, item.deliverAddress && item.deliverAddress.address, item.sum])
            }
        }
        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());

        this.setState({ loading: false })
    }

    renderReceiptByStore(order) {
        let sortedData = sort(order.carts, [{ 'store_id': 'asc' }], (item, field) => item.product.store_id)
        let html = ""
        let store = null
        let { hubStoresById } = this.state
        let storeHtml = ""

        sortedData.forEach(item => {
            if (item.product.store_id !== store) {
                if (storeHtml != "") {
                    storeHtml += `ส่งเวลา : ${moment(order.deliverDate).format('DD/MM/YYYY')} เวลา ${order.deliverTime} น.<br />`
                    if (order.timestamp && order.timestamp.paid) storeHtml += `ชำระเงินเมื่อ : ${moment(order.timestamp.paid.toDate()).format('DD/MM/YYYY เวลา HH:mm น.')}<br />`
                    storeHtml += `<br /><br /></div><p style="page-break-after: always;"></p>`;
                    html += storeHtml + storeHtml
                }
                storeHtml = ""
                store = item.product.store_id
                storeHtml += '<div style="width: 8cm; font: 16px sans-serif">'
                storeHtml += `<b>ส่วนสำหรับร้านค้า<br />#${order.order_no}</b><br />`
                storeHtml += `<b>${hubStoresById[item.product.store_id].name}</b><br />`
            }

            storeHtml += `${item.quantity} x ${item.product.name}<br />`
            if (item.option && item.option.name) storeHtml += `&nbsp;&nbsp;&nbsp;&nbsp; - ${item.option.name}<br />`
            if (item.extras) storeHtml += `${Object.keys(item.extras).map((name) => { return item.extras[name].length === 0 ? '' : `&nbsp;&nbsp;&nbsp;&nbsp;${name}: ${item.extras[name].map(x => x.name).join(',')}<br />` }).join('')}`

            if (item.note) storeHtml += `&nbsp;&nbsp;&nbsp;&nbsp;${item.note}<br />`
            let price = getCartItemPrice(item)
            storeHtml += `&nbsp;&nbsp;&nbsp;&nbsp;ราคา ${price * item.quantity} บาท<br />`
        })

        if (storeHtml != "") {
            storeHtml += `ส่งเวลา : ${moment(order.deliverDate).format('DD/MM/YYYY')} เวลา ${order.deliverTime} น.<br />`
            if (order.timestamp && order.timestamp.paid) storeHtml += `ชำระเงินเมื่อ : ${moment(order.timestamp.paid.toDate()).format('DD/MM/YYYY เวลา HH:mm น.')} <br />`
            storeHtml += `<br /><br /></div><p style="page-break-after: always;"></p>`;
            html += storeHtml + storeHtml
        }

        return html
    }

    printReceipt(order) {
        var win = window.open("", "Print", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=320,height=600");
        let html = '<div style="width: 8cm; font: 16px sans-serif">'
        html += `<b>รายการออเดอร์<br />#${order.order_no}</b><br />`
        html += `<br />`
        order.carts.forEach(item => {
            html += `${item.quantity} x ${item.product.name}<br />`
            if (item.option && item.option.name) html += `&nbsp;&nbsp;&nbsp;&nbsp; - ${item.option.name}<br />`
            if (item.extras) html += `${Object.keys(item.extras).map((name) => { return item.extras[name].length === 0 ? '' : `&nbsp;&nbsp;&nbsp;&nbsp;${name}: ${item.extras[name].map(x => x.name).join(',')}<br />` }).join('')}`
            if (item.note) html += `&nbsp;&nbsp;&nbsp;&nbsp;${item.note}<br />`
        })
        html += `ยอดรวม ${order.sumPrice + order.deliverCost} บาท<br />`
        html += `${order.paymentMethod === 'cod' ? 'ชำระเงินปลายทาง' : order.paymentMethod === 'transfer' ? 'โอนเงิน' : order.paymentMethod === 'linepay' ? 'Rabbit Line Pay' : '-'}<br />`
        if (order.coupon_code) {
            html += `คูปอง: ${order.coupon_code}<br />`
        }
        html += `<br />`
        html += `<div>${order.utensils !== true ? '** ไม่รับช้อนส้อม' : '** รับช้อนส้อม'}</div>`
        html += `ลูกค้า : <b>${order.customer.name}</b><br />โทร: <b>${order.customer.tel}</b><br />`
        if (order.deliverType === 'pickup') {
            html += `มารับเอง<br />`
        }
        else if (order.deliverType === 'donate') {
            html += `บริจาคอาหารให้ผู้ที่ต้องการ<br />`
        }
        else {
            html += `${order.deliverAddress.address}<br />`
        }
        html += `ส่งเวลา : ${moment(order.deliverDate).format('DD/MM/YYYY')} เวลา ${order.deliverTime} น.<br />`
        if (order.timestamp && order.timestamp.paid) html += `ชำระเงินเมื่อ : ${moment(order.timestamp.paid.toDate()).format('DD/MM/YYYY เวลา HH:mm น.')} <br />`
        html += `${order.deliverRemark ? "*** " + order.deliverRemark : "  "}<br />`
        html += `<br /><br /></div><p style="page-break-after: always;"></p>`

        html += html;

        html += this.renderReceiptByStore(order)


        html += '<div style="width: 8cm; font: 16px sans-serif">'
        html += `<b>ส่วนสำหรับคนขับ<br />#${order.order_no}</b><br />`
        // html += `<hr />`
        // order.carts.forEach(item => {
        //     html += `${item.quantity} X ${item.product.name}`
        //     html += `${(item.option && item.option.name) ? ` - ${item.option.name}` : ''}<br />`
        // })
        // html += `<hr />`
        // html += `ยอดรวม ${order.sumPrice + order.deliverCost} บาท<br />`
        // html += `${order.paymentMethod === 'cod' ? 'ชำระเงินปลายทาง' : order.paymentMethod === 'transfer' ? 'โอนเงิน' : order.paymentMethod === 'linepay' ? 'Rabbit Line Pay' : '-'}<br />`
        // if (order.coupon_code) {
        //     html += `คูปอง: ${order.coupon_code}<br />`
        // }
        // html += `<hr />`
        html += `${order.customer.name} ${order.customer.tel}<br />`
        if (order.deliverType === 'pickup') {
            html += `มารับเอง<br />`
        }
        else if (order.deliverType === 'donate') {
            html += `บริจาคอาหารให้ผู้ที่ต้องการ<br />`
        }
        else {
            html += `${order.deliverAddress.address}<br /><img src="https://chart.googleapis.com/chart?chs=200x200&chld=Q|1&cht=qr&chl=${encodeURIComponent(`http://maps.google.com/maps?daddr=${order.markerPosition.lat},${order.markerPosition.lng}&amp;ll=`)}&choe=UTF-8" style="width:60%" /><br />`
            html += `ค่าส่ง : ${Math.ceil(30 + (7 * ((order.distance - 3) > 0 ? (order.distance - 3) : 0)))} บาท<br />`
        }
        html += `ส่งเวลา : ${moment(order.deliverDate).format('DD/MM/YYYY')} เวลา ${order.deliverTime} น.<br />`
        html += `${order.deliverRemark ? "*** " + order.deliverRemark : "  "}<br />`
        html += `<br /><br /></div>`
        win.document.body.innerHTML = html
        if (order.deliverType === 'pickup') {
            win.print()
        }
        else {
            let img = new Image()
            img.onload = () => { win.print() }
            img.src = `https://chart.googleapis.com/chart?chs=200x200&chld=Q|1&cht=qr&chl=${encodeURIComponent(`http://maps.google.com/maps?daddr=${order.markerPosition.lat},${order.markerPosition.lng}&amp;ll=`)}&choe=UTF-8`
        }
    }

    export() {
        var filename = "exportOrder.xlsx";
        var data = ["NO", "Name", "Tel", "Total", "Status", "Address", "Payment", "Submit Date", "Deliver Date", "TimePaid", "TimePaid[HH:mm]", "TimeOnTheWay", "TimeOnTheWay[HH:mm]", "TimeDelivered", "TimeDelivered[HH:mm]"];
        if (this.state.filteredItems) {
            data = [[...data], ...this.state.filteredItems.map((item, key) => [item.id, item.customer.name, item.customer.tel, item.totalPrice, item.status.humanize, item.deliverAddress.address, item.paymentMethod, item.orderDate && item.orderDate.toString(), item.deliverDate && item.deliverDate.toString(),
            item.timestamp && item.timestamp.paid && item.timestamp.paid.toDate().toString(),
            item.timestamp && item.timestamp.paid && moment(item.timestamp.paid.toDate()).format("HH:mm"),
            item.timestamp && item.timestamp.ontheway && item.timestamp.ontheway.toDate().toString(),
            item.timestamp && item.timestamp.ontheway && moment(item.timestamp.ontheway.toDate()).format("HH:mm"),
            item.timestamp && item.timestamp.delivered && item.timestamp.delivered.toDate().toString(),
            item.timestamp && item.timestamp.delivered && moment(item.timestamp.delivered.toDate()).format("HH:mm")])]
        }
        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());
    }

    exportByProduct() {
        let { hubStoresById } = this.state
        let { current_store } = this.props
        var filename = "exportOrderByProduct.xlsx";
        var data = []
        if (this.state.filteredItems) {
            data.push(["HUB", "NO", "Name", "Tel", "Order Reference Note", "Remark", "Total", "Status", "Address", "Payment", "Submit Date", "Deliver Date", "Product", "Quantity", "Price", "Total Price", "Cost", "Total Cost", "Deliver Price", "Ref. Note", "Store", "Coupon", "Subdistrict", "District", "Province", "TimePaid", "TimeOnTheWay", "TimeDelivered"])
            for (let i in this.state.filteredItems) {
                let item = this.state.filteredItems[i];
                for (let c in item.carts) {
                    let cart_item = item.carts[c]
                    let name = cart_item.product.name
                    let price = getCartItemPrice(cart_item)
                    let cost = cart_item.product.cost
                    if (cart_item.option) {
                        name += ` - ${cart_item.option.name}`;
                        if (cart_item.option.cost) {
                            cost += cart_item.option.cost;
                        }
                        else {
                            for (let o in cart_item.product.options) {
                                if (cart_item.product.options[o].name === cart_item.option.name && cart_item.product.options[o].cost) {
                                    cost += cart_item.product.options[o].cost;
                                }
                            }
                        }
                    }

                    let totalPrice = price * cart_item.quantity
                    let totalCost = cost * cart_item.quantity

                    if (!item.cancel && !cart_item.cancel) {
                        data.push([current_store, item.id, item.customer.name, item.customer.tel, item.refnote, item.remarknote, item.totalPrice, item.status.humanize, item.deliverAddress.address, item.paymentMethod, item.orderDate && item.orderDate.toString(), item.deliverDate && item.deliverDate.toString(), name, cart_item.quantity, price, totalPrice, cost, totalCost, '', cart_item.product.refnote, hubStoresById[cart_item.product.store_id].name, item.coupon_code, item.deliverAddress.city, item.deliverAddress.area, item.deliverAddress.state, item.timestamp.paid && item.timestamp.paid.toDate().toString(), item.timestamp.ontheway && item.timestamp.ontheway.toDate().toString(), item.timestamp.delivered && item.timestamp.delivered.toDate().toString()])
                    }
                }
                data.push([current_store, item.id, item.customer.name, item.customer.tel, item.refnote, item.remarknote, item.totalPrice, item.status.humanize, item.deliverAddress.address, item.paymentMethod, item.orderDate && item.orderDate.toString(), item.deliverDate && item.deliverDate.toString(), 'ค่าส่ง', '', '', '', '', '', item.deliverCost, '', '', '', item.coupon_code, item.deliverAddress.city, item.deliverAddress.area, item.deliverAddress.state, item.timestamp.paid && item.timestamp.paid.toDate().toString(), item.timestamp.ontheway && item.timestamp.ontheway.toDate().toString(), item.timestamp.delivered && item.timestamp.delivered.toDate().toString()])
            }
        }
        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());
    }

    exportByCoupon() {
        let { current_store } = this.props
        var filename = "exportOrderByCoupon.xlsx";
        var data = []
        if (this.state.filteredItems) {

            data = this.state.filteredItems.filter(x => x.coupon_code).map((item, key) => [item.id, current_store, item.coupon_code, item.customer.name, item.customer.tel, item.totalPrice, item.status.humanize, item.deliverAddress.address, item.paymentMethod, item.orderDate && item.orderDate.toString(), item.deliverDate && item.deliverDate.toString(),
            item.timestamp && item.timestamp.paid && item.timestamp.paid.toDate().toString(),
            item.timestamp && item.timestamp.paid && moment(item.timestamp.paid.toDate()).format("HH:mm"),
            item.timestamp && item.timestamp.ontheway && item.timestamp.ontheway.toDate().toString(),
            item.timestamp && item.timestamp.ontheway && moment(item.timestamp.ontheway.toDate()).format("HH:mm"),
            item.timestamp && item.timestamp.delivered && item.timestamp.delivered.toDate().toString(),
            item.timestamp && item.timestamp.delivered && moment(item.timestamp.delivered.toDate()).format("HH:mm")])
        }

        var exportdata = [["NO", "HUB", "Coupon", "Name", "Tel", "Discount", "Total", "Count", "Address", "Payment", "Submit Date", "Deliver Date", "TimePaid", "TimePaid[HH:mm]", "TimeOnTheWay", "TimeOnTheWay[HH:mm]", "TimeDelivered", "TimeDelivered[HH:mm]"]]

        let sortedData = sort(data, [{ 'coupon_code': 'asc' }], (item, field) => item[2])
        console.log(data)
        let currentStore = null
        let totalPrice = 0
        let totalDiscount = 0
        let count = 0
        for (let d in sortedData) {
            let store = sortedData[d][2]
            if (currentStore === null || currentStore !== store) {
                if (currentStore !== null) {
                    exportdata.push(['', '', '', '', totalDiscount, totalPrice, count])
                }

                totalPrice = 0
                totalDiscount = 0
                count = 0
                currentStore = store
            }
            totalDiscount += sortedData[d][5]
            totalPrice += sortedData[d][6]
            count++
            exportdata.push(sortedData[d])
        }

        if (currentStore !== null) {
            exportdata.push(['', '', '', '', totalDiscount, totalPrice, count])
        }
        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(exportdata);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());
    }

    exportByStore() {
        let { hubStoresById } = this.state
        let { current_store } = this.props
        var filename = "exportOrderByStore.xlsx";
        var data = []
        if (this.state.filteredItems) {

            let items = this.state.filteredItems
            for (let i in items) {
                let item = items[i];
                for (let c in item.carts) {
                    let cart_item = item.carts[c]
                    let name = cart_item.product.name
                    let price = getCartItemPrice(cart_item)
                    let cost = cart_item.product.cost
                    if (cart_item.option) {
                        name += ` - ${cart_item.option.name}`;
                        if (cart_item.option.cost) {
                            cost += cart_item.option.cost;
                        }
                        else {
                            for (let o in cart_item.product.options) {
                                if (cart_item.product.options[o].name === cart_item.option.name && cart_item.product.options[o].cost) {
                                    cost += cart_item.product.options[o].cost;
                                }
                            }
                        }
                    }

                    let totalPrice = price * cart_item.quantity
                    let totalCost = cost * cart_item.quantity

                    if (!item.cancel && !cart_item.cancel) {
                        data.push([current_store, hubStoresById[cart_item.product.store_id].name, item.paymentMethod, item.orderDate && item.orderDate.toString(), item.deliverDate && item.deliverDate.toString(), name, cart_item.quantity, price, totalPrice, cost, totalCost])
                    }
                }

            }
        }

        let sortedData = sort(data, [{ 'store_id': 'asc' }], (item, field) => item[1])
        console.log(data)

        data = [["HUB", "รหัสร้าน", "Store", "Payment", "Submit Date", "Deliver Date", "Product", "Quantity", "Price", "Total Price", "Cost", "Total Cost"]]
        let currentStore = null
        let totalPrice = 0
        let totalCost = 0
        for (let d in sortedData) {
            let store = sortedData[d][1]
            if (currentStore === null || currentStore != store) {
                if (currentStore !== null) {
                    data.push(['', '', '', '', '', '', '', '', totalPrice, '', totalCost])
                }

                totalPrice = 0
                totalCost = 0
                currentStore = store
            }
            totalPrice += sortedData[d][8]
            totalCost += sortedData[d][10]
            data.push(sortedData[d])
        }

        if (currentStore !== null) {
            data.push(['', '', '', '', '', '', '', '', totalPrice, '', totalCost])
        }


        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());
    }

    exportByDriver() {
        let { current_store } = this.props
        var filename = "exportOrderByDriver.xlsx";
        var data = []
        if (this.state.filteredItems) {

            data = this.state.filteredItems.filter(x => x.driver_id).map((item, key) => [item.id, current_store, this.getDriverData(item.driver_id).name, item.customer.name, item.customer.tel, item.totalPrice, item.deliverCost, item.status.humanize, item.deliverAddress.address, item.paymentMethod, item.orderDate && item.orderDate.toString(), item.deliverDate && item.deliverDate.toString(),
            item.timestamp && item.timestamp.paid && item.timestamp.paid.toDate().toString(),
            item.timestamp && item.timestamp.paid && moment(item.timestamp.paid.toDate()).format("HH:mm"),
            item.timestamp && item.timestamp.ontheway && item.timestamp.ontheway.toDate().toString(),
            item.timestamp && item.timestamp.ontheway && moment(item.timestamp.ontheway.toDate()).format("HH:mm"),
            item.timestamp && item.timestamp.delivered && item.timestamp.delivered.toDate().toString(),
            item.timestamp && item.timestamp.delivered && moment(item.timestamp.delivered.toDate()).format("HH:mm")])
        }

        var exportdata = [["NO", "HUB", "Driver", "Name", "Tel", "Discount", "Total", "Deliver Cost", "Count", "Address", "Payment", "Submit Date", "Deliver Date", "TimePaid", "TimePaid[HH:mm]", "TimeOnTheWay", "TimeOnTheWay[HH:mm]", "TimeDelivered", "TimeDelivered[HH:mm]"]]

        let sortedData = sort(data, [{ 'driver_id': 'asc' }], (item, field) => item[2])
        console.log(data)
        let currentStore = null
        let totalPrice = 0
        let totalDiscount = 0
        let totalDeliverCost = 0
        let count = 0
        for (let d in sortedData) {
            let store = sortedData[d][2]
            if (currentStore === null || currentStore !== store) {
                if (currentStore !== null) {
                    exportdata.push(['', '', '', '', totalDiscount, totalPrice, totalDeliverCost, count])
                }

                totalPrice = 0
                totalDiscount = 0
                totalDeliverCost = 0
                count = 0
                currentStore = store
            }
            totalDiscount += sortedData[d][5]
            totalPrice += sortedData[d][6]
            totalDeliverCost += sortedData[d][7]
            count++
            exportdata.push(sortedData[d])
        }

        if (currentStore !== null) {
            exportdata.push(['', '', '', '', totalDiscount, totalPrice, totalDeliverCost, count])
        }
        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(exportdata);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());
    }


    setItem(item, value) {
        let $this = this;

        this.dataRef.doc(item.id).set(value, { merge: true }).then(function () {
            // $this.reloadList();
            $this.setState({
                loading: false,
            })
        }).catch(function (error) {
            console.error("Error removing document: ", error);
            $this.setState({
                loading: false,
                error_message: error
            })
        });


    }

    toggleActive(item_id, active) {
        let $this = this;

        this.dataRef.doc(item_id).update({ is_active: active }).then(function () {
            // $this.reloadList();
            $this.setState({
                loading: false,
            })
        }).catch(function (error) {
            console.error("Error removing document: ", error);
            $this.setState({
                loading: false,
                error_message: error
            })
        });


    }


    delete(item_id) {
        if (!window.confirm("แน่ใจหรือไม่่ว่าจะลบรายการนี้")) return
        let $this = this;

        this.setState({
            loading: true,
            loading_message: "Delete menu ...."
        })


        this.dataRef.doc(item_id).delete().then(function () {
            // $this.reloadList();
            $this.setState({
                loading: false,
            })
        }).catch(function (error) {
            console.error("Error removing document: ", error);
            $this.setState({
                loading: false,
                error_message: error
            })
        });
    }

    loadEditor = (item) => {
        console.log("Loading", item)
        this.setState({
            loading: true,
            loading_message: "Loading menu....",
            file: null
        })

        let $this = this;

        if (item.image) {
            storageRef.child(item.image).getDownloadURL().then(function (url) {
                $this.props.history.push(`/${$this.props.current_store}/admin/orders?${item.id}`)
                $this.setState({ fileObjURL: url, currentItem: item, step: 1, loading: false })

            }).catch(function (error) {
                console.error(error)
                // Handle any errors
                $this.setState({ loading: false })
            });
        }
        else {
            this.props.history.push(`/${this.props.current_store}/admin/orders?${item.id}`)
            this.setState({ fileObjURL: null, currentItem: item, step: 1, loading: false })
        }

    }

    cancelCartItem(item) {
        if (!window.confirm("การเปลี่ยนแปลงนี้ไม่สามารถย้อนคืนได้ แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return;
        item.cancel = true;
        let price = item.quantity * getCartItemPrice(item)
        this.state.currentItem.totalPrice -= price
        this.save(null, false)
        this.forceUpdate();
    }

    save = (item_desc = null, exit) => {
        // Save data to fire store
        let $this = this;

        if (item_desc === null) {
            item_desc = this.state.currentItem;
        }

        this.setState({ loading: true, loading_message: "Saving item...." })
        if (this.state.currentItem.id) {
            var itemRef = this.dataRef.doc(this.state.currentItem.id);

            itemRef.update(item_desc)
                .then(function () {
                    if ($this.state.file) {
                        $this.upload($this.state.currentItem.id, $this.state.file.type, $this.state.base64Image)
                    }
                    else {
                        if (exit !== false) {
                            $this.setState({ loading: false, step: 0 })
                        }
                        else {
                            $this.setState({ loading: false })
                        }
                        // $this.reloadList();
                    }
                })
                .catch(function (error) {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                    $this.setState({ loading: true, error_message: error })
                });
        }
        else {
            this.dataRef.add(item_desc)
                .then(function (docRef) {
                    if ($this.state.file) {
                        $this.upload(docRef.id, $this.state.file.type, $this.state.base64Image)
                    }
                    else {

                        if (exit !== false) {
                            $this.setState({ loading: false, step: 0 })
                        }
                        else {
                            $this.setState({ loading: false })
                        }
                        // $this.reloadList();
                    }
                })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                    $this.setState({ loading: false, error_message: error })
                });
        }
    };

    upload = (itemId, type, base64str) => {
        let $this = this;
        var filename = 'ordering/' + itemId + '.' + type.replace('image/', '');
        var fileRef = storageRef.child(filename);
        this.setState({
            loading: true,
            loading_message: "Start upload item image to database...."
        })

        fileRef.putString(base64str, 'base64').then(function (snapshot) {
            var itemRef = $this.dataRef.doc(itemId);
            $this.setState({
                loading: true,
                loading_message: "Update item image infomation to database...."
            })

            itemRef.update({ image: filename }).then(function () {
                $this.setState({ loading: false, step: 0 })
                // $this.reloadList();
            })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                    $this.setState({ loading: false, error_message: error })
                });
        });
    };

    applyFilter(filter) {
        let filteredItems = []
        if (filter) {
            filteredItems = this.state.items.filter(item => {
                if (filter === 'active' && ['submitted', 'paid', 'ontheway'].includes(item.status))
                    return true
                else
                    return item.status === filter
            })

        }
        else {
            filteredItems = this.state.items
        }

        filteredItems = filteredItems.sort((a, b) => {
            if (a.orderDate === null && b.orderDate === null) {
                return 0;
            }
            if (a.orderDate === null) {
                return 1;
            }
            if (b.orderDate === null) {
                return -1;
            }
            else {

            }


            return moment(b.orderDate) - moment(a.orderDate)
        })

        filteredItems.sort((a, b) => {
            if (b.deliverDate === a.deliverDate) {
                return b.deliverTime.localeCompare(a.deliverTime)
            }
            return moment(b.deliverDate) - moment(a.deliverDate);
        })
        this.setState({ filter, filteredItems })
    }

    handleFileChange(event) {
        let $this = this;
        // console.log(event.target.files[0])
        // console.log(URL.createObjectURL(event.target.files[0]))
        this.setState({
            file: event.target.files[0],
            fileObjURL: URL.createObjectURL(event.target.files[0])
        })

        var fr = new FileReader();
        fr.onload = function (e) {
            let base64Image = this.result.replace(/^data:image\/(png|jpeg);base64,/, "");
            $this.setState({ 'base64Image': base64Image })
            console.log($this.state);
        };
        fr.readAsDataURL(event.target.files[0]);
    }

    handleDescriptionChange = (e, name, value) => {
        let updateState = this.state.currentItem;
        if (!e) {
            eval(`updateState.${name} = value`)
        }
        else {
            if (e.target.type === "number") {
                eval(`updateState.${e.target.name} = Number.parseFloat(e.target.value)`)
            }
            else {
                eval(`updateState.${e.target.name} = e.target.value`)
            }

        }
        this.setState({ currentItem: updateState })
    }

    toggleSelectAll(select) {
        let selectedItem = []

        if (select) {
            for (let i in this.state.filteredItems) {
                selectedItem.push(this.state.filteredItems[i].id)
            }
        }

        for (let i in this.state.filteredItems) {
            this.state.filteredItems[i].selected = select
        }

        this.setState({ selectedItem })
        this.forceUpdate();
    }

    toggleSelect(select, item) {
        let { selectedItem } = this.state

        item.selected = select
        if (select) {
            selectedItem.push(item.id)
        }
        else {
            selectedItem.splice(selectedItem.indexOf(item.id), 1)
        }

        this.setState({ selectedItem })
        this.forceUpdate();
    }

    createOrderTicket() {
        let ticketedItem = {}
        let items = this.state.filteredItems
        var batch = db.batch();

        for (let i in items) {
            if (items[i].selected && !items[i].ticketed) {
                let carts = items[i].carts;
                for (let c in carts) {
                    let refnote = carts[c].product.refnote;
                    if (!ticketedItem[refnote]) {
                        ticketedItem[refnote] = []
                    }
                    carts[c].order_id = items[i].id;
                    carts[c].customer_name = items[i].customer.name;
                    carts[c].customer_tel = items[i].customer.tel;
                    ticketedItem[refnote].push(carts[c])
                }
                var sfRef = this.dataRef.doc(items[i].id);
                batch.update(sfRef, { ticketed: new Date() })
            }
        }

        for (var t in ticketedItem) {
            this.ticketRef.add({ store: t, carts: ticketedItem[t], status: 'pending', created_at: new Date() })
        }
        batch.commit()
        console.log(ticketedItem)

    }

    addCart() {
        let { currentItem, productsById, addSelectedProduct, addSelectedOption, addSelectedExtras, addSelectedQuantity } = this.state
        // let newItem = {
        //     product: productsById[addSelectedProduct],
        //     option: addSelectedOption && productsById[addSelectedProduct].options[addSelectedOption],
        //     quantity: 1,
        //     add_by_admin: true
        // }
        let product = productsById[addSelectedProduct]
        let option = addSelectedOption && productsById[addSelectedProduct].options[addSelectedOption]
        let extras = addSelectedExtras
        let quantity = addSelectedQuantity || 1
        currentItem.carts = addToCart(currentItem.carts, product, quantity, option, "", extras, true);
        currentItem.totalPrice = getTotalPrice(currentItem)
        // currentItem.totalPrice += newItem.product.price
        // if (addSelectedOption && productsById[addSelectedProduct].options[addSelectedOption]) {
        //     currentItem.totalPrice += newItem.option.price
        // }

        // currentItem.carts.push(newItem)

        this.forceUpdate()
    }

    async createCartTicket(item) {
        // let ticketedItem = {}
        // let items = this.state.filteredItems
        let { hubStoresById } = this.state
        var batch = db.batch();

        // for (let i in items) {
        //     if (items[i].selected && !items[i].ticketed) {
        let carts = item.carts;
        for (let c in carts) {
            let newDoc = await this.ticketRef.add({ store_id: carts[c].product.store_id, product: carts[c].product, cart_info: carts[c], quantity: carts[c].quantity, order_id: item.id, status: 'pending', created_at: new Date() })
            // let refnote = carts[c].product.refnote;
            // if (!ticketedItem[refnote]) {
            //     ticketedItem[refnote] = []
            // }
            // carts[c].order_id = items[i].id;
            // carts[c].customer_name = items[i].customer.name;
            // carts[c].customer_tel = items[i].customer.tel;
            // ticketedItem[refnote].push(carts[c])
            carts[c].ticket_id = newDoc.id
            console.log(carts[c].product.store_id)
            let token = hubStoresById[carts[c].product.store_id].lnt_access_token
            console.log(token)
            if (token) {
                this.lineHubStoreNotify(token, `รายการสั่งอาหารใหม่ \n
                    #${item.order_no}\n
                    ${carts[c].product.name}\n
                    ${carts[c].option ? carts[c].option.name : ''} \n
                    ${Object.keys(carts[c].extras).map((name) => { return carts[c].extras[name].length === 0 ? '' : `&nbsp;&nbsp;&nbsp;&nbsp;${name}: ${carts[c].extras[name].map(x => x.name).join(',')}\n` }).join('')}
                    ${carts[c].note && carts[c].note} \n
                    จำนวน: ${carts[c].quantity}\n
                    ${AppConfig.endpoint}${this.props.current_store}/admin/tickets?${newDoc.id}`)
            }
        }
        var sfRef = this.dataRef.doc(item.id);
        batch.update(sfRef, { ticketed: new Date(), carts })
        // }
        // }

        // for (var t in ticketedItem) {
        //     this.ticketRef.add({ store: t, carts: ticketedItem[t], status: 'pending', created_at: new Date() })
        // }
        batch.commit()
        // console.log(ticketedItem)

    }


    setPaid(item) {
        if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return;
        this.setItem(item, { status: 'paid', timestamp: { paid: new Date() } })
        this.createCartTicket(item)

    }

    showAssignDriver(item) {
        if (true || this.props.current_store.indexOf('locall') >= 0) {
            this.setState({ assign_driver_entity: item })
        }
        else {
            if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return;
            this.setItem(item, { status: 'ontheway', timestamp: { ontheway: new Date() } })
        }
    }

    selectDriver(e) {
        this.setState({ assign_driver: e.currentTarget.value })
    }

    saveDriver() {
        let { assign_driver, assign_driver_entity } = this.state
        let driver = this.getDriverData(assign_driver)
        let item = assign_driver_entity
        this.setItem(item, { driver_id: assign_driver || null, driver, status: 'ontheway', timestamp: { ontheway: new Date() } })

        if (driver && driver.lnt_access_token) {
            if (!assign_driver_entity.markerPosition)
                this.lineDriverNotify(driver.lnt_access_token, `มีรายการต้องไปส่งใหม่ ${assign_driver_entity.order_no}\nส่งคุณ ${assign_driver_entity.customer.name}\nโทร${assign_driver_entity.customer.tel}\n${assign_driver_entity.deliverRemark}\n ${assign_driver_entity.delivernote}`)
            else
                this.lineDriverNotify(driver.lnt_access_token, `มีรายการต้องไปส่งใหม่ ${assign_driver_entity.order_no}\nส่งคุณ ${assign_driver_entity.customer.name}\nโทร${assign_driver_entity.customer.tel}\n${assign_driver_entity.deliverAddress.route} ${assign_driver_entity.deliverAddress.city} ${assign_driver_entity.deliverAddress.area} ${assign_driver_entity.deliverAddress.state} ${assign_driver_entity.deliverAddress.postcode}\n${assign_driver_entity.deliverRemark}\n ${assign_driver_entity.delivernote}\n${`http://maps.google.com/maps?daddr=${assign_driver_entity.markerPosition.lat},${assign_driver_entity.markerPosition.lng}&amp;ll=`}`)
        }
        this.setState({ assign_driver: null, assign_driver_entity: null })
    }

    getDriverData(id) {
        console.log(id, this.state.drivers)
        if (id && this.state.drivers) {
            for (let d in this.state.drivers) {
                if (id === this.state.drivers[d].id) {
                    return this.state.drivers[d]
                }
            }
        }

        return null
    }


    async lineHubStoreNotify(token, message) {
        let { storeData } = this.state
        // console.log(message)
        // 
        if (message) {
            try {
                let response = await fetch(`https://us-central1-${AppConfig.firebase.projectId}.cloudfunctions.net/sendLineNotify`, { // Your POST endpoint
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ message: message, token })

                })
                await response.json()
                // console.log(success) // Handle the success response object
            }
            catch (error) {
                console.log(message) // Handle the error response object
                Sentry.captureException(error)
            }
        }
    }



    async lineDriverNotify(token, message) {
        let { storeData } = this.state
        // console.log(message)
        // 
        if (message && token) {
            try {
                let response = await fetch(`https://us-central1-${AppConfig.firebase.projectId}.cloudfunctions.net/sendLineNotify`, { // Your POST endpoint
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ message: message, token })

                })
                await response.json()
                // console.log(success) // Handle the success response object
            }
            catch (error) {
                console.log(error) // Handle the error response object
                Sentry.captureException(error)
            }
        }
    }


    render() {
        let { assign_driver_entity, assign_driver, hubStoresById } = this.state
        if (!this.props.current_user || !hubStoresById) return <div className="title p20 has-text-centered"></div>;
        // if (this.props.current_user === false) return <div className="title p20 has-text-centered"></div>;
        // 
        if (this.props.current_user && this.props.owner === false) return <div className="title p20 has-text-centered">คุณไม่มีสิทธิในการเข้าถึงข้อมูลส่วนนี้!<br />({this.props.current_user.email})</div>;

        // if (!this.props.current_user) return "";
        return (
            <div className="order-editor item-editor m20">
                <h1 className="title mt20">Orders</h1>
                {this.state.loading && <div className="notification is-info">
                    <i className="fas fa-chevron-left"></i> Loading....<br />
                    <div className="loading_message">{this.state.loading_message}</div>
                </div>}

                {this.state.error_message && <div className="notification is-danger">
                    <div className="error_message"><SanitizedHTML allowedTags={['br', 'li', 'ul']} html={this.state.error_message} /></div>
                </div>}

                {(!this.state.loading && this.state.step) === 0 && <div className="step step0">
                    <div className="columns ">
                        <div className="column">
                            <div style={{ lineHeight: '36px' }}>
                                ตั้งแต่ <input type="date" className="input is-inline" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }); this.subscribeData() }} /> ถึง <input type="date" className="input is-inline" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }); this.subscribeData() }} /> <button className="button is-info" onClick={() => this.subscribeData()}>แสดง</button>
                            </div>
                        </div>
                        <div className="column ">

                            <div className="buttons is-pulled-right pb10">
                                {/* <button className="button is-primary" onClick={() => { this.setState({ currentItem: defaultDesc, layout: this.state.layouts[0], fileObjURL: null, file: null, step: 1 }) }}>CREATE NEW </button>&nbsp; */}
                                {/* {this.props.current_store.indexOf('locall') >= 0 && <button className="button is-warning" onClick={() => this.createOrderTicket()}>GEN. TICKETS </button>}&nbsp; */}
                                <div class="dropdown is-right is-hoverable">
                                    <div class="dropdown-trigger">
                                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu4">
                                            <span>ดาวน์โหลดรายงาน</span>
                                            <span class="icon is-small">
                                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                                        <div class="dropdown-content">
                                            <div class="dropdown-item">
                                                <div class="content">
                                                    <ul>
                                                        <li><a onClick={() => this.exportCustomer()}>รายงานลูกค้า</a></li>
                                                        <li><a onClick={() => this.export()}>รายงานการขาย </a></li>
                                                        <li><a onClick={() => this.exportByProduct()}>การขายแยกตาม สินค้า </a></li>
                                                        <li><a onClick={() => this.exportByStore()}>การขายแยกตาม ร้านค้า </a></li>
                                                        <li><a onClick={() => this.exportByDriver()}>การขายแยกตาม คนขับ </a></li>
                                                        <li><a onClick={() => this.exportByCoupon()}>การขายแยกตาม คูปอง </a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="filters mt10 mb10">
                        <button className={`button is-small ${this.state.filter === 'active' ? 'is-info' : ''}`} onClick={() => this.applyFilter("active")}>All active</button>
                        <button className={`button is-small ${this.state.filter === 'submitted' ? 'is-info' : ''}`} onClick={() => this.applyFilter("submitted")}>Submitted</button>
                        <button className={`button is-small ${this.state.filter === 'paid' ? 'is-info' : ''}`} onClick={() => this.applyFilter("paid")}>Paid</button>
                        <button className={`button is-small ${this.state.filter === 'ontheway' ? 'is-info' : ''}`} onClick={() => this.applyFilter("ontheway")}>On theway</button>
                        <button className={`button is-small ${this.state.filter === 'delivered' ? 'is-info' : ''}`} onClick={() => this.applyFilter("delivered")}>Delivered</button>
                        <button className={`button is-small ${this.state.filter === 'pending' ? 'is-info' : ''}`} onClick={() => this.applyFilter("pending")}>Pending</button>
                        <button className={`button is-small ${this.state.filter === 'cancel' ? 'is-info' : ''}`} onClick={() => this.applyFilter("cancel")}>Cancel</button>
                        <button className={`button is-small ${this.state.filter === null ? 'is-info' : ''}`} onClick={() => this.applyFilter(null)}>All</button>
                    </div>
                    <div>{this.state.filteredItems.length} รายการ</div>
                    {this.state.filteredItems === false && <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /></div>}
                    {this.state.filteredItems.length === 0 && <div className="has-text-centered p20">
                        <h3 className="subtitle">ยังไม่มีรายการในส่วนนี้</h3>
                        {this.canCreate && <button className="button is-primary" onClick={() => { this.loadEditor(this.defaultValue) }}><i className="fa fa-plus"></i>&nbsp;เพิ่มรายการใหม่</button>}
                        <br /></div>}
                    {this.state.filteredItems && this.state.filteredItems.length > 0 &&

                        <table className="table is-striped is-fullwidth">
                            <thead>
                                <tr><th><input type="checkbox" onChange={(e) => this.toggleSelectAll(e.currentTarget.checked)} /></th><th></th><th>Name</th><th>Total</th><th>Status</th><th>Payment</th><th>Coupon</th><th>Submit Date</th><th>Deliver Date</th><th>Driver</th><th>Action</th></tr>
                            </thead>
                            <tbody>
                                {this.state.filteredItems.map((item, key) =>
                                    <tr key={item.id} >
                                        <td>{!item.ticketed && <input type="checkbox" checked={item.selected} onChange={(e) => this.toggleSelect(e.currentTarget.checked, item)} />}</td>
                                        <td>{item.image && <CloudImage src={item.image} className="thumbnail" onClick={() => this.setState({ fullscreenImage: `${AppConfig.firebase.storageEndpoint}${encodeURIComponent(item.image)}?alt=media` })} />}</td>
                                        <td onClick={() => {
                                            // //this.loadEditor(item)
                                        }}><b>{item.customer.name}</b> ({item.customer.tel})<br /><small>{item.deliverAddress.address}</small><br /><small>{item.order_no} [{item.id}]</small><br />
                                            {item.status === 'submitted' && <button className="button is-info is-small" onClick={() => { this.setPaid(item) }} ><i className="fa fa-dollar" />&nbsp;PAID</button>}
                                            {(AppConfig.build === 'imperial' || item.deliverType !== 'pickup') && (item.status === 'submitted' || item.status === 'paid') && <button className="button is-info is-small" onClick={() => { this.showAssignDriver(item) }} ><i className="fa fa-truck" />&nbsp;ON THE WAY</button>}
                                            {(item.status === 'submitted' || item.status === 'paid' || item.status === 'ontheway') && <button className="button is-success is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'delivered', timestamp: { delivered: new Date() } }) }} ><i className="fa fa-truck" />&nbsp;DELIVERED</button>}
                                            <button className="button is-danger is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'cancel', timestamp: { cancel: new Date() } }) }} ><i className="fa fa-ban" />&nbsp;CANCEL</button>
                                        </td>
                                        <td>{item.totalPrice}</td>
                                        <td>
                                            <div className={`order status ${item.status}`}>{item.status && item.status.humanize}</div>
                                            {item.status !== 'submitted' && item.status !== 'pending' && <button className="button is-info is-small is-fullwidth mb5" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'submitted' }) }} ><i className="fa fa-ban" />&nbsp;RESET</button>}
                                            {moment(item.deliverDate.toString()) > moment() && <div className={`button is-warning is-small is-fullwidth `}><i className="fa fa-clock"></i>&nbsp;PRE-ORDER</div>}
                                        </td>
                                        <td>{item.paymentMethod}</td>
                                        <td>{item.coupon_code}</td>
                                        <td>{item.orderDate && item.orderDate.toString()}</td>
                                        <td>{(item.deliverDate && typeof item.deliverDate === 'string' ? item.deliverDate.toString() : "ASAP")} {item.deliverTime} </td>

                                        <td>{item.driver && item.driver.name}</td>
                                        <td style={{ textAlign: 'right' }}>

                                            {/* {item.lineUserId && <a className="button is-info is-small" href={`https://chat.line.biz/U90904b229616be06dfe7b85b3b54fbcf/chat/${item.lineUserId}`} target="_blank" ><i className="fa fa-comments" /></a>} */}
                                            <button className="button is-info is-small" onClick={() => { this.printReceipt(item) }} ><i className="fa fa-print" /></button>
                                            <button className="button is-warning is-small" onClick={() => { this.loadEditor(item) }} ><i className="fa fa-edit" /></button>
                                            {/* <button className="button is-danger is-small" onClick={() => { this.delete(item.id) }} ><i className="fa fa-trash" /></button> */}
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    }
                </div>}
                {
                    (!this.state.loading && this.state.step) === 1 &&
                    <div className="step step1">
                        <div className="columns">
                            <div className="column is-two-thirds">
                                <button className="button is-warning" onClick={() => { this.props.history.push(`/${this.props.current_store}/admin/orders`); this.setState({ step: 0 }) }}> <i className="fa fa-chevron-left" aria-hidden="true"></i> &nbsp;BACK </button>

                                <h2 className="title">รายการ #{this.state.currentItem.order_no} [{this.state.currentItem.id}]</h2>
                                <table className="table is-striped is-fullwidth">
                                    {this.state.currentItem.carts.map((item) =>
                                        <tr key={item.product.id + (item.option ? item.option.name : "")} className="">

                                            <td className={`${item.cancel && 'item-canceled'}`}>
                                                <div className="item-name">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small className="hilight">{this.state.hubStoresById && this.state.hubStoresById[item.product.store_id] && this.state.hubStoresById[item.product.store_id].name}</small><br />
                                                    {item.quantity} x {item.product.name}
                                                    {item.option && <div className="item-option">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.option.name}</div>}
                                                    {item.extras && Object.keys(item.extras).map((name) => { return item.extras[name].length > 0 && <div className="item-option item-extra">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{name}: {item.extras[name].map(x => x.name).join(',')}</div> })}
                                                    {item.note}
                                                </div>
                                            </td>

                                            <td>
                                                {item.quantity * getCartItemPrice(item)}
                                                <br />
                                                {item.add_by_admin && <div className="item-by-admin">By admin</div>}
                                            </td>

                                            <td style={{ width: '100px' }}>
                                                {!item.cancel && <button className="button is-danger is-small" onClick={() => { this.cancelCartItem(item) }} ><i className="fa fa-ban" />&nbsp;ยกเลิก</button>}
                                                {item.cancel && <span className="has-text-danger">ยกเลิกแล้ว</span>}
                                            </td>


                                        </tr>)}
                                    <tr>
                                        <td>
                                            <div>เพิ่มรายการใหม่</div>

                                            <Select options={this.state.products.map(x => { return { value: x.id, label: x.name } })} value={this.state.addSelectedProduct} style={{ maxWidth: '400px' }} onChange={(selected) => { this.setState({ addSelectedProduct: selected, addSelectedOption: null }) }} />
                                            {this.state.addSelectedProduct && this.state.productsById[this.state.addSelectedProduct.value] && <Select options={this.state.productsById[this.state.addSelectedProduct.value].options.map((x, i) => { return { value: i, label: x.name } })} value={this.state.addSelectedOption} style={{ maxWidth: '400px' }} onChange={(selected) => this.setState({ addSelectedOption: selected })} />}
                                            {/* <div className="select">
                                                <select value={this.state.addSelectedProduct} style={{ maxWidth: '400px' }} onChange={(e) => this.setState({ addSelectedProduct: e.target.value, addSelectedOption: null })}>
                                                    <option value={null} selected disabled></option>
                                                    {this.state.products.map(x => <option value={x.id}>{x.name}</option>)}
                                                </select>
                                            </div>
                                            {this.state.addSelectedProduct && <div className="select">
                                                <select value={this.state.addSelectedOption} style={{ maxWidth: '400px' }} onChange={(e) => this.setState({ addSelectedOption: e.target.value })}>
                                                    <option value={null} selected disabled></option>
                                                    {this.state.productsById[this.state.addSelectedProduct].options.map((x, i) => <option value={i}>{x.name}</option>)}
                                                </select>
                                            </div>} */}
                                        </td>
                                        <td><input className="input" type="number" value={this.state.addSelectedAmount} onChange={(e) => this.setState({ addSelectedAmount: e.target.value })} /> </td>
                                        <td>
                                            <button className="button is-success is-small" onClick={() => { this.addCart() }} ><i className="fa fa-plus" />&nbsp;เพิ่ม</button>
                                        </td>
                                    </tr>
                                </table>
                                {this.state.currentItem.promotions && this.state.currentItem.promotions.length > 0 && <div>
                                    <div className="promotions animated fadeIn">
                                        <label>โปรโมชั่น</label>
                                        {this.state.currentItem.promotions.map(item =>
                                            <li>&nbsp;{item.name} {item.applyTime > 0 ? " x " + item.applyTime : ''}</li>
                                        )}

                                    </div>
                                </div>
                                }

                                <div className="level is-mobile mb5">
                                    <div className="level-left">ค่าจัดส่ง</div>

                                    <div className="level-right">
                                        <div className="level-item">{this.state.currentItem.deliverCost}</div>
                                    </div>
                                </div>
                                <div className="level is-mobile mb5">
                                    <div className="level-left">ส่วนลด</div>

                                    <div className="level-right">
                                        <div className="level-item">(-) {this.state.currentItem.discount}</div>
                                    </div>
                                </div>

                                <div className="level is-mobile mb5">
                                    <div className="level-left">รวมทั้งสิ้น</div>

                                    <div className="level-right">
                                        <div className="level-item">{this.state.currentItem.totalPrice}</div>
                                    </div>
                                </div>
                                <div>{this.state.currentItem.utensils !== true ? '** ไม่รับช้อนส้อม' : '** รับช้อนส้อม'}</div>
                                <hr />
                                <label>ผู้รับ</label>
                                <div className="columns is-mobile">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">ชื่อผุ้รับ</label>
                                            <div className="control">
                                                <input
                                                    name="customer.name"
                                                    value={this.state.currentItem.customer.name}

                                                    className="input"
                                                    type="text"
                                                    placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">เบอร์ติดต่อ</label>
                                            <div className="control">
                                                <input
                                                    name="customer.tel"
                                                    value={this.state.currentItem.customer.tel}

                                                    className="input" type="text" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <label className="mt20">ที่อยู่จัดส่ง</label>

                                {this.state.currentItem.markerPosition && <div>
                                    <a className="location-item" href={`http://maps.google.com/maps?daddr=${this.state.currentItem.markerPosition.lat},${this.state.currentItem.markerPosition.lng}&amp;ll=`} target="_blank">
                                        {this.state.currentItem.deliverAddress.route} {this.state.currentItem.deliverAddress.city} {this.state.currentItem.deliverAddress.area} {this.state.currentItem.deliverAddress.state} {this.state.currentItem.deliverAddress.postcode}
                                        {/* {JSON.stringify(this.state.currentItem.deliverAddress)} */}
                                    </a><br />
                                    <a className="location-item" href={`http://maps.google.com/maps?daddr=${this.state.currentItem.markerPosition.lat},${this.state.currentItem.markerPosition.lng}&amp;ll=`} target="_blank">
                                        {`http://maps.google.com/maps?daddr=${this.state.currentItem.markerPosition.lat},${this.state.currentItem.markerPosition.lng}&amp;ll=`}
                                    </a><br />
                                    {`${this.state.currentItem.markerPosition.lat},${this.state.currentItem.markerPosition.lng}`}
                                </div>}

                                <hr />
                                <label className="mt20">รายละเอียดที่อยู่ เพิ่มเติม</label>
                                {/* {this.state.currentItem.deliverAddressType && <div>{this.state.currentItem.deliverAddressType ? 'บ้่าน' : 'คอนโด/ออฟฟิศ'}</div>} */}
                                {/* <div>{this.state.currentItem.deliverMethod}</div> */}
                                <div>{this.state.currentItem.deliverRemark}</div>

                                <hr />
                                <label className="mt20">เวลาจัดส่ง</label>
                                <div>
                                    {/* {typeof this.state.currentItem.deliverDate === "string" ? this.state.currentItem.deliverDate : "ASAP"} เวลาประมาณ : {this.state.currentItem.deliverTime} */}

                                    <DatePicker
                                        className={"input deliverDate"}
                                        name="deliverDate"
                                        selected={moment(this.state.currentItem.deliverDate, "YYYY-MM-DD").toDate()}
                                        onChange={(v) => { this.handleDescriptionChange(null, "deliverDate", moment(v).format("YYYY-MM-DD")) }}
                                        // showTimeSelect
                                        // timeFormat="HH:mm"
                                        // timeIntervals={15}
                                        // timeCaption="time"

                                        // minDate={moment().toDate()}
                                        // maxDate={moment().add(30, 'days').toDate()}
                                        locale="th"
                                        dateFormat="dd MMMM ค.ศ.yyyy"
                                    />

                                    <div className="select">
                                        <select name="deliverTime" onChange={this.handleDescriptionChange} value={this.state.currentItem.deliverTime || null}>
                                            <option>ASAP</option>
                                            {(() => {
                                                let options = []
                                                for (let i = 0; i <= 23; i++) {
                                                    options.push(<option>{i}:00-{i}:30</option>)
                                                    options.push(<option>{i}:30-{i + 1}:00</option>)
                                                }
                                                return options
                                            })()}
                                        </select>
                                    </div>
                                </div>

                                <label className="mt20">วิธีการชำระเงิน</label>

                                {this.state.currentItem.paymentMethod != null && <a className="payment-item button is-fullwidth" onClick={() => { this.setState({ showPayment: true }) }} style={{ justifyContent: 'left' }}>{this.state.currentItem.paymentMethod === "transfer" ? "โอนเงิน" : "ชำระเงินปลายทาง"}</a>}
                                {!this.state.currentItem.paymentMethod && <a className="payment-item button is-fullwidth" onClick={() => { this.setState({ showPayment: true }) }} style={{ justifyContent: 'left' }}><i className="fa fa-plus"></i>&nbsp;เลือกการชำระเงิน</a>}

                                <label className="mt20">Order Reference note</label>
                                <div className="columns is-mobile">
                                    <div className="column">
                                        <div className="field">
                                            <div className="control">
                                                <input
                                                    name="refnote"
                                                    value={this.state.currentItem.refnote}
                                                    className="input"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={this.handleDescriptionChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <label className="mt20">หมายเหตุเพิ่มเติม</label>
                                <div className="columns is-mobile">
                                    <div className="column">
                                        <div className="field">
                                            <div className="control">
                                                <textarea
                                                    name="remarknote"
                                                    value={this.state.currentItem.remarknote}
                                                    className="textarea"
                                                    placeholder=""
                                                    onChange={this.handleDescriptionChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <label className="mt20">ที่อยู่เพิ่มเติม (สำหรับส่งให้คนขับ)</label>
                                <div className="columns is-mobile">
                                    <div className="column">
                                        <div className="field">
                                            <div className="control">
                                                <textarea
                                                    name="delivernote"
                                                    value={this.state.currentItem.delivernote}
                                                    className="textarea"
                                                    placeholder=""
                                                    onChange={this.handleDescriptionChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt10">
                                    <button className="button is-primary" onClick={() => this.save(this.state.currentItem)}>SAVE</button>
                                </div>

                            </div>
                            <div className="column is-one-thirds">
                                <CloudImage src={this.state.currentItem.image} className="order-image" />
                            </div>
                        </div>
                    </div>

                }
                {this.state.fullscreenImage && <div className="overlay-image" onClick={() => this.setState({ fullscreenImage: null })}><img src={this.state.fullscreenImage} /></div>}
                {assign_driver_entity &&
                    <ModalDialog title="เลือกคนขับ" onClose={() => this.setState({ assign_driver_entity: null })} onOK={() => this.saveDriver()}>
                        <div className="field">
                            <label className="label is-small">ร้านค้า</label>
                            <div className="control">
                                <select className="input is-small"
                                    name="assign_driver"
                                    type="text"
                                    placeholder=""
                                    onChange={this.selectDriver}
                                    value={assign_driver || null}>
                                    <option value={null}>ไม่ระบุ</option>
                                    {this.state.drivers.map((x, i) =>
                                        <option key={x.id} value={x.id}>{x.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </ModalDialog>
                }
            </div >
        )
    }
}
const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(OrderEditor))