import React, { Component } from 'react';

class ToggleButton extends Component {

    constructor() {
        super()
    }

    renderOption(key, label, icon) {
        let currentValue = this.props.value
        return <p className="control column">
            <button className={`button is-fullwidth ${currentValue === key && 'is-active'}`} onClick={() => { this.props.onChange && this.props.onChange(key, label) }}>
                {icon && <span className="icon is-small"><i className={`fas fa-${icon}`}></i></span>}
                <span>{label}</span>
            </button>
        </p>
    }
    render() {
        let { choices } = this.props
        let options = []

        if (Array.isArray(choices)) {
            for (let c in choices) {
                if (Array.isArray(choices[c]) && choices[c].length > 1) {
                    options.push(this.renderOption(choices[c][0], choices[c][1], choices[c][2]))
                }
                else {
                    options.push(this.renderOption(choices[c], choices[c]))
                }
            }
        }
        else {
            options = Object.keys(choices).map(k => this.renderOption(k, choices[k]))
        }
        return <div className="toggle-button field has-addons">
            {options}
        </div>
    }
}


export default ToggleButton