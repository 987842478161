
import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions/index'

const FormInput = (props) => {
    if (props.type === 'select') {
        return <div className="select is-small">
            <select name={props.name} value={eval(`props.current_entity.${props.name}`)} onChange={props.handleCurrentEntityChange}>
                {props.children}
            </select>
        </div>
    }
    if (props.type === 'checkbox') {
        return <input className="checkbox" type={props.type || 'text'} name={props.name} checked={eval(`props.current_entity.${props.name}`)} onChange={props.handleCurrentEntityChange} />
    }
    return <input className="input is-small" type={props.type || 'text'} name={props.name} value={eval(`props.current_entity.${props.name}`)} onChange={props.handleCurrentEntityChange} />
}
const FormField = (props) => {
    return <div className="field">
        {props.type !== 'checkbox' && <label className="label is-small">{(props.label || props.name.humanize)}</label>}
        <div className="control">
            <FormInput {...props} />
            {
                // props.type !== 'checkbox' && <span className="icon is-small is-left">

                // </span>
            }
            {props.type === 'checkbox' && <label>&nbsp;{(props.label || props.name.humanize)}</label>}
        </div>
    </div>
}

const mapStateToProps = ({ current_entity }) => ({ current_entity });
export default connect(mapStateToProps, actions)(FormField)