import * as Sentry from '@sentry/browser';
import './firebaseInitialize'
import './extendFunctions'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

import * as firebase from 'firebase';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from './reducers'
import { CookiesProvider } from 'react-cookie';
import $ from "jquery";
import { build_version } from './metadata.json';
import * as AppConfig from './AppConfig.json';
import { logScreen } from './Helper';
import * as mixpanel from 'mixpanel-browser';

if (AppConfig.build === 'locall') {
    mixpanel.init("b651ba333baa36378e4387e74c1587fc");
}

let version = `${AppConfig.build}@${build_version}`
console.log(version)
logScreen(version)

Sentry.init({ dsn: AppConfig.sentryDNS, release: version });

const store = createStore(rootReducer)

const ProviderApp = () => (
    <CookiesProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </CookiesProvider>
)

ReactDOM.render(<ProviderApp />, document.getElementById('root'))
serviceWorker.unregister();

// -  Firebase user function
$(document).ready(function () {
    firebase.auth().onAuthStateChanged(async function (user) {
        // 
        if (user) {
            let idTokenResult = await user.getIdTokenResult()
            user.admin = !!idTokenResult.claims.admin
            store.dispatch({ type: 'USER_ROLES_SET', payload: user.admin && ['admin'] })
            store.dispatch({ type: 'USER_SET', payload: user })
            // User is signed in.

        } else {
            store.dispatch({ type: 'USER_SET', payload: false })
            // No user is signed in.
        }
    });


})
