import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import moment from 'moment'

import * as actions from '../actions/index'
import { CloudImage } from './CloudImage';
import BaseEditor from './BaseEditor';
import FormField from './FormField';
import { WithContext as ReactTags } from 'react-tag-input';
import GlobalPromotion from '../models/GlobalPromotion';
import { dateFormat } from '../Helper';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

var productDataRef = null;//db.collection("products");

const defaultDesc = { name: "New item", startDate: moment().format('YYYY-MM-DD'), endDate: null, startTime: null, endTime: null, options: [""] };

class GlobalPromotionEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Promotions"
        this.state.tags = [
        ]
        this.state.products = [
        ]
        this.defaultValue = defaultDesc;
        this.canCreate = true;
        this.listFields = ['name', 'date', 'time', 'active']

        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleAreaDelete = this.handleAreaDelete.bind(this);
        this.handleAreaAddition = this.handleAreaAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.filterSuggestions = this.filterSuggestions.bind(this);
        this.updateCartProducts = this.updateCartProducts.bind(this);

    }

    componentDidMount() {
        this.dataRef = GlobalPromotion.getCollection();
        super.componentDidMount()

        this.availableLocation = []
        this.availableLocation.push('คลองเตย');
        this.availableLocation.push('คลองสาน');
        this.availableLocation.push('คลองสามวา');
        this.availableLocation.push('คันนายาว');
        this.availableLocation.push('จตุจักร');
        this.availableLocation.push('จอมทอง');
        this.availableLocation.push('ดอนเมือง');
        this.availableLocation.push('ดินแดง');
        this.availableLocation.push('ดุสิต');
        this.availableLocation.push('ตลิ่งชัน');
        this.availableLocation.push('ทวีวัฒนา');
        this.availableLocation.push('ทุ่งครุ');
        this.availableLocation.push('ธนบุรี');
        this.availableLocation.push('บางกอกน้อย');
        this.availableLocation.push('บางกอกใหญ่');
        this.availableLocation.push('บางกะปิ');
        this.availableLocation.push('บางขุนเทียน');
        this.availableLocation.push('บางเขน');
        this.availableLocation.push('บางคอแหลม');
        this.availableLocation.push('บางแค');
        this.availableLocation.push('บางซื่อ');
        this.availableLocation.push('บางนา');
        this.availableLocation.push('บางบอน');
        this.availableLocation.push('บางพลัด');
        this.availableLocation.push('บางรัก');
        this.availableLocation.push('บึงกุ่ม');
        this.availableLocation.push('ปทุมวัน');
        this.availableLocation.push('ประเวศ');
        this.availableLocation.push('ป้อมปราบศัตรูพ่าย');
        this.availableLocation.push('พญาไท');
        this.availableLocation.push('พระโขนง');
        this.availableLocation.push('พระนคร');
        this.availableLocation.push('ภาษีเจริญ');
        this.availableLocation.push('มีนบุรี');
        this.availableLocation.push('ยานนาวา');
        this.availableLocation.push('ราชเทวี');
        this.availableLocation.push('ราษฎร์บูรณะ');
        this.availableLocation.push('ลาดกระบัง');
        this.availableLocation.push('ลาดพร้าว');
        this.availableLocation.push('วังทองหลาง');
        this.availableLocation.push('วัฒนา');
        this.availableLocation.push('สวนหลวง');
        this.availableLocation.push('สะพานสูง');
        this.availableLocation.push('สัมพันธวงศ์');
        this.availableLocation.push('สาทร');
        this.availableLocation.push('สายไหม');
        this.availableLocation.push('หนองแขม');
        this.availableLocation.push('หนองจอก');
        this.availableLocation.push('หลักสี่');
        this.availableLocation.push('ห้วยขวาง');

        this.availableLocation.push('นนทบุรี');

    }

    fieldData(item, name) {
        let result = super.fieldData(item, name)
        if (name === 'name') {
            result = [<div>{item.name}</div>,
            <div>
                {item.discount_deliver_area && item.deliver_areas && <span className="is-small"><b>พื้นที่</b> : {item.deliver_areas.join(', ')}</span>}&nbsp;
                {item.discount_fix_date && item.deliver_date && <span className="is-small"><b>วันที่</b> : {`${dateFormat(item.deliver_date, false)} ${item.deliver_time} น.`}</span>}
            </div>]
        }

        if (name === 'date') {
            if (item.startDate && item.endDate) {
                return `${dateFormat(item.startDate, false)} - ${dateFormat(item.endDate, false)}`
            }
            return 'All date'
        }

        if (name === 'time') {
            if (item.startTime && item.endTime) {
                return `${item.startTime} - ${item.endTime}`
            }
            return 'All time'
        }

        if (name === 'image') {
            if (item.image) {
                return <CloudImage src={item.image} style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }} />
            }
        }

        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }

        return result
    }

    loadEditor(item) {
        super.loadEditor(item)
        // console.log("loadEditor", item.cart_products)
        let tags = []
        for (let i in item.cart_products) {
            this.state.products.forEach(p => {
                // console.log(item.cart_products[i], p.id, item.cart_products[i] === p.id)
                if (item.cart_products[i] === p.id) {
                    tags.push(p)
                }
            })
        }

        let areas = []
        if (item.deliver_areas) {
            areas = item.deliver_areas.map(i => { return { id: i, text: i } })
        }

        // console.log(item)
        // console.log("tags", tags)
        // console.log("areas", areas)
        this.setState({ tags, areas })

    }


    filterSuggestions(tags) {
        let possibleSuggestionsArray = []
        this.state.products.forEach(item => {
            let exist = false;
            for (var e in tags) {
                if (tags[e].id === item.id) {
                    exist = true
                }
            }

            if (!exist) {
                possibleSuggestionsArray.push(item)
            }
        })
        console.log(possibleSuggestionsArray)
        return possibleSuggestionsArray
    }

    updateCartProducts() {
        let { tags } = this.state

        let updateState = this.state.item_desc;
        updateState.cart_products = tags.map(item => item.id)
        this.setState({ item_desc: updateState })
    }

    handleDelete(i) {
        const { tags } = this.state;
        this.setState({
            tags: tags.filter((tag, index) => index !== i),
        }, () => this.updateCartProducts());

    }

    handleAddition(tag) {
        this.setState(state => ({ tags: [...state.tags, tag] }), () => this.updateCartProducts());
    }

    handleAreaDelete(i) {
        let { current_entity } = this.props
        let areas = this.state.areas
        areas = areas.filter((tag, index) => index !== i)
        current_entity.deliver_areas = areas.map(i => i.id)
        this.setState({
            areas,
        }, () => { });

    }

    handleAreaAddition(tag) {
        let { current_entity } = this.props
        let areas = this.state.areas
        if (areas) {
            areas = [...areas, tag]
        }
        else {
            areas = [tag]
        }
        current_entity.deliver_areas = areas.map(i => i.id)
        this.setState({ areas }, () => { });

    }

    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags }, () => this.updateCartProducts());
    }
    renderEditor() {
        let { current_entity } = this.props
        let { tags, areas } = this.state

        return <div className="mt10">
            <div className="admin-panel">
                <FormField name="name" value={this.props.current_entity} label="ชื่อโปรโมชั่น" />

                <div className="columns">
                    <div className="column">
                        <label className="label is-small">เริ่มต้นวันที่</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input is-small"
                                name="startDate"
                                type="date"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={this.state.item_desc.startDate} />
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>

                    <div className="column">
                        <label className="label is-small">ถึงวันที่</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input is-small"
                                name="endDate"
                                type="date"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={this.state.item_desc.endDate} />
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>

                    <div className="column">
                        <label className="label is-small">ตั้งแต่เวลา</label>
                        <div className="control">
                            <div className="select is-small">
                                <select className="input is-small"
                                    name="startTime"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={this.state.item_desc.startTime}>
                                    <option value={null} selected ></option>
                                    {[...Array(48)].map((x, i) =>
                                        <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <label className="label is-small">ถึง</label>
                        <div className="control">
                            <div className="select is-small">
                                <select
                                    name="endTime"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={this.state.item_desc.endTime}>
                                    <option value={null} selected ></option>
                                    {[...Array(48)].map((x, i) =>
                                        <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="admin-panel">
                <h4 className="title">ส่วนลดค่าอาหาร</h4>
                <FormField name="discount_type" type="select" label="Discount Type (วิธีการลดราคา)" >
                    <option value={null} selected>-</option>
                    <option value="percent">Percent (ลดเป็น % จากราคาอาหาร)</option>
                    <option value="fixed-discount">Fixed(THB) (ลดเป็นจำนวนเงิน จากราคาอาหาร)</option>
                    <option value="fixed-price">Pay Fixed(THB) (จ่ายคงที่ ไม่ติดตามราคาอาหาร)</option>
                </FormField>
                {current_entity.discount_type && current_entity.discount_type !== 'free' &&
                    <div className="columns">
                        <div className="column">
                            <div className="control has-icons-left has-icons-right">
                                <FormField name="discount" type="number" />

                            </div>
                        </div>
                        <div className="column">
                            <div className="control has-icons-left has-icons-right">
                                <FormField name="max_discount" type="number" />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="admin-panel">
                <h4 className="title">ส่วนลดค่าส่ง</h4>
                <div className="columns">
                    <div className="column">
                        <FormField name="deliver_discount_type" type="select" >
                            <option value={null} selected>-</option>
                            <option value="percent">Percent (ลดเป็น % จากค่าส่งปกติ)</option>
                            <option value="fixed-discount">Fixed(THB) (ลดเป็นจำนวนเงิน จากค่าส่งปกติ)</option>
                            <option value="fixed-price">Pay Fixed(THB) (จ่ายคงที่ ไม่ติดค่าส่งปกติ)</option>
                            <option value="free">Free (ไม่คิดค่าขนส่ง)</option>
                        </FormField>

                    </div>
                    <div className="column">
                        {current_entity.deliver_discount_type && current_entity.deliver_discount_type !== 'free' &&
                            <FormField name="deliver_discount" type="number" />
                        }
                    </div>
                </div>
            </div>
            <hr />

            <h3 className="title">เงื่อนไขโปรโมชั่น</h3>
            <div className="admin-panel">
                <FormField name="only_new_user" type="checkbox" label="ใช้ได้เฉพาะสั่งครั้งแรก" />
            </div>
            <div className="admin-panel">
                <FormField name="apply_to_all" type="checkbox" label="ลดทุกออเดอร์ ไม่ต้องใช้คูปองโค้ด" />
            </div>
            <div className="admin-panel">
                <h3 className="title">ลดราคาเมื่อ</h3>
                <FormField label="ซื้ออย่างน้อย (บาท)" name="is_buy_at_least" type="checkbox" />
                {
                    current_entity.is_buy_at_least &&
                    <div className="columns">
                        <div className="column">
                            <FormField name="buy_at_least" type="number" label="จำนวนเงิน" />
                        </div>
                    </div>
                }

                <FormField label="ลดเฉพาะในพื้นที่จัดส่งที่กำหนด" name="discount_deliver_area" type="checkbox" />
                {
                    current_entity.discount_deliver_area &&
                    <div>
                        {/* <select
                            name="deliver_area"
                            value={current_entity.deliver_area}
                            onChange={this.handleDescriptionChange}>
                            <option value={null} selected>เลือกพื้นที่</option>
                            {
                                this.availableLocation.map((x, i) => {
                                    return <option key={x} value={x}>{x}</option>

                                })
                            }
                        </select> */}
                        {this.availableLocation && <ReactTags tags={areas}
                            suggestions={this.availableLocation.map(i => { return { id: i, text: i } })}
                            handleDelete={this.handleAreaDelete}
                            handleAddition={this.handleAreaAddition}
                            delimiters={delimiters}
                            minQueryLength={1}
                            allowUnique={true}
                            autocomplete={true} />}
                    </div>
                }

                <FormField label="ลดเฉพาะวันเวลาจัดส่งที่กำหนด" name="discount_fix_date" type="checkbox" />
                {
                    current_entity.discount_fix_date &&
                    <div className="columns">
                        <div className="column">
                            <label className="label is-small">วันที่</label>
                            <div className="control has-icons-left has-icons-right">
                                <input className="input is-small"
                                    name="deliver_date"
                                    type="date"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.deliver_date} />
                                <span className="icon is-small is-left">
                                    <i className="fa fa-clock"></i>
                                </span>
                            </div>
                        </div>

                        <div className="column">
                            <label className="label is-small">เวลา</label>
                            <div className="select has-icons-left has-icons-right">
                                <select
                                    name="deliver_time"
                                    value={current_entity.deliver_time}
                                    onChange={this.handleDescriptionChange}>
                                    <option value={""} disabled>กรุณาเลือกเวลา</option>
                                    {
                                        [...Array(9)].map((x, i) => {
                                            return [<option key={i} value={`${(i + 11)}:00-${(i + 11)}:30`}>{(i + 11)}:00-{(i + 11)}:30</option>,
                                            <option key={i + "-"} value={`${(i + 11)}:30-${(i + 12)}:00`}>{(i + 11)}:30-{(i + 12)}:00</option>]

                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {/* {JSON.stringify(current_entity)} */}
            <div className="mt10">
                <button className="button is-primary is-fullwidth" onClick={() => this.save(this.props.current_entity)} >SAVE</button>
            </div>
        </div >

    }
}

const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(GlobalPromotionEditor))