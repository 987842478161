import StoreFirebaseModel from './StoreFirebaseModel';

export default class StoreDriver extends StoreFirebaseModel {

}

StoreDriver.collection = 'drivers';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();