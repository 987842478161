import * as firebase from 'firebase';
var db = firebase.firestore();

export default class FirebaseModel {

    static getCollection() {
        return db.collection(this.collection)
    }

    static async subscribe() {

        if (!this.unsubscribe) {
            this.unsubscribe = this.getCollection().onSnapshot(querySnapshot => {
                var children = [];
                var children_by_id = {};
                querySnapshot.forEach((doc) => {

                    var childKey = doc.id;
                    var childData = doc.data();
                    childData.id = childKey;
                    children_by_id[childKey] = childData;
                    children.push(childData)
                });
                this.items = children;
                this.items_by_id = children_by_id;
                this.loaded = true;


                for (let l in this.collectionListener) {
                    this.collectionListener[l](this.items)
                }
            });
        }
        return this.unsubscribe
    }

    static async addListener(listener_func) {
        if (!this.unsubscribe) {
            this.subscribe()
        }
        console.log(this)
        console.log(this.collectionListener)
        if (!this.collectionListener) this.collectionListener = []
        this.collectionListener.push(listener_func)
    }

    static async getAll() {
        await this.loadCollectionData()
        return this.items
    }

    static async getAllById() {
        await this.loadCollectionData()
        return this.items_by_id
    }

    static async get(key) {
        return await this.getCollection().doc(key).get()
    }

    static async add(data) {
        return await this.getCollection().add(data)
    }

    static async set(key_id, data, merge) {
        if (typeof merge === "undefined") {
            merge = true;
        }
        return await this.getCollection().doc(key_id).set(data, { merge })
    }

    static async update(key_id, data) {
        return await this.getCollection().doc(key_id).update(data)
    }

    static async delete(key_id) {
        return await this.getCollection().doc(key_id).delete()
    }

    static async delete_collection() {
        return await this.getCollection().delete()
    }

    static getItems() {
        return this.items || [];
    }

    static getItemsById() {
        return this.items_by_id || [];
    }

    static async loadCollectionData() {
        let querySnapshot = await this.getCollection().get();

        var children = [];
        var children_by_id = {};
        querySnapshot.forEach((doc) => {

            var childKey = doc.id;
            var childData = doc.data();
            childData.id = childKey;
            children_by_id[childKey] = childData;
            children.push(childData)
        });
        this.items = children;
        this.items_by_id = children_by_id;
        this.loaded = true;
    }
}