import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import moment from 'moment'

import * as firebase from 'firebase';
import BaseEditor from './BaseEditor';
import FormField from './FormField';
import HubStoreProduct from '../models/HubStoreProduct'
import ModalDialog from './ModalDialog';
import StateFieldInput from './inputs/StateFieldInput';
import StoreConfig from './StoreConfig';
import { isSuperAdmin } from '../Helper';

// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")
var db = firebase.firestore();

class StoreRequestEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Store"
        this.dataRef = db.collection('store_requests')
        this.canCreate = false;
        this.canEdit = false;
        this.canDelete = false;
        this.defaultValue = { name: "", open_time: null, close_time: null, preorder_open_time: null, preorderClose_time: null, owner: [] };
        this.listFields = ['store_id', 'firstname', 'lastname', 'open_time', 'close_time', 'email', 'preorderClose_time', 'package.name']
        // this.editFields = [['firstname', 'lastname'], ['mobile', 'email'], 'gender', 'grade']
        this.entityInfo = {
            name: 'String',
        }
        this.customActions = [<button className="button is-primary" onClick={() => this.setState({ showCreateDialog: true })}><i className="fa fa-plus"></i>&nbsp;เพิ่มใหม่ </button>]
        this.state.showCreateDialog = false;
        this.state.showConfigDialog = false
        this.state.newEntity = { owner: [] }

        this.createStore = this.createStore.bind(this)
    }

    async createStore(item) {
        // console.log(this, this.state.newEntity)
        let storeRef = db.collection('stores')
        if (item) {
            // newEntity.owner = [""]
            item.owner = [item.email]
            // console.log(newEntity)
            let doc = await storeRef.doc(item.store_id).get()
            if (!doc.exists) {
                await storeRef.doc(item.store_id).set(item, { merge: true })
                this.setState({ showCreateDialog: false, item: { owner: [] } })
                window.open(`/${item.store_id}/admin/settings`);
            }
            else {
                alert("ไม่สามารถสร้างร้านใหม่ได้ เนื่องจากมีร้านนี้แล้ว")
            }
            // window.location.href = `/${newEntity.name}/admin/settings`
        }
    }

    customRowAction(item) {
        return [<button className="button is-success is-small" onClick={() => { this.createStore(item) }} >Create Store</button>]
    }

    render() {
        let { current_user } = this.props
        if (!current_user || !isSuperAdmin(current_user.email)) return ""

        let result = super.render()

        return result
    }
}


const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(StoreRequestEditor))