import * as Sentry from '@sentry/browser';
import React from "react"
import { withRouter } from "react-router";
import * as firebase from 'firebase';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as AppConfig from '../AppConfig.json';
import { withCookies } from 'react-cookie';
import { CloudImage } from './CloudImage';
import { sendRN, isNative, mpTrackEvent, sort } from '../Helper';
import StoreDayoff from '../models/StoreDayoff';
import moment from 'moment'
import LineAddNotify from './LineAddNotify';
import * as mixpanel from 'mixpanel-browser';
// mixpanel.init("b651ba333baa36378e4387e74c1587fc"); mixpanel.track("Hello World");

class SearchView extends React.Component {
    constructor() {
        super()
        this.state = { loading: true }
    }

    async componentDidMount() {

        firebase.analytics().logEvent('page_view', { page_title: `Search List` })

        let storeData = {}
        let hubStoreData = {}
        var db = firebase.firestore();
        let qs = await db.collectionGroup('stores').get();
        qs.forEach(x => {
            storeData[x.id] = { ...x.data(), id: x.id }
        })

        qs = await db.collectionGroup('hub_stores').get();
        qs.forEach(x => {
            hubStoreData[x.id] = { ...x.data(), id: x.id }
        })

        let { query } = this.props.match.params

        if (query) {
            let qs = await db.collectionGroup('products').get()
            if (!qs.empty) {
                // qs.forEach(x => x.ref.parent.id)
                let products = qs.docs.map(x => { return { ...x.data(), id: x.id, hub: x.ref.parent.parent.id } }).filter(x => storeData[x.hub] && storeData[x.hub].is_active && x.is_active && x.name.toLowerCase().includes(query.toLowerCase()))
                products = sort(products, [{ hub: "asc" }])
                this.setState({ products, storeData, hubStoreData, loading: false })
            }
            else {
                this.setState({ products: [], storeData, hubStoreData, loading: false })
            }
        }
    }

    renderHub(hub) {
        return <div className="store-name-section">{hub.name}</div>
        // return <CloudImage src={hub.image || `hubs/${hub.id}.jpg`} />
    }

    renderProduct(product) {
        let store = this.state.hubStoreData[product.store_id]
        return <div className="level is-mobile" onClick={() => window.location.href = `${AppConfig.liffUrl}/${product.hub}/?i=${product.id}`}>
            <div className="level-left">
                <div className="thumbnail-image" style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(product.image)}?alt=media)`, width: "50px", borderRadius: "25px" }}></div>
                {/* <CloudImage src={item.product.image} className="thumbnail" /> */}
            </div>
            <div style={{ display: 'block', width: '100%', padding: '0 10px' }}>
                <div className="item-name is-clearfix">{product.name}<br />
                    <small style={{ color: "#888" }}>{store && store.name}</small>
                </div>
            </div>
        </div>
    }

    render() {
        let { products, loading, storeData } = this.state
        let currentHub = null
        if (loading) return <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /></div>


        return <div>
            {!isNative() && <div className="header-nav">
                <div style={{ padding: '10px' }}><a onClick={() => { this.props.history.push('/'); this.props.setCurrentCity(null) }}><i className="fas fa-chevron-left"></i>&nbsp;ย้อนกลับ</a></div>
            </div>}
            <div className="store-application store-list" style={{ padding: '30px 15px' }}>
                {products.map(x => {
                    if (currentHub === null || currentHub !== x.hub) {
                        currentHub = x.hub
                        if (currentHub === null)
                            return <>{this.renderHub(storeData[x.hub])}<div>{this.renderProduct(x)}</div></>
                        else
                            return <><hr />{this.renderHub(storeData[x.hub])}<div>{this.renderProduct(x)}</div></>
                    }
                    return <div>{this.renderProduct(x)}</div>
                })}
            </div>
        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store }) => ({ current_entity, current_store });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(SearchView)))
