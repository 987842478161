import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions/index'
import * as firebase from 'firebase';
import * as AppConfig from '../AppConfig.json';

import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import { SheetJSFT } from './types';


import { CloudImage } from './CloudImage';
import BaseEditor from './BaseEditor';
import StoreProduct from '../models/StoreProduct';
import GlobalCategory from '../models/GlobalCategory';
import StoreCategory from '../models/StoreCategory';
import { dateFormat, getConfig } from '../Helper';
import HubStoreProduct from '../models/HubStoreProduct';

class MenuEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Menus";
        this.dataRef = StoreProduct.getCollection()

        this.canCreate = true;
        this.defaultValue = { name: "", startDate: null, endDate: null, startTime: null, endTime: null, options: [] };
        this.listFields = ['image', 'name', 'category', 'store', 'date', 'time', 'active', 'soldout']
        this.exportFields = ['store', 'name', 'option', 'description', 'date', 'time', 'price', 'cost', 'is_active', 'soldout', 'category']
        this.searchFields = ['name', 'store']
        // this.editFields = [['firstname', 'lastname'], ['mobile', 'email'], 'gender', 'grade']
        this.entityInfo = {
            name: 'String',
        }

        this.customActions = <>
            {/* {<button className="button is-primary" onClick={() => { }}>ปรับราคา</button>} */}
        </>
    }

    async componentDidMount() {
        // super.componentDidMount()
        let { current_store } = this.props
        let hubStores = await HubStoreProduct.setStore(current_store).getAll()
        this.setState({ hubStores })

        let categories = await StoreCategory.setStore(current_store).getAll()
        // let categories = await StoreCategory.setStore(current_store).getAllById()
        let globalCategories = await GlobalCategory.getAll()
        globalCategories.forEach(x => x.isGlobal = true)
        categories = [...categories, ...globalCategories]
        this.setState({ categories })

        this.defaultImagePath = `products/${this.props.current_store}/`

        let { orders } = this.props
        let { current_entity } = this.props
        orders = orders || []
        this.setState({ orders })



        let $this = this;
        this.unsubscribe = this.dataRef
            .onSnapshot(querySnapshot => {
                var children = [];
                querySnapshot.forEach((doc) => {
                    var childKey = doc.id;
                    var childData = doc.data();
                    childData.id = childKey;
                    if (childData.type !== 'product') {
                        children.push(childData)
                    }
                });
                $this.setState({ items: children }, () => {
                    $this.applyFilter($this.state.filter)

                    if ($this.props.location.search) {

                        for (let c in children) {
                            if (!current_entity && children[c].id === $this.props.location.search.replace("?", "") && (!$this.state.currentItem || ($this.state.currentItem && $this.state.currentItem.id !== children[c].id))) {
                                $this.loadEditor(children[c])
                            }
                        }
                    }
                })

                // this.unsubscribe();
            });
    }

    export() {
        let { items, scope } = this.state
        if (scope) {
            items = this.applyScope(scope)
        }

        items = this.applyListOrder(items)
        var filename = "export.xlsx";
        var data = this.exportFields
        if (items) {
            data = [[...data]]
            items.forEach((row) => {

                if (row.options && row.options.length > 0) {
                    console.log(row.options)
                    row.options.forEach(o => {
                        let rowData = []
                        this.exportFields.forEach(x => {
                            if (x === 'option') {
                                rowData.push(o.name)
                            }
                            else if (x === 'price') {
                                rowData.push(Number.parseFloat(this.fieldData(row, x)) + o.price)
                            }
                            else if (x === 'cost') {
                                rowData.push(Number.parseFloat(this.fieldData(row, x)) + o.cost)
                            }
                            else {
                                rowData.push(this.fieldData(row, x))
                            }
                        })
                        data.push(rowData)
                    })

                }
                else {
                    let rowData = []
                    this.exportFields.forEach(x => rowData.push(this.fieldData(row, x)))
                    data.push(rowData)
                }
            })
        }
        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());
    }

    toggleSoldout(item_id, active) {
        let $this = this;

        for (let e in this.state.items) {
            if (this.state.items[e].id === item_id) {
                this.state.items[e].isSoldout = active
                this.forceUpdate()
            }
        }

        this.dataRef.doc(item_id).update({ isSoldout: active }).then(function () {
            // $this.reloadList();
            $this.setState({
                loading: false,
            })
        }).catch(function (error) {
            console.error("Error removing document: ", error);
            $this.setState({
                loading: false,
                error_message: error
            })
        });
    }

    fieldData(item, name) {
        let result = super.fieldData(item, name)
        let hubStoresById = HubStoreProduct.getItemsById()
        if (name === 'store') {
            return hubStoresById && hubStoresById[item.store_id] && hubStoresById[item.store_id].name
        }
        if (name === 'date') {
            if (item.startDate && item.endDate) {
                return `${dateFormat(item.startDate, false)} - ${dateFormat(item.endDate, false)}`
            }
            return 'All date'
        }

        if (name === 'time') {
            if (item.startTime && item.endTime) {
                return `${item.startTime} - ${item.endTime}`
            }
            return 'All time'
        }

        if (name === 'image') {
            if (item.image) {
                return <CloudImage src={item.image} style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }} />
            }
        }
        if (name === 'category') {
            if (!item.category) return ''
            for (let c in this.state.categories) {
                if (this.state.categories[c].id === item.category) {
                    return `${this.state.categories[c].name}`
                }
            }

            return '-'
        }
        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }

        if (name === 'soldout') {
            return item.is_active && <button className={"button is-small " + (item.isSoldout ? "is-warning" : "is-success")} onClick={() => { this.toggleSoldout(item.id, !item.isSoldout) }}>{(item.isSoldout ? "Soldout" : "Instock")}</button>
        }

        return result
    }

    validate(current_entity) {
        if (AppConfig.build === 'locall') {
            return !current_entity.name || !(current_entity.price >= 0) || !(current_entity.cost >= 0)
        }
        else {
            return !current_entity.name || !(current_entity.price >= 0)
        }
    }

    handleDescriptionChange(e) {
        super.handleDescriptionChange(e)

        let name = e.target.name

        let updateState = this.props.current_entity;
        let { current_store_data } = this.props
        console.log(getConfig('set_price_by_cost'))
        if (getConfig('set_price_by_cost')) {
            let markup_ratio = current_store_data.markup_ratio || 1.15

            if (name === 'cost') {
                console.log('cost')
                updateState.price = Math.ceil(markup_ratio * updateState.cost)
            }

            else if (name.includes('.cost')) {
                eval(`updateState.${name.replace('.cost', '.price')} = Math.ceil(updateState.${name} * markup_ratio)`)
            }
        }

        this.setState({ item_desc: updateState })
        this.props.setCurrentEntity(updateState)

    }

    addExtraOption() {
        let item = this.props.current_entity;
        if (!item.extra_options) item.extra_options = []
        item.extra_options.push({ options: [{}] })
        this.setState({ item_desc: item })
    }

    removeExtraOption(i) {
        let item = this.props.current_entity;
        item.extra_options.splice(i, 1)
        this.setState({ item_desc: item })
    }

    addExtraOptionItem(extra) {
        if (!extra.options) extra.options = []
        extra.options.push({})
        this.forceUpdate()
    }

    removeExtraOptionItem(extra, i) {
        extra.options.splice(i, 1)
        this.forceUpdate()
    }

    renderEditor() {
        let { current_entity, current_store_data } = this.props
        current_entity.extra_options = current_entity.extra_options || []

        return <div><div className="columns">
            <div className="column is-three-quarters">
                {(this.props.current_store.indexOf('locall') >= 0 || this.props.current_store.indexOf('demo') >= 0) &&
                    <div className="field">
                        <label className="label is-small">ร้านค้า</label>
                        <div className="control">
                            <select className="input is-small"
                                name="store_id"
                                type="text"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.store_id || null}>
                                <option value={null}></option>
                                {this.state.hubStores && this.state.hubStores.map((x, i) =>
                                    <option key={x.id} value={x.id}>{x.name}</option>
                                )}
                            </select>
                        </div>
                    </div>

                }
                <div className="field">
                    <label className="label is-small">ชื่อ</label>
                    <div className="control">
                        <input className="input is-small"
                            name="name"
                            type="text"
                            placeholder=""
                            onChange={this.handleDescriptionChange}
                            value={current_entity.name} />
                    </div>
                </div>


                <div className="field">
                    <label className="label is-small">หมวดหมู่</label>
                    <div className="control">
                        <select className="input is-small"
                            name="category"
                            placeholder=""
                            onChange={this.handleDescriptionChange}
                            value={current_entity.category || null}>
                            <option value={null}></option>
                            {this.state.categories && this.state.categories.map((x, i) => x.isGlobal ? <option key={x.id} value={x.id}>G: {x.name}</option> : <option key={x.id} value={x.id}>{x.name}</option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="field">
                    <div className="columns">
                        <div className="column">
                            <label className="label is-small">ราคาขาย</label>
                            <div className="control">
                                <input className="input is-small"
                                    style={{ background: '#eee' }}
                                    name="price"
                                    type="number"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.price}
                                    readOnly={getConfig('set_price_by_cost')} />
                            </div>
                        </div>
                        <div className="column">
                            <label className="label is-small">ต้นทุน</label>
                            <div className="control">
                                <input className="input is-small"
                                    name="cost"
                                    type="number"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.cost} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="field">
                    <label className="label is-small">รายละเอียด</label>
                    <div className="control">
                        <textarea className="textarea is-small"
                            name="description"
                            type="text"
                            placeholder=""
                            onChange={this.handleDescriptionChange}
                            value={current_entity.description}>
                        </textarea>
                    </div>
                </div>

                <label className="label is-small">ขายเฉพาะช่วงเวลา (หากขายตลอดเวลาที่ร้านเปิด ไม่จำเป็นต้องระบุ)</label>
                <label className="label">ขายเฉพาะช่วงเวลา (หากขายตลอดเวลาที่ร้านเปิด ไม่จำเป็นต้องระบุ)</label>
                <div className="field">
                    <div className="columns">
                        <div className="column">
                            <label className="label is-small">ขายตั้งแต่วันที่</label>
                            <div className="control has-icons-left has-icons-right">
                                <input className="input is-small"
                                    name="startDate"
                                    type="date"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.startDate} />
                                <span className="icon is-small is-left">
                                    <i className="fa fa-clock"></i>
                                </span>
                            </div>
                        </div>

                        <div className="column">
                            <label className="label is-small">ถึงวันที่</label>
                            <div className="control has-icons-left has-icons-right">
                                <input className="input is-small"
                                    name="endDate"
                                    type="date"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.endDate} />
                                <span className="icon is-small is-left">
                                    <i className="fa fa-clock"></i>
                                </span>
                            </div>
                        </div>

                        <div className="column">
                            <label className="label is-small">ขายช่วงเวลา</label>
                            <div className="control has-icons-left has-icons-right">
                                <select className="input is-small"
                                    name="startTime"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.startTime || null}>
                                    <option value={null}>ไม่กำหนด</option>
                                    {[...Array(48)].map((x, i) =>
                                        <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                    )}
                                </select>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-clock"></i>
                                </span>
                            </div>
                        </div>
                        <div className="column">
                            <label className="label is-small">ถึงเวลา</label>
                            <div className="control has-icons-left has-icons-right">
                                <select className="input is-small"
                                    name="endTime"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.endTime || null}>
                                    <option value={null}>ไม่กำหนด</option>
                                    {[...Array(48)].map((x, i) =>
                                        <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                    )}
                                </select>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-clock"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <label className="checkbox is-small"><input type="checkbox" name="preorderOverTime" onChange={this.handleDescriptionChange} checked={current_entity.preorderOverTime || false} />  สั่งล่วงหน้าเท่านั้น</label>
                    {current_entity.preorderOverTime && <div className="columns">
                        <div className="column">
                            <label className="label is-small">จำนวนวันที่ต้องสั่งล่วงหน้า</label>
                            <div className="control">
                                <select className="input is-small"
                                    name="preorderDay"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.preorderDay || null}>
                                    <option value={null}>ไม่กำหนด</option>
                                    {[...Array(30)].map((x, i) =>
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="column">
                            <label className="label is-small">เวลา</label>
                            <div className="control has-icons-left has-icons-right">
                                <select className="input is-small"
                                    name="preorderTime"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.preorderTime || null}>
                                    <option value={null}>ไม่กำหนด</option>
                                    {[...Array(48)].map((x, i) =>
                                        <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                    )}
                                </select>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-clock"></i>
                                </span>
                            </div>
                        </div>
                    </div>}
                </div>
                <hr />
                <div className="field options">
                    <label className="label">ตัวเลือก - เลือก 1 อย่าง (ถ้ามี) เช่น เส้นเล็ก เส้นใหญ่ เส้นหมื่</label>
                    <div className="columns is-mobile">
                        <div className="column is-half">ชื่อ</div>
                        <div className="column">จ่ายเพิ่ม (บาท)</div>
                        <div className="column">ราคา</div>
                        <div className="column">ต้นทุน</div>
                        <div className="column"></div>
                        <div className="column"></div>
                    </div>
                    {current_entity.options.map((item, i) =>
                        <div className="columns is-mobile">
                            <div className="column  is-half">
                                <div className="control">
                                    <input className="input is-small"
                                        name={`options[${i}].name`}
                                        type="text"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={item.name} />

                                </div>
                            </div>
                            <div className="column">
                                <div className="control">
                                    <input className="input is-small"
                                        name={`options[${i}].price`}
                                        type="number"
                                        placeholder="addition Price"
                                        onChange={this.handleDescriptionChange}
                                        value={item.price}
                                        readOnly={getConfig('set_price_by_cost')} />

                                </div>

                            </div>
                            <div className="column">{(current_entity.price || 0) + (item.price || 0)}</div>

                            <div className="column">
                                <div className="control">
                                    <input className="input is-small"
                                        name={`options[${i}].cost`}
                                        type="number"
                                        placeholder="Cost"
                                        onChange={this.handleDescriptionChange}
                                        value={item.cost} />

                                </div>
                            </div>
                            <div className="column">
                                <button className={"button is-small " + (item.isSoldout ? "is-warning" : "is-success")} onClick={() => { item.isSoldout = !item.isSoldout; this.forceUpdate() }}>{(item.isSoldout ? "Soldout" : "Instock")}</button>
                            </div>
                            <div className="column">
                                <div className="control">
                                    <button className="button is-warning is-small" onClick={() => this.removeOption(i)}>
                                        <i className="fa fa-minus" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <button className="button is-info is-small" onClick={() => this.addOption()}><i className="fa fa-plus" />เพิ่มตัวเลือก</button>
                </div>
                <hr />
                <div className="field options">
                    <label className="label">ตัวเลือกเพิ่มเติม (ถ้ามี)</label>
                    {current_entity.extra_options && current_entity.extra_options.map((extra, ei) => <div className="extra-options-wrapper">
                        <div className="columns is-mobile">
                            <div className="column is-half">
                                <div className="field" >
                                    <label className="label is-small">หัวข้อ</label>
                                    <div className="control has-icons-right">
                                        <input className="input is-small"
                                            name={`extra_options[${ei}].title`}
                                            type="text"
                                            placeholder=""
                                            onChange={this.handleDescriptionChange}
                                            value={extra.title} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="column">
                                <div className="field" >
                                    <label className="label is-small">วิธีการเลือก</label>
                                    <div className="control has-icons-right">
                                        <div className="select is-small">
                                            <select
                                                name={`extra_options[${ei}].method`}
                                                onChange={this.handleDescriptionChange}
                                                value={extra.method}>
                                                <option value="select_one">เลือก 1 อย่าง</option>
                                                <option value="select_many">เลือกหลายอย่าง</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="column">
                                <div className="field" >
                                    <label className="label is-small">เลือกอย่างน้อย</label>
                                    <div className="control has-icons-right">
                                        <input className="input is-small"
                                            name={`extra_options[${ei}].minimum`}
                                            type="text"
                                            placeholder=""
                                            onChange={this.handleDescriptionChange}
                                            value={extra.minimum} />
                                    </div>
                                </div>
                            </div>
                            <div className="column">
                                <div className="field" >
                                    <label className="label is-small">เลือกได้มากที่สุด</label>
                                    <div className="control has-icons-right">
                                        <input className="input is-small"
                                            name={`extra_options[${ei}].maximum`}
                                            type="text"
                                            placeholder=""
                                            onChange={this.handleDescriptionChange}
                                            value={extra.maximum} />
                                    </div>
                                </div>
                            </div>
                            <div className="column" style={{ flexGrow: 0, flexBasis: 'auto' }}>
                                <div className="control has-text-right">
                                    <label className="label is-small">ลบหัวข้อ?</label>
                                    <button className="button is-danger is-small" onClick={() => this.removeExtraOption(ei)}>
                                        <i className="fa fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="extra-options">
                            <div className="columns is-mobile">
                                <div className="column is-half"><label className="label is-small">ตัวเลือก</label></div>
                                <div className="column"><label className="label is-small">จ่ายเพิ่ม (บาท)</label></div>
                                <div className="column"><label className="label is-small">ต้นทุน</label></div>
                                <div className="column"></div>
                                <div className="column"></div>
                            </div>

                            {extra.options && extra.options.map((item, i) =>
                                <div className="columns is-mobile extra-option-item">
                                    <div className="column  is-half">
                                        <div className="control">
                                            <input className="input is-small"
                                                name={`extra_options[${ei}].options[${i}].name`}
                                                type="text"
                                                placeholder=""
                                                onChange={this.handleDescriptionChange}
                                                value={item.name} />

                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="control">
                                            <input className="input is-small"
                                                name={`extra_options[${ei}].options[${i}].price`}
                                                type="number"
                                                placeholder="addition Price"
                                                onChange={this.handleDescriptionChange}
                                                value={item.price}
                                                readOnly={getConfig('set_price_by_cost')} />

                                        </div>

                                    </div>

                                    <div className="column">
                                        <div className="control">
                                            <input className="input is-small"
                                                name={`extra_options[${ei}].options[${i}].cost`}
                                                type="number"
                                                placeholder="Cost"
                                                onChange={this.handleDescriptionChange}
                                                value={item.cost} />

                                        </div>
                                    </div>
                                    <div className="column" style={{ flexGrow: 0, flexBasis: 'auto' }}>
                                        <button className={"button is-small " + (item.isSoldout ? "is-warning" : "is-success")} onClick={() => { item.isSoldout = !item.isSoldout; this.forceUpdate() }}>{(item.isSoldout ? "Soldout" : "Instock")}</button>
                                    </div>
                                    <div className="column has-text-right" style={{ flexGrow: 0, flexBasis: 'auto' }}>
                                        <button className="button is-warning is-small" onClick={() => this.removeExtraOptionItem(extra, i)}>
                                            <i className="fa fa-minus" />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <br />
                        <button className="button is-info is-small" onClick={() => this.addExtraOptionItem(extra)}><i className="fa fa-plus" />เพิ่มตัวเลือก</button>
                    </div>)}

                    <button className="button is-success" onClick={() => this.addExtraOption()}><i className="fa fa-plus" />เพิ่มหัวข้อตัวเลือก</button>
                </div>
                <hr />
                <div>
                    <div className="columns">
                        <div className="column">
                            <div className="field">
                                <label className="label is-small">การเรียงลำดับ</label>
                                <div className="control">
                                    <input className="input is-small"
                                        name="sort_order"
                                        type="number"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.sort_order} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="column is-one-quarter">
                <div className="field">
                    <label className="label is-small">Images</label>
                    <div className="control">
                        <input type="file" onChange={this.handleFileChange} />
                        {this.state.file && <img src={this.state.fileObjURL} />}
                        {!this.state.file && <CloudImage src={current_entity.image} className="thumbnail" />}
                    </div>
                </div>
            </div>
        </div>
            {current_entity.id && <div>Direct Link: <a href={`${AppConfig.liffUrl}/${this.props.current_store}/?i=${current_entity.id}`}>{AppConfig.liffUrl}/{this.props.current_store}/?i={current_entity.id}</a></div>}

            <div className="mt10">
                <button className="button is-primary is-fullwidth   " onClick={() => this.save(current_entity)} disabled={this.validate(current_entity)}>SAVE</button>
            </div>
        </div>
    }
}

const mapStateToProps = ({ current_user, current_entity, current_store, current_store_data }) => ({ current_user, current_entity, current_store, current_store_data });
export default withRouter(connect(mapStateToProps, actions)(MenuEditor))