import FirebaseModel from './FirebaseModel';

export default class StoreRequest extends FirebaseModel {

}

StoreRequest.collection = 'store_requests';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();