import { combineReducers } from 'redux'
import current_user from './current_user'
import user_roles from './user_roles'
import line_user from './line_user'
import current_store from './current_store'
import current_store_data from './current_store_data'
import current_city from './current_city'
import current_entity from './current_entity'
import app_params from './app_params'
import stores_map from './stores_map'

export default combineReducers({
    current_user, user_roles, line_user, current_store, current_entity, app_params, current_city, current_store_data, stores_map
})