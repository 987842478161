export function setAppParams(payload) {
    return { type: 'APP_PARAMS_SET', payload }
}
export function setCurrentUser(payload) {
    return { type: 'USER_SET', payload }
}
export function setUserRoles(payload) {
    return { type: 'USER_ROLE_SET', payload }
}
export function setStoresMap(payload) {
    return { type: 'stores_map_SET', payload }
}
export function setLineUser(payload) {
    return { type: 'LINE_USER_SET', payload }
}
export function setCurrentStore(payload) {
    return { type: 'CURRENT_STORE_SET', payload }
}
export function setCurrentStoreData(payload) {
    return { type: 'CURRENT_STORE_DATA_SET', payload }
}
export function setCurrentCity(payload) {
    return { type: 'CURRENT_CITY_SET', payload }
}
export function setCurrentEntity(payload) {
    return { type: 'CURRENT_ENTITY_SET', payload }
}
export function handleCurrentEntityChange(payload) {
    return { type: 'CURRENT_ENTITY_HANDLE_CHANGE', payload }
}
