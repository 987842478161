import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions/index'
import BaseEditor from './BaseEditor';
import StoreCoupon from '../models/StoreCoupon';
import StorePromotion from '../models/StorePromotion';
import { CloudImage } from './CloudImage';
import { dateFormat } from '../Helper';

var promotionDataRef = null;//db.collection("promotions");

class CouponEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Coupons";
        this.canCreate = true;
        this.listFields = ['code', 'limit', 'used', 'date', 'time', 'active']

    }


    componentDidMount() {
        this.dataRef = StoreCoupon.getCollection(this.props.current_store);
        promotionDataRef = StorePromotion.getCollection(this.props.current_store);
        super.componentDidMount()
        this.loadPromotions()
    }

    fieldData(item, name) {
        let result = super.fieldData(item, name)

        if (name === 'date') {
            if (item.startDate && item.endDate) {
                return `${dateFormat(item.startDate, false)} - ${dateFormat(item.endDate, false)}`
            }
            return 'All date'
        }

        if (name === 'time') {
            if (item.startTime && item.endTime) {
                return `${item.startTime} - ${item.endTime}`
            }
            return 'All time'
        }

        if (name === 'image') {
            if (item.image) {
                return <CloudImage src={item.image} style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }} />
            }
        }

        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }

        return result
    }

    loadPromotions() {
        promotionDataRef.get().then((querySnapshot) => {
            var children = [];
            querySnapshot.forEach((doc) => {

                var childKey = doc.id;
                var childData = doc.data();
                childData.id = childKey;
                children.push(childData)
            });
            this.setState({ promotions: children });
        });
    }

    handleDescriptionChange(e) {
        if (e.target.name === 'code') {
            var ss = e.target.selectionStart;
            var se = e.target.selectionEnd;
            e.target.value = e.target.value.toUpperCase();
            e.target.selectionStart = ss;
            e.target.selectionEnd = se;
        }
        super.handleDescriptionChange(e);
    }

    // renderListHeader() {
    //     return <tr><th>Code</th><th>Limit</th><th>Used</th><th>Date</th><th>Time</th><th>Active</th><th>Action</th></tr>;
    // }
    // renderListRow() {
    //     return this.state.items.map((item, key) =>
    //         <tr key={item.id}>
    //             {/* <td onClick={() => {
    //                 //this.loadEditor(item)
    //             }}>{item.code}</td>
    //             <td>{item.limit} </td>
    //             <td>{item.used} </td> */}
    //             <td>{item.startDate} - {item.endDate}</td>
    //             <td>{item.startTime} - {item.endTime} </td>
    //             <td><button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button></td>
    //             <td>
    //                 <button className="button is-primary is-small" onClick={() => { this.clone(item) }} >DUPLICATE</button>&nbsp;
    //                 <button className="button is-warning is-small" onClick={() => { this.loadEditor(item) }} ><i className="fa fa-edit" /></button>
    //                 <button className="button is-danger is-small" onClick={() => { this.delete(item.id) }} ><i className="fa fa-trash" />
    //                 </button></td>
    //         </tr>
    //     )
    // }

    renderEditor() {
        let { current_entity } = this.props
        return <div>
            <div className="field">
                <label className="label is-small">Code</label>
                <div className="control has-icons-left has-icons-right">
                    <input className="input is-small"
                        name="code"
                        type="text"
                        placeholder=""
                        onChange={this.handleDescriptionChange}
                        value={current_entity.code} />
                    <span className="icon is-small is-left">
                        <i className="fa fa-tag"></i>
                    </span>

                </div>
            </div>
            <div className="field">
                <label className="label is-small">Promotion</label>
                <div className="control">
                    <select className="select"
                        name="promotion"
                        placeholder=""
                        onChange={this.handleDescriptionChange}
                        value={current_entity.promotion}>
                        <option selected disabled={true}>Please select</option>
                        {
                            this.state.promotions && this.state.promotions.map((item) => {
                                return <option value={item.id}>{item.name}</option>
                            })
                        }

                    </select>

                </div>
            </div>

            <div className="field">
                <label className="label is-small">Limit</label>
                <div className="control has-icons-left has-icons-right">
                    <input className="input is-small"
                        name="limit"
                        type="number"
                        placeholder=""
                        onChange={this.handleDescriptionChange}
                        value={current_entity.limit} />
                    <span className="icon is-small is-left">
                        <i className="fa fa-tag"></i>
                    </span>

                </div>
            </div>
            <div className="field">
                <div className="columns">
                    <div className="column">
                        <label className="label is-small">From</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input is-small"
                                name="startDate"
                                type="date"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.startDate} />
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>


                    <div className="column">
                        <label className="label is-small">To</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input is-small"
                                name="endDate"
                                type="date"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.endDate} />
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>


                    <div className="column">
                        <label className="label is-small">Time</label>
                        <div className="control has-icons-left has-icons-right">
                            <select className="input is-small"
                                name="startTime"
                                type="text"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.startTime}>
                                <option value={null} selected ></option>
                                {[...Array(48)].map((x, i) =>
                                    <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                )}
                            </select>
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>
                    <div className="column">
                        <label className="label is-small">To</label>
                        <div className="control has-icons-left has-icons-right">
                            <select className="input is-small"
                                name="endTime"
                                type="text"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.endTime}>
                                <option value={null} selected ></option>
                                {[...Array(48)].map((x, i) =>
                                    <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                )}
                            </select>
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt10">
                <button className="button is-primary" onClick={() => this.save(current_entity)} >SAVE</button>
            </div>
        </div>
    }
}

const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(CouponEditor))