import FirebaseModel from './FirebaseModel';

export default class Payment extends FirebaseModel {

}

Payment.collection = 'payments';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();