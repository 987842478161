import * as firebase from 'firebase';

var db = firebase.firestore();

class Running {
    constructor(ref_name, size = 20) {
        this.dataRef = db.collection('runnings');
        this.ref = this.dataRef.doc(ref_name);
        this.num_shards = size;
        this.ready = false;

        var $this = this;
        return this.ref.get().then(function (doc) {

            if (!doc.exists) {
                return $this.createCounter().then(() => {
                    $this.ready = true;
                    return $this;
                })
            }
            else {
                $this.ready = true;
            }

            return $this;
        })
    }

    // [START create_counter]
    createCounter() {

        var num_shards = this.num_shards;
        var ref = this.ref;
        var batch = db.batch();

        // Initialize the counter document
        batch.set(ref, { num_shards: num_shards });

        // Initialize each shard with count=0
        for (let i = 0; i < num_shards; i++) {
            let shardRef = ref.collection('shards').doc(i.toString());
            batch.set(shardRef, { count: 0 });
        }

        // Commit the write batch
        return batch.commit();
    }
    // [END create_counter]

    // [START increment_counter]
    incrementCounter() {
        var ref = this.ref;
        var num_shards = this.num_shards;
        // Select a shard of the counter at random
        const shard_id = Math.floor(Math.random() * num_shards).toString();
        const shard_ref = ref.collection('shards').doc(shard_id);

        // Update count
        return shard_ref.update("count", firebase.firestore.FieldValue.increment(1));
    }
    // [END increment_counter]

    // [START get_count]
    getCount() {
        var ref = this.ref;
        // Sum the count of each shard in the subcollection
        return ref.collection('shards').get().then(snapshot => {
            let total_count = 0;
            snapshot.forEach(doc => {
                total_count += doc.data().count;
            });

            return total_count;
        });
    }
}

export default Running;