import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as Sentry from '@sentry/browser';
import * as firebase from 'firebase';
import * as AppConfig from '../AppConfig.json';
import * as actions from '../actions/index'
import StoreOrder from '../models/StoreOrder';
import Store from '../models/Store';
import { mpTrackEvent } from '../Helper';

var storageRef = firebase.storage().ref();

class TransferSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order_id: null,
            data: null
        }

        // alert(decodeURIComponent(this.props.location.search.replace("liff.state=%3F", "")).replace("?", ""))
        this.handleFileChange = this.handleFileChange.bind(this)

    }

    componentDidMount() {
        let { current_store } = this.props
        if (current_store) {
            this.setState({ order_id: this.props.match.params.order_id })
            this.loadOrder()
        }

    }

    async componentDidUpdate() {
        // 
        let { current_store } = this.props
        if (current_store) {
            if (this.state.order_id !== this.props.match.params.order_id) {
                await this.setState({ order_id: this.props.match.params.order_id })

                await this.loadOrder()
            }
        }
    }

    async loadOrder() {
        // 
        let { current_store } = this.props
        // Sentry.captureMessage(`reloadList ${current_store}`);
        // 
        if (current_store) {
            // console.log(current_store)
            let store = await Store.get(current_store)
            if (store.exists) {
                // console.log("Store data", store.data())

                this.setState({ storeData: store.data() })
            }
        }

        this.orderRef = StoreOrder.setStore(current_store).getCollection();
        let docRef = await this.orderRef.doc(this.state.order_id).get()
        let data = await docRef.data();
        console.log(data)
        data.id = docRef.id
        this.setState({ data })

        // orderRef.add(item).then(function (docRef) {
        //     item.id = docRef.id
        //     $this.setState({ order: item })
        // })
        //     .catch(function (error) {
        //         console.error("Error adding document: ", error);
        //     });
    }

    handleFileChange(event) {
        let $this = this;
        let target = event.currentTarget
        if (target.files.length === 0) return;
        let file = target.files[0]

        this.setState({
            file: file,
            fileObjURL: URL.createObjectURL(file)
        })

        var fr = new FileReader();
        fr.onload = function (e) {
            let base64Image = this.result.replace(/^data:image\/(png|jpeg);base64,/, "");
            $this.setState({ 'base64Image': base64Image })
        };
        fr.readAsDataURL(file);
    }

    save = (data = null) => {
        this.upload(this.state.order_id, this.state.file.type, this.state.base64Image)


    };

    lineNotify(message) {
        let { storeData } = this.state
        console.log(message)
        // 
        if (message && storeData && storeData.lnt_access_token) {
            fetch(`${AppConfig.firebase.functionEndpoint}sendLineNotify`, { // Your POST endpoint
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ message: message, token: storeData.lnt_access_token })

            }).then(
                response => response.json() // if the response is a JSON object
            ).then(
                success => {
                    console.log(success) // Handle the success response object

                }
            ).catch(
                error => {
                    console.log(error) // Handle the error response object

                }
            );
        }
    }

    upload = (itemId, type, base64str) => {
        let $this = this;
        var filename = 'slip/' + itemId + '.' + type.replace('image/', '');
        var fileRef = storageRef.child(filename);



        fileRef.putString(base64str, 'base64').then(function (snapshot) {
            var itemRef = $this.orderRef.doc(itemId);


            itemRef.update({ image: filename }).then(function () {
                if (window.liff && window.liff.getOS() !== 'web') {
                    window.liff.sendMessages([
                        {
                            type: 'flex',
                            altText: "รายการสั่งอาหาร",
                            contents:
                            {
                                "type": "bubble",
                                "body": {
                                    "type": "box",
                                    "layout": "vertical",
                                    "contents": [
                                        {
                                            "type": "text",
                                            "weight": "bold",
                                            "text": "หลักฐานการชำระเงิน",
                                            "size": "lg"
                                        },
                                        {
                                            "type": "image",
                                            "url": `https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(filename)}?alt=media`,
                                            "margin": "none",
                                            "aspectMode": "fit",
                                            "gravity": "top",
                                            "aspectRatio": "3:4.5",
                                            "offsetTop": "0px",
                                            "offsetBottom": "0px",
                                            "offsetEnd": "0px",
                                            "offsetStart": "0px",
                                            "size": "full"
                                        }
                                    ],
                                    "spacing": "none",
                                    "margin": "none",
                                    "flex": 1
                                }
                            }
                        },
                        {
                            type: 'text',
                            text: `หลักฐานการโอนเงินรายการสั่งอาหาร\n #${$this.state.order_id}`
                        }
                    ])

                    // $this.lineNotify(`ส่งหลักฐานการโอนเงินรายการสั่งอาหาร\n #${$this.state.order_id}\n https://firebasestorage.googleapis.com/v0/b/locall-bkk.appspot.com/o/${encodeURIComponent(filename)}?alt=media`);

                    window.liff.closeWindow();
                }
                else {
                    $this.setState({ state: 'complete' })
                    mpTrackEvent("Send payment proof", { "Send  payment proof": "Yes" })
                }
            })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                    $this.setState({ loading: false, error_message: error })
                });
        });
    };

    render() {
        let { current_store } = this.props
        let { data, fileObjURL } = this.state

        if (this.state.state === 'complete') {
            return <div className="has-text-centered mt20">
                <h1 className="title is-3">ทางร้านได้รับข้อมูลของท่านแล้ว</h1>
                ขอบคุณที่ใช้บริการ</div>
        }

        return <div className="">
            {data && <div style={{ textAlign: "center" }} className="mt20">
                <h1 className="title is-3">ส่งหลักฐานการโอนเงิน</h1>
                <img src={`/${current_store}_transfer.jpg`} style={{ width: '100%', maxWidth: '350px' }} />
                <h2 className="subtitle is-5 mt20 hilight">จำนวนเงินที่ต้องโอน {data.totalPrice} บาท</h2>
                <div className="image-upload-button">
                    <input type="file" onChange={e => this.handleFileChange(e)} />
                </div>
                <br />

                <br />
                {fileObjURL && <button className="button is-large is-primary" onClick={() => this.save()}>ส่งหลักฐาน</button>}
            </div>}
        </div>
    }
}
const mapStateToProps = ({ users, line_user, current_store }) => ({ users, line_user, current_store });
export default withRouter(connect(mapStateToProps, actions)(TransferSubmit))