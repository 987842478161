import * as Sentry from '@sentry/browser';
import React from "react"
import { withRouter } from "react-router";
import * as firebase from 'firebase';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as AppConfig from '../AppConfig.json';
import { withCookies } from 'react-cookie';
import { CloudImage } from './CloudImage';
import { sendRN, isNative, mpTrackEvent } from '../Helper';
import StoreDayoff from '../models/StoreDayoff';
import moment from 'moment'
import LineAddNotify from './LineAddNotify';
import * as mixpanel from 'mixpanel-browser';
import City from '../models/City';
// mixpanel.init("b651ba333baa36378e4387e74c1587fc"); mixpanel.track("Hello World");

class CityList extends React.Component {
    constructor() {
        super()
        this.state = { storeDayOff: {}, loading: false }
    }

    async componentDidMount() {
        let cities = await (await City.getAll()).filter(x => x.is_active)

        this.setState({ cities })
        firebase.analytics().logEvent('page_view', { page_title: `City List` })
    }

    componentDidUpdate() {

    }


    loadCity(city) {
        if (AppConfig.build === 'locall') {
            mixpanel.track("Select city", { "Restaurant City": city });
        }

        // if (isNative()) {
        //     sendRN({ action: "loadCity", params: { city } })
        // }
        // else {
        this.props.setCurrentCity(city)
        // }
    }

    render() {


        let { cities, loading } = this.state
        if (loading) return <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /><br />กำลังโหลดข้อมูลเมือง</div>

        // 
        return <div className="store-application store-list" style={{ padding: '15px' }}>
            <LineAddNotify lineAccount={AppConfig.lineAccount} />

            <div>
                <div className="quicksearch front-search" style={{ width: 'calc(100% - 80px)', display: 'inline-block' }}>
                    <input type="text" className="input" value={this.state.query} onChange={(e) => {
                        if (e.target.value.length >= 4) {
                            mpTrackEvent("Search", {
                                "Search Term": e.target.value,
                            });
                        }

                        this.setState({ query: e.target.value })
                    }} placeholder="ค้นหาจากชื่อร้าน/ชื่ออาหาร" />
                    <i className="fa fa-search" />
                    {this.state.query && <button className="search-reset-button" onClick={(e) => { this.setState({ query: null }) }}><i className="fa fa-times" /></button>}
                </div>
                <button className="button is-small is-primary" style={{ width: 70, height: 30, borderRadius: 20, paddingLeft: 5 }} onClick={() => this.props.history.push(`/search/${this.state.query}`)}>ค้นหา</button>
            </div>

            {/* <div><a onClick={() => this.props.setCurrentStore('locall')}><CloudImage src="hubs/locall.jpg" /></a></div>
            <div><a onClick={() => this.props.setCurrentStore('locall.yaowarat')}><CloudImage src="hubs/locall.yaowarat.jpg" /></a></div> */}
            {/* {this.storeList.map(x => <div key={x} className={`${storeDayOff[x] && 'closed'}`}><a onClick={() => this.props.setCurrentStore(x)}><CloudImage src={`hubs/${x}.jpg`} /></a></div>)} */}
            {cities && cities.map(x => <div style={{ margin: '0 0 4px', border: '1px solid #ccc' }}><a onClick={() => this.loadCity(x.id)}><CloudImage src={x.image || `hubs/locall_city_${x.id}.jpg`} /></a></div>)}
            {/* <div style={{ margin: '0 0 4px', border: '1px solid #ccc' }}><a onClick={() => this.loadCity('bangkok')}><CloudImage src="hubs/locall_city_bangkok.jpg" /></a></div>
            <div style={{ margin: '0 0 4px', border: '1px solid #ccc' }}><a onClick={() => this.loadCity('chiangmai')}><CloudImage src="hubs/locall_city_chiangmai.jpg" /></a></div>
            <div style={{ margin: '0 0 4px', border: '1px solid #ccc' }}><a onClick={() => this.loadCity('saraburi')}><CloudImage src="hubs/locall_city_saraburi.jpg" /></a></div> */}

        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store }) => ({ current_entity, current_store });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(CityList)))
