import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions/index';
import * as AppConfig from '../AppConfig.json';
import * as firebase from 'firebase';

import { CloudImage } from './CloudImage';
import BaseEditor from './BaseEditor';
import StoreProduct from '../models/StoreProduct';
import { dateFormat } from '../Helper';
import HubStoreProduct from '../models/HubStoreProduct';
import InputImageHolder from './InputImageHolder';
import InputToggleButton from './InputToggleButton';

class ProductEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Products";
        this.dataRef = StoreProduct.getCollection()

        this.canCreate = true;
        this.defaultValue = { name: "", startDate: null, endDate: null, startTime: null, endTime: null, options: [] };
        this.listFields = ['image', 'name', 'store', 'date', 'time', 'active', 'stock']
        this.searchFields = ['name', 'store']
        // this.editFields = [['firstname', 'lastname'], ['mobile', 'email'], 'gender', 'grade']
        this.entityInfo = {
            name: 'String',
        }

        this.createOptions = this.createOptions.bind(this)
        this.toggleDelivery = this.toggleDelivery.bind(this)
        this.getDeliveryPrice = this.getDeliveryPrice.bind(this)
        this.getDeliveryActive = this.getDeliveryActive.bind(this)
    }

    async componentDidMount() {
        // super.componentDidMount()
        let { current_store } = this.props
        let hubStores = await HubStoreProduct.setStore(current_store).getAll()
        this.setState({ hubStores })

        let { orders } = this.props
        let { current_entity } = this.props
        orders = orders || []
        this.setState({ orders })


        let $this = this;
        this.unsubscribe = this.dataRef.where('type', '==', 'product')
            .onSnapshot(querySnapshot => {
                var children = [];
                querySnapshot.forEach((doc) => {
                    var childKey = doc.id;
                    var childData = doc.data();
                    childData.id = childKey;
                    children.push(childData)
                });
                $this.setState({ items: children }, () => {
                    $this.applyFilter($this.state.filter)

                    if ($this.props.location.search) {

                        for (let c in children) {
                            if (!current_entity && children[c].id === $this.props.location.search.replace("?", "") && (!$this.state.currentItem || ($this.state.currentItem && $this.state.currentItem.id !== children[c].id))) {
                                $this.loadEditor(children[c])
                            }
                        }
                    }
                })

                // this.unsubscribe();
            });
    }

    reloadList() {
        this.dataRef.where('type', '==', 'product').get().then((querySnapshot) => {
            var children = [];
            querySnapshot.forEach((doc) => {

                var childKey = doc.id;
                var childData = doc.data();
                childData.id = childKey;
                children.push(childData)
            });

            this.setState({ items: children });
        });

    }

    toggleSoldout(item_id, active) {
        let $this = this;

        for (let e in this.state.items) {
            if (this.state.items[e].id === item_id) {
                this.state.items[e].isSoldout = active
                this.forceUpdate()
            }
        }

        this.dataRef.doc(item_id).update({ isSoldout: active }).then(function () {
            // $this.reloadList();
            $this.setState({
                loading: false,
            })
        }).catch(function (error) {
            console.error("Error removing document: ", error);
            $this.setState({
                loading: false,
                error_message: error
            })
        });
    }

    fieldData(item, name) {
        let result = super.fieldData(item, name)
        let hubStoresById = HubStoreProduct.getItemsById()

        if (name === 'store') {
            return hubStoresById[item.store_id] && hubStoresById[item.store_id].name
        }
        if (name === 'date') {
            if (item.startDate && item.endDate) {
                return `${dateFormat(item.startDate, false)} - ${dateFormat(item.endDate, false)}`
            }
            return 'All date'
        }

        if (name === 'time') {
            if (item.startTime && item.endTime) {
                return `${item.startTime} - ${item.endTime}`
            }
            return 'All time'
        }

        if (name === 'image') {
            if (item.image) {
                return <CloudImage src={item.image} style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }} />
            }
        }

        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }

        if (name === 'soldout') {
            return item.is_active && <button className={"button is-small " + (item.isSoldout ? "is-warning" : "is-success")} onClick={() => { this.toggleSoldout(item.id, !item.isSoldout) }}>{(item.isSoldout ? "Soldout" : "Instock")}</button>
        }

        return result
    }
    createOptions() {
        let { current_entity } = this.props
        current_entity.options = [{}]

        this.forceUpdate()
    }

    toggleDelivery(channel, value) {
        let { current_entity } = this.props
        if (!current_entity.delivery_channel) {
            current_entity.delivery_channel = {}
        }
        if (!current_entity.delivery_channel[channel]) {
            current_entity.delivery_channel[channel] = {}
        }

        current_entity.delivery_channel[channel].active = value
        this.forceUpdate()
    }

    getDeliveryPrice(channel) {
        let { current_entity } = this.props
        if (channel === 'delivery') return ''
        if (!current_entity.weight) return 'กรุณาระบุน้ำหนัก'
        return <span>&#3647; 39</span>
    }

    getDeliveryActive(channel) {
        let { current_entity } = this.props
        if (current_entity.delivery_channel && current_entity.delivery_channel[channel]) {
            return current_entity.delivery_channel[channel].active
        }

        return false
    }


    renderEditor() {
        let { current_entity } = this.props
        let isOptionsEnable = current_entity.options && current_entity.options.length > 0;
        let isHub = true || this.props.current_store.indexOf('locall') >= 0 || this.props.current_store.indexOf('demo') >= 0;

        current_entity.type = 'product'

        return <div><div className="columns">
            <div className="column is-three-quarters">
                {isHub &&
                    <div className="field">
                        <label className="label is-small">ร้านค้า</label>
                        <div className="control">
                            <select className="input is-small"
                                name="store_id"
                                type="text"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.store_id || null}>
                                <option value={null}></option>
                                {this.state.hubStores && this.state.hubStores.map((x, i) =>
                                    <option key={x.id} value={x.id}>{x.name}</option>
                                )}
                            </select>
                        </div>
                    </div>

                }
                <div className="field">
                    <label className="label is-small">ชื่อ</label>
                    <div className="control">
                        <input className="input is-small"
                            name="name"
                            type="text"
                            placeholder=""
                            onChange={this.handleDescriptionChange}
                            value={current_entity.name} />
                    </div>
                </div>

                <div className="field">
                    <label className="label is-small">รายละเอียด</label>
                    <div className="control">
                        <textarea className="textarea is-small"
                            name="description"
                            type="text"
                            placeholder=""
                            onChange={this.handleDescriptionChange}
                            value={current_entity.description}>
                        </textarea>
                    </div>
                </div>
                <div className="admin-panel dashboard-panel">
                    <label className="label is-small">ขายเฉพาะช่วงเวลา (หากขายตลอดเวลาที่ร้านเปิด ไม่จำเป็นต้องระบุ)</label>
                    <div className="field">
                        <div className="columns">
                            <div className="column">
                                <label className="label is-small">ขายตั้งแต่วันที่</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input is-small"
                                        name="startDate"
                                        type="date"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.startDate} />
                                    <span className="icon is-small is-left">
                                        <i className="fa fa-clock"></i>
                                    </span>
                                </div>
                            </div>

                            <div className="column">
                                <label className="label is-small">ถึงวันที่</label>
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input is-small"
                                        name="endDate"
                                        type="date"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.endDate} />
                                    <span className="icon is-small is-left">
                                        <i className="fa fa-clock"></i>
                                    </span>
                                </div>
                            </div>

                            <div className="column">
                                <label className="label is-small">ขายช่วงเวลา</label>
                                <div className="control has-icons-left has-icons-right">
                                    <select className="input is-small"
                                        name="startTime"
                                        type="text"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.startTime || null}>
                                        <option value={null}>ไม่กำหนด</option>
                                        {[...Array(48)].map((x, i) =>
                                            <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                        )}
                                    </select>
                                    <span className="icon is-small is-left">
                                        <i className="fa fa-clock"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="column">
                                <label className="label is-small">ถึงเวลา</label>
                                <div className="control has-icons-left has-icons-right">
                                    <select className="input is-small"
                                        name="endTime"
                                        type="text"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.endTime || null}>
                                        <option value={null}>ไม่กำหนด</option>
                                        {[...Array(48)].map((x, i) =>
                                            <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                        )}
                                    </select>
                                    <span className="icon is-small is-left">
                                        <i className="fa fa-clock"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="admin-panel dashboard-panel">
                    {!isOptionsEnable && <div className="field">
                        <div className="columns">
                            <div className="column">
                                <label className="label is-small">ราคาขาย</label>
                                <div className="control">
                                    <input className="input is-small"
                                        name="price"
                                        type="number"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.price} />
                                </div>
                            </div>
                            {isHub && <div className="column">
                                <label className="label is-small">ต้นทุน</label>
                                <div className="control">
                                    <input className="input is-small"
                                        name="cost"
                                        type="number"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.cost} />
                                </div>
                            </div>}
                            <div className="column">
                                <label className="label is-small">คลังสินค้า</label>
                                <div className="control">
                                    <input className="input is-small"
                                        name="stock"
                                        type="number"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.stock} />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {!isOptionsEnable && <div><button className="button-enable-options button is-info is-small is-outlined is-fullwidth" onClick={this.createOptions}><i className="fa fa-plus" /> เปิดใช้งานตัวเลือกสินค้า</button></div>}
                    {isOptionsEnable && <div className="field options">
                        <label className="label">ตัวเลือก</label>

                        <div className="product-options">
                            {current_entity.options.map((item, i) =>
                                <div className="option-item">
                                    <div className="columns is-multiline">
                                        {/* <div className="column column-image">
                                            <InputImageHolder />
                                        </div> */}
                                        <div className="column">
                                            <div className="columns is-mobile is-multiline">
                                                <div className="column is-full">
                                                    <button className="button button-remove is-warning is-small" onClick={() => this.removeOption(i)}>
                                                        <i className="fa fa-minus" />
                                                    </button>
                                                    <div className="control">
                                                        <label className="label is-small">ชื่อ</label>
                                                        <input className="input is-small"
                                                            name={`options[${i}].name`}
                                                            type="text"
                                                            placeholder=""
                                                            onChange={this.handleDescriptionChange}
                                                            value={item.name} />

                                                    </div>
                                                </div>

                                                <div className="column">
                                                    <div className="control">
                                                        <label className="label is-small">ราคา</label>
                                                        <input className="input is-small"
                                                            name={`options[${i}].real_price`}
                                                            type="number"
                                                            placeholder=""
                                                            onChange={(e) => { this.handleDescriptionChange(e); item.price = item.real_price - (current_entity.price || 0); this.forceUpdate() }}
                                                            value={item.real_price} />
                                                    </div>
                                                </div>

                                                {isHub && <div className="column">
                                                    <div className="control">
                                                        <label className="label is-small">ต้นทุน</label>
                                                        <input className="input is-small"
                                                            name={`options[${i}].cost`}
                                                            type="number"
                                                            placeholder="Cost"
                                                            onChange={this.handleDescriptionChange}
                                                            value={item.cost} />

                                                    </div>
                                                </div>}
                                                <div className="column">
                                                    <div className="control">
                                                        <label className="label is-small">คลังสินค้า</label>
                                                        <input className="input is-small"
                                                            name={`options[${i}].stock`}
                                                            type="number"
                                                            placeholder=""
                                                            onChange={this.handleDescriptionChange}
                                                            value={item.stock} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <button className="button-add-options button is-info is-small is-outlined is-fullwidth" onClick={() => this.addOption()}><i className="fa fa-plus" />Add new option</button>
                        </div>

                    </div>}
                </div>
                {/* <div className="admin-panel dashboard-panel">
                    <div className="field deliver-options">
                        <label className="label">การขนส่ง</label>
                        <div className="columns">
                            <div className="column">
                                <div className="columns">
                                    <div className="column">
                                        <div className="control">
                                            <label className="label is-small">น้ำหนัก</label>
                                            <input className="input is-small"
                                                name={`weight`}
                                                type="number"
                                                placeholder=""
                                                onChange={this.handleDescriptionChange}
                                                value={current_entity.weight} />

                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="control">
                                            <label className="label is-small">ขนาด</label>
                                            <div className="columns is-mobile">
                                                <div className="column">
                                                    <input className="input is-small"
                                                        name={`size_width`}
                                                        type="number"
                                                        placeholder=""
                                                        onChange={this.handleDescriptionChange}
                                                        value={current_entity.size_width} />
                                                </div>
                                                <div className="column">
                                                    <input className="input is-small"
                                                        name={`size_height`}
                                                        type="number"
                                                        placeholder=""
                                                        onChange={this.handleDescriptionChange}
                                                        value={current_entity.size_height} />
                                                </div>
                                                <div className="column">
                                                    <input className="input is-small"
                                                        name={`size_depth`}
                                                        type="number"
                                                        placeholder=""
                                                        onChange={this.handleDescriptionChange}
                                                        value={current_entity.size_depth} />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label className="label">ค่าจัดส่ง</label>
                        <div className="delivery-options">
                            <div className="option-item">
                                <label className="label is-small">DHL Domestic</label>
                                {this.getDeliveryActive('dhl') && <div className="delivery-price">{this.getDeliveryPrice('dhl')}</div>}
                                <div className="delivery-toggle"><InputToggleButton value={this.getDeliveryActive('dhl')} onChange={v => this.toggleDelivery('dhl', v)} /></div>
                            </div>
                            <div className="option-item">
                                <label className="label is-small">Thailand Post - EMS</label>
                                {this.getDeliveryActive('thaipost_ems') && <div className="delivery-price">{this.getDeliveryPrice('thaipost_ems')}</div>}
                                <div className="delivery-toggle"><InputToggleButton value={this.getDeliveryActive('thaipost_ems')} onChange={v => this.toggleDelivery('thaipost_ems', v)} /></div>
                            </div>
                            <div className="option-item">
                                <label className="label is-small">Thailand Post - Registered Mail</label>
                                {this.getDeliveryActive('thaipost_reg') && <div className="delivery-price">{this.getDeliveryPrice('thaipost_reg')}</div>}
                                <div className="delivery-toggle"><InputToggleButton value={this.getDeliveryActive('thaipost_reg')} onChange={v => this.toggleDelivery('thaipost_reg', v)} /></div>
                            </div>
                            <div className="option-item">
                                <label className="label is-small">Kerry</label>
                                {this.getDeliveryActive('kerry') && <div className="delivery-price">{this.getDeliveryPrice('kerry')}</div>}
                                <div className="delivery-toggle"><InputToggleButton value={this.getDeliveryActive('kerry')} onChange={v => this.toggleDelivery('kerry', v)} /></div>
                            </div>
                            <div className="option-item">
                                <label className="label is-small">จัดส่งโดยผู้ขาย</label>
                                {this.getDeliveryActive('seller') && <div className="delivery-price-input">
                                    &#3647; <input className="input is-small"
                                        name={`delivery_channel.seller.price`}
                                        type="number"
                                        placeholder=""
                                        onChange={this.handleDescriptionChange}
                                        value={current_entity.delivery_channel.seller.price} />
                                </div>}
                                <div className="delivery-toggle"><InputToggleButton value={this.getDeliveryActive('seller')} onChange={v => this.toggleDelivery('seller', v)} /></div>
                            </div>
                            <div className="option-item">
                                <label className="label is-small">จัดส่งทันที (บริการ Delivery)</label>

                                <div className="delivery-toggle"><InputToggleButton value={this.getDeliveryActive('delivery')} onChange={v => this.toggleDelivery('delivery', v)} /></div>
                            </div>
                        </div>
                    </div>

                </div>
                <hr /> */}
                <div className="columns">
                    <div className="column">
                        <div className="field">
                            <label className="label is-small">การเรียงลำดับ</label>
                            <div className="control">
                                <input className="input is-small"
                                    name="sort_order"
                                    type="number"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.sort_order} />

                            </div>
                        </div>
                    </div>
                    {isHub && <div className="column">
                        <div className="field">
                            <label className="label is-small">Reference note</label>
                            <div className="control">
                                <input className="input is-small"
                                    name="refnote"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.refnote} />

                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <div className="column is-one-quarter">
                <div className="field">
                    <label className="label is-small">Images</label>
                    <div className="control">
                        <input type="file" onChange={this.handleFileChange} />
                        <CloudImage src={current_entity.image} className="thumbnail" />
                    </div>
                </div>
            </div>
        </div>
            {current_entity.id && <div>Link: <a href={`${AppConfig.liffUrl}/${this.props.current_store}/?i=${current_entity.id}`}>https://liff.line.me/1653970986-4wmzlGnd/{this.props.current_store}/?i={current_entity.id}</a></div>}

            <div className="mt10">
                <button className="button is-primary is-fullwidth   " onClick={() => this.save(current_entity)} disabled={!current_entity.name || !current_entity.price || (isHub && (!current_entity.cost))}>SAVE</button>
            </div>
        </div>
    }
}

const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(ProductEditor))