import * as Sentry from '@sentry/browser';
import React from "react"
import { withRouter } from "react-router";
import * as firebase from 'firebase';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as AppConfig from '../AppConfig.json';
import { withCookies } from 'react-cookie';
import { CloudImage } from './CloudImage';
import StoreDayoff from '../models/StoreDayoff';
import moment from 'moment'
import LineAddNotify from './LineAddNotify';
import { sort, isNative, sendRN } from '../Helper';
import { data } from 'jquery';
import * as mixpanel from 'mixpanel-browser';

class StoreList extends React.Component {
    constructor() {
        super()
        this.state = { storeDayOff: {}, loading: true }
        this.updateCurrentLocation = this.updateCurrentLocation.bind(this)
        this.fail = this.fail.bind(this)
        this.sortByDistance = this.sortByDistance.bind(this)

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.updateCurrentLocation, this.fail);
        }
    }

    async componentDidMount() {

        let storeData = []
        var db = firebase.firestore();
        let qs = await db.collection('stores').get();
        qs.forEach(x => {
            let data = x.data();
            data.id = x.id;
            storeData.push(data)
        })

        this.setState({ storeData })

        let storeDayOff = {}
        for (let s in this.storeData) {
            let dayoffSnap = await StoreDayoff.setStore(this.storeData[s].id).getCollection().get()
            let today = moment();
            dayoffSnap.forEach((doc) => {

                var childKey = doc.id;
                var childData = doc.data();
                childData.id = childKey;

                if (moment(childData.startDate).isSame(today, 'day')) {
                    storeDayOff[this.storeList[s]] = true;
                }
            });
        }


        // storeRaining['locall'] = true;
        // storeRaining['locall.yaowarat'] = true;

        this.setState({ storeDayOff, loading: false })

        firebase.analytics().logEvent('page_view', { page_title: `Hub List` })
    }

    updateCurrentLocation(currentLocation) {

        this.setState({ currentLocation })
    }

    fail(error) // 'error' can be named anything
    {
        // alert('ระบบไม่สามารถเข้าถึงข้อมูลที่อยู่')
        // console.log('failed location')
        // this.updateLocation({ coords: { latitude:13.7649084, longitude: 100.5382846 } })
        this.setState({ currentLocation: { coords: { latitude: 13.7649084, longitude: 100.5382846 } } })
    }

    loadStore(store) {
        if (AppConfig.build === 'locall') {
            mixpanel.track("Select restaurant hub", { "Restaurant Hub": store });
        }

        if (isNative()) {
            sendRN({ action: "loadStore", params: { store } })
        }
        else {
            this.props.setCurrentStore(store)
        }
    }


    sortByDistance(storeList) {
        if (!this.state.storeData || !this.state.currentLocation) return storeList

        for (let x in this.state.storeData) {
            let data = this.state.storeData[x]
            data.dist = 0;
            if (data.branches && data.branches.length > 0) {
                let lat = data.branches[0].lat;
                let lng = data.branches[0].lng;
                let mylat = this.state.currentLocation.coords.latitude
                let mylng = this.state.currentLocation.coords.longitude

                data.dist = Math.sqrt(Math.pow(Math.abs(lat - mylat), 2) + Math.pow(Math.abs(lng - mylng), 2))
            }
        }

        return this.state.storeData.sort((a, b) => a.dist - b.dist)
    }

    render() {
        let { storeDayOff, loading } = this.state
        if (loading) return <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /></div>
        if (!this.state.storeData) return "No store found!"
        // this.storeList = this.storeList.sort((a, b) => { console.log(a, b, storeDayOff[a], storeDayOff[b], storeDayOff[a] > storeDayOff[b]); return storeDayOff[a] && storeDayOff[b] ? 0 : storeDayOff[a] ? 1 : -1 })
        // 

        this.state.storeData = this.sortByDistance(this.state.storeData)

        return <div>
            {AppConfig.firebase.projectId === 'locall-bkk' && <div className="header-nav">
                <div style={{ padding: '10px' }}><a onClick={() => { this.props.history.push('/'); this.props.setCurrentCity(null) }}><i className="fas fa-chevron-left"></i>&nbsp;เลือกเมืองอื่น</a></div>
            </div>}
            <div className="store-application store-list" style={{ padding: !isNative() ? '70px 15px' : '15px' }}>
                <LineAddNotify lineAccount={AppConfig.lineAccount} />
                {/* <div><a onClick={() => this.props.setCurrentStore('locall')}><CloudImage src="hubs/locall.jpg" /></a></div>
            <div><a onClick={() => this.props.setCurrentStore('locall.yaowarat')}><CloudImage src="hubs/locall.yaowarat.jpg" /></a></div> */}
                {this.state.storeData.filter(x => !storeDayOff[x.id]).map(x => <div key={x.id} className={`${storeDayOff[x.id] && 'closed'} store-${x} ${x.show_raining && 'raining'}`}><a onClick={() => this.loadStore(x.id)}><CloudImage src={`hubs/${x.id}.jpg`} /></a></div>)}
                {this.state.storeData.filter(x => storeDayOff[x.id]).map(x => <div key={x.id} className={`${storeDayOff[x.id] && 'closed'} store-${x} ${x.show_raining && 'raining'}`}><a onClick={() => this.loadStore(x.id)}><CloudImage src={`hubs/${x.id}.jpg`} /></a></div>)}

            </div>
        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store, app_params }) => ({ current_entity, current_store, app_params });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(StoreList)))
