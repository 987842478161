import StoreFirebaseModel from './StoreFirebaseModel';

export default class StoreProduct extends StoreFirebaseModel {

}

StoreProduct.collection = 'products';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();