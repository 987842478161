import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as AppConfig from '../AppConfig.json';
import * as actions from '../actions/index'
import * as firebase from 'firebase'
import Store from "../models/Store";
import StoreProduct from '../models/StoreProduct';
import StoreOrder from '../models/StoreOrder';
import Chart from "react-google-charts";
import moment from 'moment'

class Dashboard extends Component {
    constructor() {
        super()
        this.state = { showLineNotifyConnect: false, showAddMenuDialog: false }
    }

    componentDidMount() {
        let { current_store } = this.props
        if (current_store) {
            // console.log(current_store)
            Store.get(current_store).then(store => {
                if (store.exists) {
                    // console.log("Store data", store.data())
                    let storeData = store.data()
                    if (storeData && !storeData.lnt_access_token) {
                        this.setState({ showLineNotifyConnect: true })
                    }
                    this.setState({ storeData })

                    StoreProduct.setStore(current_store).getCollection().get().then(snap => {
                        // size = snap.size // will return the collection size
                        this.setState({ showAddMenuDialog: snap.size === 0 })
                    });
                }
                else {
                    this.setState({ storeData: false })
                }
            })

            let orderRef = StoreOrder.setStore(current_store).getCollection();
            // orderRef = firebase.firestore().collectionGroup('orders')
            orderRef.where('status', 'in', ['paid', 'ontheway', 'delivered'])
                .where('deliverDate', '>', moment().subtract(10, 'days').format('YYYY-MM-DD'))
                .get().then((querySnapshot) => {
                    var children = [];
                    querySnapshot.forEach((doc) => {
                        var childKey = doc.id;
                        var childData = doc.data();
                        childData.id = childKey;
                        children.push(childData)
                    });

                    let today = moment().format('YYYY-MM-DD')
                    let todayCount = 0
                    let todaySum = 0
                    let orderCount = {}
                    let orderSum = {}
                    let orderTimeCount = {}
                    let orderTimeSum = {}
                    children.reduce((r, a) => {
                        // console.log("a", a);
                        // console.log('r', r);
                        if (!a.deliverDate) return r;

                        let date = moment(a.deliverDate.toString()).format('YYYY-MM-DD')
                        let time = a.orderDate ? moment(a.orderDate.toString()).format('HH') + ':00' : '00:00'
                        // console.log(date, today)
                        if (date === today) {
                            todaySum += a.totalPrice;
                            todayCount++
                        }
                        // console.log(todaySum, todayCount)
                        if (!orderCount[date]) orderCount[date] = 0;
                        orderCount[date]++;

                        if (!orderTimeCount[time]) orderTimeCount[time] = 0;
                        orderTimeCount[time]++;

                        if (!orderSum[date]) orderSum[date] = 0;
                        orderSum[date] += a.totalPrice;

                        if (!orderTimeSum[time]) orderTimeSum[time] = 0;
                        orderTimeSum[time] += a.totalPrice;

                        r[date] = [...r[date] || [], a];

                        return r;
                    }, {});
                    //console.log("orderGroup", orderGroup, orderCount, orderSum, todaySum, todayCount);
                    this.setState({ orderCount, orderSum, todaySum, todayCount, orderTimeCount, orderTimeSum })
                });
        }

        this.chartOptions = {
            title: 'จำนวนออเดอร์',
            colors: ['#ffa30a'],
            hAxis: {
                textStyle: {
                    color: 'gray',
                    fontSize: 11
                }
            },
            vAxis: {
                minValue: 0,
                textStyle: {
                    color: 'gray',
                    fontSize: 11
                }
            }
            ,
            // areaOpacity: 0.24,
            // lineWidth: 1,
            backgroundColor: 'transparent',
            chartArea: {
                backgroundColor: "transparent",
                width: '90%',
                height: '80%'
            },
            bar: { groupWidth: "30" },

        }
    }


    render() {
        let { current_store } = this.props
        let { storeData, showLineNotifyConnect, showAddMenuDialog, orderCount, orderSum, todayCount, todaySum, orderTimeCount, orderTimeSum } = this.state
        if (!this.props.current_user || !storeData) return <div className="title p20 has-text-centered"></div>;
        // 
        if (this.props.current_user && this.props.owner === false) return <div className="title p20 has-text-centered">คุณไม่มีสิทธิในการเข้าถึงข้อมูลส่วนนี้!<br />({this.props.current_user.email})</div>;

        return <div className="container" style={{ padding: '20px' }}>
            <h1 className="title mt20">Dashboard</h1>
            {current_store}
            <div className="admin-panel dashboard-panel">

                <div className="level">
                    <div className="level-right ">
                        <h3 className="title is-4 m20" >เชื่อมต่อ LINE NOTIFY เพื่อแจ้งเตือนออเดอร์</h3>
                    </div>
                    {storeData.lnt_access_token && <div className="level-left ">
                        เชื่อมต่อแล้ว&nbsp;&nbsp;
                        <a className="button is-success" href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${AppConfig.lineNotifyClientId}&redirect_uri=${AppConfig.firebase.functionEndpoint}lineNotifyCallback&scope=notify&state=${current_store}&response_mode=form_post`}>เปลี่ยนแปลงการเชื่อมต่อ</a>
                    </div>}
                    {!storeData.lnt_access_token && <div className="level-left ">
                        ยังไม่เชื่อมต่อ&nbsp;&nbsp;
                        <a className="button is-success" href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${AppConfig.lineNotifyClientId}&redirect_uri=${AppConfig.firebase.functionEndpoint}lineNotifyCallback&scope=notify&state=${current_store}&response_mode=form_post`}>เชื่อมต่อ</a>
                    </div>}
                </div>
                {/* <div className="level">
                    <div className="level-right ">
                        <h3 className="title is-4 m20" >ลิงค์สำหรับใส่ ใน Rich menu ของ Line</h3>
                    </div>

                    <div className="level-left ">
                        {`${AppConfig.liffUrl}/${current_store}`}&nbsp;&nbsp;<button className="button is-success" onClick={() => { navigator.clipboard.writeText(`${AppConfig.liffUrl}/${current_store}`) }}> COPY</button>
                    </div>
                </div> */}
            </div>
            <nav className="level">
                <div className="level-item has-text-centered">
                    <div>
                        <p className="heading">ออเดอร์วันนี้</p>
                        <p className="title">{todayCount || '-'}</p>
                    </div>
                </div>
                <div className="level-item has-text-centered">
                    <div>
                        <p className="heading">ยอดรวมวันนี้ (บาท)</p>
                        <p className="title">{todaySum || '-'}</p>
                    </div>
                </div>
                <div className="level-item has-text-centered">
                    <div>
                        <p className="heading">ยอดเฉลี่ยออเดอร์ (บาท)</p>
                        <p className="title">{todayCount > 0 ? Math.floor(todaySum / todayCount) : '-'}</p>
                    </div>
                </div>
                {/* <div className="level-item has-text-centered">
                    <div>
                        <p className="heading">Today Menu</p>
                        <p className="title">-</p>
                    </div>
                </div>
                <div className="level-item has-text-centered">
                    <div>
                        <p className="heading">Weekly Menu</p>
                        <p className="title">-</p>
                    </div>
                </div> */}
            </nav>
            {/* <h3>Pending Orders</h3>
            <table className="table">
                <tr><th>Order</th><th>Status</th><th>Customer</th><th>Deliver to</th></tr>
                <tr><td>#0001</td><td>Cooking</td><td>เมฆ</td><td>พญาไท</td></tr>
            </table> */}

            {showLineNotifyConnect && <div className={`modal is-active animated fadeIn admin-modal`}>
                <div className="modal-background"></div>
                <div className="modal-content has-text-centered">
                    <h1 className="title is-4">คุณยังไม่ได้ทำการเชื่อต่อกับ Line notify</h1>
                    <div className="content">
                        เพื่อความสำดวกในการแจ้งเตือนเมื่อมีออเดอร์ใหม่ ผ่าน Line<br />
                        คุณสามารถเชื่อมต่อไปกับ Line notify <br />
                        เพื่อส่งการแจ้งเตือนให้คุณ หรือ ส่งเข้ากลุ่ม Line ที่ต้องการได้
                        </div>
                    <div className="columns is-mobile pt10">
                        <div className="column"><button className="button is-fullwidth" onClick={() => this.setState({ showLineNotifyConnect: false })}>เอาไว้ก่อน</button></div>
                        <div className="column"><a className="button is-success is-fullwidth" href={`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${AppConfig.lineNotifyClientId}&redirect_uri=${AppConfig.firebase.functionEndpoint}lineNotifyCallback&scope=notify&state=${current_store}&response_mode=form_post`}>เชื่อมต่อทันที!</a></div>
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({ showLineNotifyConnect: false })}></button>
            </div>}

            {!showLineNotifyConnect && showAddMenuDialog && <div className={`modal is-active animated fadeIn admin-modal`}>
                <div className="modal-background"></div>
                <div className="modal-content has-text-centered">
                    <h1 className="title is-4">คุณยังไม่มีเมนูของร้านเลย</h1>
                    <div className="content">
                        ร้านของคุณยังไม่มีเมนูของร้านเลย<br />
                        เพื่อเริ่มใช้งานเราแนะนำให้คุณเพิ่มเมนูของร้านคุณก่อน
                        </div>
                    <div className="columns is-mobile pt10">
                        <div className="column"><button className="button is-fullwidth" onClick={() => this.setState({ showAddMenuDialog: false })}>เอาไว้ก่อน</button></div>
                        <div className="column"><button className="button is-success is-fullwidth" onClick={() => this.props.history.push(`/${current_store}/admin/menus`)}>ไปเพิ่มเมนูกัน!</button></div>
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({ showAddMenuDialog: false })}></button>
            </div>}
            <div className="columns">
                <div className="column">
                    <div className="admin-panel dashboard-panel">
                        {orderCount && <Chart
                            width={'100%'}
                            height={300}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={[["วันที่", "จำนวนออเดอร์"], ...Object.keys(orderCount).sort().map(x => [x, orderCount[x]])]}

                            options={{ ...this.chartOptions, title: 'จำนวนออเดอร์', }}
                            legendToggle
                        />}
                    </div>
                </div>
                <div className="column">

                    <div className="admin-panel dashboard-panel">
                        {orderSum && <Chart
                            width={'100%'}
                            height={300}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={[["วันที่", "ยอดขาย"], ...Object.keys(orderSum).sort().map(x => [x, orderSum[x]])]}

                            options={{ ...this.chartOptions, title: 'ยอดขาย', }}
                            legendToggle
                        />}
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="admin-panel dashboard-panel">
                        {orderTimeCount && <Chart
                            width={'100%'}
                            height={300}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={[["เวลา", "จำนวนออเดอร์"], ...Object.keys(orderTimeCount).sort().map(x => [x, orderTimeCount[x]])]}

                            options={{ ...this.chartOptions, title: 'จำนวนออเดอร์', }}
                            legendToggle
                        />}
                    </div>
                </div>

                <div className="column">

                    <div className="admin-panel dashboard-panel">
                        {orderTimeSum && <Chart
                            width={'100%'}
                            height={300}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={[["เวลา", "ยอดขายเฉลี่ย"], ...Object.keys(orderTimeSum).sort().map(x => [x, orderTimeSum[x] / orderTimeCount[x]])]}

                            options={{ ...this.chartOptions, title: 'ยอดขาย', }}
                            legendToggle
                        />}
                    </div>
                </div>
            </div>

        </div>;
    }
}

const mapStateToProps = ({ current_user, current_store }) => ({ current_user, current_store });

export default withRouter(connect(mapStateToProps, actions)(Dashboard))