import * as mixpanel from 'mixpanel-browser';
import moment from "./moment-tz"
import * as firebase from 'firebase';
import { build_version } from './metadata.json'
import AppConfig, { build } from './AppConfig.json'

export function isNative() {
    // return false
    return window.navigator.userAgent.includes('LocallApp')
}
export function sendRN(message) {
    if (window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify(message))
        return true
    }

    return false
}

export function getConfig(config) {
    const configs = {
        app_mode: AppConfig.build === 'rine' ? 'single' : 'hub',
        ...AppConfig.config
    }
    console.log(AppConfig.config)
    return configs[config] || null
}

export function isHubMode() {
    return getConfig('app_mode') === 'hub'
}

export function logScreen(screen_name) {
    firebase.analytics().logEvent('screen_view', { screen_name, app_version: build_version, app_name: build })
}

export function isSuperAdmin(email) {
    if (AppConfig.build === 'imperial' && (email === 'mekku.nk@gmail.com' || email === 'imperial.tamjai01@gmail.com')) {
        return true
    }
    // 'peangploy@satarana.com', 'sanon@satarana.com', 'ping@satarana.com', 'mekku.nk@gmail.com', 'klinsanity@gmail.com', 'yada@satarana.com'
    if (email === 'mekku.nk@gmail.com' || email === 'danuchdech1@gmail.com' || email === 'peangploy@satarana.com' || email === 'ping@satarana.com' || email === 'yada@satarana.com') {
        return true;
    }

    return false;
}

export function isAdmin(storeData, email) {
    return (storeData.admin && storeData.admin.includes(email));
}

export function isStaff(storeData, email) {
    return (storeData.owner && storeData.owner.includes(email));
}

function filename(path) {
    return path.match(/[-_\w]+[.][\w]+$/i)[0];
}

function dir(path) {
    return path.replace(filename(path), '')
}

function thumbPath(path) {
    let f = filename(path)
    return path.replace(f, `thumbs/${f}`)
}

export { filename, dir, thumbPath }

export function sort(data, orders, dataFormatter) {
    if (!orders) orders = [{ "sort_order": "asc" }, { "name": "asc" }]
    return data.sort((a, b) => {
        for (let o in orders) {
            let key = Object.keys(orders[o])[0]
            let value = orders[o][key]

            let av = null
            let bv = null
            try {
                if (typeof a[key] === 'undefined') av = dataFormatter(a, key, false)
                else av = a[key]
                if (typeof b[key] === 'undefined') bv = dataFormatter(b, key, false)
                else bv = b[key]
            }
            catch (e) {
                // console.log(e)
            }

            // console.log(av, a[key], key, av > bv)

            if (value === "asc") {
                if (av === null && bv === null) continue;
                if (av === null) return -1;
                if (bv === null) return 1;
                if (av > bv) return 1;
                else if (av === bv) continue;
                else return -1;
            }
            else if (value === "desc") {
                if (av === null && bv === null) continue;
                if (av === null) return 1;
                if (bv === null) return -1;
                if (av < bv) return 1;
                else if (av === bv) continue;
                else return -1;
            }
        }

        return 0;
    })
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function dateFormat(date, hours) {
    if (!date) {
        return ''
    }
    try {
        if (date.constructor.name === 'Timestamp' || date.toDate) {
            date = date.toDate()
        }
        var mdate = moment(date);
        if (hours) {
            mdate.set({ hours })
        }
        if (hours === false || hours === null)
            return mdate.format('ddd D MMM Y')
        else
            return mdate.format('ddd D MMM Y HH:mm น.')
    }
    catch (e) {
        return ''
    }
}

export function timeFormat(date) {
    if (!date) {
        return ''
    }
    try {
        if (date.constructor.name === 'Timestamp' || date.toDate) {
            date = date.toDate()
        }
        var mdate = moment(date);
        return mdate.format('HH:mm น.')
    }
    catch (e) {
        return ''
    }
}

export function minimalDateFormat(date, hours) {
    if (date.constructor.name === 'Timestamp' || date.toDate) {
        date = date.toDate()
    }
    var mdate = moment(date);
    if (hours) {
        mdate.set({ hours })
    }
    return mdate.fromNow()
}

export function calculateDeliverPrice(storeData, distance) {
    let { delivery_tiers, delivery_calculate_mode } = storeData
    let initPrice = Number.parseFloat(storeData.delivery_start_price) || 0
    let price = initPrice
    if (AppConfig.distanceMode === 'round') {
        distance = Math.round(distance)
    }
    else {
        distance = Math.ceil(distance)
    }
    if (!delivery_tiers || delivery_tiers.length === 0) return false

    if (delivery_calculate_mode == 'dis-accurate') {

        let currentDeliveryTier = 0
        for (let i = 0; i < distance; i++) {
            if (delivery_tiers[currentDeliveryTier].km <= i) {
                if (currentDeliveryTier + 1 < delivery_tiers.length) {
                    currentDeliveryTier++
                }
            }
            let tierPrice = delivery_tiers[currentDeliveryTier].price
            price += Number.parseFloat(tierPrice) || 0
        }
    }
    else if (delivery_calculate_mode == 'dis-muliply') {

        let currentDeliveryTier = 0
        for (let i = 0; i < distance; i++) {
            if (delivery_tiers[currentDeliveryTier].km <= i) {
                if (currentDeliveryTier + 1 < delivery_tiers.length) {
                    currentDeliveryTier++
                }
            }
            let tierPrice = delivery_tiers[currentDeliveryTier].price
            price = initPrice + distance * Number.parseFloat(tierPrice) || 0
        }
    }
    else if (delivery_calculate_mode == 'dis-fix') {

        let currentDeliveryTier = 0
        for (let i = 0; i < distance; i++) {
            if (delivery_tiers[currentDeliveryTier].km <= i) {
                if (currentDeliveryTier + 1 < delivery_tiers.length) {
                    currentDeliveryTier++
                }
            }
            let tierPrice = delivery_tiers[currentDeliveryTier].price
            price = Number.parseFloat(tierPrice) || 0
        }
    }

    return price;
}

export function renderOpenDay(openday) {
    let opName = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.']
    let startday = null
    let endday = null
    let str = ""
    let strdata = ""
    let dayCount = 0;
    let fh = null
    for (let d in openday) {
        if (openday[d]) dayCount++
    }

    if (dayCount === 7) {
        return "ทุกวัน";
    }

    if (dayCount === 0) {
        return false;
    }

    for (let d in openday) {
        if (!openday[d] && fh === null)
            fh = d * 1
    }

    for (let c = fh; c < 7 + fh + 1; c++) {
        let d = c % 7
        if (openday[d]) {
            if (startday === null) {
                startday = d;
            }
            str += opName[d]
            endday = d
        }
        else {
            if (startday !== null) {
                if (startday !== endday)
                    strdata += `${opName[startday]}-${opName[endday]} `
                else
                    strdata += `${opName[startday]} `

                startday = endday = null
            }
        }
    }

    // if (startday !== null && openday[6]) {
    //     endday = 6;
    //     strdata += `${opName[startday]}-${opName[endday]}`
    // }

    return strdata
}


export function flexText(text, size, color, weight, align, wrap, gravity) {
    let type = "text";
    size = size || "md";
    color = color || "#444444";
    weight = weight || "regular";
    align = align || "start";
    wrap = wrap || true;
    gravity = gravity || "top"

    return { type, text, size, color, weight, align, wrap, gravity }
}

export function mpTrackEvent(name, props) {
    if (AppConfig.build === 'locall') {
        mixpanel.track(name, props);
    }
}

export function sortByDistance(storeList, storeData, currentLocation) {
    if (!storeData || !currentLocation) return storeList

    for (let x in storeData) {
        let data = storeData[x]
        data.dist = 0;
        if (data.branches && data.branches.length > 0) {
            let lat = data.branches[0].lat;
            let lng = data.branches[0].lng;
            let mylat = currentLocation.coords.latitude
            let mylng = currentLocation.coords.longitude

            data.dist = Math.sqrt(Math.pow(Math.abs(lat - mylat), 2) + Math.pow(Math.abs(lng - mylng), 2))
        }
    }

    return storeList.sort((a, b) => storeData[a].dist - storeData[b].dist)
}
export function extraHint(extra) {
    if (!extra.minimum && !extra.maximum) return ''
    if (extra.minimum > 0 && extra.minimum === extra.maximum) return `( เลือก ${extra.minimum} อย่าง )`
    return `( เลือก${extra.minimum > 0 ? `อย่างน้อย ${extra.minimum} อย่าง` : ''} ${extra.maximum > extra.minimum ? `ไม่เกิน ${extra.maximum} อย่าง` : ''} )`
}

export function isCartItemValid(item) {
    console.log(item)
    let valid = true

    if (item.product.extra_options) {
        item.product.extra_options.forEach(extra => {
            if (extra.minimum && (!item.extras || !item.extras[extra.title] || item.extras[extra.title].length < extra.minimum)) {
                valid = false
            }
        })
    }

    return valid
}

export function compareCartItem(cartItem, compCartItem) {
    let { product, option, extras } = compCartItem
    return cartItem.product.id === product.id && (!cartItem.option || cartItem.option === option) && (!cartItem.extras || deepCompare(cartItem.extras, extras))
}

export function getCartItemPrice(item) {

    let price = item.product.price;
    if (item.option && item.option.price) {
        price += Number.parseFloat(item.option.price)
    }
    if (item.extras) {
        Object.keys(item.extras).forEach(extra => {
            item.extras[extra].forEach(option => {
                if (option.price) {
                    price += Number.parseFloat(option.price)
                }
            })
        })
    }
    return price
}

export function addToCart(carts, product, quantity, option = null, note = null, extras = null, isAddByAdmin) {
    console.log(arguments)
    if (!extras) extras = {}
    if (product.isSoldout) return carts
    if (option === null) {
        if (product.options.length > 0) {
            option = product.options[0]
        }
    }
    else {
        if (option.isSoldout) return carts
    }

    if (quantity === 0) return carts

    for (let c in carts) {

        if (compareCartItem(carts[c], { product, option, extras, note })) {
            console.log(carts[c].extras, extras)
            carts[c].quantity += quantity;
            return carts
        }
    }


    // mpTrackEvent("Add Item To Cart", {
    //     "dish name": option ? option.name : "",
    //     "dish price": product.price + (option ? option.price : 0),
    //     "menu name": product.name,
    //     "menu price": product.price,
    //     "restaurant name": store.name
    // });


    carts.push({ product, quantity, option, note, extras: JSON.parse(JSON.stringify(extras)), add_by_admin: isAddByAdmin === true })
    return carts
}

export function getTotalPrice(order, promotions) {
    console.log(order, promotions)

    let sum = 0;
    order.carts.forEach((item) => {
        let price = getCartItemPrice(item)

        sum += item.quantity * price;
    })

    order.sumProductPrice = sum

    // 
    // Apply promotion
    if (!promotions) promotions = order.promotions
    let applyPromotions = []
    if (order.coupon_promotion) {
        promotions = [...promotions, order.coupon_promotion]
    }
    order.discount = 0
    let product_discount = 0
    let deliver_discount = 0

    promotions = promotions.filter(item => {
        let currentTime = moment();    // e.g. 11:00 pm
        let inTime = true;
        let inDate = true;

        if (item.startDate && item.endDate) {
            inDate = (moment(item.startDate).toDate() <= currentTime.startOf('day').toDate()) && (moment(item.endDate).toDate() >= currentTime.startOf('day').toDate())
        }


        currentTime = moment();

        if (item.startTime && item.endTime) {
            inTime = currentTime.isBetween(moment(item.startTime, "HH:mm"), moment(item.endTime, "HH:mm")) || currentTime.isSame(moment(item.startTime, "HH:mm"))
        }

        // this.calculateDeliverCost()

        return item.is_active && inDate && inTime
    })

    // if (!order.activePromotion) {
    order.activePromotion = null;
    // }
    // if (!order.activeDeliverPromotion) {
    order.activeDeliverPromotion = null;
    order.discount_fix_date = false
    order.discount_fix_time = false
    // }

    promotions.forEach(p => {

        let apply = true

        if (p.is_buy_at_least && sum < p.buy_at_least) {
            apply = false;
        }
        else {
            // Discount 
            if (p.cart_at_least && p.cart_amount) {
                let cart_quantity = 0
                order.carts.forEach(c => {
                    cart_quantity += c.quantity
                })
                // Cart at least
                //console.log("cart_at_least", cart_quantity, p.cart_amount)
                if (cart_quantity < p.cart_amount) {
                    apply = false

                }
                else {
                    apply = false
                    order.carts.forEach(item => {
                        if (!p.cart_products || p.cart_products === null || p.cart_products.length === 0 ||
                            p.cart_products.includes(item.product.id)) {
                            apply = true
                        }
                    })
                }
            }
        }


        if (p.discount_deliver_area && p.deliver_areas) {
            let inPromoArea = false;
            if (p.discount_deliver_area && p.deliver_areas) {
                let deliver_areas = p.deliver_areas
                for (let l in deliver_areas) {
                    if (order.deliverAddress.area.toLowerCase().includes(deliver_areas[l]) ||
                        order.deliverAddress.state.toLowerCase().includes(deliver_areas[l]) ||
                        order.deliverAddress.city.toLowerCase().includes(deliver_areas[l])) {
                        inPromoArea = true;
                    }
                }
            }

            if (!inPromoArea) {
                apply = false;
                this.setState({ promotionError: "โปรโมชั่นนี้สามารถใช้ได้เฉพาะพื้นที่" + p.deliver_areas.join(', ') })
            }
            else {
                this.setState({ promotionError: null })
            }
        }
        else if (p.discount_deliver_area && p.deliver_area) {
            if (!order.deliverAddress.area.toLowerCase().includes(p.deliver_area) && !order.deliverAddress.state.toLowerCase().includes(p.deliver_area) && !order.deliverAddress.city.toLowerCase().includes(p.deliver_area)) {
                apply = false;
                this.setState({ promotionError: "โปรโมชั่นนี้สามารถใช้ได้เฉพาะพื้นที่" + p.deliver_area })

            }
            else {
                this.setState({ promotionError: null })
            }
        }

        if (apply) {
            applyPromotions.push(p)

            let applyTime = 0;

            let discount = 0
            p.discount = Number.parseFloat(p.discount)
            p.max_discount = Number.parseFloat(p.max_discount)



            if (p.discount_fix_date && p.deliver_date) {

                order.deliverDate = p.deliver_date;
                order.discount_fix_date = true
            }

            if (p.discount_fix_date && p.deliver_time) {
                let times = this.getDeliverTimesData(order.deliverDate)
                let curTime = null
                // console.log(times, p.deliver_time)
                for (let t in times) {
                    // console.log(times[t], times[t].indexOf(p.deliver_time), p.deliver_time)
                    if (times[t].indexOf(p.deliver_time) === 0) {
                        curTime = times[t]
                    }
                }
                // console.log(curTime)
                order.deliverTime = curTime || p.deliver_time;
                order.discount_fix_time = true
            }

            if (p.discount_type === "percent") {
                discount = p.discount * 0.01 * sum;
                if (p.max_discount && discount > p.max_discount) {
                    discount = p.max_discount
                }
            }
            if (p.discount_type === "fixed-discount") {
                discount = p.discount;
            }
            if (p.discount_type === "fixed-price") {
                if (p.cart_at_least && p.cart_amount) {
                    let count = 0;
                    let sumC = 0
                    order.carts.forEach(item => {
                        if (!p.cart_products || p.cart_products === null || p.cart_products.length === 0 ||
                            p.cart_products.includes(item.product.id)) {
                            for (let i = 0; i < item.quantity; i++) {
                                count++
                                sumC += getCartItemPrice(item)

                                if (count === p.cart_amount) {
                                    discount += sumC - (p.discount * Math.floor(count / p.cart_amount));
                                    sumC = 0
                                    count -= p.cart_amount
                                    applyTime++
                                }
                            }
                        }
                    })

                    if (p.max_discount && discount > p.max_discount) {
                        discount = p.max_discount
                    }
                }
                else {
                    discount = sum - p.discount;
                }

                p.applyTime = applyTime

            }


            if (discount > sum) {
                discount = sum
            }

            if (product_discount < discount || product_discount === 0) {
                // Can get only one promotion
                if (discount !== 0) {
                    order.activePromotion = p.id
                    // console.log('activePromotion', order.activePromotion)
                }
                product_discount = discount
            }
            ////////
            let ddiscount = 0
            p.deliver_discount = Number.parseFloat(p.deliver_discount)

            // Deliver discount
            if (p.deliver_discount_type === "percent") {
                ddiscount = order.deliverCost * p.deliver_discount * 0.01;
            }
            else if (p.deliver_discount_type === "fixed-discount") {
                if (order.deliverCost > p.deliver_discount) {
                    ddiscount = p.deliver_discount;
                }
                else {
                    ddiscount = order.deliverCost;
                }
            }
            else if (p.deliver_discount_type === "fixed-price") {
                if (order.deliverCost > p.deliver_discount) {
                    ddiscount = order.deliverCost - p.deliver_discount
                }
            }
            else if (p.deliver_discount_type === "free") {
                ddiscount = order.deliverCost;
            }

            if (deliver_discount < ddiscount || deliver_discount === 0) {
                // Can get only one promotion
                if (ddiscount !== 0) {
                    order.activeDeliverPromotion = p.id
                    // console.log('activeDeliverPromotion', order.activeDeliverPromotion)
                }
                deliver_discount = ddiscount
            }
        }
    })
    order.discount = product_discount + deliver_discount;
    order.discount = Math.floor(order.discount)
    sum -= order.discount
    // Apply promotion

    order.promotions = applyPromotions
    order.sumPrice = sum
    order.totalPrice = sum + order.deliverCost;
    return order

}

export function deepCompare() {
    var i, l, leftChain, rightChain;

    function compare2Objects(x, y) {
        var p;

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        // Compare primitives and functions.     
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            }
            else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            }
            else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof (x[p])) {
                case 'object':
                case 'function':

                    leftChain.push(x);
                    rightChain.push(y);

                    if (!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    }

    if (arguments.length < 1) {
        return true; //Die silently? Don't know how to handle such case, please help...
        // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = arguments.length; i < l; i++) {

        leftChain = []; //Todo: this can be cached
        rightChain = [];

        if (!compare2Objects(arguments[0], arguments[i])) {
            return false;
        }
    }

    return true;
}

