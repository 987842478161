import * as Sentry from '@sentry/browser';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, NavLink } from "react-router-dom";
import liff from '@line/liff';

// import logo from './logo.svg';
import './App.scss';

import * as firebase from 'firebase';
import * as AppConfig from './AppConfig.json';
import * as actions from './actions/index'
import Header from './components/Header';
import AdminRoutes from './components/AdminRoutes';
import StoreRoutes from './components/StoreRoutes';
import { createBrowserHistory } from 'history';
import { withCookies, Cookies } from 'react-cookie';
import StoreList from './components/StoreList';
import StoreListChiangMai from './components/StoreListChiangMai';
import RegisterScreen from './components/RegisterScreen';
import RegisterPaymentCompleteScreen from './components/RegisterPaymentCompleteScreen';
import StoreEditor from './components/StoreEditor';
import StoreRequestEditor from './components/StoreRequestEditor';
import CityList from './components/CityList';
import StoreListSaraburi from './components/StoreListSaraburi';
import DownloadScreen from './components/DownloadScreen';
import StandardStoreList from './components/StandardStoreList';
import GlobalCategoryEditor from './components/GlobalCategoryEditor';
import GlobalPromotionEditor from './components/GlobalPromotionEditor';
import GlobalCouponEditor from './components/GlobalCouponEditor';
import SearchView from './components/SearchView';
import DriverView from './components/DriverView';
import { isNative, mpTrackEvent } from './Helper';
import OrderView from './components/OrderView';
import CityEditor from './components/CityEditor';
import Store from './models/Store';

const history = createBrowserHistory();

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class App extends Component {

  city = {
    // bangkok: { lat: 13.7563309, lng: 100.5017651 },
    // chiangmai: { lat: 18.7883439, lng: 98.9853008 },
    // saraburi: { lat: 14.5289154, lng: 100.9101421 },
    thaimung: { lat: 13.7563309, lng: 100.5017651 },
  }

  constructor(props) {
    super(props)
    // console.log(window.location.href)
    // Sentry.captureMessage(window.location.href)
    this.state = {
      currentLocation: null
    }
    if (window.location.href.includes('localhost')) {
      props.setAppParams({ localhost: true })
    }
    else {
      props.setAppParams({ localhost: false })
    }

    this.updateCurrentLocation = this.updateCurrentLocation.bind(this)
    this.fail = this.fail.bind(this)
    this.sortByDistance = this.sortByDistance.bind(this)

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.updateCurrentLocation, this.fail, { enableHighAccuracy: true });
    }
    else {
    }
  }

  sortByDistance(storeList) {
    if (!this.city || !this.state.currentLocation) return storeList
    // console.log(this.state.currentLocation)
    for (let x in this.city) {
      let data = this.city[x]
      data.dist = 0;
      if (data.branches && data.branches.length > 0) {
        let lat = data.branches[0].lat;
        let lng = data.branches[0].lng;
        let mylat = this.state.currentLocation.coords.latitude
        let mylng = this.state.currentLocation.coords.longitude

        data.dist = Math.sqrt(Math.pow(Math.abs(lat - mylat), 2) + Math.pow(Math.abs(lng - mylng), 2))
      }
    }

    return storeList.sort((a, b) => this.city[a].dist - this.city[b].dist)
  }


  updateCurrentLocation(currentLocation) {
    if (!this.state.currentLocation) {
      this.setState({ currentLocation })
      let res = this.sortByDistance(Object.keys(this.city))
      if (res[0]) this.props.setCurrentCity(res[0])
    }
  }

  fail(error) // 'error' can be named anything
  {
    alert(`ระบบไม่สามารถเข้าถึงตำแหน่งของคุณได้ เพื่อความสะดวกในการใช้งานกรุณาเปิด GPS และ ตั้งค่าอนุญาติการเข้าถึงตำแหน่งของคุณ\n[${error.message}]`)
    // console.log('failed location')
    // this.updateLocation({ coords: { latitude:13.7649084, longitude: 100.5382846 } })
    this.setState({ currentLocation: { coords: { latitude: 13.7649084, longitude: 100.5382846 } } })
  }



  async componentDidMount() {
    const { cookies } = this.props;
    // let store_id = cookies.get('store_id')
    // if (store_id) {
    //   this.props.setCurrentStore(store_id)
    // }

    if (AppConfig.build === 'locall') {
      let storeData = {}
      var db = firebase.firestore();
      let qs = await db.collection('stores').get();
      qs.forEach(x => {
        let data = x.data();
        data.id = x.id;
        storeData[x.id] = data

      })

      this.props.setStoresMap(storeData)
      this.setState({ storeData })
    }

    let state = getParameterByName('liff.state', window.location.href)
    let params = {}

    if (state) {
      state = decodeURI(state);
      // alert(state)
      // Sentry.captureMessage(`State = ${state}`)
      let match = state.match(/\/([\w]+)/)
      if (match) {
        this.state.store = match[1]
        this.props.setCurrentStore(match[1])

        cookies.set('store_id', this.state.store, { path: '/' });
      }
      params = {}
      params['customer'] = getParameterByName('customer', state);
      params['phone'] = getParameterByName('phone', state);
      params['destination'] = getParameterByName('destination', state);
      params['note'] = getParameterByName('note', state);
      params['date'] = getParameterByName('date', state);
      params['time'] = getParameterByName('time', state);
      params['location'] = getParameterByName('location', state);
      // params['uid'] = getParameterByName('uid', state);
      // console.log(params)
      this.props.setAppParams(params)

    }
    else {
      let match = window.location.href.match(/\?([\w]+)#/)
      if (match) {
        this.state.store = match[1]
        const { cookies } = this.props;
        cookies.set('store_id', this.state.store, { path: '/' });
      }
      else {
        match = window.location.href.match(/\?([\w]+)$/)
        if (match) {
          this.state.store = match[1]
          const { cookies } = this.props;
          cookies.set('store_id', this.state.store, { path: '/' });
        }
      }

      params = {}
      params['customer'] = getParameterByName('customer', window.location.href);
      params['phone'] = getParameterByName('phone', window.location.href);
      params['destination'] = getParameterByName('destination', window.location.href);
      params['note'] = getParameterByName('note', window.location.href);
      params['date'] = getParameterByName('date', window.location.href);
      params['time'] = getParameterByName('time', window.location.href);
      params['location'] = getParameterByName('location', window.location.href);
      // alert(params['phone'])
      // params['uid'] = getParameterByName('uid', window.location.href);
      // console.log(params)
      this.props.setAppParams(params)
    }


    let url = window.location.href
    let localhost = url.includes("localhost")

    // if (localhost) {
    //   this.props.setLineUser("U9d72ae54455c1aafb09e3b7ed7c81bfc")
    // }
    // else
    if (!localhost && !url.includes("admin") && !url.includes("app") && !isNative()) {
      console.log("Init")
      liff.ready.then(() => {
        console.log("Ready")
      })

      liff.init({
        liffId: AppConfig.liffId
      }).then(() => {
        console.log("Ready!")
        if (!liff.isLoggedIn()) {

          // window.location.href = "https://liff.line.me/1653970986-4wmzlGnd"

          // Rine: https://liff.line.me/1653970986-4wmzlGnd
          // window.location.href = `line://app/${AppConfig.liffId}/${current_store}`
          // console.log(params)
          if (!localhost) {
            liff.login({ redirectUri: window.location.href })
          }
          else {
            liff.login({ redirectUri: window.location.href })
          }
          // liff.login()
          // liff.login({ redirectUri: window.location.href })
          // setTimeout(() => {
          //     liff.login({ redirectUri: `${AppConfig.endpoint}${current_store}` });
          // }, 1000)

        } else {

          const context = liff.getContext();

          if (context) {

            mpTrackEvent("Login");

            const userId = context.userId;
            console.log("Login as", userId)
            this.props.setLineUser(userId);
            // 	U9d72ae54455c1aafb09e3b7ed7c81bfc
            // alert("UserID" + userId)

            firebase.firestore().collection('line_user').doc(userId).set({ userId });
            // firebase.analytics().setUserId(userId);
            // firebase.analytics().setUserId(userId);
            this.setState({ context })


            let lineDecoded = liff.getDecodedIDToken()

            liff.getProfile()
              .then(profile => {
                this.setState({ lineProfile: profile, lineDecoded })

              })
              .catch((err) => {
                console.log('error', err);
              });
            // Sentry.captureMessage(`${JSON.stringify(context)}`)

          }



          // let state = getParameterByName('liff.state', window.location.href)
          // if (state) {
          //   // window.location.href = decodeURI(queryString);
          //   // alert(state)
          //   history.push(state)
          // }

        }
      }).catch((err) => {
        // Error happens during initialization
        Sentry.captureException(err);
      });
    }
    else {
      this.props.setLineUser("-")
    }


  }

  renderStore() {
    const { cookies } = this.props;
    let { current_city } = this.props
    let store_id = this.props.current_store || this.state.store || cookies.get('store_id');

    if (AppConfig.firebase.projectId === 'locall-bkk') {
      store_id = this.props.current_store || this.state.store // || cookies.get('store_id');
    }

    console.log(store_id)
    if (AppConfig.firebase.projectId !== 'locall-bkk') {
      window.document.title = "RINE"
      if (store_id)
        return <StoreRoutes store_id={store_id} />
      return <StandardStoreList />
    }
    else {
      window.document.title = "LOCALL X RINE"
      if (!store_id && !current_city)
        return <CityList />
      if (store_id === 'bangkok' || (!store_id && current_city === 'bangkok'))
        return <StoreList />
      if (store_id === 'saraburi' || (!store_id && current_city === 'saraburi'))
        return <StoreList />
      if (store_id === 'chiangmai' || (!store_id && current_city === 'chiangmai'))
        return <StoreList />
      if (store_id)
        return <StoreRoutes store_id={store_id} />
      else
        return <StoreList />
    }
  }

  render() {
    let props = this.props;
    const { cookies } = this.props;
    let { current_city, line_user } = props
    let { storeData } = this.state

    if (!storeData || ((!line_user && !isNative()) && !window.location.href.includes("admin") && !window.location.href.includes("app"))) return <div className="has-text-centered p20"><img src="/loading.gif" width="80" /><br />กำลังเริ่มทำงาน</div>

    // debugger
    return (
      <div className="App">
        {!props.current_user && <div id="firebaseui-auth-container"></div>}
        <div style={{ minHeight: 'calc(100vh - 80px)' }}>
          <Router history={history}>
            <div className={`${!window.location.href.includes('/admin') && !window.location.href.includes('/register') ? 'container' : ''}`}>
              <Switch>
                <Route path="/register/:payment_id/payment/complete"><RegisterPaymentCompleteScreen /></Route>
                <Route exect path="/register"><RegisterScreen /></Route>
                <Route exect path="/app"><DownloadScreen /></Route>
                {/* <Route path={`/admin/store_requests`}><StoreRequestEditor /></Route> */}
                <Route path={`/admin/stores`}><Header /><StoreEditor /></Route>
                <Route path={`/admin/cities`}><Header /><CityEditor /></Route>

                <Route path={`/admin/promotions`}><Header /><GlobalPromotionEditor /></Route>
                <Route path={`/admin/coupons`}><Header /><GlobalCouponEditor /></Route>
                <Route path={`/admin/categories`}><Header /><GlobalCategoryEditor /></Route>

                <Route path={`/search/:query`}><SearchView /></Route>
                <Route path={`/driver/:store/:order_id`}><DriverView /></Route>
                <Route path={`/order/:store/:order_id`}><OrderView /></Route>

                {/* <Route path="/:store/admin/:action"><AdminRoutes /></Route> */}
                <Route path="/:store/admin"><AdminRoutes /></Route>

                {/* <Route path="/:store/:action"><StoreRoutes /></Route> */}
                <Route path="/:store"><StoreRoutes /></Route>
                {/* <Route path="/"><StoreList /></Route> */}
                {/* <Route path="/"><StoreRoutes store_id={store_id} /></Route> */}
                <Route exect path="/">
                  {this.renderStore()}
                </Route>
              </Switch>
            </div>
          </Router>
        </div>
        <div className="footer-credit mb20">Powered by <img src="/favicon-32x32.png" style={{ height: '15px' }} />RINE by <a href="///www.hitevent.com">HitEvent</a></div>
      </div>
    );
  }
}

const mapStateToProps = ({ current_user, line_user, current_store, current_store_data, current_city }) => ({ current_user, line_user, current_store, current_store_data, current_city });
export default withCookies(connect(mapStateToProps, actions)(App))
// export default App;
