import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as AppConfig from '../AppConfig.json';
import moment from 'moment'

import * as firebase from 'firebase';
import BaseEditor from './BaseEditor';
import FormField from './FormField';
import StoreDriver from '../models/StoreDriver'

// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")
var db = firebase.firestore();

class StoreDriverEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Driver"
        this.canCreate = true;
    }

    componentDidMount() {
        this.dataRef = StoreDriver.getCollection(this.props.current_store);
        super.componentDidMount()
    }

    renderListHeader() {
        return <tr><th>ชื่อคนขับ</th>
            {/* {[...Array(7)].map((i, x) => <th>{moment(x, 'd').format('ddd')}</th>)} */}
            <th>Active</th><th>Action</th></tr>;
    }
    fieldData(item, name) {
        let result = super.fieldData(item, name)

        if (name === 'time') {
            if (item.startTime && item.endTime) {
                return `${item.startTime} - ${item.endTime}`
            }
            return 'All time'
        }

        return result
    }
    renderListRow() {
        return this.state.items.map((item, key) =>
            <tr key={item.id}>
                <td onClick={() => {
                    //this.loadEditor(item)
                }}>{item.name}</td>
                {/* {[...Array(7)].map((i, x) => <td>{item.openday[x] && <div className="has-text-success"><i className="fa fa-check" /></div>}</td>)} */}
                {/* <td>{this.fieldData(item, 'time')}</td> */}
                <td><button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button></td>
                <td>
                    <button className="button is-primary is-small" onClick={() => { this.clone(item) }} >DUPLICATE</button>&nbsp;
                    <button className="button is-warning is-small" onClick={() => { this.loadEditor(item) }} ><i className="fa fa-edit" /></button>
                    <button className="button is-danger is-small" onClick={() => { this.delete(item.id) }} ><i className="fa fa-trash" />
                    </button></td>
            </tr>
        )
    }

    renderEditor() {
        let { current_entity, current_store } = this.props
        if (!current_entity.openday) current_entity.openday = {}
        let lntState = encodeURIComponent(JSON.stringify({ type: 'driver', store: current_store, doc_id: current_entity.id }))

        return <div className="columns">
            <div className="column is-three-quarters">
                <FormField label="ชื่อ" name="name" type="text" onChange={this.handleDescriptionChange} value={current_entity.name} />
                <FormField label="เบอร์ติดต่อ" name="tel" type="text" onChange={this.handleDescriptionChange} value={current_entity.tel} />
                {/* <div className="field">
                    <label className="label is-small">วันเปิดร้าน</label>
                    <div className="control">
                        <input className="checkbox" name="openday[0]" type="checkbox" onChange={this.handleDescriptionChange} checked={current_entity.openday[0]} />&nbsp;อา&nbsp;&nbsp;&nbsp;&nbsp;
                    <input className="checkbox" name="openday[1]" type="checkbox" onChange={this.handleDescriptionChange} checked={current_entity.openday[1]} />&nbsp;จ&nbsp;&nbsp;&nbsp;&nbsp;
                    <input className="checkbox" name="openday[2]" type="checkbox" onChange={this.handleDescriptionChange} checked={current_entity.openday[2]} />&nbsp;อ&nbsp;&nbsp;&nbsp;&nbsp;
                    <input className="checkbox" name="openday[3]" type="checkbox" onChange={this.handleDescriptionChange} checked={current_entity.openday[3]} />&nbsp;พ&nbsp;&nbsp;&nbsp;&nbsp;
                    <input className="checkbox" name="openday[4]" type="checkbox" onChange={this.handleDescriptionChange} checked={current_entity.openday[4]} />&nbsp;พฤ&nbsp;&nbsp;&nbsp;&nbsp;
                    <input className="checkbox" name="openday[5]" type="checkbox" onChange={this.handleDescriptionChange} checked={current_entity.openday[5]} />&nbsp;ศ&nbsp;&nbsp;&nbsp;&nbsp;
                    <input className="checkbox" name="openday[6]" type="checkbox" onChange={this.handleDescriptionChange} checked={current_entity.openday[6]} />&nbsp;ส&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                </div>

                <div className="field">
                    <div className="columns">

                        <div className="column">
                            <label className="label is-small">ขายช่วงเวลา</label>
                            <div className="control has-icons-left has-icons-right">
                                <select className="input is-small"
                                    name="startTime"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.startTime || null}>
                                    <option value={null}>ไม่กำหนด</option>
                                    {[...Array(48)].map((x, i) =>
                                        <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                    )}
                                </select>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-clock"></i>
                                </span>
                            </div>
                        </div>
                        <div className="column">
                            <label className="label is-small">ถึงเวลา</label>
                            <div className="control has-icons-left has-icons-right">
                                <select className="input is-small"
                                    name="endTime"
                                    type="text"
                                    placeholder=""
                                    onChange={this.handleDescriptionChange}
                                    value={current_entity.endTime || null}>
                                    <option value={null}>ไม่กำหนด</option>
                                    {[...Array(48)].map((x, i) =>
                                        <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                    )}
                                </select>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-clock"></i>
                                </span>
                            </div>
                        </div>

                    </div>
                </div> */}

                <div className="mt10">
                    <button className="button is-primary" onClick={() => this.save(current_entity)}>SAVE</button>
                </div>
                <hr />
            </div>
            <div className="column is-one-quarter">
                <div className="admin-panel dashboard-panel has-text-centered">

                    <h3 className="title is-4 m10" >เชื่อมต่อ LINE NOTIFY เพื่อแจ้งเตือนออเดอร์</h3>

                    {current_entity.lnt_access_token && <div className="has-text-success">
                        เชื่อมต่อแล้ว&nbsp;&nbsp;
                        
                    </div>}
                    {!current_entity.lnt_access_token && <div className="has-text-danger">
                        ยังไม่เชื่อมต่อ&nbsp;&nbsp;
                        
                    </div>}

                    <img src={`https://chart.googleapis.com/chart?chs=200x200&chld=Q|1&cht=qr&chl=${encodeURIComponent(`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${AppConfig.lineNotifyClientId}&redirect_uri=${AppConfig.firebase.functionEndpoint}lineNotifyCallback&scope=notify&state=${lntState}&response_mode=form_post`)}&choe=UTF-8`} style={{ width: '100%' }} />

                </div>
            </div>
        </div>
    }
}


const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(StoreDriverEditor))