import * as Sentry from '@sentry/browser';
import React from "react"
import { withRouter } from "react-router";
import * as firebase from 'firebase';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as AppConfig from '../AppConfig.json';
import { withCookies } from 'react-cookie';
import { CloudImage } from './CloudImage';
import { sendRN, isNative, mpTrackEvent, sort } from '../Helper';
import StoreDayoff from '../models/StoreDayoff';
import moment from 'moment'
import LineAddNotify from './LineAddNotify';
import * as mixpanel from 'mixpanel-browser';
import HubStoreProduct from '../models/HubStoreProduct';
import Rating from 'react-rating';

const customIcons = ["angry", "frown", "meh", "smile", "grin-stars"].map((x, i) => <i className={`fa fa-${x} rating${i + 1} rating-icon`}></i>);
const customActiveIcons = ["angry", "frown", "meh", "smile", "grin-stars"].map((x, i) => <i className={`fa fa-${x} rating${i + 1} rating-icon active`}></i>);

class OrderView extends React.Component {
    constructor() {
        super()
        this.state = { loading: true }
    }

    async componentDidMount() {
        firebase.analytics().logEvent('page_view', { page_title: `Order View` })

        let { order_id, store } = this.props.match.params

        HubStoreProduct.setStore(store).getAllById().then(hubStoresById => this.setState({ hubStoresById }))
        if (order_id) {
            let qs = await firebase.firestore().collection('stores').doc(store).collection('orders').doc(order_id).get()

            if (qs.exists) {
                let order = qs.data()
                this.setState({ order })

                let sqs = await qs.ref.parent.parent.get()
                if (sqs.exists) {
                    let storeData = sqs.data()
                    this.setState({ storeData, loading: false })
                }
            }
        }
    }


    render() {
        let { order, storeData, loading } = this.state
        let { order_id, store } = this.props.match.params

        if (loading) return <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /></div>

        return <div>
            {!isNative() && <div className="header-nav">
                <div style={{ padding: '10px' }}><h2 className="title"><a>ออเดอร์ #{order.order_no || order.id}</a></h2></div>
            </div>}
            <div className="store-application order-view" style={{ padding: '70px 15px' }}>

                {order.status === 'submitted' && <>
                    <div className={`order-status ${order.status} mb10`}>
                        รอการชำระเงิน<br />
                    </div>
                    <div className="has-text-centered">
                        <div className="bank-account" style={{ border: '0' }}>
                            กรุณาชำระเงินโดยการโอนผ่านบัญชี
                            <div className="bank">ธนาคาร{storeData.bank_accounts[0].bank}</div>
                            <div className="account-name">ชื่อบัญชี: {storeData.bank_accounts[0].account_name}</div>
                            <div className="account-no">เลขที่:{storeData.bank_accounts[0].account_no}</div>
                            <div className="account-branch">สาขา:{storeData.bank_accounts[0].account_branch}</div>
                            <img src={storeData.bank_accounts[0].image} style={{ width: '100%', maxWidth: 400 }} />
                        </div>
                    </div>
                    <hr />
                </>}

                {order.status === 'paid' && <div className={`order-status ${order.status} mb10`}>
                    กำลังจัดเตรียม
                </div>}
                {order.status === 'ontheway' && <div className={`order-status ${order.status} mb10`}>
                    กำลังไปส่ง
                </div>}
                {order.status === 'cancel' && <div className={`order-status ${order.status} mb10`}>
                    ยกเลิกแล้ว
                </div>}
                {order.status === 'delivered' && <><div className={`order-status ${order.status} mb10`}>
                    จัดส่งสำเร็จแล้ว
                    </div>
                    <div className="rating-wrapper">
                        <h3>คุณพอใจกับการสั่งครั้งนี้แค่ไหน?</h3>
                        <Rating
                            emptySymbol={customIcons}
                            fullSymbol={customActiveIcons}
                            initialRating={order.rating || this.state.rate || 3}
                            onChange={(rate) => {
                                this.setState({ rate })
                            }}
                            readonly={order.rating}
                        />
                        <br />
                        {!order.rating && <button className="button is-success" onClick={() => {
                            this.setState({ loading: true })
                            order.rating = this.state.rate
                            firebase.firestore().collection('stores').doc(store).collection('orders').doc(order_id).update({ rating: order.rating })
                            this.setState({ order, loading: false })
                        }}>ส่งความคิดเห็น</button>}


                    </div>
                    <hr />
                </>}
                {order.canceled === 'delivered' && <div className={`order-status ${order.status} mb10`}>
                    ถูกยกเลิกแล้ว
                </div>}

                <div className="columns is-mobile">
                    <div className="column">
                        <div className="field">
                            <label className="label">ชื่อลูกค้า</label>
                            <div className="control">
                                {order.customer.name}
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label className="label">เบอร์ติดต่อ</label>
                            <div className="control">
                                {order.customer.tel}
                            </div>
                        </div>
                    </div>
                </div>

                <label className="label">รายการ</label>
                <table className="table is-narrow is-fullwidth">

                    {order.carts.map((item) =>
                        <tr key={item.product.id + (item.option ? item.option.name : "")} className="">

                            <td className={`${item.cancel && 'item-canceled'}`}>
                                <div className="item-name">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small className="hilight">{this.state.hubStoresById && this.state.hubStoresById[item.product.store_id] && this.state.hubStoresById[item.product.store_id].name}</small><br />
                                    {item.quantity} x {item.product.name}
                                    {item.option && <div className="item-option">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.option.name}</div>}
                                    {item.note}
                                </div>
                            </td>

                            <td className="has-text-right">
                                {item.quantity * (item.product.price + (item.option && item.option.price ? Number.parseFloat(item.option.price) : 0))}

                            </td>
                        </tr>)}

                    {order.promotions && order.promotions.length > 0 && <tr>
                        <td className="promotions" colSpan="2">
                            {order.utensils !== true ? '** ไม่รับช้อนส้อม' : '** รับช้อนส้อม'}
                            <label className="label">โปรโมชั่น</label>
                            {order.promotions.map(item =>
                                <li>&nbsp;{item.name} {item.applyTime > 0 ? " x " + item.applyTime : ''}</li>
                            )}

                        </td>
                    </tr>
                    }

                    <tr>
                        <td><label className="label">ค่าจัดส่ง</label></td>

                        <td className="has-text-right">
                            {order.deliverCost}
                        </td>
                    </tr>
                    <tr>
                        <td><label className="label">ส่วนลด</label></td>

                        <td className="has-text-right">
                            (-) {order.discount}
                        </td>
                    </tr>

                    <tr>
                        <td><label className="label">รวมทั้งสิ้น</label></td>

                        <td className="has-text-right">
                            {order.totalPrice}
                        </td>
                    </tr>
                </table>


                {order.deliverType !== 'pickup' && <>
                    <label className="label mt20">ที่อยู่จัดส่ง</label>


                    {order.markerPosition && <div>
                        <a className="location-item" href={`http://maps.google.com/maps?daddr=${order.markerPosition.lat},${order.markerPosition.lng}&amp;ll=`} target="_blank">
                            {order.deliverAddress.route} {order.deliverAddress.city} {order.deliverAddress.area} {order.deliverAddress.state} {order.deliverAddress.postcode}
                            {/* {JSON.stringify(order.deliverAddress)} */}
                        </a>
                    </div>}


                    <hr />
                    <label className="label  mt20">รายละเอียดที่อยู่ เพิ่มเติม</label>
                    {/* {order.deliverAddressType && <div>{order.deliverAddressType ? 'บ้่าน' : 'คอนโด/ออฟฟิศ'}</div>} */}
                    {/* <div>{order.deliverMethod}</div> */}
                    <div>{order.deliverRemark}</div>
                </>}

                <hr />
                <label className="label  mt20">เวลาจัดส่ง</label>
                <div>
                    {typeof order.deliverDate === "string" ? order.deliverDate : "ASAP"} เวลาประมาณ : {order.deliverTime}
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store }) => ({ current_entity, current_store });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(OrderView)))
