import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/index'

import { CloudImage } from './CloudImage';
import BaseEditor from './BaseEditor';
import StoreTicket from '../models/StoreTicket';
import { dateFormat } from '../Helper';
import HubStoreProduct from '../models/HubStoreProduct';

// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")

class TicketEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Tickets"
        this.canCreate = true;
        this.defaultValue = { name: "", startDate: null, endDate: null, startTime: null, endTime: null, options: [] };
        this.listFields = ['store_id', 'product_name', 'quantity', 'status', 'created_at']
        // this.editFields = [['firstname', 'lastname'], ['mobile', 'email'], 'gender', 'grade']
        this.entityInfo = {
            name: 'String',
        }
        this.state.scope = 'pending'
        this.scopeList = [['pending', 'Pending'], ['prepared', 'Prepared'], ['picked', 'Picked']]
    }

    async componentDidMount() {
        let { current_store } = this.props
        this.dataRef = StoreTicket.getCollection(current_store);
        super.componentDidMount()

        let hubStoresById = await HubStoreProduct.setStore(current_store).getAllById()
        this.setState({ hubStoresById })
    }

    applyScope(scope) {
        let items = super.applyScope(scope);
        items = items.filter(x => x.status === scope)
        return items;
    }

    setItem(item, value) {
        let $this = this;

        this.dataRef.doc(item.id).update(value).then(function () {
            // $this.reloadList();
            $this.setState({
                loading: false,
            })
        }).catch(function (error) {
            console.error("Error removing document: ", error);
            $this.setState({
                loading: false,
                error_message: error
            })
        });


    }

    fieldData(item, name) {
        let result = super.fieldData(item, name)
        if (name === 'product_name') {
            return item.product && item.product.name
        }
        if (name === 'store_id' && this.state.hubStoresById) {
            return <div>{this.state.hubStoresById[item.store_id] && this.state.hubStoresById[item.store_id].name}<br />
                {item.status === 'pending' && <button className="button is-info is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'prepared' }) }} ><i className="fa fa-dollar" />&nbsp;PREPARED</button>}
                {(item.status === 'pending' || item.status === 'prepared') && <button className="button is-info is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'picked' }) }} ><i className="fa fa-truck" />&nbsp;PICKED</button>}
                <button className="button is-danger is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'cancel' }) }} ><i className="fa fa-ban" />&nbsp;CANCEL</button>
            </div>
        }
        if (name === 'date') {
            if (item.startDate && item.endDate) {
                return `${dateFormat(item.startDate, false)} - ${dateFormat(item.endDate, false)}`
            }
            return 'All date'
        }

        if (name === 'time') {
            if (item.startTime && item.endTime) {
                return `${item.startTime} - ${item.endTime}`
            }
            return 'All time'
        }

        if (name === 'image') {
            if (item.image) {
                return <CloudImage src={item.image} style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }} />
            }
        }

        if (name === 'store') {
            if (item.store) {
                return <div>{item.store}<br />
                    {item.status === 'pending' && <button className="button is-info is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'prepared' }) }} ><i className="fa fa-dollar" />&nbsp;PREPARED</button>}
                    {(item.status === 'pending' || item.status === 'prepared') && <button className="button is-info is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'picked' }) }} ><i className="fa fa-truck" />&nbsp;PICKED</button>}
                    <button className="button is-danger is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(item, { status: 'cancel' }) }} ><i className="fa fa-ban" />&nbsp;CANCEL</button>
                </div>
            }
        }

        if (name === 'status') {
            return <div className={`ticket status ${item.status}`}>{item.status && item.status.humanize}</div>
        }

        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }

        return result
    }


    renderEditor() {
        let { current_entity } = this.props
        return <div>
            <h2 className="title">รายการ #{current_entity.id}</h2>
            <b>{current_entity.product.name}</b><br />
            {current_entity.cart_info.option && current_entity.cart_info.option.name} <br />
            จำนวน {current_entity.quantity}

            <div className="mt10">

                {current_entity.status === 'pending' && <button className="button is-info is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(current_entity, { status: 'prepared' }) }} ><i className="fa fa-dollar" />&nbsp;PREPARED</button>}
                {/* {(current_entity.status === 'pending' || current_entity.status === 'prepared') && <button className="button is-info is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(current_entity, { status: 'picked' }) }} ><i className="fa fa-truck" />&nbsp;PICKED</button>} */}
                {/* <button className="button is-danger is-small" onClick={() => { if (!window.confirm("แน่ใจหรือไม่่ว่าจะเปลี่ยนแปลงรายการนี้")) return; this.setItem(current_entity, { status: 'cancel' }) }} ><i className="fa fa-ban" />&nbsp;CANCEL</button> */}
                {/* <button className="button is-primary" onClick={() => this.save(current_entity)} disabled={!this.state.fileObjURL}>SAVE</button> */}
            </div>
        </div>
    }
}


const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(TicketEditor))