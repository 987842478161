import * as Sentry from '@sentry/browser';
import React from "react"
import {
    Route,
} from "react-router-dom";
import { withRouter } from "react-router";

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as firebase from 'firebase';
import Ordering from './Ordering';
import TransferSubmit from './TransferSubmit';
import LineCompleteOrder from './LineCompleteOrder';
import StoreProduct from "../models/StoreProduct";
import { withCookies } from 'react-cookie';
import LineAddNotify from './LineAddNotify';

class StoreRoutes extends React.Component {

    componentDidMount() {
        if (!window.liff.isInClient()) {
            firebase.analytics().logEvent('external_browser')
            firebase.analytics().logEvent('page_view', { page_title: `External-${this.props.current_store}` })
        }

        if (this.props.store_id) {
            this.props.setCurrentStore(this.props.store_id)
            StoreProduct.init(this.props.store_id)
        }
        else if (this.props.match.params.store) {
            this.props.setCurrentStore(this.props.match.params.store)
            StoreProduct.init(this.props.match.params.store)
            const { cookies } = this.props;
            cookies.set('store_id', this.props.match.params.store, { path: '/' });
        }
    }

    componentDidUpdate() {
        let store = this.props.store_id || this.props.match.params.store
        console.log(`Store: ${store}`)
        if (store && this.props.current_store !== store) {
            if (this.props.store_id) {
                this.props.setCurrentStore(this.props.store_id)
                StoreProduct.init(this.props.store_id)
            }
            else if (this.props.match.params.store) {
                this.props.setCurrentStore(this.props.match.params.store)
                StoreProduct.init(this.props.match.params.store)
                const { cookies } = this.props;
                cookies.set('store_id', this.props.match.params.store, { path: '/' });
            }
        }
    }

    render() {
        let store = this.props.current_store || this.props.store_id || this.props.match.params.store

        // Sentry.captureMessage(`Store ${store}`)
        if (!store) return ""
        let { path } = this.props.match;

        console.log(this.props.current_store, path)
        // 
        return <div className="store-application">
            <Route path={`${path}/linepayComplete`}><LineCompleteOrder /></Route>
            <Route path={`${path}/:order_id/payment`}><TransferSubmit /></Route>
            {this.props.current_store && <Route exact path={`${path}`}><Ordering /></Route>}
        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store }) => ({ current_entity, current_store });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(StoreRoutes)))
