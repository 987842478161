import React from "react"
import moment from 'moment'
import { withRouter } from "react-router";

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import Payment2c2pForm from './Payment2c2pForm'
import StoreRequest from "../models/StoreRequest";
import { withCookies } from 'react-cookie';

class DownloadScreen extends React.Component {
    constructor(props) {
        super(props)
        window.location.href = "https://lin.ee/mvYtOUt"
    }
}

const mapStateToProps = ({ current_entity, current_store }) => ({ current_entity, current_store });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(DownloadScreen)))
