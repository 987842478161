import React from "react"
import FieldInput from "./FieldInput";

export default class FieldMultipleInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = { fileOrUrlType: "url" }

        this.onChange = this.onChange.bind(this)
        this.addNewItem = this.addNewItem.bind(this)
    }

    componentDidMount() {
        let { value, type } = this.props;
        if (type === 'Text' && value) {
            this.updateTextInput(value)
        }
    }


    componentDidUpdate() {
        let { value, type } = this.props;
        if (type === 'Text' && value) {
            this.updateTextInput(value)
        }

    }

    addNewItem() {
        let { value, name } = this.props;
        if (!value) {
            value = []
        }
        value.push({})

        if (this.props.onChange) {
            this.props.onChange(null, name, value)
        }
    }

    onChange(e, name, value) {

        if (this.props.onChange) {
            this.props.onChange(e, name, value)
        }
    }

    render() {
        let { type, value, name, dependences } = this.props;

        if (!value) {
            value = type.default_value || []
        }
        let form = []
        // form.push(<div>{JSON.stringify(value)}</div>)
        let header = []
        for (let t in type.fields) {
            header.push(<div className="column">{(t)}</div>)
        }
        form.push(<div className="columns">{header}</div>)
        for (let v in value) {
            let subform = []
            for (let t in type.fields) {
                subform.push(<div className="column"><FieldInput type={type.fields[t]} className="form-control" name={`${name}[${v}][${t}]`} value={value[v][t]} onChange={this.onChange} placeholder="" dependences={dependences} /></div>)
            }
            form.push(<div className="columns">{subform}</div>)
        }

        if (type.can_add === true) {
            form.push(<button onClick={() => this.addNewItem()}>+ เพิ่ม</button>)
        }

        return form
    }
}