import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/index'

import BaseEditor from './BaseEditor';
import FormField from './FormField';
import StoreDayoff from '../models/StoreDayoff';
import { dateFormat } from '../Helper';

// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")

class DayoffEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Day-offs"
        this.canCreate = true;
        this.listFields = ['name', 'startDate', 'active']
    }


    componentDidMount() {
        this.dataRef = StoreDayoff.getCollection(this.props.current_store);
        super.componentDidMount()
    }
    fieldData(item, name, humanize) {
        let result = super.fieldData(item, name)

        if (name === 'startDate') {

            if (humanize === false) return item.startDate
            return item.startDate ? `${dateFormat(item.startDate, false)}` : ''
        }


        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }

        return result
    }

    renderEditor() {
        let { current_entity } = this.props
        return <div>
            <FormField label="Name" name="name" type="text" onChange={this.handleDescriptionChange} value={current_entity.name} />
            <div className="field">
                <div className="columns">
                    <div className="column">
                        <label className="label is-small">Date</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input is-small"
                                name="startDate"
                                type="date"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.startDate} />
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt10">
                <button className="button is-primary" onClick={() => this.save(current_entity)}>SAVE</button>
            </div>
        </div>
    }
}


const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(DayoffEditor))