import FirebaseModel from './FirebaseModel';

export default class GlobalPromotion extends FirebaseModel {

}

GlobalPromotion.collection = 'promotions';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();