import * as firebase from 'firebase';
import * as AppConfig from './AppConfig.json';

const projectId = AppConfig.firebase.projectId;

const config = {
    apiKey: AppConfig.firebase.apiKey,
    authDomain: `${projectId}.firebaseapp.com`,
    databaseURL: `https://${projectId}.firebaseio.com/`,
    projectId: projectId,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: AppConfig.firebase.messagingSenderId,
    appId: AppConfig.firebase.appId,
    measurementId: AppConfig.firebase.measurementId
}

console.log(`Initialize firebase ${projectId}`)
firebase.initializeApp(config);

// Initialize Performance Monitoring and get a reference to the service
let perf = firebase.performance();
firebase.analytics();
// firebase.firestore().enablePersistence().catch(function (err) {
//     console.log("Offline mode is not working")
//     if (err.code === 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a a time.
//         // ...
//     } else if (err.code === 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence
//         // ...
//     }
// });
