import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Geocode from "react-geocode";

import * as Sentry from '@sentry/browser';
import * as firebase from 'firebase';
import * as AppConfig from '../AppConfig.json';
import * as actions from '../actions/index'

var db = firebase.firestore();
var orderRef = db.collection("orders");
var storageRef = firebase.storage().ref();
const googleAPIKey = 'AIzaSyCLAsBm9LduxGU3qL5Yl2uzk5pX_8dtRMs'
Geocode.setApiKey(googleAPIKey);
Geocode.enableDebug();

class LineCompleteOrder extends Component {
    constructor(props) {


        super(props);

        this.state = {
            order_id: decodeURIComponent(this.props.location.search.replace("liff.state==%3F", "")).replace("?", ""),
        }


        setTimeout(() => {
            this.loadOrder()
        }, 3000)
    }

    async loadOrder() {
        // alert(this.state.order_id)
        if (this.state.order_id === null || this.state.order_id === "") {
            window.liff.closeWindow();
            return
        }
        let docRef = await orderRef.doc(this.state.order_id).get()
        let data = await docRef.data();

        data.id = docRef.id
        this.setState({ data })

        if (data.status === 'paid') {

            window.liff.sendMessages([
                {
                    type: 'text',
                    text: `ชำระเงินผ่าน Line Pay #${data.id}`
                }
            ])
                .then(() => {
                    window.liff.closeWindow();
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    // alert(JSON.stringify(err))

                    setTimeout(() => {
                        this.loadOrder()
                    }, 1000)
                });
        }
        else {
            window.liff.closeWindow();
        }

        // orderRef.add(item).then(function (docRef) {
        //     item.id = docRef.id
        //     $this.setState({ order: item })
        // })
        //     .catch(function (error) {
        //         console.error("Error adding document: ", error);
        //     });
    }

    save = (data = null) => {
        this.upload(this.state.order_id, this.state.file.type, this.state.base64Image)


    };

    upload = (itemId, type, base64str) => {
        let $this = this;
        var filename = 'slip/' + itemId + '.' + type.replace('image/', '');
        var fileRef = storageRef.child(filename);


        fileRef.putString(base64str, 'base64').then(function (snapshot) {
            var itemRef = orderRef.doc(itemId);


            itemRef.update({ image: filename }).then(function () {
                window.liff.sendMessages([
                    {
                        type: 'flex',
                        altText: "รายการสั่งอาหาร",
                        contents:
                        {
                            "type": "bubble",
                            "body": {
                                "type": "box",
                                "layout": "vertical",
                                "contents": [
                                    {
                                        "type": "text",
                                        "weight": "bold",
                                        "text": "หลักฐานการชำระเงิน",
                                        "size": "lg"
                                    },
                                    {
                                        "type": "image",
                                        "url": `https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(filename)}?alt=media`,
                                        "margin": "none",
                                        "aspectMode": "fit",
                                        "gravity": "top",
                                        "aspectRatio": "3:4.5",
                                        "offsetTop": "0px",
                                        "offsetBottom": "0px",
                                        "offsetEnd": "0px",
                                        "offsetStart": "0px",
                                        "size": "full"
                                    }
                                ],
                                "spacing": "none",
                                "margin": "none",
                                "flex": 1
                            }
                        }
                    },
                    {
                        type: 'text',
                        text: `รายการสั่งอาหาร\n #${$this.state.order_id}`
                    }
                ])

                window.liff.closeWindow();
            })
                .catch(function (error) {
                    console.error("Error adding document: ", error);
                    $this.setState({ loading: false, error_message: error })
                });
        });
    };

    render() {
        return <div className="ordering">
            <div className="complete-payment">
                <br />
                <img src="/sticket.png" />
                <br /><br />
                ขอบคุณสำหรับการชำระเงินนะครับ<br />
                <small>รอทานอาหารอร่อยๆกันได้เลย :)</small>
                <br />
                <img src="/loading.gif" style={{ "width": "60px" }} /><br />
                <small>ระบบกำลังยืนยันการชำระเงิน</small>
            </div>
        </div>
    }
}
const mapStateToProps = ({ users, line_user }) => ({ users, line_user });
export default withRouter(connect(mapStateToProps, actions)(LineCompleteOrder))