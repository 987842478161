import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import moment from 'moment'
import XLSX from 'xlsx';

import * as firebase from 'firebase';
import BaseEditor from './BaseEditor';
import FormField from './FormField';
import HubStoreProduct from '../models/HubStoreProduct'
import ModalDialog from './ModalDialog';
import StateFieldInput from './inputs/StateFieldInput';
import StoreConfig from './StoreConfig';
import { isSuperAdmin, sort } from '../Helper';
import Header from './Header';
import City from '../models/City';
import { CloudImage } from './CloudImage';


// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")
var db = firebase.firestore();

class CityEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "เมือง"
        this.dataRef = db.collection('cities')
        this.canCreate = true;
        this.canEdit = true;
        this.canDelete = true;
        this.defaultValue = { name: "" };
        this.listFields = ['image', 'id', 'name', 'sort_order', 'active']
        this.editFields = ['id', 'name', 'sort_order', 'lat', 'lng', 'image']
        this.entityInfo = {
            id: 'String',
            name: 'String',
            sort_order: 'Number',
            lat: 'String',
            lng: 'String',
            image: 'ImageFile'
        }
        this.state.showCreateDialog = false;
        this.state.showConfigDialog = false
        this.state.newEntity = { owner: [] }
        this.defaultImagePath = `cities/`
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    fieldData(item, name) {
        let value = super.fieldData(item, name)

        if (name === 'image') {
            if (item.image) {
                return <CloudImage src={item.image} style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }} />
            }
        }

        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }
        else if (name === 'export_coupon') {
            return <button className={"button is-small is-warning"} onClick={() => { this.exportByCoupon(item) }}>Export</button>

        }
        else if (name === 'link') {
            return <>
                <a href={`/${item.id}/admin`} className="button is-info is-small">Admin Panel</a>
                {item.markup_ratio && <button className="button is-warning is-small" onClick={async () => {
                    if (window.confirm("แน่ใจหรือไม่ว่าต้องการปรับราคาทั้งหมด?")) {
                        let products = await db.collection('stores').doc(item.id).collection('products').get()
                        if (!products.empty) {
                            this.setState({ loading: true })

                            for (let pi in products.docs) {
                                let p = products.docs[pi]
                                // p.ref.update(price:}
                                let pd = p.data()
                                if (pd.cost) {
                                    pd.price = Math.ceil(pd.cost * item.markup_ratio)

                                    // console.log(pd, pd.cost, pd.price, pd.cost * item.markup_ratio)
                                    pd.options.forEach(x => {
                                        if (x.cost) x.price = Math.ceil(x.cost * item.markup_ratio)
                                    })

                                    await p.ref.update({ price: pd.price, options: pd.options })
                                }

                                this.setState({ loading_message: `กำลังปรับราคา: ${pi}/${products.docs.length}` })
                            }

                            alert("ปรับราคาเรียบร้อยแล้ว")
                            this.setState({ loading: false })
                        }
                    }
                }}>ตั้งราคาใหม่</button>}
            </>
        }

        return value
    }

    render() {
        // let { current_user } = this.props
        // if (!current_user || !isSuperAdmin(current_user.email)) return <Header />

        let result = super.render()

        return result
    }
}


const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(CityEditor))