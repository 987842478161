import StoreFirebaseModel from './StoreFirebaseModel';

export default class StorePromotion extends StoreFirebaseModel {

}

StorePromotion.collection = 'promotions';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();