import $ from "jquery";

// -  Extend Function
// eslint-disable-next-line
Object.defineProperty(String.prototype, 'humanize', {
    get: function () {
        var frags = this.split('_');
        for (let i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }
})

// eslint-disable-next-line
Object.defineProperty(String.prototype, 'capitalize', {
    get: function () {
        return this.charAt(0).toUpperCase() + this.slice(1).replace("_", " ")
    }
})

$(document).ready(function () {
    $('body').on('change', '.image-upload-button input[type=file]', function (e) {
        let $this = $(e.target)
        $this.parent().find('.preview').remove()

        if (e.target.files.length === 0) {
            $this.parent().removeClass('selected')
        }
        else {
            const image = e.target.files[0];

            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = ev => {
                $this.parent().addClass('selected')
                let img = $('<div class="preview"><img style="display:block; margin:auto" /></div>')
                img.find('img').height(100).attr('src', ev.target.result)
                $this.parent().append(img)

            };
        }
    })
})