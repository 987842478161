import React, { Component } from 'react';
import * as firebase from 'firebase';
import moment from "moment-timezone"
import CryptoJS from "crypto-js";
import Running from '../models/Running';
import * as AppConfig from '../AppConfig.json';
import 'moment/locale/th';
moment.locale('th')
moment().tz("Asia/Bangkok").format();

var db = firebase.firestore();

const callback_url = `${AppConfig.firebase.functionEndpoint}tctp_callback`
const callback_backend_url = `${AppConfig.firebase.functionEndpoint}tctp_bs_callback`
const url_2c2p = AppConfig.tctp.endpoint;
const merchant_id_2c2p = AppConfig.tctp.merchant_id;
const secret_key_2c2p = AppConfig.tctp.secret_key;

class Payment2c2pForm extends Component {
    constructor(props) {
        super(props)


        this.state = { tctp_params: {}, tctp_params_concat: '', tctp_hash_value: '' }

        this.tctp_form = React.createRef()

        this.dataRef = db.collection('store_requests');
        this.paymentDataRef = db.collection('payments');

    }
    componentDidMount() {

        var $this = this;
        console.log("construct")
        new Running("payments").then((running) => {

            $this.paymentRunning = running
            console.log("running", running)
        });

        let tctp_params = {
            version: '7.5',
            payment_url: url_2c2p,
            secret_key: secret_key_2c2p,
            merchant_id: merchant_id_2c2p,
            customer_email: this.props.email,
            payment_description: this.props.description,
            order_id: this.props.order_id,
            currency: '764',
            promotion: '',
            payment_option: 'B',
            ipp_interest_type: 'C',
            ipp_period_filter: '', //3,4,6,12
            payment_expiry: moment().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),//obj_order.expire_date.strftime("%Y-%m-%d %H:%M:%S"),
            amount: (this.props.total_price * 100).toString().padStart(12, '0'),
            result_url: callback_url,
            result_url_2: callback_backend_url
        }

        let tctp_params_concat = tctp_params['version'] + tctp_params['merchant_id'] + tctp_params['payment_description'] +
            tctp_params['order_id'] + tctp_params['currency'] + tctp_params['amount'] + tctp_params['customer_email'] + tctp_params['promotion'] +
            tctp_params['result_url'] + tctp_params['result_url_2'] + tctp_params['payment_option'] + tctp_params['ipp_interest_type'] +
            tctp_params['payment_expiry'] + tctp_params['ipp_period_filter']

        let tctp_hash_value = CryptoJS.HmacSHA1(tctp_params_concat, tctp_params['secret_key']);
        //hash.sha1().update(tctp_params_concat, tctp_params['secret_key']).digest('hex')

        this.setState({ tctp_hash_value, tctp_params_concat, tctp_params })

        this.onSubmit = this.onSubmit.bind(this)
    }

    async onSubmit(e) {
        e.preventDefault();
        await this.paymentRunning.incrementCounter()
        let count = await this.paymentRunning.getCount()
        await this.dataRef.doc(this.props.order_id).update({ status: 'waiting_payment', payment_method: '2c2p', updated_at: new Date() });

        let payment = {
            transaction_no: 'T' + count.toString().padStart(6, '0'),
            register_no: this.props.order_no,
            register_id: this.props.order_id,
            total: this.props.total_price,
            payment_method: '2c2p',
            status: 'waiting_payment',
            created_at: new Date()
        }
        await this.paymentDataRef.add(payment)

        let tctp_params = {
            version: '7.5',
            payment_url: url_2c2p,
            secret_key: secret_key_2c2p,
            merchant_id: merchant_id_2c2p,
            customer_email: this.props.email,
            payment_description: this.props.description,
            order_id: payment.transaction_no,
            currency: '764',
            promotion: '',
            payment_option: 'B',
            ipp_interest_type: 'C',
            ipp_period_filter: '', //3,4,6,12
            payment_expiry: moment().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),//obj_order.expire_date.strftime("%Y-%m-%d %H:%M:%S"),
            amount: (this.props.total_price * 100).toString().padStart(12, '0'),
            result_url: callback_url,
            result_url_2: callback_backend_url
        }

        let tctp_params_concat = tctp_params['version'] + tctp_params['merchant_id'] + tctp_params['payment_description'] +
            tctp_params['order_id'] + tctp_params['currency'] + tctp_params['amount'] + tctp_params['customer_email'] + tctp_params['promotion'] +
            tctp_params['result_url'] + tctp_params['result_url_2'] + tctp_params['payment_option'] + tctp_params['ipp_interest_type'] +
            tctp_params['payment_expiry'] + tctp_params['ipp_period_filter']

        let tctp_hash_value = CryptoJS.HmacSHA1(tctp_params_concat, tctp_params['secret_key']);
        //hash.sha1().update(tctp_params_concat, tctp_params['secret_key']).digest('hex')

        await this.setState({ tctp_hash_value, tctp_params_concat, tctp_params })

        this.tctp_form.current.submit();
    }

    render() {
        return <form id="PostForm" name="PostForm" method="POST" action={url_2c2p} ref={this.tctp_form}>
            <input type="hidden" name="version" value="7.5" />
            <input type="hidden" name="merchant_id" value={this.state.tctp_params['merchant_id']} />
            <input type="hidden" name="currency" value={this.state.tctp_params['currency']} />
            <input type="hidden" name="result_url_1" value={this.state.tctp_params['result_url']} />
            <input type="hidden" name="result_url_2" value={this.state.tctp_params['result_url_2']} />
            <input type="hidden" name="promotion" value={this.state.tctp_params['promotion']} />
            <input type="hidden" name="payment_option" value={this.state.tctp_params['payment_option']} />
            <input type="hidden" name="customer_email" value={this.state.tctp_params['customer_email']} />
            <input type="hidden" name="ipp_interest_type" value={this.state.tctp_params['ipp_interest_type']} />
            <input type="hidden" name="ipp_period_filter" value={this.state.tctp_params['ipp_period_filter']} />
            <input type="hidden" name="payment_expiry" value={this.state.tctp_params['payment_expiry']} />
            <input type="hidden" name="payment_description" value={this.state.tctp_params['payment_description']} />
            <input type="hidden" name="order_id" value={this.state.tctp_params['order_id']} />
            <input type="hidden" name="amount" value={this.state.tctp_params['amount']} />
            <input type="hidden" name="hash_value" value={this.state.tctp_hash_value} />
            <button onClick={this.onSubmit} className="button is-success is-large is-fullwidth">ชำระเงิน</button>
        </form>

    }
}

export default Payment2c2pForm;