import React, { Component, Children } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as firebase from 'firebase';
import * as actions from '../actions/index'

import { CloudImage } from './CloudImage';
import moment from "moment-timezone"
import ToggleButton from './forms/ToggleButton';
import { dateFormat } from '../Helper';
moment().tz("Asia/Bangkok").format();

// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")
var db = firebase.firestore();
var storageRef = firebase.storage().ref();

const defaultDesc = {};

class ModalDialog extends Component {
    render() {
        let { current_store } = this.props
        return <div className={`modal is-active animated fadeIn admin-modal`}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <h1 className="title is-4 has-text-centered">{this.props.title}</h1>
                <div className="content">
                    {this.props.children}
                </div>
                <div className="columns is-mobile pt10">
                    <div className="column"><button className="button is-fullwidth" onClick={this.props.onCancel || this.props.onClose}>{this.props.cancelText || 'ยกเลิก'}</button></div>
                    <div className="column"><a className="button is-success is-fullwidth" onClick={this.props.onOK}>{this.props.okText || 'ตกลง!'}</a></div>
                </div>
            </div>
            <button className="modal-close is-large" aria-label="close " onClick={this.props.onClose}></button>
        </div>
    }
}

const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(ModalDialog))