import React from "react"
import moment from 'moment'
import { withRouter } from "react-router";

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import Payment2c2pForm from './Payment2c2pForm'
import StoreRequest from "../models/StoreRequest";
import { withCookies } from 'react-cookie';

class RegisterScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.packages = {
            'FREE': { price: 0, order_count: 30, branch_count: 1, admin_count: 1 },
            'SEED': { price: 330, order_count: 1000, branch_count: 1, admin_count: 1 },
            'STANDARD': { price: 1600, order_count: 5000, branch_count: 2, admin_count: 5 },
            'PRO': { price: 4800, order_count: 15000, branch_count: 5, admin_count: 10 },
            'SME': { price: 12800, order_count: 30000, branch_count: 10, admin_count: 20 }
        }
    }

    componentDidMount() {

        // 
    }

    componentDidUpdate() {

    }


    handleDescriptionChange = (e) => {
        let updateState = this.state;
        if (e.target.type === "number") {
            eval(`updateState.${e.target.name} = Number.parseFloat(e.target.value)`)
        }
        else {
            eval(`updateState.${e.target.name} = e.target.value`)
        }
        this.setState(updateState)
    }


    async submit() {
        let storeRequestData = this.state
        storeRequestData.payment_total = 0;
        storeRequestData.payment_total = this.packages[storeRequestData.package].price;

        try {
            let storeRequest = await StoreRequest.add(storeRequestData)

            console.log(storeRequest)
            // storeRequestData = storeRequest.data()
            storeRequestData.id = storeRequest.id
            this.setState({ request: storeRequestData })
        } catch (e) {
            console.log(e)
        }


    }

    formValidate() {
        let state = this.state
        return state.store_name && state.store_id && state.firstname && state.lastname && state.email && state.tel && state.open_time && state.close_time && state.package
    }

    getDeliverTimes(date) {
        let dateList = []

        let currentTime = moment('00:00', "HH:mm")
        let currentEndTime = currentTime.clone().add(30, "minutes");
        let endTime = moment('23:59', "HH:mm")
        // console.log("getDeliverTime", currentTime.format(), endTime.format(), currentTime < endTime)
        while (currentTime < endTime) {
            // console.log(currentTime.format());
            dateList.push(`${currentTime.format("HH:mm")}`);
            currentTime = currentEndTime
            currentEndTime = currentTime.clone().add(30, "minutes");
        }

        return dateList.map(x => <option key={x} value={x}>{x}</option>)
    }

    render() {
        let { request } = this.state
        if (this.state.linepayLoading) return <div className="register-application">
            <div style={{ textAlign: 'center', paddingTop: '100px', fontWeight: 'bolder' }}>
                <h1 className="title is-3">ทาง RINE ได้รับข้อมูลของท่านแล้ว</h1><br />
                ทางเราจะทำการตรวจสอบข้อมูลของท่าน และ ติดต่อกลับอีกครั้ง ตามข้อมูลตามที่ท่านได้แจ้ง
            </div>
        </div>
        if (this.state.linepayLoading) return <div style={{ textAlign: 'center', paddingTop: '100px', fontWeight: 'bolder' }}>กำลังเชื่อมต่อไปยัง <br />Rabbit Line Pay<br /><img src="/loading.gif" width="60" /><br /><img src="https://scdn.line-apps.com/linepay/portal/assets/img/portal/th/logo.svg" /></div>

        if (request) {
            if (request.payment_total > 0) {
                return <div className="register-application">
                    <div className="container">
                        <h1 className="title has-text-centered">รายละเอียดการลงทะเบียน</h1>
                    ชื่อร้าน: {request.store_name}<br />
                    Store ID: {request.store_id}<br />
                    แพ็คเกจ: {request.package}<br />
                    จำนวนเงินที่ต้องชำระ: {request.payment_total}<hr />
                        <Payment2c2pForm total_price={request.payment_total} order_no={request.id} order_id={request.id} email={request.email} description={'Rine Service Fee'} />
                    </div>
                </div>
            }
            else {
                return <div className="register-application">
                    <div className="container">
                        <h1 className="title has-text-centered">เราได้รับข้อมูลการลงทะเบียนของแล้ว</h1>
                        <h3 className="subtitle has-text-centered mt20">
                            เมื่อกระบวนการเปิดร้านเสร็จสิ้น <br />
                ท่านจะได้รับข้อมูลการใช้งานผ่านทางอีเมล <br />{request.email}
                        </h3>
                    </div>
                </div>
            }
        }

        return <div className="register-application">
            <div className="container">
                <h1 className="title has-text-centered">ลงทะเบียนร้านค้าใหม่</h1>

                <h3 className="subtitle  has-text-centered mt20">{!this.formValidate() && 'กรุณากรอกข้อมูลต่อไปนี้ให้ครบ เพื่อทำการเปิดร้าน'}</h3>
                <hr />
                <h3 className="subtitle">รายละเอียดร้าน</h3>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">ชื่อร้าน</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="store_name"
                                    value={this.state.store_name}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder=""
                                />
                            </p>
                        </div>

                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">Store ID</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="store_id"
                                    value={this.state.store_id}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder=""
                                />
                            </p>
                            <p className="help">STORE ID เป็นตัวอักษรภาษาอังกฤษ หรือ ตัวเลข ความยาว 4-15 ตัวอักษร</p>
                        </div>

                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">เวลาเปิดร้าน</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <div className="control is-expanded">
                                <div className="select is-fullwidth">
                                    <select
                                        name="open_time"
                                        value={this.state.open_time}
                                        onChange={this.handleDescriptionChange}>
                                        <option value={null} disabled>กรุณาเลือกเวลา</option>
                                        {this.getDeliverTimes()}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control is-expanded">
                                <div className="select is-fullwidth">
                                    <select
                                        name="close_time"
                                        value={this.state.close_time}
                                        onChange={this.handleDescriptionChange}>
                                        <option value={null} disabled>กรุณาเลือกเวลา</option>
                                        {this.getDeliverTimes()}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <h3 className="subtitle">ผู้ติดต่อ</h3>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">ชื่อผู้ติดต่อ</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="firstname"
                                    value={this.state.firstname}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder=""
                                />
                            </p>
                        </div>
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="lastname"
                                    value={this.state.lastname}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder=""
                                />
                            </p>
                        </div>
                    </div>
                </div>


                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">อีเมล</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder=""
                                />
                            </p>
                        </div>

                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">เบอร์ติดต่อ</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="tel"
                                    value={this.state.tel}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder=""
                                />
                            </p>
                        </div>

                    </div>
                </div>

                <hr />


                <h3 className="subtitle">ข้อมูลสำหรับใบเสร็จ</h3>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">ชื่อบริษัท</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="company_name"
                                    value={this.state.company_name}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder=""
                                />
                            </p>
                        </div>

                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">เลขประจำตัวผู้เสียภาษี</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="tax"
                                    value={this.state.tax}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder="เลขผู้เสียภาษี"
                                />
                            </p>
                        </div>
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="tax_branch"
                                    value={this.state.tax_branch}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder="สาขา เช่น 00000 หมายถึง สำนักงานใหญ่"
                                />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label is-normal">
                        <label className="label">ที่อยู่บริษัท</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded">
                                <input
                                    name="company_address"
                                    value={this.state.company_address}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder=""
                                />
                            </p>
                        </div>
                    </div>
                </div>


                <hr />

                <h3 className="subtitle">กรุณาเลือกแพ็คเกจ (ราคานี้รวม VAT 7%)</h3>
                <div className="packages columns is-variable is-1 is-multiline is-mobile">
                    {Object.keys(this.packages).map(currentPackage => <div className="column is-half-mobile">
                        <div className={`card ${this.state.package === currentPackage && 'selected'}`} onClick={() => this.setState({ package: currentPackage })}>
                            <div className="card-content">
                                <div className="media">
                                    <div className="media-content">
                                        <p className="title is-4">{currentPackage}</p>
                                        {this.packages[currentPackage].price > 0 && <p className="subtitle is-6">{this.packages[currentPackage].price} บาท</p>}
                                    </div>
                                </div>

                                <div className="content">
                                    {this.packages[currentPackage].order_count}  ออเดอร์/เดือน<br />
                                    {this.packages[currentPackage].branch_count} สาขา<br />
                                    {this.packages[currentPackage].admin_count} ผู้ดูแลร้าน
                                </div>
                            </div>
                        </div>
                    </div>)}
                </div>

                <button className="button is-success is-large is-fullwidth" onClick={() => this.submit()} disabled={!this.formValidate()}>ลงทะเบียน</button>
                {/* <Payment2c2pForm total_price={'3333'} order_no={'dddd'} order_id={'tttt'} email={'dddd'} description={'Rine Service Fee'} /> */}

            </div>
        </div >
    }
}

const mapStateToProps = ({ current_entity, current_store }) => ({ current_entity, current_store });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(RegisterScreen)))
