import * as Sentry from '@sentry/browser';
import React from "react"

import {
    Route,
} from "react-router-dom";
import { withRouter } from "react-router";

import { connect } from 'react-redux';
import * as actions from '../actions/index'

import Dashboard from './Dashboard';
import PromotionEditor from './PromotionEditor';
import MenuEditor from './MenuEditor';
import OrderEditor from './OrderEditor';
// import RichMenuEditor from './RichMenuEditor';
import BannerEditor from './BannerEditor';
import CouponEditor from './CouponEditor';
import DayoffEditor from './DayoffEditor';
import StoreProduct from "../models/StoreProduct";
import Store from "../models/Store";
import TicketEditor from './TicketEditor';

import StoreConfig from './StoreConfig';
import HubStoreEditor from './HubStoreEditor';
import { isSuperAdmin } from '../Helper';
import StoreDriverEditor from './StoreDriverEditor';
import Header from './Header';
import ProductEditor from './ProductEditor';
import DeliveryConfig from './DeliveryConfig'
import StoreCategoryEditor from './StoreCategoryEditor';
import StoreBilling from './StoreBilling';

class AdminRoutes extends React.Component {
    constructor(props) {
        super(props)
        this.state = { storeData: null }
        props.setCurrentStore(this.props.match.params.store)
        StoreProduct.init(this.props.match.params.store)
        let current_store = this.props.match.params.store

        if (current_store) {
            // console.log(current_store)
            Store.get(current_store).then(store => {
                if (store.exists) {
                    console.log("Store data", store.data())
                    this.setState({ storeData: store.data() })
                    this.props.setCurrentStoreData(store.data())
                }
                else {
                    this.setState({ storeData: false })
                }
            })
        }
    }

    async componentDidUpdate() {
        if (this.props.current_store !== this.props.match.params.store) {
            this.props.setCurrentStore(this.props.match.params.store)
            let current_store = this.props.match.params.store

            if (current_store) {
                let store = await Store.get(current_store)
                if (store.exists) {
                    console.log("Store data", store.data())
                    this.setState({ storeData: store.data() })
                    this.props.setCurrentStoreData(store.data())
                }

                else {
                    this.setState({ storeData: false })
                }
            }
        }
    }

    render() {
        let { path } = this.props.match;
        let owner = null
        // console.log(this.state.storeData.owner, this.props.current_user && this.props.current_user.email)


        if (this.props.current_user && this.state.storeData !== null) {
            if ((this.state.storeData.owner && this.state.storeData.owner.includes(this.props.current_user.email)) || isSuperAdmin(this.props.current_user.email)) {
                owner = true
            }
            else if (this.state.storeData === false) {
                owner = false
                Sentry.captureMessage(`Admin : Invalid store ${this.props.current_store} ${this.props.current_user.email}`)
            }
            else if (!this.state.storeData.owner) {
                // 
                owner = false
                Sentry.captureMessage(`Admin : Invalid store owner ${this.props.current_store} ${this.props.current_user.email}`)
            }
            else if (this.state.storeData.owner && !this.state.storeData.owner.includes(this.props.current_user.email)) {
                // 
                owner = false
                Sentry.captureMessage(`Admin : Access denied ${this.props.current_store} ${this.props.current_user.email}`)
            }
        }
        return <div className="admin-application">
            <Header />
            <Route path={`${path}/menus`}><MenuEditor owner={owner} /></Route>
            <Route path={`${path}/products`}><ProductEditor owner={owner} /></Route>
            <Route path={`${path}/category`}><StoreCategoryEditor owner={owner} /></Route>
            <Route path={`${path}/banners`}><BannerEditor owner={owner} /></Route>
            <Route path={`${path}/promotions`}><PromotionEditor owner={owner} /></Route>
            <Route path={`${path}/coupons`}><CouponEditor owner={owner} /></Route>
            <Route path={`${path}/orders`}><OrderEditor owner={owner} /></Route>
            <Route path={`${path}/tickets`}><TicketEditor owner={owner} /></Route>
            {/* <Route path={`${path}/richmenus`}><RichMenuEditor owner={owner} /></Route> */}
            <Route path={`${path}/dayoff`}><DayoffEditor owner={owner} /></Route>
            <Route path={`${path}/billings`}><StoreBilling owner={owner} /></Route>
            <Route path={`${path}/settings`}><StoreConfig owner={owner} /></Route>
            <Route path={`${path}/hub_stores`}><HubStoreEditor owner={owner} /></Route>
            <Route path={`${path}/drivers`}><StoreDriverEditor owner={owner} /></Route>
            <Route path={`${path}/delivery`}><DeliveryConfig owner={owner} /></Route>
            <Route exact path={`${path}`}><Dashboard owner={owner} /></Route>
        </div>

    }
}

const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(AdminRoutes))
