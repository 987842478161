import FirebaseModel from './FirebaseModel';

export default class GlobalCoupon extends FirebaseModel {

}

GlobalCoupon.collection = 'coupons';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();