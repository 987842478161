import * as Sentry from '@sentry/browser';
import React from "react"
import PropTypes from "prop-types"
import moment from 'moment'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { withRouter } from "react-router";

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import Payment2c2pForm from './Payment2c2pForm'
import StoreRequest from "../models/StoreRequest";
import Payment from "../models/Payment";
import { withCookies, Cookies } from 'react-cookie';

class RegisterPaymentCompleteScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = { requestData: null, }



    }

    async componentDidMount() {
        if (this.props.match.params.payment_id) {
            let payment = await Payment.get(this.props.match.params.payment_id);
            let paymentData = payment.data()
            paymentData.id = payment.id;
            console.log(paymentData)

            let request = await StoreRequest.get(paymentData.request_id);
            let requestData = request.data();
            console.log(requestData)

            this.setState({ paymentData, requestData })
        }
    }

    componentDidUpdate() {

    }


    render() {
        if (!this.state.requestData) return ""

        return <div className="register-application">
            <div className="container">
                <h1 className="title has-text-centered">เราได้รับรายการการชำระเงินแล้ว</h1>
                <h3 className="subtitle has-text-centered mt20">
                    เมื่อกระบวนการเปิดร้านเสร็จสิ้น <br />
                ท่านจะได้รับข้อมูลการใช้งานผ่านทางอีเมล <br />{this.state.requestData.email}
                </h3>
            </div>
        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store }) => ({ current_entity, current_store });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(RegisterPaymentCompleteScreen)))
