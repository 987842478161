import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import CarouselSlider from "react-carousel-slider"
import moment from 'moment'
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import LazyLoad from 'react-lazyload';
import liff from '@line/liff';

import * as firebase from 'firebase';
import * as Sentry from '@sentry/browser';
import * as AppConfig from '../AppConfig.json';
import * as actions from '../actions/index';

import { CloudImage } from './CloudImage';
import StoreProduct from '../models/StoreProduct';
import StoreBanner from '../models/StoreBanner';
import StoreCoupon from '../models/StoreCoupon';
import StoreDayoff from '../models/StoreDayoff';
import GlobalCoupon from '../models/GlobalCoupon';
import StorePromotion from '../models/StorePromotion';
import Store from '../models/Store';
import StoreOrder from '../models/StoreOrder';
import { dateFormat, getParameterByName, thumbPath, isNative, calculateDeliverPrice, renderOpenDay, mpTrackEvent, sort, extraHint, deepCompare, getCartItemPrice, compareCartItem, addToCart, getTotalPrice, isCartItemValid, flexText, sendRN, sortByDistance } from '../Helper';
import HubStoreProduct from '../models/HubStoreProduct';
import GlobalPromotion from '../models/GlobalPromotion';
import * as mixpanel from 'mixpanel-browser';
import StoreCategory from '../models/StoreCategory';
import GlobalCategory from '../models/GlobalCategory';
import Running from '../models/Running';
import { ProductOptions } from './ProductOptions';


const googleAPIKey = AppConfig.googleAPIKey;
Geocode.setApiKey(googleAPIKey);
Geocode.enableDebug();

const minFixDeliverRate = 300;


const AsyncMap = withScriptjs(
    withGoogleMap(
        props => (
            <GoogleMap
                ref={props.mapRef}
                google={props.google}
                defaultZoom={props.zoom}
                defaultCenter={props.markerPosition}
                center={props.mapPosition}
                options={{ streetViewControl: false, disableDoubleClickZoom: true, gestureHandling: 'greedy' }}
                // onClick={props.onMapDragEnd}
                onDragEnd={() => setTimeout(props.onMapDragEnd, 500)}
                onBoundsChanged={props.onCenterChanged}
            >
                {/* For Auto complete Search Box */}
                <Autocomplete
                    onPlaceSelected={props.onPlaceSelected}
                    types={[]}
                    componentRestrictions={{ country: "th" }}
                />
                {/*Marker*/}

                {/* InfoWindow on top of marker */}
                {/* {props.deliverAddress.address && <InfoWindow
                    onClose={() => { }}

                    position={props.markerPosition}
                >
                    <div className="m5">
                        <span style={{ padding: 0, margin: 0 }}>{props.deliverAddress.address}</span><br />
                        <button className="button is-small is-success is-fullwidth mt5" onClick={() => this.confirmAddress()}>ส่งที่นี่</button>
                    </div>
                </InfoWindow>} */}
                <Marker
                    google={props.google}
                    name={''}
                    draggable={false}
                    // onDragEnd={this.onMarkerDragEnd}
                    position={props.markerPosition}
                />
                <Marker />
            </GoogleMap>
        )
    )
);


class Ordering extends Component {
    categories = {}
    state = {}
    menuOffset = 0

    constructor(props) {
        super(props);

        console.log("construct ordering")
        // firebase.firestore().collection('line_user').get().then(snap => {
        //     let size = snap.size // will return the collection size
        //     alert(size)
        // });

        this.selectedMenu = getParameterByName('i', window.location.href)
        // alert(this.selectedMenu)

        this.mapRef = null;
        this.markerRef = null;


        this.state = {
            showHubSelect: false,
            loading: false,
            loadingOrder: false,
            loading_message: "",
            error_message: "",
            linepayLoading: false,
            storeData: null,
            firstTime: true,
            showProductType: 'menu',

            products: null,
            banners: [],
            state: null,
            selectedAddress: false,
            hasOrderChanged: false,
            order: {
                lineUserId: null,
                utensils: false,
                carts: [],
                status: 'pending',
                totalPrice: 0,
                sumPrice: 0,
                sumProductPrice: 0,
                deliverCost: 0,
                deliverDate: this.getMinDate(),
                deliverTime: null,
                paymentMethod: 'transfer', //null
                customer: {
                    name: "",
                    tel: ""
                },
                deliverAddress: {
                    address: '',
                    route: '',
                    city: '',
                    area: '',
                    state: ''
                },
                markerPosition: null,
                promotions: []
            },


            promotions: [],
            showDescription: false,
            showPayment: false,
            showSummary: false,
            showOption: false,
            deliverCost: 50,

            showDeliverMap: false,
            zoom: 18,
            mapPosition: null,
            center: null,
            deliverAddress: {
                address: '',
                route: '',
                city: '',
                area: '',
                state: ''
            },
            // markerPosition: null,

        }

        this.addToCart = this.addToCart.bind(this)
        this.updateCart = this.updateCart.bind(this)
        this.updateOption = this.updateOption.bind(this)
        this.showDescription = this.showDescription.bind(this)
        this.showOptionDialog = this.showOptionDialog.bind(this)
        this.quickAddToCart = this.quickAddToCart.bind(this)
        this.getTotalPrice = this.getTotalPrice.bind(this);
        this.updateCurrentLocation = this.updateCurrentLocation.bind(this)
        this.updateLocation = this.updateLocation.bind(this);
        this.confirmAddress = this.confirmAddress.bind(this);
        this.summaryOrder = this.summaryOrder.bind(this)
        this.getMinDate = this.getMinDate.bind(this)
        this.formValidate = this.formValidate.bind(this)
        this.useCoupon = this.useCoupon.bind(this)
        this.cancelCoupon = this.cancelCoupon.bind(this)
        this.fail = this.fail.bind(this)

        this.isDayoff = this.isDayoff.bind(this)

        this.showSummary = this.showSummary.bind(this)
        this.hideSummary = this.hideSummary.bind(this)


        setInterval(() => {
            //console.log(this.state.hasOrderChanged)
            if (this.state.hasOrderChanged === true) {
                this.saveOrder()
                this.setState({ hasOrderChanged: false })
            }
        }, 10000)

        window.addEventListener('scroll', (e) => {
            if (this.timer !== null) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                let scroll = window.scrollY;
                let min = 9999999
                let selected = null
                // console.log(scroll, this.menuOffset)
                for (let c in this.categories) {
                    if (this.categories[c]) {
                        let offset = Math.abs((this.categories[c].offsetTop - this.menuOffset) - scroll)
                        // console.log(offset)
                        if (offset >= 0) {
                            if (min > offset) {
                                min = offset
                                selected = c
                            }
                        }
                    }
                }

                this.setState({ selectedCategory: selected })
            }, 100);
        });
    }

    componentWillUnmount() {
        // this.unsubscribe();
    }

    fail(error) // 'error' can be named anything
    {
        // alert('ระบบไม่สามารถเข้าถึงข้อมูลที่อยู่')
        // console.log('failed location')
        // this.updateLocation({ coords: { latitude:13.7649084, longitude: 100.5382846 } })
        let { storePickupBranch } = this.state
        if (storePickupBranch && storePickupBranch.length > 0) {
            this.setState({ currentLocation: { coords: { latitude: Number.parseFloat(storePickupBranch[0].lat), longitude: Number.parseFloat(storePickupBranch[0].lng) } } })
        }
        else {
            this.setState({ currentLocation: { coords: { latitude: 13.7649084, longitude: 100.5382846 } } })
        }


        // Geocode.fromLatLng(13.7649084, 100.5382846, googleAPIKey, 'th').then(
        //     response => {
        //         const address = response.results[0].formatted_address,
        //             addressArray = response.results[0].address_components
        //         const deliverAddress = {
        //             address: address,
        //             route: this.getRoute(addressArray) || '',
        //             city: this.getCity(addressArray) || '',
        //             area: this.getArea(addressArray) || '',
        //             state: this.getState(addressArray) || '',
        //             postcode: this.getPostcode(addressArray) || '',
        //         }

        //         this.setState({ deliverAddress }, () => {

        //             this.confirmAddress()

        //         })
        //     },
        //     error => {
        //         console.error(error);
        //     }
        // );

        Sentry.captureException(error)

    }

    async componentDidMount() {
        let current_store = this.props.current_store;
        console.log(`component mount ${current_store}`)
        firebase.analytics().logEvent('page_view', { page_title: `Menu-${current_store}` })
        this.globalCouponRef = GlobalCoupon.getCollection()
        this.globalPromotionRef = GlobalPromotion.getCollection()

        if (current_store) {
            this.state.current_store = current_store

            this.orderRef = StoreOrder.setStore(current_store).getCollection();
            this.productRef = StoreProduct.setStore(current_store).getCollection()
            this.bannerRef = StoreBanner.setStore(current_store).getCollection();
            this.couponRef = StoreCoupon.setStore(current_store).getCollection();

            this.dayoffRef = StoreDayoff.setStore(current_store).getCollection();
            this.promotionRef = StorePromotion.setStore(current_store).getCollection();

            let hubStoresById = await HubStoreProduct.setStore(current_store).getAllById()
            this.setState({ hubStoresById })

            let categories = await StoreCategory.setStore(current_store).getAll()
            let globalCategories = await GlobalCategory.getAll()
            globalCategories.forEach(x => x.isGlobal = true)
            categories = [...sort(categories, [{ "sort_order": "asc" }]), ...sort(globalCategories, [{ "sort_order": "asc" }])]
            // categories = categories

            this.setState({ categories })

            await this.reloadList();
            this.loadCurrentOrder();
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.updateCurrentLocation, this.fail);

        }
    }

    async componentWillUpdate() {

        let current_store = this.props.current_store;
        console.log(current_store, this.state.current_store, this.state)
        if (current_store && current_store !== this.state.current_store) {
            console.log("Load store " + current_store)
            this.state.current_store = current_store
            this.orderRef = StoreOrder.setStore(current_store).getCollection();
            this.productRef = StoreProduct.setStore(current_store).getCollection()
            this.bannerRef = StoreBanner.setStore(current_store).getCollection();
            this.couponRef = StoreCoupon.setStore(current_store).getCollection();
            this.dayoffRef = StoreDayoff.setStore(current_store).getCollection();
            this.promotionRef = StorePromotion.setStore(current_store).getCollection();
            let hubStoresById = await HubStoreProduct.setStore(current_store).getAllById()

            let categories = await StoreCategory.setStore(current_store).getAll()
            let globalCategories = await GlobalCategory.getAll()
            globalCategories.forEach(x => x.isGlobal = true)
            categories = [...categories, ...globalCategories]
            categories = sort(categories)
            this.setState({ categories, hubStoresById, current_store })

            await this.reloadList();
            this.loadCurrentOrder();

        }

        if (this.state.current_store && this.props.line_user && this.props.line_user !== this.state.order.lineUserId) {
            console.log("UPDATE LINE USER", this.props.line_user, this.state.order.lineUserId)
            if (!this.state.order.lineUserId) {
                this.state.order.lineUserId = this.props.line_user
            }
            else {
                this.loadCurrentOrder()
            }
        }


    }


    handleDescriptionChange = (e) => {
        let updateState = this.state.order;
        let value = e.target.value
        if (e.target.name === 'customer.tel') {
            // console.log(e.target.value, e.target.value.replace(/[^0-9]/g, ''))
            value = value.replace(/[^0-9]/g, '')
        }

        if (e.target.name === 'coupon_code') {
            value = value.toUpperCase();
        }

        if (e.target.name === 'deliverDate') {
            if (moment().startOf('day') - moment(value).startOf('day') === 0) {
                if (moment(this.state.order.deliverTime, "HH:mm") <= moment()) {
                    updateState.deliverTime = "ASAP"
                }
            }
            else {
                if (this.state.order.deliverTime === "ASAP") {
                    updateState.deliverTime = this.getOpenTime()
                }
            }
        }

        if (e.target.type === 'checkbox') {
            eval(`updateState.${e.target.name} = !updateState.${e.target.name}`)
        }
        else if (e.target.type === "number") {
            eval(`updateState.${e.target.name} = Number.parseFloat(value)`)
        }
        else {
            let ss = e.target.selectionStart;
            let se = e.target.selectionEnd;
            eval(`updateState.${e.target.name} = value`)
            e.target.selectionStart = ss;
            e.target.selectionEnd = se;
        }


        //console.log(updateState, e.target)

        this.setState({ order: updateState, hasOrderChanged: true })
    }

    isDayoff(date) {

        for (let d in this.state.dayoff) {
            let df = this.state.dayoff[d]
            // console.log(moment(df.startDate).toDate(), date.toDate(), moment(df.startDate).isSame(date, 'day'))
            if (moment(df.startDate).isSame(date, 'day')) {
                return true;
            }
        }

        return false;
    }

    getOpenTime() {
        let { storeData } = this.state
        if (storeData && storeData.open_time) {
            return storeData.open_time
        }
        return "11:00"
    }

    getCloseTime() {
        let { storeData } = this.state
        if (storeData && storeData.close_time) {
            return storeData.close_time
        }
        return "23:59"
    }

    getPreorderOpenTime() {
        let { storeData } = this.state
        if (storeData && storeData.preorder_open_time) {
            return storeData.preorder_open_time
        }
        if (storeData && storeData.open_time) {
            return storeData.open_time
        }
        return "11:00"
    }

    getPreorderCloseTime() {
        let { storeData } = this.state
        if (storeData && storeData.preorder_close_time) {
            return storeData.preorder_close_time
        }
        if (storeData && storeData.close_time) {
            return storeData.close_time
        }
        return "23:59"
    }

    getMinDate() {
        // 
        let today = moment();
        // Pre order only
        // let today = moment().add(1, "days");
        let tomorrow = today.add(1, "days");

        if (this.isDayoff(today)) {
            let date = today.clone();
            while (this.isDayoff(date)) {
                date.add(1, 'days')
            }
            return date.format('YYYY-MM-DD');
        }
        else {
            let deliverTime = this.getDeliverTimesData(today)
            let endTime = moment(this.getCloseTime(), "HH:mm")
            if (moment().isAfter(endTime)) {
                // if (deliverTime.length === 0) {
                let date = today.clone().add(1, "days");;
                while (this.isDayoff(date)) {
                    date.add(1, 'days')
                }
                return date.format('YYYY-MM-DD');
            }
            else {
                return today.format('YYYY-MM-DD')
            }
        }
    }

    fixOrder() {
        let { order } = this.state

        if (this.props.app_params && this.props.app_params.customer) {
            this.setState({ isFixCustomer: true })
            order.customer = {
                name: this.props.app_params.customer,
                tel: this.props.app_params.phone
            }
            order.deliverAddress = {
                address: this.props.app_params.destination,
                route: '',
                city: '',
                area: '',
                state: ''
            }
            order.deliverRemark = this.props.app_params.note
            order.deliverDate = this.props.app_params.date

            if (this.props.app_params.location) {
                let location = this.props.app_params.location.split(',')
                order.markerPosition = { lat: location[0], lng: location[1] }
            }

            if (this.props.app_params.time) {
                order.deliverTime = this.props.app_params.time
            }
            else {
                order.deliverTime = this.getDeliverTimesData(order.deliverDate)[0]
            }
        }
        else {
            // alert(JSON.stringify(this.props.app_params))
            if (this.props.app_params && this.props.app_params.phone) {
                // alert(this.props.app_params.phone)
                order.customer.tel = this.props.app_params.phone
            }
            // now > deliverDate
            let minDate = moment(this.getMinDate())
            let deliverTimes = this.getDeliverTimesData(order.deliverDate)
            let deliverTime = deliverTimes[0]

            // 
            if (this.isDayoff(order.deliverDate) || minDate.isAfter(order.deliverDate, 'day')) {
                // alert("min date")
                order.deliverDate = minDate.format('YYYY-MM-DD');
                deliverTimes = this.getDeliverTimesData(order.deliverDate)
                deliverTime = deliverTimes[0]

                order.deliverTime = deliverTime
            }

            if (moment().isSame(order.deliverDate, 'day')) {
                // alert("today")
                let endTime = moment(this.getCloseTime(), "HH:mm")
                if (moment().isAfter(endTime)) {
                    order.deliverDate = minDate.add(1, 'day').format("YYYY-MM-DD")
                    deliverTimes = this.getDeliverTimesData(order.deliverDate)
                    deliverTime = deliverTimes[0]

                    order.deliverTime = deliverTime
                }
                else {
                    let openTime = moment(this.getOpenTime(), "HH:mm")
                    if (moment().isBefore(openTime)) {
                        deliverTimes = this.getDeliverTimesData(order.deliverDate)
                        deliverTime = deliverTimes[0]
                    }
                    else {
                        deliverTime = 'ASAP'
                    }
                }
            }

            order.deliverTime = deliverTime
        }



        if (!order.deliverType) order.deliverType = 'deliver'

        if (this.state.storePickupBranch && this.state.storePickupBranch.length === 0) {
            order.deliverType = 'deliver'
        }

        if (!order.deliverAddress || order.deliverAddress.address === '') {
            order.deliverAddress = this.state.deliverAddress;
        }

        if (!order.deliverAddressType || order.deliverAddressType === '') {
            order.deliverAddressType = 'home'
        }

        if (!order.deliverMethod || order.deliverMethod === '') {
            order.deliverMethod = 'โทรหาเมื่อถึง'
        }

        if (this.state.storePickupBranch && this.state.storePickupBranch.length === 0) {
            order.deliverType = 'deliver'
        }

        // console.log("FixOrder", order)
        this.setState({ order })


        if (!order.distance && order.markerPosition) {
            this.getDistance()
        }

        let carts = order.carts;
        let newCarts = []
        for (let c in carts) {

            if (carts[c].quantity > 0) {
                newCarts.push(carts[c])
            }

        }

        this.updateCarts(newCarts)
    }

    async loadCurrentOrder() {
        let line_user = this.props.line_user

        if (this.props.app_params && this.props.app_params.phone) {
            let phone = this.props.app_params.phone

            this.state.loadingOrder = true
            this.setState({ loading: true, loading_message: "กำลังโหลดออเดอร์" })
            let querySnapshot = null
            let debugId = null
            // debugId = "zXjyhV4qReU2911BmNvK"

            try {
                querySnapshot = await this.orderRef.where("customer.tel", '==', phone).orderBy("created_at", "desc").limit(1).get()

                if (!querySnapshot.empty) {
                    console.log("Order found!")
                    this.setState({ firstTime: false })
                }

                let item = this.state.order;
                item.lineUserId = line_user;

                if (!querySnapshot.empty) {

                    let doc = querySnapshot.docs[0]
                    item = { ...doc.data(), id: doc.id }

                    if (item) {
                        console.log(item.id, item.status, debugId, item, doc.ref.parent.parent.id)

                        if (item.status === 'pending' || debugId) {
                            let lastUpdate = item.updated_at ? item.updated_at.toDate() : item.created_at.toDate()
                            console.log("Last update", lastUpdate, moment(lastUpdate).format('YYYY-MM-DD'))

                            if (!debugId && moment(lastUpdate).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
                                // If not debug mode and new day then reset cart
                                item.carts = []
                            }
                        }
                        else {
                            item.id = null;
                            item.lineUserId = line_user;
                            item.status = "pending"
                            item.paymentMethod = 'transfer'
                            item.liffMessage = null
                            item.liffOrderError = null
                            item.liffError = null

                            // If not pending reset cart
                            item.carts = []
                        }

                        if (item.coupon && item.status === 'pending') {
                            this.cancelCoupon();
                        }

                        if (item.coupon_code) {
                            querySnapshot = await this.globalCouponRef.where('is_active', "==", true)
                                .where("code", "==", item.coupon_code).get()

                            if (querySnapshot.empty) {
                                querySnapshot = await this.couponRef.where('is_active', "==", true)
                                    .where("code", "==", item.coupon_code).get()

                                if (querySnapshot.empty) {
                                    item.coupon_code = "";
                                    item.coupon = null;
                                    item.coupon_promotion = null;
                                }
                            }
                        }

                        // console.log(item)
                        this.setState({ loading_message: "", order: item, markerPosition: item.markerPosition, center: item.markerPosition, mapPosition: item.markerPosition, deliverAddress: item.deliverAddress }, () => {
                            // if (item.markerPosition && item.markerPosition.lat === 13.7649084 && item.markerPosition.lng === 100.5382846) {
                            //     this.updateLocation(this.state.currentLocation, true)
                            // }
                            this.fixOrder()
                            this.getTotalPrice()
                            // this.getDistance()
                            this.calculateDeliverCost()
                        })
                    }
                    else {
                        this.setState({ loading_message: "" })
                        // New user

                        this.fixOrder()
                        this.getTotalPrice()
                        // this.getDistance()
                        this.calculateDeliverCost()
                    }
                }
                else {

                    this.fixOrder()
                    this.getTotalPrice()
                    // this.getDistance()
                    this.calculateDeliverCost()
                }
            }
            catch (e) {
                // alert("error")
                alert(e.message)
                Sentry.captureMessage("Error load order by phone number")

                this.fixOrder()
                this.getTotalPrice()
                // this.getDistance()
                this.calculateDeliverCost()
            }

            this.state.loadingOrder = false
        }
        else if (line_user && !this.state.loadingOrder) {
            console.log("loadCurrentOrder", this.state.loadingOrder, line_user)

            this.state.loadingOrder = true
            this.setState({ loading: true, loading_message: "กำลังโหลดออเดอร์" })
            let querySnapshot = null
            let debugId = null
            // debugId = "zXjyhV4qReU2911BmNvK"
            if (debugId && this.props.app_params.localhost) {
                console.log("Load debug! " + debugId)
                querySnapshot = await firebase.firestore().collectionGroup('orders').where("id", '==', debugId).limit(1).get()
                let hub = querySnapshot.docs[0].ref.parent.parent.id
                console.log(hub)
                console.log(querySnapshot.docs[0].data())
                console.log(JSON.stringify(querySnapshot.docs[0].data()))
            }
            else {
                querySnapshot = await this.orderRef.where("lineUserId", '==', line_user).orderBy("created_at", "desc").limit(1).get()
            }

            if (!querySnapshot.empty) {
                console.log("Order found!")
                this.setState({ firstTime: false })
            }

            let item = this.state.order;
            item.lineUserId = line_user;

            if (!querySnapshot.empty) {

                let doc = querySnapshot.docs[0]
                item = { ...doc.data(), id: doc.id }

                if (item) {
                    console.log(item.id, item.status, debugId, item, doc.ref.parent.parent.id)

                    if (item.status === 'pending' || debugId) {
                        let lastUpdate = item.updated_at ? item.updated_at.toDate() : item.created_at.toDate()
                        console.log("Last update", lastUpdate, moment(lastUpdate).format('YYYY-MM-DD'))

                        if (!debugId && moment(lastUpdate).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
                            // If not debug mode and new day then reset cart
                            item.carts = []
                        }
                    }
                    else {
                        item.id = null;
                        item.lineUserId = line_user;
                        item.status = "pending"
                        item.paymentMethod = 'transfer'
                        item.liffMessage = null
                        item.liffOrderError = null
                        item.liffError = null

                        // If not pending reset cart
                        item.carts = []
                    }

                    if (item.coupon && item.status === 'pending') {
                        this.cancelCoupon();
                    }

                    if (item.coupon_code) {
                        querySnapshot = await this.globalCouponRef.where('is_active', "==", true)
                            .where("code", "==", item.coupon_code).get()

                        if (querySnapshot.empty) {
                            querySnapshot = await this.couponRef.where('is_active', "==", true)
                                .where("code", "==", item.coupon_code).get()

                            if (querySnapshot.empty) {
                                item.coupon_code = "";
                                item.coupon = null;
                                item.coupon_promotion = null;
                            }
                        }
                    }

                    // console.log(item)
                    this.setState({ loading_message: "", order: item, markerPosition: item.markerPosition, center: item.markerPosition, mapPosition: item.markerPosition, deliverAddress: item.deliverAddress }, () => {
                        // if (item.markerPosition && item.markerPosition.lat === 13.7649084 && item.markerPosition.lng === 100.5382846) {
                        //     this.updateLocation(this.state.currentLocation, true)
                        // }
                        this.fixOrder()
                        this.getTotalPrice()
                        // this.getDistance()
                        this.calculateDeliverCost()
                    })
                }
                else {
                    this.setState({ loading_message: "" })
                    // New user

                    this.fixOrder()
                    this.getTotalPrice()
                    // this.getDistance()
                    this.calculateDeliverCost()
                }
            }

            this.state.loadingOrder = false
        }
        else {
            this.fixOrder()
            this.getTotalPrice()
            // this.getDistance()
            this.calculateDeliverCost()
        }
    }

    async reloadList() {
        // Load apply_to_all promotions
        let $this = this;
        let { current_store } = this.props
        console.log(`reload list ${current_store}`)
        // Sentry.captureMessage(`reloadList ${current_store}`);

        if (current_store) {
            // console.log(current_store)
            let store = await Store.get(current_store)
            if (store.exists) {
                let storeData = store.data()

                let storePickupBranch = [];
                let branches = storeData ? storeData.branches : []
                for (let b in branches) {
                    if (branches[b].can_pickup) {
                        storePickupBranch.push(branches[b])
                    }
                }

                this.setState({ storeData, storePickupBranch })
            }
        }

        let querySnapshot = await this.dayoffRef.where('is_active', "==", true).get()
        let children = [];
        querySnapshot.forEach((doc) => {

            let childKey = doc.id;
            let childData = doc.data();
            if (childData.is_active === true) {
                childData.id = childKey;
                children.push(childData)
            }


        });
        // console.log(children)
        $this.setState({ dayoff: children });


        querySnapshot = await this.promotionRef.where('is_active', "==", true).where('apply_to_all', "==", true).get()
        children = [];
        querySnapshot.forEach((doc) => {

            let childKey = doc.id;
            let childData = doc.data();
            if (childData.is_active === true) {
                childData.id = childKey;
                children.push(childData)
            }


        });
        // console.log(children)

        $this.setState({ promotions: children });


        querySnapshot = await this.productRef.get()
        children = [];
        let selectedProduct = null
        querySnapshot.forEach((doc) => {

            let childKey = doc.id;
            let childData = doc.data();
            // if (childData.is_active === true) {
            childData.id = childKey;
            if (isNaN(childData.price)) childData.price = 0;
            children.push(childData)
            // }

            if (childData.id === this.selectedMenu) {
                selectedProduct = childData;
            }
        });

        if (selectedProduct) {
            this.showDescription(selectedProduct)
        }

        children = children.filter(item => {
            let currentTime = moment();    // e.g. 11:00 pm
            let inTime = true;
            let inDate = true;
            if (item.startDate && item.endDate) {
                inDate = (moment(item.startDate).toDate() <= currentTime.startOf('day').toDate()) && (moment(item.endDate).toDate() >= currentTime.startOf('day').toDate())
            }


            currentTime = moment();

            if (item.startTime && item.endTime) {
                inTime = currentTime.isBetween(moment(item.startTime, "HH:mm"), moment(item.endTime, "HH:mm")) || currentTime.isSame(moment(item.startTime, "HH:mm"))
            }

            if (item.preorderDay && item.preorderTime) {
                inTime = false
            }

            item.inTime = inTime;

            return item.is_active && inDate


            // return item.is_active && inDate && inTime
        })

        children = sort(children, [{ image: 'desc' }, { sort_order: 'desc' }, { store_id: 'asc' }, { created_at: 'asc' }])

        console.log("load product", children)
        $this.setState({ products: children });


        querySnapshot = await this.bannerRef.get()
        children = [];
        querySnapshot.forEach((doc) => {

            let childKey = doc.id;
            let childData = doc.data();
            if (childData.is_active === true) {
                childData.id = childKey;
                children.push(childData)
            }
        });


        children = children.filter(item => {
            let currentTime = moment();    // e.g. 11:00 pm
            // let inTime = true;
            let inDate = true;
            if (item.startDate && item.endDate) {
                inDate = (moment(item.startDate).toDate() <= currentTime.startOf('day').toDate()) && (moment(item.endDate).toDate() >= currentTime.startOf('day').toDate())
            }

            currentTime = moment();

            // if (item.startTime && item.endTime) {
            //     inTime = currentTime.isBetween(moment(item.startTime, "HH:mm"), moment(item.endTime, "HH:mm"))
            // }

            return item.is_active && inDate;
            //&& inTime
        })

        $this.setState({ banners: children });


    }

    updateCurrentLocation(currentLocation) {

        this.setState({ currentLocation })
        // if (!this.props.app_params.customer && !this.props.app_params.location) {
        //     if (!this.state.selectedAddress) {
        //         console.log("current_position", currentLocation)
        //         this.setState({ currentLocation })

        //         this.updateLocation(currentLocation, true)

        //     }
        //     else if (this.state.order && !this.state.order.selectedAddress) {
        //         this.setState({ currentLocation })

        //         this.updateLocation(currentLocation, true)
        //     }
        // }
    }

    updateLocation(position, isConfirm) {
        // alert(JSON.stringify(position))
        if (position) {
            //console.log("Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude);
            let location = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }

            let markerPosition = location
            // let order = this.state.order
            // order.markerPosition = markerPosition;
            this.setState({ markerPosition, mapPosition: markerPosition, center: markerPosition }, () => {

                Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng, googleAPIKey, 'th').then(
                    response => {
                        const address = response.results[0].formatted_address,
                            addressArray = response.results[0].address_components
                        const deliverAddress = {
                            address: address,
                            route: this.getRoute(addressArray) || '',
                            city: this.getCity(addressArray) || '',
                            area: this.getArea(addressArray) || '',
                            state: this.getState(addressArray) || '',
                            postcode: this.getPostcode(addressArray) || '',
                        }


                        this.setState({ deliverAddress }, () => {
                            if (isConfirm) {
                                this.confirmAddress()
                            }
                        })
                    },
                    error => {
                        console.error(error);
                    }
                );
            })
        }

    }

    formValidate() {
        let { order, cannotDeliver, promotionError } = this.state
        let { current_store } = this.props
        if (current_store === 'dcards.kiosks' || current_store === 'dentalline') {
            return promotionError || order.customer.name === "" || order.customer.tel.length < 9 || order.paymentMethod === null || order.deliverTime === "" || order.deliverDate === ""
        }

        return promotionError ||
            order.customer.name === "" ||
            order.customer.tel.length < 9 ||
            cannotDeliver ||
            (order.deliverType === 'deliver' && order.deliverAddress.address === "") ||
            order.paymentMethod === null ||
            order.deliverTime === "" ||
            order.deliverDate === ""
    }

    async lineNotify(message) {
        let { storeData } = this.state
        // console.log(message)

        if (message && storeData && storeData.lnt_access_token) {
            try {
                let response = await fetch(`https://us-central1-${AppConfig.firebase.projectId}.cloudfunctions.net/sendLineNotify`, { // Your POST endpoint
                    method: 'POST',
                    mode: 'no-cors',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ message: message, token: storeData.lnt_access_token })

                })
                await response.json()
                // console.log(success) // Handle the success response object
            }
            catch (error) {
                console.log(error) // Handle the error response object
                Sentry.captureException(error)
            }
        }
    }

    async summaryOrder() {

        let { order, storePickupBranch, hubStoresById } = this.state
        let { current_store } = this.props

        let confirm = true
        if (!moment().isSame(moment(order.deliverDate), 'day')) {
            confirm = window.confirm(`ออเดอร์ของคุณเป็นออเดอร์ล่วงหน้า\nวันที่${moment(order.deliverDate).format('DD/MM/YYYY')} เวลา ${order.deliverTime}`)
        }

        if (!confirm) return;

        if (this.formValidate()) {
            alert("กรุณาระบุข้อมูลให้ครบก่อนสั่ง")
            return
        }

        if (moment(order.deliverDate).format() === 'Invalid Date') order.deliverDate = moment().format('YYYY-MM-DD');

        order.status = 'submitted'
        order.submitDate = new Date()
        order.orderDate = moment().format()
        this.state.order = order

        await this.saveOrder(true)


        try {
            mpTrackEvent('Order Placement Complete', {
                "cart size": order.carts.length,
                "cart value": order.sumProductPrice,
                "cart menu names": order.carts.map(x => x.product.name),
                "cart dish names": order.carts.filter(x => x.option).map(x => x.option.name),
                "cart restaurant names": order.carts.map(x => hubStoresById[x.product.store_id]),
                "recipient's name": order.customer.name,
                "recipient's phone number": order.customer.tel,
                "utensils request": order.utensils ? "Yes" : "No",
                "condiments request": order.flavoring ? "Yes" : "No",
                "delivery request": order.deliverType !== 'pickup' ? "Yes" : "No",
                "self pick up request": order.deliverType === 'pickup' ? "Yes" : "No",
                "shipping address (map)": `${order.markerPosition.lat},${order.markerPosition.lng}`,
                "house number, road": order.deliverAddress.address,
                "sub-district": order.deliverAddress.city,
                "district": order.deliverAddress.area,
                "province": order.deliverAddress.state,
                "postal code": order.deliverAddress.postcode,
                "additional address details": order.deliverRemark,
                "delivery date": `${moment(order.deliverDate).format('DD/MM/YYYY')}`,
                "delivery time": `${order.deliverTime}`,
                "lifetime orders placed": "",
                "lifetime orders value": "",
                "last successful order date": "",
                "coupon code": order.coupon_code,
            })
        }
        catch (e) {

        }

        let notiMessage = ""
        let textContent = ""

        notiMessage = `มีรายการใหม่ ${current_store}\n#${order.order_no}\n`
        notiMessage += `${AppConfig.endpoint}/${current_store}/admin/orders?${order.id}\n`
        notiMessage += `--\n`
        order.carts.forEach(item => {
            textContent += `${item.quantity} X ${item.product.name}`
            textContent += `${(item.option && item.option.name) ? ` - ${item.option.name}` : ''}\n`
            textContent += `${!item.extras ? '' : Object.keys(item.extras).map((name) => { return item.extras[name].length === 0 ? '' : `${name}: ${item.extras[name].map(x => x.name).join(',')}\n` }).join('')}`
        })
        textContent += `--\n`
        textContent += `ยอดรวม ${order.sumPrice + order.deliverCost} บาท\n`
        textContent += `${order.paymentMethod === 'cod' ? 'ชำระเงินปลายทาง/เงินสด' : order.paymentMethod === 'transfer' ? 'โอนเงิน' : order.paymentMethod === 'linepay' ? 'Rabbit Line Pay' : '-'}\n`
        if (order.coupon_code) {
            textContent += `คูปอง: ${order.coupon_code}\n`
        }
        textContent += `--\n`
        textContent += `${order.customer.name} ${order.customer.tel}\n`
        if (order.deliverType === 'pickup') {
            textContent += `มารับเอง<br />`
        }
        else if (order.deliverType === 'donate') {
            textContent += `บริจาคอาหารให้ผู้ที่ต้องการ<br />`
        }
        else {
            textContent += `${order.deliverAddress.address}\n`
        }
        textContent += `${moment(order.deliverDate).format('DD/MM/YYYY')} เวลา ${order.deliverTime}\n`
        textContent += `${order.deliverRemark ? "*** " + order.deliverRemark : "  "}\n`

        notiMessage += textContent
        textContent = `มีรายการใหม่ ${current_store}\n` +
            `--\n` + textContent

        let flexContent = [
            flexText("RECEIPT " + this.state.storeData.name, "sm", "#1DB446", "bold")
            // {
            //     "type": "text",
            //     "text": `${this.textOrder}`,
            //     "weight": "bold",
            //     "size": "xxl",
            //     "margin": "md"
            // },

        ];

        order.carts.forEach(item => {
            let store = hubStoresById && hubStoresById[item.product.store_id]
            flexContent.push({
                "type": "box",
                "layout": "horizontal",
                "margin": "xxl",
                "contents": [
                    {
                        "type": "image",
                        "url": `https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(item.product.image)}?alt=media`,
                        "size": "xxs",
                        "align": "start",
                        "aspectMode": "cover",
                    },
                    {
                        "type": "box",
                        "layout": "vertical",
                        "spacing": "none",
                        "margin": "none",
                        "width": "140px",
                        "contents": [
                            flexText(item.product.name, "sm", "#555555", "bold"),
                            flexText(`${(item.option && item.option.name) ? item.option.name : '  '} x ${item.quantity}\n${!item.extras ? '' : Object.keys(item.extras).map((name) => { return item.extras[name].length === 0 ? '' : `${name}: ${item.extras[name].map(x => x.name).join(',')}\n` }).join('')} `, "sm", "#555555"),
                            flexText(`${store && store.name} `, "sm", "#555555"),
                        ]
                    },
                    flexText(`${item.quantity * (item.product.price + (item.option && item.option.price ? Number.parseFloat(item.option.price) : 0))}`, "sm", "#111111", null, "end"),

                ]
            });
        })

        let deliverContent = []
        if (current_store === 'dentalline') {
            deliverContent = []
        }
        else if (order.deliverType === 'pickup' || order.deliverType === 'donate') {
            if (order.deliverType === 'pickup') {
                deliverContent = [
                    flexText(`รายละเอียดจัดส่ง`, "lg", "#444444", "bold", "start"),
                    flexText(`${order.customer.name} ${order.customer.tel}`, "md", "#444444", null, "start"),
                    flexText(`มารับเอง`, "lg", "#444444", "bold", "start")
                ]
            }
            else if (order.deliverType === 'donate') {
                deliverContent = [
                    flexText(`รายละเอียดจัดส่ง`, "lg", "#444444", "bold", "start"),
                    flexText(`${order.customer.name} ${order.customer.tel}`, "md", "#444444", null, "start"),
                    flexText(`บริจาคอาหารให้ผู้ที่ต้องการ`, "lg", "#444444", "bold", "start")
                ]
            }

            storePickupBranch.forEach(x => {
                deliverContent.push(flexText(`${x.name}\n${x.address}`, null, null, null, null, null, "top"))
                deliverContent.push({
                    "type": "button",
                    "style": "link",
                    "height": "sm",
                    "action": {
                        "type": "uri",
                        "label": "ดูแผนที่",
                        "uri": `http://maps.google.com/maps?daddr=${x.lat},${x.lng}&amp;ll=`
                    }
                })
            })

            deliverContent.push(flexText(`${moment(order.deliverDate).format('DD/MM/YYYY')} เวลา ${order.deliverTime}`),)
            deliverContent.push(flexText("หากต้องการเปลี่ยนแปลงรายละเอียดกรุณาติดต่อผ่านช่องทาง Line"))
        }
        else {
            deliverContent = [
                flexText("รายละเอียดจัดส่ง", "lg"),
                flexText(`${order.customer.name} ${order.customer.tel}`),
                flexText(`${order.deliverAddress.address}`, null, "#888888"),
                // flexText(`${moment(order.deliverDate).format('DD/MM/YYYY')} เวลา ${order.deliverTime}`),
                flexText(`${order.deliverRemark ? "*** " + order.deliverRemark : "  "}`),
                flexText("หากต้องการเปลี่ยนแปลงรายละเอียดกรุณาติดต่อผ่านช่องทาง Line")
            ]
        }

        flexContent = [
            ...flexContent,

            {
                "type": "box",
                "layout": "horizontal",
                "margin": "xxl",
                "spacing": "md",
                "contents": [
                    flexText((current_store !== 'dentalline' ? (`${order.utensils !== true ? '** ไม่รับช้อนส้อม' : '** รับช้อนส้อม'}`) : '-'), "md", "#111111", null, "start")
                ]
            },
            {
                "type": "separator",
                "margin": "xxl"
            },
            {
                "type": "box",
                "layout": "vertical",
                "margin": "xxl",
                "spacing": "md",
                "contents": [
                    {
                        "type": "box",
                        "layout": "horizontal",
                        "margin": "xxl",
                        "contents": [
                            flexText(`รวม`, "sm", "#555555", "bold"),
                            flexText(`${order.sumProductPrice} `, "sm", "#111111", null, "end")
                        ]
                    },
                    {
                        "type": "box",
                        "layout": "horizontal",
                        "contents": [
                            flexText(`ค่าส่ง`, "sm", "#555555", "bold"),
                            flexText(`${order.deliverCost} `, "sm", "#111111", null, "end")
                        ]
                    },

                    {
                        "type": "box",
                        "layout": "horizontal",
                        "contents": [
                            flexText(`ส่วนลด`, "sm", "#555555", "bold"),
                            flexText(`(-) ${order.discount} `, "sm", "#111111", null, "end")
                        ]
                    },
                    {
                        "type": "separator",
                        "margin": "xxl"
                    },
                    {
                        "type": "box",
                        "layout": "horizontal",
                        "contents": [
                            flexText(`ยอดรวม`, "md", "#555555", "bold"),
                            flexText(`${order.sumPrice + order.deliverCost} `, "sm", "#111111", "bold", "end"),
                        ]
                    },
                    {
                        "type": "box",
                        "layout": "horizontal",
                        "contents": [
                            flexText(`ชำระเงินโดย`, "sm", "#555555", null),
                            flexText(`${order.paymentMethod === 'cod' ? 'ชำระเงินปลายทาง' : order.paymentMethod === 'transfer' ? 'โอนเงิน' : order.paymentMethod === 'linepay' ? 'Rabbit Line Pay' : order.paymentMethod === 'credit_card' ? 'บัตรเครดิต' : ''}\n ${order.coupon_code ? "คูปอง:" + order.coupon_code : ""} `, "sm", "#111111", null, "end")
                        ]
                    }
                ]
            },
            {
                "type": "separator",
                "margin": "xxl"
            }
        ]

        if (deliverContent.length > 0) {
            flexContent.push({
                "type": "box",
                "layout": "vertical",
                "margin": "xxl",
                "spacing": "md",
                "contents": deliverContent
            })
        }

        flexContent.push({
            "type": "separator",
            "margin": "xxl"
        })

        deliverContent.push({
            "type": "button",
            "style": "primary",
            "height": "sm",
            "action": {
                "type": "uri",
                "label": "ตรวจสอบสถานะออเดอร์",
                "uri": `${AppConfig.liffUrl}/order/${current_store}/${order.id}`
            }
        })

        flexContent.push({
            "type": "box",
            "layout": "horizontal",
            "margin": "md",
            "contents": [
                {
                    ...flexText("ORDER ID", "xs", "#AAAAAA"),
                    "flex": 0
                },
                flexText(`#${order.order_no} [${order.id}]`, "xs", "#AAAAAA", null, "end")
            ]
        })


        // https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(props.src)}?alt=media
        // console.log(JSON.stringify(flexContent))
        flexContent = {
            type: 'flex',
            altText: `รายการ`,
            contents:
            {
                "type": "bubble",
                "styles": {
                    "footer": {
                        "separator": true
                    }
                },
                "body": {
                    "type": "box",
                    "layout": "vertical",
                    "contents": flexContent
                }
            }
        }

        if (order.paymentMethod === 'transfer') {
            this.lineNotify(notiMessage)
            this.setState({ state: 'complete' })


            if (liff && liff.getOS() !== 'web') {
                let liffMessage = [];

                liffMessage.push(flexContent)

                try {
                    let bank_accounts_text = ''

                    if (this.state.storeData) {
                        let { bank_accounts } = this.state.storeData

                        if (bank_accounts && bank_accounts.length > 0) {
                            for (let b in bank_accounts) {

                                try {
                                    if (bank_accounts[b].image) {
                                        liffMessage.push({
                                            type: 'image',
                                            originalContentUrl: bank_accounts[b].image,
                                            previewImageUrl: bank_accounts[b].image,
                                        })
                                    }
                                } catch (err) {
                                    Sentry.captureException(err)
                                }

                                if (bank_accounts[b].account_no) {
                                    if (bank_accounts_text === '')
                                        bank_accounts_text = '\n----------------\nกรุณาโอนเงินเข้าบัญชี\n'
                                    if (bank_accounts[b].bank)
                                        bank_accounts_text += `\nธนาคาร${bank_accounts[b].bank}\n\n`
                                    if (bank_accounts[b].account_name)
                                        bank_accounts_text += `ชื่อบัญชี:\n${bank_accounts[b].account_name}\n\n`
                                    if (bank_accounts[b].account_no)
                                        bank_accounts_text += `เลขที่:\n${bank_accounts[b].account_no}\n\n`
                                    if (bank_accounts[b].account_branch)
                                        bank_accounts_text += `สาขา:\n${bank_accounts[b].account_branch}`
                                }
                            }
                        }
                    }

                    liffMessage.push({
                        type: 'text',
                        text: `รายการ\n #${order.order_no} [${order.id}]`
                            + bank_accounts_text
                    })
                } catch (err) {
                    Sentry.captureException(err)
                }


                liffMessage.push({
                    type: 'flex',
                    altText: "รายการ",
                    contents:
                    {
                        "type": "bubble",
                        "body": {
                            "type": "box",
                            "layout": "vertical",
                            "contents": [
                                flexText("ชำระด้วยการโอนเงิน", "lg", "#000000", "bold"),
                                flexText(`โปรดชำระผ่านบัญชีธนาคารตามที่ระบุ\nยอดชำระ ${order.sumPrice + order.deliverCost} บาท\nพร้อมส่งหลักฐานการโอน`, "sm", "#000000", "bold", null, null, "top"),
                                {
                                    "type": "button",
                                    "style": "link",
                                    "height": "sm",
                                    "action": {
                                        "type": "uri",
                                        "label": "ส่งหลักฐานการโอน",
                                        "uri": `https://liff.line.me/${AppConfig.liffId}/${current_store}/${order.id}/payment`
                                    }
                                }
                            ],
                            "spacing": "none",
                            "margin": "none",
                            "flex": 1
                        }
                    }
                })

                order.liffMessage = liffMessage;

                let i = 0
                try {
                    await liff.sendMessages([liffMessage[0]])
                }
                catch (err) {
                    await this.orderRef.doc(order.id).update({ liffMessage, liffOrderError: JSON.stringify(err) })
                    console.log(JSON.stringify(liffMessage[i]))

                    Sentry.captureException(err);

                    liff.sendMessages([
                        {
                            type: 'text',
                            text: textContent
                        }
                    ])
                }

                try {
                    await liff.sendMessages(liffMessage.slice(1))
                }
                catch (err) {
                    await this.orderRef.doc(order.id).update({ liffMessage, liffError: JSON.stringify(err) })

                    Sentry.captureException(err);
                }

                liff.closeWindow();

            }
        }
        else if (order.paymentMethod === 'linepay') {
            this.setState({ state: 'complete' })
            this.lineNotify(notiMessage)

            liff.sendMessages([
                flexContent,
                {
                    type: 'text',
                    text: `ส่งรายการ\n #${order.id}`
                }
            ])

            this.setState({ linepayLoading: true })
            fetch(`https://us-central1-${AppConfig.firebase.projectId}.cloudfunctions.net/linePayRequest`, { // Your POST endpoint
                // fetch('http://localhost:5001/${AppConfig.firebase.projectId}/us-central1/linePayRequest', { // Your POST endpoint
                method: 'POST',
                // mode: 'no-cors',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ orderId: order.id })
            }).then(
                response => {

                    // console.log(response.json())
                    return response.json() // if the response is a JSON object
                }
            ).then(
                success => {

                    // console.log(success) // Handle the success response object
                    this.lineNotify(notiMessage)
                    if (success.order.linepayPaymentUrlWeb) {
                        // alert(success.order.linepayPaymentUrlWeb)
                        window.location.href = success.order.linepayPaymentUrlWeb;
                    }
                    else {
                        // alert(JSON.stringify(success))
                    }
                }
            ).catch(
                error => {

                    console.log(error) // Handle the error response object
                    alert(JSON.stringify(error))
                }
            );



            //
        }
        else {
            this.lineNotify(notiMessage)
            this.setState({ state: 'complete' })
            try {
                await liff.sendMessages([
                    flexContent,
                    {
                        type: 'text',
                        text: `ส่งรายการ\n #${order.id}`
                    }
                ])

                liff.closeWindow();
            }
            catch (err) {
                Sentry.captureException(err);
                liff.sendMessages([
                    {
                        type: 'text',
                        text: JSON.stringify(flexContent)
                    }
                ])
                // alert(JSON.stringify(err))
            }
        }
    }

    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {

        if (
            // this.state.mapPosition.lat !== nextState.mapPosition.lat ||
            // this.state.mapPosition.lng !== nextState.mapPosition.lng ||
            // this.state.order.markerPosition.lat !== nextstate.order.markerPosition.lat ||
            // this.state.order.markerPosition.lng !== nextstate.order.markerPosition.lng ||
            this.state.center && nextState.center && (this.state.center.lat !== nextState.center.lat || this.state.center.lng !== nextState.center.lng)

            //     // this.state.order.deliverAddress.address !== nextState.order.deliverAddress.address ||
            //     // this.state.order.deliverAddress.city !== nextState.order.deliverAddress.city ||
            //     // this.state.order.deliverAddress.area !== nextState.order.deliverAddress.area ||
            //     // this.state.order.deliverAddress.state !== nextState.order.deliverAddress.state ||
            //     // this.state.order.deliverAddress.postcode !== nextState.order.deliverAddress.postcode
        ) {
            // console.log(nextState.mapPosition)
            return false
        }

        return true;
    }
    /**
      * Get the city and set the city input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getRoute = (addressArray) => {
        let route = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'street_number' === addressArray[i].types[0]) {
                    route += addressArray[i].long_name;

                }
            }
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'route' === addressArray[i].types[0]) {
                    route += addressArray[i].long_name;
                }
            }
        }

        return route
    };
    /**
      * Get the city and set the city input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getCity = (addressArray) => {
        let city = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                // console.log(addressArray[i].long_name, addressArray[i].long_name.includes('เขต'), addressArray[i].long_name.includes('อำเภอ'))
                if (addressArray[i].long_name.includes('แขวง') || addressArray[i].long_name.includes('ตำบล')) {
                    city = addressArray[i].long_name
                    return city;
                }
            }

            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {

                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if ('administrative_area_level_2' === addressArray[i].types[j]) {
                            city = addressArray[i].long_name
                            return city;
                        }
                    }
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if ('sublocality_level_2' === addressArray[i].types[j]) {
                            city = addressArray[i].long_name
                            return city;
                        }
                    }
                }
            }
        }

        return ''
    };
    /**
      * Get the area and set the area input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                // console.log(addressArray[i].long_name, addressArray[i].long_name.includes('เขต'), addressArray[i].long_name.includes('อำเภอ'))
                if (addressArray[i].long_name.includes('เขต') || addressArray[i].long_name.includes('อำเภอ')) {
                    area = addressArray[i].long_name
                    return area;
                }
            }

            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {


                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                            area = addressArray[i].long_name
                            return area;
                        }
                    }

                }
            }
        }

        return ''
    };
    /**
      * Get the address and set the address input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getState = (addressArray) => {
        let state = '';

        // console.log(addressArray)
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && addressArray[i].types[0].indexOf('administrative_area_level_1') >= 0) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }

        return ''
    };

    /**
      * Get the address and set the address input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getPostcode = (addressArray) => {
        let state = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && "postal_code" === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }

        return ''
    };
    /**
      * And function for city,state and address input
      * @param event
      */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
      * This Event triggers when the marker window is closed
      *
      * @param event
      */
    onInfoWindowClose = (event) => {
    };
    /**
      * When the user types an address in the search box
      * @param place
      */
    onPlaceSelected = (place) => {
        if (!place || !place.geometry) {
            window.alert('ที่อยู่ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง')
            return
        }
        // console.log(place, place.address_components)

        // const address = place.formatted_address,
        //     addressArray = place.address_components

        // const deliverAddress = {
        //     address: address,
        //     route: this.getRoute(addressArray) || '',
        //     city: this.getCity(addressArray),
        //     area: this.getArea(addressArray),
        //     state: this.getState(addressArray),
        //     postcode: this.getPostcode(addressArray),
        // }



        this.updateLocation({ coords: { latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() } })

        // this.setState({ deliverAddress })
    };
    /*
    * When the marker is dragged you get the lat and long using the functions available from event object.
    * Use geocode to get the address, city, area and state from the lat and lng positions.
    * And then set those values in the state.
    *
    * @param event
    */
    onCenterChanged = (event) => {
        if (!this.mapRef || !this.mapRef.getCenter()) return
        let center = this.mapRef.getCenter()
        let newLat = center.lat(),
            newLng = center.lng()

        if (this.state.markerPosition.lat === newLat &&
            this.state.markerPosition.lng === newLng) {
            return;
        }

        // console.log("NEW CENTER", newLat, newLng);
        // this.markerRef.setCenter(newLat, newLng)
        if (this.timeout) {
            clearInterval(this.timeout)
        }

        // this.timeout = setTimeout(() => {
        //     this.timeout = null;
        //     this.onMarkerDragEnd()
        // }, 2000);

        let markerPosition = { lat: newLat, lng: newLng }
        this.setState({ markerPosition })

    }
    onMarkerDragEnd = (event) => {
        if (!this.mapRef || !this.mapRef.getCenter()) return
        // console.log('event', event, this, this.mapRef.getCenter());
        let newLat = null, newLng = null;

        if (event) {
            newLat = event.latLng.lat()
            newLng = event.latLng.lng()
        }
        else {
            let center = this.mapRef.getCenter()
            if (center) {
                newLat = center.lat()
                newLng = center.lng()
            }
        }

        Geocode.fromLatLng(newLat, newLng, googleAPIKey, 'th').then(
            response => {

                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components
                // console.log(addressArray)
                const deliverAddress = {
                    address: address,
                    route: this.getRoute(addressArray) || '',
                    city: this.getCity(addressArray),
                    area: this.getArea(addressArray),
                    state: this.getState(addressArray),
                    postcode: this.getPostcode(addressArray),
                }



                //console.log('city', deliverAddress);
                let markerPosition = { lat: newLat, lng: newLng }

                this.setState({ deliverAddress, markerPosition })


            },
            error => {
                console.error(error);
            }
        );
    };

    addToCart(product, quantity, option = null, note, extras) {
        this.setState({ selectedNote: '' })
        if (!note) note = ''
        if (product.isSoldout) return

        if (option === null) {
            if (product.options.length > 0) {
                option = product.options[0]
            }
        }
        else {
            if (option.isSoldout) return
        }

        if (quantity === 0) return
        this.setState({ showCartAdded: true }, () => {
            setTimeout(() => { this.setState({ showCartAdded: false }) }, 2000)
        })

        let carts = this.state.order.carts;
        carts = addToCart(carts, product, quantity, option, note, extras);
        this.updateCarts(carts)

        firebase.analytics().logEvent('add_to_cart', {
            items: [`${product.name}-${option && option.name}`]
        })

    }

    updateCarts(carts) {
        let sum = this.getTotalPrice()

        let order = this.state.order;
        if (order) {

            if (order.sumPrice !== sum && order.totalPrice !== order.sumPrice + order.deliverCost) {
                order.sumPrice = sum;
                order.totalPrice = order.sumPrice + order.deliverCost
            }

            order.carts = carts
            this.setState({ order: order }, () => {
                // this.getDistance()
                this.calculateDeliverCost()
            })

            //console.log(order)
            this.saveOrder()
            // if (order.id) {
            //     this.orderRef.doc(order.id).update(order)
            // }
            // else {
            //     this.orderRef.add(order)
            // }
        }
    }

    confirmAddress(by_user) {

        let markerPosition = this.state.markerPosition;
        let deliverAddress = this.state.deliverAddress;
        //console.log("Confirm", markerPosition, deliverAddress)
        let order = this.state.order;
        order.markerPosition = markerPosition;
        order.deliverAddress = deliverAddress;
        let showDeliverMap = false
        let selectedAddress = !!by_user
        order.selectedAddress = selectedAddress
        this.setState({ order, showDeliverMap, selectedAddress, markerPosition, center: markerPosition, mapPosition: markerPosition }, () => {
            this.getDistance();
            this.getTotalPrice()
        })
    }

    async getDistance() {
        let { order } = this.state
        if (!order.markerPosition) return

        let origin1 = new window.google.maps.LatLng(order.markerPosition.lat, order.markerPosition.lng);
        let branchLocation = []
        let branches = this.state.storeData ? this.state.storeData.branches : []

        if (!window.google) {
            this.setState({ isFixlocation: true, cannotDeliver: false }, () => this.getTotalPrice())
            return
        }

        if (branches && branches.length > 0) {
            for (let b in branches) {
                if (branches[b].lat && branches[b].lng) {
                    branchLocation.push(new window.google.maps.LatLng(branches[b].lat, branches[b].lng))
                }
            }
        }

        if (branchLocation.length === 0) {
            this.setState({ isFixlocation: true, cannotDeliver: false }, () => this.getTotalPrice())
            return
        }

        let service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix({
            origins: [origin1],
            destinations: branchLocation,
            travelMode: 'DRIVING',
            avoidHighways: true,
            avoidTolls: true,
            drivingOptions: {
                departureTime: new Date(Date.now()),  // for the time N milliseconds from now.
                trafficModel: 'optimistic'
            }
        }, (result) => {
            if (!result || !result.rows[0] || !result.rows[0].elements[0].distance) return
            let distance = result.rows[0].elements[0].distance.value / 1000;
            order.distance = distance;
            this.saveOrder()
            this.calculateDeliverCost()
        })
    }

    async getLmQuote() {
        await this.saveOrder()

        let { order } = this.state

        if (order.id) {
            let response = await fetch(`${AppConfig.firebase.functionEndpoint}lmGetQuotation?order_id=${order.id}`, { // Your POST endpoint
                method: 'GET',
                // mode: 'no-cors',
                headers: {
                    "Content-Type": "application/json"
                },
                // body: JSON.stringify({ order_id: order.id })

            })
            let result = await response.json()
            debugger
            if (result.result === 'success') {
                return result.data
            }
        }

        return false
    }
    async calculateDeliverCost() {
        let { current_store } = this.props
        let { order } = this.state
        let storeData = this.state.storeData
        let totalPrice = order.sumProductPrice
        let distance = order.distance;

        if (this.state.order.deliverType === 'shipping') {
            let deliverCost = 39;
            this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
            return
        }
        else if (order.deliverType === 'pickup') {
            if (Number.parseFloat(storeData.delivery_collecting_price)) {
                this.setState({ deliverCost: Number.parseFloat(storeData.delivery_collecting_price) || 0, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
                return
            }
            else if (current_store.indexOf('locall.saraburi') >= 0) {
                if (totalPrice < 100) {
                    this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ส่งเมื่อค่าอาหารขั้นต่ำ 100 บาท' }, () => this.getTotalPrice())
                }
                else {
                    this.setState({ deliverCost: 20, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
                }
                return
            }
            else if (current_store.indexOf('locall.lamchang') >= 0) {
                if (totalPrice < 100) {
                    this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ส่งเมื่อค่าอาหารขั้นต่ำ 100 บาท' }, () => this.getTotalPrice())
                }
                else {
                    this.setState({ deliverCost: 18, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
                }
                return
            }
            else if (current_store.indexOf('locall.watdansamrong') >= 0) {
                if (totalPrice < 150) {
                    this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ส่งเมื่อค่าอาหารขั้นต่ำ 150 บาท' }, () => this.getTotalPrice())
                }
                else {
                    this.setState({ deliverCost: 30, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
                }
                return
            }
            else if (current_store.indexOf('locall') >= 0) {
                if (totalPrice < 60) {
                    this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ส่งเมื่อค่าอาหารขั้นต่ำ 60 บาท' }, () => this.getTotalPrice())
                }
                else {
                    this.setState({ deliverCost: 30, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
                }
                return
            }
            else {
                this.setState({ deliverCost: Number.parseFloat(storeData.delivery_collecting_price) || 0, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
                return
            }
        }

        else if (storeData && storeData.delivery_calculate_mode) {
            let { delivery_tiers } = storeData
            let roundedDistance = Math.ceil(distance)
            if (delivery_tiers[roundedDistance] && delivery_tiers[roundedDistance].useLalamove) {

                // Quote
                let lmQuotedTotalFee = order.lmQuotedTotalFee
                if (!lmQuotedTotalFee) lmQuotedTotalFee = this.state.lmQuotedTotalFee

                if (!lmQuotedTotalFee) {
                    this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: `กำลังคำณวนค่าส่ง` }, () => this.getTotalPrice())
                    lmQuotedTotalFee = await this.getLmQuote()
                }

                if (lmQuotedTotalFee) {
                    let deliverCost = Number.parseFloat(lmQuotedTotalFee.amount)
                    this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
                    return
                }
                else {
                    this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: `ไม่สามารถจัดส่งได้` }, () => this.getTotalPrice())
                    return
                }
            }
            // debugger
            if (storeData.delivery_min_order_price && totalPrice < storeData.delivery_min_order_price) {
                this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: `ส่งเมื่อค่าอาหารขั้นต่ำ ${storeData.delivery_min_order_price} บาท` }, () => this.getTotalPrice())
                return
            }
            else {
                let deliverCost = calculateDeliverPrice(storeData, distance)

                if (!deliverCost) {
                    this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ไม่สามารถจัดส่งได้' }, () => this.getTotalPrice())
                }
                else {
                    if (current_store.indexOf('locall') && totalPrice >= 1000) {
                        deliverCost *= 2
                    }

                    let isInZone = true
                    if (order.coupon_promotion) {
                        if (order.coupon_promotion.discount_deliver_area && order.coupon_promotion.deliver_area) {
                            if (order.deliverAddress.area.toLowerCase().includes(order.coupon_promotion.deliver_area) || order.deliverAddress.state.toLowerCase().includes(order.coupon_promotion.deliver_area) || order.deliverAddress.city.toLowerCase().includes(order.coupon_promotion.deliver_area)) {
                                isInZone = true;
                            }
                            else {
                                isInZone = false
                            }
                        }
                        if (order.coupon_promotion.discount_deliver_area && order.coupon_promotion.deliver_areas) {
                            let deliver_areas = order.coupon_promotion.deliver_areas
                            isInZone = false

                            for (let l in deliver_areas) {
                                if (order.deliverAddress.area.toLowerCase().includes(deliver_areas[l]) ||
                                    order.deliverAddress.state.toLowerCase().includes(deliver_areas[l]) ||
                                    order.deliverAddress.city.toLowerCase().includes(deliver_areas[l])) {
                                    isInZone = true;
                                }
                            }
                        }
                    }

                    if (!isInZone) {
                        this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'พื้นที่นี้อยู่นอกโปรโมชั่น กรุณาเอาโปรโมชั่นออกแล้วลองอีกครั้ง' }, () => this.getTotalPrice())
                    }
                    else {
                        this.setState({ deliverCost, totalPrice: totalPrice + deliverCost, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => this.getTotalPrice())
                    }
                }
            }
            return
        }


        // let totalPrice = order.sumPrice

        if (!this.state.deliverAddress.address || this.state.deliverAddress.address === '') {
            this.setState({ deliverCost: 0, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: null }, () => this.getTotalPrice())
            return
        }

        let deliverCost = 20;
        //Khet Sathon
        let fixlocation = {}
        let availableLocation = []
        let isFixlocation = false;
        let keys = Object.keys(fixlocation)
        for (let l in keys) {
            if (order.deliverAddress.area.toLowerCase().includes(keys[l]) || order.deliverAddress.state.toLowerCase().includes(keys[l]) || order.deliverAddress.city.toLowerCase().includes(keys[l])) {

                isFixlocation = true
                deliverCost = fixlocation[keys[l]]

                // console.log(fixlocation[keys[l]], keys[l], order.sumPrice)

            }
        }

        if (isFixlocation !== false) {

            let totalPrice = order.sumPrice + deliverCost
            this.setState({ deliverCost, totalPrice, isFixlocation: true, cannotDeliver: false }, () => this.getTotalPrice())
        }
        else {

            let $this = this;
            if (current_store.indexOf('locall.saraburi') >= 0) {
                if (totalPrice < 100) {


                    $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ส่งเมื่อค่าอาหารขั้นต่ำ 100 บาท' }, () => $this.getTotalPrice())

                }
                else {
                    // ค่าส่งคิดเป็นขั้นบันได
                    // เริ่มต้น 30 บาท

                    deliverCost = 20;
                    // distance = Math.ceil(distance )

                    if (distance > 7) {
                        deliverCost = 0;
                        let totalPrice = order.sumPrice
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: null }, () => $this.getTotalPrice())
                        return
                    }
                    else if (distance <= 2) {

                    }
                    else if (distance <= 3) {
                        deliverCost = 30;
                    }
                    else if (distance <= 3.5) {
                        deliverCost = 35;
                    }
                    else if (distance <= 4) {
                        deliverCost = 40;
                    }
                    else {
                        deliverCost = 50;
                    }

                    if (totalPrice >= 1000) {
                        deliverCost *= 2
                    }

                    let isInZone = true;
                    for (let l in availableLocation) {
                        if (order.deliverAddress.area.toLowerCase().includes(availableLocation[l]) || order.deliverAddress.state.toLowerCase().includes(availableLocation[l]) || order.deliverAddress.city.toLowerCase().includes(availableLocation[l])) {
                            isInZone = true;
                            break;
                        }
                    }

                    if (!isInZone) {
                        if (order.coupon_promotion) {
                            if (order.coupon_promotion.discount_deliver_area && order.coupon_promotion.deliver_area) {
                                if (order.deliverAddress.area.toLowerCase().includes(order.coupon_promotion.deliver_area) || order.deliverAddress.state.toLowerCase().includes(order.coupon_promotion.deliver_area) || order.deliverAddress.city.toLowerCase().includes(order.coupon_promotion.deliver_area)) {
                                    isInZone = true;
                                }
                            }
                            if (order.coupon_promotion.discount_deliver_area && order.coupon_promotion.deliver_areas) {
                                let deliver_areas = order.coupon_promotion.deliver_areas
                                for (let l in deliver_areas) {
                                    if (order.deliverAddress.area.toLowerCase().includes(deliver_areas[l]) ||
                                        order.deliverAddress.state.toLowerCase().includes(deliver_areas[l]) ||
                                        order.deliverAddress.city.toLowerCase().includes(deliver_areas[l])) {
                                        isInZone = true;
                                    }
                                }
                            }
                        }
                    }

                    if (!isInZone) {
                        deliverCost = 0;
                        let totalPrice = order.sumPrice
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: null }, () => $this.getTotalPrice())
                    }
                    else {
                        deliverCost = Math.ceil(deliverCost)
                        let totalPrice = order.sumPrice + deliverCost
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => $this.getTotalPrice())
                    }
                }
            }
            else if (current_store.indexOf('locall.lamchang') >= 0) {
                if (totalPrice < 100) {

                    $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ส่งเมื่อค่าอาหารขั้นต่ำ 100 บาท' }, () => $this.getTotalPrice())

                }
                else {
                    // ค่าส่งคิดเป็นขั้นบันได
                    // เริ่มต้น 30 บาท

                    deliverCost = 30;
                    // distance = Math.ceil(distance )

                    if (distance > 25) {
                        deliverCost = 0;
                        let totalPrice = order.sumPrice
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: null }, () => $this.getTotalPrice())
                        return
                    }
                    else if (distance < 5) {

                    }
                    else if (distance <= 10) {
                        deliverCost += distance * 4;
                    }
                    else if (distance <= 15) {
                        deliverCost += distance * 7;
                    }
                    else if (distance <= 20) {
                        deliverCost += distance * 8;
                    }
                    else if (distance <= 25) {
                        deliverCost += distance * 8.5;
                    }

                    if (totalPrice >= 1000) {
                        deliverCost *= 2
                    }

                    let isInZone = true;
                    for (let l in availableLocation) {
                        if (order.deliverAddress.area.toLowerCase().includes(availableLocation[l]) || order.deliverAddress.state.toLowerCase().includes(availableLocation[l]) || order.deliverAddress.city.toLowerCase().includes(availableLocation[l])) {
                            isInZone = true;
                            break;
                        }
                    }

                    if (!isInZone) {
                        if (order.coupon_promotion) {
                            if (order.coupon_promotion.discount_deliver_area && order.coupon_promotion.deliver_area) {
                                if (order.deliverAddress.area.toLowerCase().includes(order.coupon_promotion.deliver_area) || order.deliverAddress.state.toLowerCase().includes(order.coupon_promotion.deliver_area) || order.deliverAddress.city.toLowerCase().includes(order.coupon_promotion.deliver_area)) {
                                    isInZone = true;
                                }
                            }
                            if (order.coupon_promotion.discount_deliver_area && order.coupon_promotion.deliver_areas) {
                                let deliver_areas = order.coupon_promotion.deliver_areas
                                for (let l in deliver_areas) {
                                    if (order.deliverAddress.area.toLowerCase().includes(deliver_areas[l]) ||
                                        order.deliverAddress.state.toLowerCase().includes(deliver_areas[l]) ||
                                        order.deliverAddress.city.toLowerCase().includes(deliver_areas[l])) {
                                        isInZone = true;
                                    }
                                }
                            }
                        }
                    }

                    if (!isInZone) {
                        deliverCost = 0;
                        let totalPrice = order.sumPrice
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: null }, () => $this.getTotalPrice())
                    }
                    else {
                        deliverCost = Math.ceil(deliverCost)
                        let totalPrice = order.sumPrice + deliverCost
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => $this.getTotalPrice())
                    }
                }
            }
            else if (current_store.indexOf('locall') >= 0) {
                if (current_store.indexOf('locall.watdansamrong') >= 0 && totalPrice < 150) {
                    $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ส่งเมื่อค่าอาหารขั้นต่ำ 150 บาท' }, () => $this.getTotalPrice())
                }
                else if (current_store.indexOf('locall.watdansamrong') < 0 && totalPrice < 150) {

                    $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: 'ส่งเมื่อค่าอาหารขั้นต่ำ 150 บาท' }, () => $this.getTotalPrice())

                }
                else {
                    // ค่าส่งคิดเป็นขั้นบันได
                    // เริ่มต้น 30 บาท
                    // ระยะทาง 0-9 กิโลเมตร กิโลเมตรละ 7 บาท
                    // ระยะทาง 10-19 กิโลเมตร กิโลเมตรละ 9 บาท
                    // ระยะทาง 20-29 กิโลเมตร กิโลเมตรละ 9.5 บาท
                    // ระยะทาง 30 กิโลเมตรขึ้นไป กิโลเมตรละ 10 บาท @Mekku ค่าส่งขั้นบันได effective 29 Apr 20
                    deliverCost = 30;
                    distance = Math.ceil(distance)

                    if (distance > 18) {
                        deliverCost = 0;
                        let totalPrice = order.sumPrice
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: null }, () => $this.getTotalPrice())
                        return
                    }
                    if (distance < 10) {
                        deliverCost += distance * 7;
                    }
                    else if (distance < 20) {
                        // deliverCost += 9 * 7;
                        // deliverCost += (distance - 9) * 9;
                        deliverCost += distance * 9;
                    }
                    else if (distance < 30) {
                        // deliverCost += 9 * 7;
                        // deliverCost += 10 * 9;
                        // deliverCost += (distance - 19) * 9.5;
                        deliverCost += distance * 9.5;
                    }
                    else {
                        // deliverCost += 9 * 7;
                        // deliverCost += 10 * 9;
                        // deliverCost += 10 * 9.5;
                        // deliverCost += (distance - 29) * 10;
                        deliverCost += distance * 10;
                    }

                    // availableLocation.push('พระนคร');
                    // availableLocation.push('ดุสิต');
                    // availableLocation.push('บางรัก');
                    // availableLocation.push('ปทุมวัน');
                    // availableLocation.push('ป้อมปราบศัตรูพ่าย');
                    // availableLocation.push('ยานนาวา');
                    // availableLocation.push('สัมพันธวงศ์');
                    // availableLocation.push('พญาไท');
                    // availableLocation.push('ธนบุรี');
                    // availableLocation.push('บางกอกใหญ่');
                    // availableLocation.push('บางกอกน้อย');
                    // availableLocation.push('บางพลัด');
                    // availableLocation.push('ดินแดง')
                    // availableLocation.push('บางพลัด');
                    // availableLocation.push('บางกอกน้อย');
                    // availableLocation.push('ดินแดง');
                    // availableLocation.push('สาทร');
                    // availableLocation.push('บางซื่อ');
                    // availableLocation.push('จตุจักร');
                    // availableLocation.push('บางคอแหลม');
                    // availableLocation.push('คลองเตย');
                    // availableLocation.push('ราชเทวี');
                    // availableLocation.push('ลาดพร้าว');
                    // availableLocation.push('วัฒนา');
                    // availableLocation.push('วังทองหลาง');
                    // availableLocation.push('ห้วยขวาง');
                    // availableLocation.push('คลองสาน');

                    if (totalPrice >= 1000) {
                        deliverCost *= 2
                    }

                    let isInZone = true;
                    for (let l in availableLocation) {
                        if (order.deliverAddress.area.toLowerCase().includes(availableLocation[l]) || order.deliverAddress.state.toLowerCase().includes(availableLocation[l]) || order.deliverAddress.city.toLowerCase().includes(availableLocation[l])) {
                            isInZone = true;
                            break;
                        }
                    }

                    if (!isInZone) {
                        if (order.coupon_promotion) {
                            if (order.coupon_promotion.discount_deliver_area && order.coupon_promotion.deliver_area) {
                                if (order.deliverAddress.area.toLowerCase().includes(order.coupon_promotion.deliver_area) || order.deliverAddress.state.toLowerCase().includes(order.coupon_promotion.deliver_area) || order.deliverAddress.city.toLowerCase().includes(order.coupon_promotion.deliver_area)) {
                                    isInZone = true;
                                }
                            }
                            if (order.coupon_promotion.discount_deliver_area && order.coupon_promotion.deliver_areas) {
                                let deliver_areas = order.coupon_promotion.deliver_areas
                                for (let l in deliver_areas) {
                                    if (order.deliverAddress.area.toLowerCase().includes(deliver_areas[l]) ||
                                        order.deliverAddress.state.toLowerCase().includes(deliver_areas[l]) ||
                                        order.deliverAddress.city.toLowerCase().includes(deliver_areas[l])) {
                                        isInZone = true;
                                    }
                                }
                            }
                        }
                    }

                    if (!isInZone) {
                        deliverCost = 0;
                        let totalPrice = order.sumPrice
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: null }, () => $this.getTotalPrice())
                    }
                    else {
                        deliverCost = Math.ceil(deliverCost)
                        let totalPrice = order.sumPrice + deliverCost
                        $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => $this.getTotalPrice())
                    }
                }
            }
            else {
                deliverCost = 48;
                if (distance <= 30) {
                    deliverCost += distance * 7.2
                }
                else {
                    deliverCost += (30 * 7.2) + ((distance - 30) * 14)
                }

                if (deliverCost < 60) {
                    deliverCost = 60
                }

                if (deliverCost > 300) {
                    deliverCost = 0;
                    let totalPrice = order.sumPrice
                    $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: true, deliverRemark: null }, () => $this.getTotalPrice())
                }
                else {
                    deliverCost = Math.ceil(deliverCost)
                    let totalPrice = order.sumPrice + deliverCost
                    $this.setState({ deliverCost, totalPrice, isFixlocation: false, cannotDeliver: false, deliverRemark: null }, () => $this.getTotalPrice())
                }
            }


        }





        // let response = await fetch(`https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=${this.state.order.markerPosition.lat},${this.state.order.markerPosition.lng}&destinations=13.704615,100.5162978&key=${googleAPIKey}`,
        //     {
        //         mode: 'no-cors'
        //     })
        // let data = response.json()
        // //console.log(data)
    }

    async saveOrder(isSubmitted = false, complete) {

        // console.log("saveOrder")
        let order = this.state.order;
        console.log("saveOrder", order)
        let sum = this.getTotalPrice()

        if (!order.deliverDate) {
            order.deliverDate = this.getMinDate()
        }

        if (!order.deliverTime) {
            order.deliverTime = this.getOpenTime()
        }


        if (this.state.lineProfile) {
            order.lineProfile = this.state.lineProfile
        }

        if (this.state.lineDecoded) {
            order.lineDecoded = this.state.lineDecoded
        }

        if (this.state.context) {
            order.liffContext = this.state.context
        }


        order.deliverCost = order.deliverCost || 0

        if (order.sumPrice !== sum && order.totalPrice !== order.sumPrice + order.deliverCost) {
            order.sumPrice = sum;
            order.totalPrice = order.sumPrice + order.deliverCost
        }

        this.state.order = order

        if (order.carts.length > 0 && (this.props.line_user || isSubmitted)) {

            order.lineUserId = ""
            if (this.props.line_user) {
                order.lineUserId = this.props.line_user;
            }


            if (isSubmitted) {
                let pf = moment().format('YYMMDD');
                let running = await new Running("order" + pf)
                await running.incrementCounter()
                let count = await running.getCount()
                order.order_no = pf + "-" + count.toString().padStart(4, '0')
            }

            try {
                if (order.id) {
                    await this.orderRef.doc(order.id).update(order)

                    this.setState({ order }, () => {

                        if (isSubmitted) {
                            firebase.analytics().logEvent('purchase', { value: order.totalPrice, currency: 'THB' })
                        }
                        if (complete) complete(order)
                    })

                }
                else {
                    let docRef = await this.orderRef.add(order)
                    order.id = docRef.id
                    this.setState({ order }, () => {

                        if (isSubmitted) {
                            firebase.analytics().logEvent('purchase', { value: order.totalPrice, currency: 'THB' })
                        }
                        if (complete) complete(order)
                    })
                }
            }
            catch (error) {
                alert("เกิดข้อผิดพลาด")
                console.error("Error adding document: ", error);
                Sentry.captureException(error)
            }

        }

    }

    addToCartWithOptions() {
        let { currentItem, selectedAmount, selectedNote, selectedExtra } = this.state

        if (currentItem.options.length === 0) {
            this.addToCart(currentItem, selectedAmount, null, selectedNote, selectedExtra)
        }

        this.state.cartOptions.forEach((item) => {
            if (this.state.selectedOption === item.option.name) {
                this.addToCart(currentItem, selectedAmount, item.option, selectedNote, selectedExtra)
            }
        })

        // Hide description
        // this.setState({ cartOptions: {}, currentItem: null, showOption: false, showDescription: false, selectedOption: null });
    }

    updateCart(item, quantity, preventZeroRemove) {
        //console.log(preventZeroRemove)
        let carts = this.state.order.carts;
        let newCarts = []
        for (let c in carts) {
            if (compareCartItem(carts[c], item)) {
                carts[c].quantity = quantity;
                this.updateCarts(carts)
            }

            if (carts[c].quantity > 0 || preventZeroRemove === true) {
                newCarts.push(carts[c])
            }

        }

        this.updateCarts(newCarts)
    }

    updateOption(option, quantity) {
        let options = this.state.cartOptions;
        if (quantity < 0)
            quantity = 0;
        for (let i in options) {
            if (options[i].option === option) {
                options[i].amount = quantity;
                break
            }
        }

        this.setState({ cartOptions: options });
    }

    showNote(cart_item) {
        this.setState({ showNoteDialog: true, selectedNoteItem: cart_item })
    }

    hideNote() {
        this.setState({ showNoteDialog: false, selectedNoteItem: null })
    }

    renderNoteDialog() {
        let currentItem = this.state.selectedNoteItem
        return <div className="modal is-active note-dialogs">
            <div className="modal-background"></div>
            <div className="modal-content">
                <h2 className="title">รายละเอียดเพิ่มเติม</h2>
                <textarea placeholder="ตัวอย่าง: ไม่เผ็ด ไม่ใส่ผัก" onChange={(e) => { currentItem.note = e.currentTarget.value; this.forceUpdate() }} value={currentItem.note}></textarea>
                <button className="button is-success  is-fullwidth" onClick={() => this.hideNote()}>เรียบร้อย</button>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => this.hideNote()}></button>
        </div>
    }

    quickAddToCart(product) {
        // if (product.options && (product.options.length <= 1)) {
        //     this.addToCart(product, 1)
        //     return
        // }
        this.showDescription(product)
    }

    hideDescription() {
        document.body.style.overflow = 'visible'
        document.body.style.height = 'auto'
        window.scroll(0, window.scrollYPos)

        this.setState({ showDescription: false, selectedExtra: {}, selectedOption: null })
    }

    showDescription(product) {
        window.scrollYPos = window.scrollY
        document.body.style.overflow = 'hidden'
        document.body.style.height = '100vh'

        let hashOptions = [];
        let option = null
        let selectedOption = null
        if (product.options && product.options.length > 0) {
            option = product.options[0].name

            product.options.forEach(function (obj) {
                if (!selectedOption && !obj.isSoldout) {
                    selectedOption = obj.name
                }
                hashOptions.push({ option: obj, amount: 0 })
            }, {})
        }

        let { hubStoresById } = this.state
        let store = hubStoresById && hubStoresById[product.store_id]


        mpTrackEvent("Select menu", {
            "Menu Name": product.name,
            "Menu Price Range Info": product.price,
            "Restaurant Name": store.name,
            "Menu Restaurant Hub": this.props.current_store,
            "Menu Restaurant City": this.state.storeData.city,
            "Menu ID": product.id
        });


        this.setState({ currentItem: product, cartOptions: hashOptions, selectedOption, selectedAmount: 1 })

        let img1 = new Image();
        img1.src = `https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(product.image)}?alt=media`;
        // console.log("load")
        img1.onload = () => {
            // console.log("loaded")
        };
        this.setState({ showDescription: true }, () => {
            // console.log(this.state)
        })

    }

    showOptionDialog(product) {
        let { selectedNote, selectedExtra } = this.state
        //console.log(product)
        if (product.options && product.options.length > 0) {
            let hashOptions = [];
            product.options.forEach(function (obj) {
                hashOptions.push({ option: obj, amount: 0 })
            }, {})

            // console.log(hashOptions)
            this.setState({ currentItem: product, showOption: true, cartOptions: hashOptions })
            firebase.analytics().logEvent('page_view', { page_title: `Options-${product.name}` })
            firebase.analytics().logEvent('select_item', {
                items: [`${product.name}`]
            })
        }
        else {
            this.addToCart(product, 1, null, selectedNote, selectedExtra)
            this.setState({ currentItem: product, showOption: false })
        }

    }

    getTotalPrice() {
        let order = this.state.order
        order.deliverCost = this.state.deliverCost
        order = getTotalPrice(order, this.state.promotions)
        let sum = order.sumPrice

        this.setState({ order }, () => {

        })

        return sum;
    }

    async useCoupon() {
        let { order } = this.state;
        let isGlobal = true
        if (!order.coupon_code) return;
        // 
        // Check global coupon first
        let querySnapshot = await this.globalCouponRef.where('is_active', "==", true).where("code", "==", order.coupon_code).get()
        if (querySnapshot.empty) {
            isGlobal = false
            querySnapshot = await this.couponRef.where('is_active', "==", true).where("code", "==", order.coupon_code).get()
        }

        if (querySnapshot.empty) {
            alert("คูปองโค้ด ไม่ถูกต้อง");
            order.coupon_code = ""
            order.coupon = null;
            order.coupon_promotion = null;
        }
        else {
            let coupon = querySnapshot.docs[0].data();
            coupon.id = querySnapshot.docs[0].id;
            let currentTime = moment();    // e.g. 11:00 pm
            let inTime = true;
            let inDate = true;

            if (coupon.startDate && coupon.endDate) {
                inDate = (moment(coupon.startDate).toDate() <= currentTime.startOf('day').toDate()) && (moment(coupon.endDate).toDate() >= currentTime.startOf('day').toDate())
            }

            currentTime = moment();

            if (coupon.startTime && coupon.endTime) {
                inTime = currentTime.isBetween(moment(coupon.startTime, "HH:mm"), moment(coupon.endTime, "HH:mm"))
            }

            // console.log(coupon.startTime, coupon.endTime, inTime, moment(coupon.startTime, "HH:mm"), moment(coupon.endTime, "HH:mm"), currentTime)

            if (coupon.limit <= coupon.used) {
                alert("คูปองโค้ดมีผู้ใช้สิทธิครบแล้ว");
                order.coupon_code = ""
                order.coupon = null;
                order.coupon_promotion = null;
            }
            else if (!coupon.is_active || !inDate || !inTime) {
                alert("คูปองไม่อยู่ในเงื่อนไข หรือ ช่วงเวลาที่ใช้งานได้");
                order.coupon_code = ""
                order.coupon = null;
                order.coupon_promotion = null;
            }
            else {
                let promotionQS = null;
                if (isGlobal) {
                    promotionQS = await this.globalPromotionRef.doc(coupon.promotion).get()
                }
                else {
                    promotionQS = await this.promotionRef.doc(coupon.promotion).get()
                }

                if (!promotionQS.exists || !promotionQS.data().is_active) {
                    alert("คูปองโค้ด ไม่ถูกต้อง");
                    order.coupon_code = ""
                    order.coupon = null;
                    order.coupon_promotion = null;
                }
                else {
                    // 
                    let promodata = promotionQS.data()
                    promodata.id = promotionQS.id
                    let currentTime = moment();    // e.g. 11:00 pm
                    let inTime = true;
                    let inDate = true;

                    if (promodata.startDate && promodata.endDate) {
                        inDate = (moment(promodata.startDate).toDate() <= currentTime.startOf('day').toDate()) && (moment(promodata.endDate).toDate() >= currentTime.startOf('day').toDate())
                    }

                    currentTime = moment();

                    if (promodata.startTime && promodata.endTime) {
                        inTime = currentTime.isBetween(moment(promodata.startTime, "HH:mm"), moment(promodata.endTime, "HH:mm"))
                    }

                    if (promodata.only_new_user && !this.state.firstTime) {
                        alert("คูปองนี้สำหรับผู้ที่สั่งครั้งแรกเท่านั้น");
                        order.coupon_code = ""
                        order.coupon = null;
                        order.coupon_promotion = null;
                    }
                    else if (!promodata.is_active || !inDate || !inTime) {
                        alert("คูปองไม่อยู่ในเงื่อนไข หรือ ช่วงเวลาที่ใช้งานได้");
                        order.coupon_code = ""
                        order.coupon = null;
                        order.coupon_promotion = null;
                    }
                    else {
                        order.coupon = coupon;
                        order.coupon_promotion = promodata;
                        // console.log("apply_coupon", order.coupon_promotion)
                        order.promotions.push(order.coupon_promotion)
                        if (isNaN(coupon.used)) coupon.used = 0;
                        coupon.used += 1
                        order.isGlobalCoupon = isGlobal
                        if (isGlobal) {
                            this.globalCouponRef.doc(coupon.id).update(coupon);
                        }
                        else {
                            this.couponRef.doc(coupon.id).update(coupon);
                        }
                    }
                }
            }
        }

        this.setState({ order }, () => {
            this.getTotalPrice()
            // this.getDistance();
            this.calculateDeliverCost()
            this.saveOrder()
        })
    }

    async cancelCoupon() {
        let { order } = this.state;
        // console.log(order)
        // console.log("a")
        let querySnapshot = await this.couponRef.where('is_active', "==", true).where("code", "==", order.coupon_code).get()
        // console.log("b", querySnapshot.docs.length)
        if (querySnapshot.docs.length > 0) {
            let coupon = querySnapshot.docs[0].data();
            coupon.id = querySnapshot.docs[0].id
            // console.log("update_coupon")
            coupon.used -= 1
            // console.log("update_coupon")
            this.couponRef.doc(coupon.id).update(coupon);
        }

        order.coupon_code = ""
        order.coupon = null;
        order.coupon_promotion = null;
        // console.log(order)
        // console.log("update_state")
        this.setState({ order, promotionError: null }, () => {
            // console.log("updated")
            this.getTotalPrice()
            // this.getDistance();
            this.calculateDeliverCost()
            this.saveOrder()
            this.forceUpdate();
        })
    }

    checkInTimeCart(item, products) {

        let inTime = true
        if (!products) {
            products = this.state.products
        }
        for (let p in products) {
            if (products[p].id === item.product.id) {

                item.product = products[p];
                inTime = item.product.inTime
            }
        }

        return inTime

        // let currentTime = moment();    // e.g. 11:00 pm
        // let inTime = true
        // if (this.state.order.deliverTime !== 'ASAP') {
        //     currentTime = moment(this.state.order.deliverTime, "HH:mm")
        // }
        // if (item.product.startTime && item.product.endTime) {
        //     inTime = currentTime.isBetween(moment(item.product.startTime, "HH:mm"), moment(item.product.endTime, "HH:mm"))
        // }

        // item.inTime = inTime;
        // return inTime;
    }

    getCartsCount() {
        let { carts } = this.state.order
        let count = 0
        for (let c in carts) {
            count += carts[c].quantity
        }

        return count
    }

    getCartProductCount(product) {
        let { carts } = this.state.order
        let count = 0
        for (let c in carts) {
            if (carts[c].product.id === product.id) {
                count += carts[c].quantity
            }
        }

        return count
    }

    getCartOptionCount(product, option) {
        let { carts } = this.state.order
        let count = 0
        for (let c in carts) {
            if (carts[c].product.id === product.id && carts[c].option === option) {
                count++
            }
        }

        return count
    }

    renderCartItem(item, index) {
        let inTime = item.product.inTime//this.checkInTimeCart(item)
        let { hubStoresById } = this.state
        let store = hubStoresById && hubStoresById[item.product.store_id]
        return <div key={item.product.id + (item.option ? item.option.name : "") + JSON.stringify(item.extras)} className="item-product">
            <div className="level is-mobile">
                <div className="level-left">
                    <div className="thumbnail-image" style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(item.product.image)}?alt=media)`, width: "50px", borderRadius: "25px" }}></div>
                    {/* <CloudImage src={item.product.image} className="thumbnail" /> */}
                </div>
                <div style={{ display: 'block', width: '100%', padding: '0 10px' }}>
                    <div className="item-name is-clearfix">{item.product.name}
                        {item.option && <div className="item-option">{item.option.name}</div>}
                        {item.extras && Object.keys(item.extras).map((name) => { return item.extras[name].length > 0 && <div className="item-extra">{name}: {item.extras[name].map(x => x.name).join(',')}</div> })}
                        <small style={{ color: "#888" }}>{store && store.name}</small>
                        {!inTime && item.product.startTime && item.product.endTime && <div className="sale-time"><i className="fas fa-clock"></i> {moment(item.product.startTime, "HH:mm").format("HH:mm")} - {moment(item.product.endTime, "HH:mm").format("HH:mm")}</div>}
                    </div>
                    <div className="item-note">{item.note}</div>

                </div>
                <div className="level-right">
                    <div className="level-item">
                        {item.quantity * getCartItemPrice(item)}<br />

                    </div>

                </div>

            </div>
            <div className="item-actions">
                <div className="item-quantity">

                    <button className="add-button" onClick={() => this.updateCart(item, (Number.parseInt(item.quantity) || 0) - 1)}><i className="fa fa-minus" /></button>
                    <input type="number" value={item.quantity} onChange={(e) => this.updateCart(item, e.target.value, true)} onBlur={(e) => this.updateCart(item, e.target.value)} />
                    <button className="add-button" onClick={() => this.updateCart(item, (Number.parseInt(item.quantity) || 0) + 1)}><i className="fa fa-plus" /></button>
                </div>

                <button className="add-button" style={{ width: '60px', textAlign: 'right' }} onClick={() => this.showNote(item)}><i className="fa fa-sticky-note" /> แก้ไข</button>
            </div>
        </div>
    }
    getDeliverTimesData(date) {
        // 
        // let now = moment()
        let isToday = moment().isSame(date.toString(), 'day')
        let dateList = []
        let currentTime = moment()

        let openTime = moment(this.getOpenTime(), "HH:mm")
        let endTime = moment(this.getCloseTime(), "HH:mm")

        let preorderOpenTime = moment(this.getPreorderOpenTime(), "HH:mm")
        let preorderEndTime = moment(this.getPreorderCloseTime(), "HH:mm")


        if (isToday) {

            if (currentTime.isBefore(openTime) && currentTime.isBefore(preorderOpenTime)) {
                currentTime = preorderOpenTime.clone()
            }
            else {
                currentTime = currentTime.add(90, 'minutes')

                if (currentTime.minutes() >= 30) {
                    currentTime = currentTime.add(30, 'minutes').minutes(0)
                }
                else {
                    currentTime = currentTime.minutes(0)
                }
            }
            let currentEndTime = currentTime.clone().add(30, "minutes");
            // console.log("getDeliverTime", currentTime.format(), endTime.format(), currentTime < endTime)
            while (currentTime < endTime) {
                // console.log(currentTime.format());
                dateList.push(`${currentTime.format("HH:mm")}-${currentEndTime.format("HH:mm")}`);
                currentTime = currentEndTime
                currentEndTime = currentTime.clone().add(30, "minutes");
            }

            return dateList

        }
        else {
            let currentTime = preorderOpenTime;
            let currentEndTime = currentTime.clone().add(30, "minutes");
            let endTime = preorderEndTime;
            // console.log("getDeliverTime", currentTime.format(), endTime.format(), currentTime < endTime)
            while (currentTime < endTime) {
                // console.log(currentTime.format());
                dateList.push(`${currentTime.format("HH:mm")}-${currentEndTime.format("HH:mm")}`);
                currentTime = currentEndTime
                currentEndTime = currentTime.clone().add(30, "minutes");
            }

            return dateList
        }
    }

    getDeliverTimes(date) {
        // let now = moment()
        let isToday = moment().isSame(date.toString(), 'day')
        let dateList = []
        let currentTime = moment()

        let openTime = moment(this.getOpenTime(), "HH:mm")
        let endTime = moment(this.getCloseTime(), "HH:mm")

        let preorderOpenTime = moment(this.getPreorderOpenTime(), "HH:mm")
        let preorderEndTime = moment(this.getPreorderCloseTime(), "HH:mm")

        if (isToday) {

            if (currentTime.isBefore(openTime) && currentTime.isBefore(preorderOpenTime)) {
                currentTime = preorderOpenTime.clone()
            }
            else {
                currentTime = currentTime.add(90, 'minutes')

                if (currentTime.minutes() >= 30) {
                    currentTime = currentTime.add(30, 'minutes').minutes(0)
                }
                else {
                    currentTime = currentTime.minutes(0)
                }
            }
            let currentEndTime = currentTime.clone().add(30, "minutes");
            // console.log("getDeliverTime", currentTime.format(), endTime.format(), currentTime < endTime)
            while (currentTime < endTime) {
                // console.log(currentTime.format());
                dateList.push(`${currentTime.format("HH:mm")}-${currentEndTime.format("HH:mm")}`);
                currentTime = currentEndTime
                currentEndTime = currentTime.clone().add(30, "minutes");
            }

            if (moment().isBefore(openTime)) {
                return [...dateList.map(x => <option key={x} value={x}>{x}</option>)]
            }
            else if (moment().add(1, "hours").isBefore(endTime)) {
                return [<option key={"ASAP"} value={"ASAP"}>ทันที</option>,
                ...dateList.map(x => <option key={x} value={x}>{x}</option>)]
            }
            else if (moment().isBefore(endTime)) {
                return [<option key={"ASAP"} value={"ASAP"}>ทันที</option>]
            }
            else {
                return []
            }

        }
        else {
            let currentTime = preorderOpenTime;
            let currentEndTime = currentTime.clone().add(30, "minutes");
            let endTime = preorderEndTime;
            // console.log("getDeliverTime", currentTime.format(), endTime.format(), currentTime < endTime)
            while (currentTime < endTime) {
                // console.log(currentTime.format());
                dateList.push(`${currentTime.format("HH:mm")}-${currentEndTime.format("HH:mm")}`);
                currentTime = currentEndTime
                currentEndTime = currentTime.clone().add(30, "minutes");
            }

            return dateList.map(x => <option key={x} value={x}>{x}</option>)
        }

    }
    applyTime(products) {
        let { order } = this.state

        for (let p in products) {
            let item = products[p]
            let time = order.deliverTime
            if (!time) {
                time = this.getDeliverTimesData(order.deliverDate)[0]
            }
            else {
                if (time === 'ASAP') {
                    time = moment().format('HH:mm')
                }
                else {
                    time = time.slice(0, 5)
                }
            }

            let currentTime = moment(order.deliverDate + ' ' + time, 'YYYY-MM-DD HH:mm');    // e.g. 11:00 pm
            // console.log("currentTime", currentTime.format(), time)
            let { hubStoresById } = this.state
            let store = hubStoresById && hubStoresById[item.store_id]
            let inTime = true
            let isHidden = false
            if (store) {
                //check Store active
                if (!store.is_active) {
                    inTime = false;
                    isHidden = true;
                    // console.log('store in active')
                }
                // Check store date
                if (inTime && store.openday) {
                    // ISO day of the week with 1 being Monday and 7 being Sunday.
                    let day = currentTime.isoWeekday()
                    day = day % 7
                    if (!store.openday[day]) {
                        inTime = false;
                        // console.log('store out time')
                    }
                }
                // check store time
            }

            currentTime = moment(currentTime.format("HH:mm"), "HH:mm")

            if (inTime && store && store.startTime && store.endTime) {
                inTime = (currentTime.isSame(moment(store.startTime, "HH:mm")) || currentTime.isBetween(moment(store.startTime, "HH:mm"), moment(store.endTime, "HH:mm")))
                // console.log(inTime, currentTime.format(), moment(store.startTime, "HH:mm").format(), moment(store.startTime, "HH:mm") == currentTime, moment(store.startTime, "HH:mm").isSame(currentTime), store.endTime)
                // if (inTime) console.log('store inTime', inTime)
            }
            // check item time
            if (inTime && item && item.startTime && item.endTime) {
                inTime = (currentTime.isSame(moment(item.startTime, "HH:mm")) || currentTime.isBetween(moment(item.startTime, "HH:mm"), moment(item.endTime, "HH:mm")))
                // if (inTime) console.log('item inTime', inTime)
            }


            if (inTime && item.preorderDay && item.preorderTime && item.preorderDay > 0) {
                currentTime = moment(order.deliverDate + ' ' + time, 'YYYY-MM-DD HH:mm');

                let preorderTime = moment(item.preorderTime, "HH:mm").add(item.preorderDay - 1, 'days')
                // console.log(currentTime.format(), preorderTime.format())
                if (moment().isAfter(moment(item.preorderTime, "HH:mm"))) {
                    preorderTime.add(1, 'days')
                }

                if (currentTime.isBefore(preorderTime)) {
                    inTime = false;
                }
            }

            item.inTime = inTime
            item.isHidden = isHidden
        }

        products = sort(products, [{ inTime: 'desc' }, { image: 'desc' }, { sort_order: 'desc' }, { store_id: 'asc' }, { created_at: 'asc' }])

        return products
    }
    renderProductItem(item, index) {
        if (item.isHidden) return null

        if (index === 0) {
            this.listStore = null;
            this.lastInTime = true;
        }

        let { order } = this.state
        let currentTime = moment(order.deliverDate + ' ' + order.deliverTime, 'YYYY-MM-DD HH:mm');    // e.g. 11:00 pm
        // console.log("currentTime", currentTime)
        let { hubStoresById } = this.state
        let store = hubStoresById && hubStoresById[item.store_id]
        let outTimeSeperate = this.lastInTime && !item.inTime;
        if (outTimeSeperate) {
            this.lastInTime = item.inTime
        }
        let showStore = this.listStore !== item.store_id
        this.listStore = item.store_id

        if (item.image) {
            return [outTimeSeperate && <div className="out-time-seperate">สินค้าที่ไม่จัดส่งในช่วงเวลาที่เลือก</div>,
            // showStore && <div className="store-name-section">{store && store.name}
            //     {!store.isSoldout && store.startTime && store.endTime && <div className="sale-time"><i className="fas fa-clock"></i> {moment(store.startTime, "HH:mm").format("HH:mm")} - {moment(store.endTime, "HH:mm").format("HH:mm")}</div>}
            // </div>,

            <div key={index} className={`menu-item column is-half-mobile is-one-third-tablet  is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd ${item.isSoldout && 'is-soldout'} ${!item.inTime && 'is-outtime'}`}>
                <LazyLoad height={200} offset={100}>
                    <a onClick={() => this.showDescription(item)}><div className="thumbnail-image" style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(thumbPath(item.image))}?alt=media)` }}></div>
                        {/* <CloudImage src={item.image} className="thumbnail" /> */}
                    </a>
                    {/* <button className="add-button" onClick={() => this.quickAddToCart(item)}><i className="fa fa-plus" /></button> */}
                    <div className="name">
                        <a onClick={() => this.showDescription(item)}>
                            {this.getCartProductCount(item) > 0 && <span className="product-quantity">{this.getCartProductCount(item)} x </span>}{item.name}</a><br />
                        <small style={{ color: "#888" }}>{store && store.name}</small>
                    </div>
                    <div className="price">{this.getPriceRange(item)} บาท</div>
                    {item.isSoldout && <div className="soldout">หมดชั่วคราว</div>}
                    {!item.isSoldout && item.startTime && item.endTime && !item.inTime && <div className="sale-time">
                        <i className="fas fa-clock"></i> {moment(item.startTime, "HH:mm").format("HH:mm")} - {moment(item.endTime, "HH:mm").format("HH:mm")}
                        {item.preorderDay && <div className="preorder-time"><i className="fas fa-clock"></i> สั่งล่วงหน้า {item.preorderDay} วัน ก่อน {item.preorderTime}</div>}
                    </div>}
                    {!item.isSoldout && !item.startTime && !item.inTime && hubStoresById[item.store_id] && <div className="sale-time">
                        <i className="fas fa-clock"></i> {moment(hubStoresById[item.store_id].startTime, "HH:mm").format("HH:mm")} - {moment(hubStoresById[item.store_id].endTime, "HH:mm").format("HH:mm")} {renderOpenDay(hubStoresById[item.store_id].openday)}
                        {item.preorderDay && <div className="preorder-time"><i className="fas fa-clock"></i> สั่งล่วงหน้า {item.preorderDay} วัน ก่อน {item.preorderTime}</div>}
                    </div>}

                </LazyLoad>
            </div>]
        }

        return [
            // outTimeSeperate && <div className="out-time-seperate">สินค้าที่ไม่จัดส่งในช่วงเวลาที่เลือก</div>,
            // showStore && <div className="store-name-section">{store && store.name}</div>,
            <div key={index} className="menu-item text-only column is-full ">
                <button className="add-button" onClick={() => this.quickAddToCart(item)}><i className="fa fa-plus" /></button>
                <div className="name">
                    <a onClick={() => this.showDescription(item)}>{item.name}</a><br />
                    <small style={{ color: "#888" }}>{store && store.name}</small>
                </div>
                {!item.inTime && item.startTime && item.endTime && <div className="sale-time"><i className="fas fa-clock"></i> {moment(item.startTime, "HH:mm").format("HH:mm")} - {moment(item.endTime, "HH:mm").format("HH:mm")}</div>}
                <div className="price">{item.price > 0 ? item.price + "บาท" : ""}</div>
            </div>
        ]
    }

    showMapPicker() {
        if (!this.state.order || !this.state.order.markerPosition) {
            // console.log("MARKER:", this.state.order.markerPosition, this.state.order.markerPosition)
            this.updateLocation(this.state.currentLocation)
            // console.log("CURRENT:", this.state.currentLocation)
        }
        this.setState({ showDeliverMap: true });

    }

    renderDeliverOption() {
        let { current_store } = this.props
        if (current_store === 'dentalline') return []

        let { order, storePickupBranch } = this.state
        return <div>
            <label>การจัดส่ง</label>
            {storePickupBranch.length > 0 && <div className="buttons has-addons">
                <button className={`button ${order.deliverType === 'deliver' && 'is-success is-selected'}`}
                    // style={AppConfig.build === 'imperial' && { whiteSpace: "break-spaces", height: 60 }}
                    onClick={() => {
                        order.deliverType = 'deliver';
                        // this.getDistance(); 
                        this.calculateDeliverCost()
                        this.forceUpdate()
                    }}><i className="fa fa-truck"></i>&nbsp;ให้ร้านจัดส่ง</button>
                <button className={`button ${order.deliverType === 'pickup' && 'is-success is-selected'}`}
                    // style={AppConfig.build === 'imperial' && { whiteSpace: "break-spaces", height: 60, fontSize: '0.9em' }}
                    onClick={() => {
                        order.deliverType = 'pickup';
                        // this.getDistance(); 
                        this.calculateDeliverCost()
                        this.forceUpdate()
                    }}><i className="fa fa-store"></i>&nbsp;{AppConfig.build === 'imperial' ? 'ส่งภายในศูนย์การค้าอิมพีเรียลเวิลด์สำโรงและรพ.สำโรงการแพทย์' : 'มารับเอง'}</button>
                <button className={`button ${order.deliverType === 'donate' && 'is-success is-selected'}`}
                    // style={AppConfig.build === 'imperial' && { whiteSpace: "break-spaces", height: 60, fontSize: '0.9em' }}
                    onClick={() => {
                        order.deliverType = 'donate';
                        // this.getDistance(); 
                        this.calculateDeliverCost()
                        this.forceUpdate()
                    }}><i className="fa fa-store"></i>&nbsp;บริจาคอาหารให้ผู้ที่ต้องการ</button>
            </div>
            }
            {order.deliverType === 'pickup' && < div className="deliver-pickup-wrapper">
                คุณสามารถมารับเองได้ที่<br />
                {storePickupBranch.map(x => <div className="pickup-address-item">
                    <div className="pickup-name">{x.name}</div>
                    <div className="pickup-address">{x.address}</div>
                    <a href={`http://maps.google.com/maps?daddr=${x.lat},${x.lng}&amp;ll=`} target="_blank" style={{ boxShadow: 'none' }}><i className="fa fa-map-marker-alt"></i>&nbsp;แสดงบนแผนที่</a>
                </div>)}
            </div>}
            {order.deliverType === 'pickup' && <div className="deliver-address-wrapper"><label>รายละเอียดที่อยู่เพิ่มเติม</label>
                <textarea name="deliverRemark"
                    value={order.deliverRemark}
                    onChange={this.handleDescriptionChange}
                    className="textarea mt5" disabled={this.state.isFixCustomer} placeholder="เช่น ชื่อคอนโด เลขที่ห้อง จุดสังเกตใกล้เคียง">
                </textarea></div>}
            {order.deliverType === 'deliver' && <div className="deliver-address-wrapper">
                <label>ที่อยู่จัดส่ง</label>
                {!order.markerPosition && <a className="location-item" onClick={() => {
                    if (!this.state.isFixCustomer) { this.showMapPicker() }
                    firebase.analytics().logEvent('page_view', { page_title: `Map Picker-${this.props.current_store}` })
                }}>
                    <div><i className="fa fa-map-marker-alt"></i>&nbsp;เลือกที่อยู่จัดส่ง</div>
                </a>}
                {order.markerPosition && <a className="location-item" onClick={() => {
                    if (!this.state.isFixCustomer) { this.setState({ showDeliverMap: true }); }
                    firebase.analytics().logEvent('page_view', { page_title: `Map Picker-${this.props.current_store}` })
                }}>
                    <div>
                        <i className="fa fa-map-marker-alt">&nbsp;</i>
                        {order.markerPosition && order.markerPosition.lat && <span>{order.distance}km - <small>{order.markerPosition.lat}, {order.markerPosition.lng}</small></span>}
                    </div>
                    <small style={{ color: '#888' }}>{order.deliverAddress.address}</small>
                </a>}
                {this.state.storeData.showEditableAddress && order.deliverAddress.address && <div className="address-description">

                    <div className="columns is-mobile is-multiline">
                        <div className="column is-full">
                            <label>เลขที่, อาคาร/หมู่บ้าน, ถนน</label>
                            <input
                                name="deliverAddress.route"
                                value={order.deliverAddress.route}
                                onChange={this.handleDescriptionChange}
                                className="input"
                                type="text"
                                placeholder=""
                                disabled={this.state.isFixCustomer} />
                        </div>

                        <div className="column is-half">
                            <label>แขวง/ตำบล</label>
                            <input
                                name="deliverAddress.city"
                                value={order.deliverAddress.city}
                                onChange={this.handleDescriptionChange}
                                className="input"
                                type="text"
                                placeholder=""
                                disabled={this.state.isFixCustomer} />
                        </div>
                        <div className="column is-half">
                            <label>เขต/อำเภอ</label>
                            <input
                                name="deliverAddress.area"
                                value={order.deliverAddress.area}
                                onChange={this.handleDescriptionChange}
                                className="input"
                                type="text"
                                placeholder=""
                                disabled={this.state.isFixCustomer} />
                        </div>

                        <div className="column is-half">
                            <label>จังหวัด</label>
                            <input
                                name="deliverAddress.state"
                                value={order.deliverAddress.state}
                                onChange={this.handleDescriptionChange}
                                className="input"
                                type="text"
                                placeholder=""
                                disabled={this.state.isFixCustomer} />
                        </div>
                        <div className="column is-half">
                            <label>รหัสไปรษณีย์</label>
                            <input
                                name="deliverAddress.postcode"
                                value={order.deliverAddress.postcode}
                                onChange={this.handleDescriptionChange}
                                className="input"
                                type="text"
                                placeholder=""
                                disabled={this.state.isFixCustomer} />
                        </div>
                    </div>

                </div>
                }
                <label>รายละเอียดที่อยู่เพิ่มเติม</label>
                <textarea name="deliverRemark"
                    value={order.deliverRemark}
                    onChange={this.handleDescriptionChange}
                    className="textarea mt5" disabled={this.state.isFixCustomer} placeholder="เช่น ชื่อคอนโด เลขที่ห้อง จุดสังเกตใกล้เคียง">
                </textarea>
                {this.state.storeData.showDeliverMethod && <div>
                    <label>ประเภทที่อยู่</label>
                    <div className="buttons has-addons">
                        <button className={`button ${order.deliverAddressType === 'home' && 'is-warning is-selected'}`} onClick={() => { order.deliverAddressType = 'home'; this.forceUpdate(); }}>บ้าน</button>
                        <button className={`button ${order.deliverAddressType === 'condo' && 'is-warning is-selected'}`} onClick={() => { order.deliverAddressType = 'condo'; this.forceUpdate(); }}>คอนโด/ออฟฟิศ</button>
                    </div>
                    <label>วิธีการเมื่อถึง</label>
                    {order.deliverAddressType === 'home' && <div className="select mt5 is-fullwidth">
                        <select
                            name="deliverMethod"
                            value={order.deliverMethod}
                            onChange={this.handleDescriptionChange}
                            disabled={this.state.isFixCustomer}>
                            <option value={"โทรหาเมื่อถึง"}>โทรหาเมื่อถึง</option>
                            <option value={"ฝากไว้ที่รปภ"}>ฝากไว้ที่รปภ</option>
                        </select>
                    </div>}
                    {order.deliverAddressType === 'condo' && <div className="select mt5 is-fullwidth">
                        <select
                            name="deliverMethod"
                            value={order.deliverMethod}
                            onChange={this.handleDescriptionChange}
                            disabled={this.state.isFixCustomer}>
                            <option value={"โทรหาเมื่อถึง"}>โทรหาเมื่อถึง</option>
                            <option value={"ฝากไว้ที่รปภ"}>ฝากไว้ที่รปภ</option>
                            <option value={"ติดต่อนิติฯ/ส่วนต้อนรับ"}>ติดต่อนิติฯ/ส่วนต้อนรับ</option>
                        </select>
                    </div>}
                </div>}
            </div>
            }
        </div>
    }

    showSummary() {
        this.hideDescription()

        let { order } = this.state
        this.setState({ showSummary: true });
        window.scrollTo(0, 0);
        firebase.analytics().logEvent('page_view', { page_title: `Checkout-${this.props.current_store}` })

        mpTrackEvent("View Cart", {
            "cart size": order.carts.length,
            "cart value": order.sumProductPrice
        });

    }

    hideSummary() {
        this.setState({ showSummary: false })
        let { order } = this.state

        mpTrackEvent("Select More Menu", {
            "cart size": order.carts.length,
            "cart value": order.sumProductPrice
        });

    }


    applySearch(products) {
        let query = this.state.query
        let { hubStoresById } = this.state
        if (query) {
            return products.filter(x => x.name.toLowerCase().includes(query.toLowerCase()) || (hubStoresById && hubStoresById[x.store_id] && hubStoresById[x.store_id].name.toLowerCase().includes(query.toLowerCase())))
        }
        else {
            return products
        }
    }

    getPriceRange(product) {
        let minPrice = product.price;
        let maxPrice = product.price;
        let options = product.options;
        for (let o in options) {
            let price = product.price + options[o].price;

            if (maxPrice < price) {
                maxPrice = price;
            }
        }

        if (minPrice === maxPrice) {
            return minPrice
        }

        return `${minPrice} - ${maxPrice}`
    }

    render() {
        let { current_store } = this.props

        let { order, deliverRemark, promotionError, storePickupBranch, hubStoresById, storeData, categories, currentItem, selectedAmount, selectedNote, selectedExtra, loading_message } = this.state

        if (this.state.linepayLoading) return <div style={{ textAlign: 'center', paddingTop: '100px', fontWeight: 'bolder' }}>กำลังเชื่อมต่อไปยัง <br />Rabbit Line Pay<br /><img src="/loading.gif" width="60" /><br /><img src="https://scdn.line-apps.com/linepay/portal/assets/img/portal/th/logo.svg" /></div>
        // console.log(this.state.mapPosition, order.markerPosition)

        if (storeData && !storeData.is_active) return <div style={{ textAlign: 'center', paddingTop: '100px', fontWeight: 'bolder' }}>ร้านนี้ยังไม่เปิดบริการในขณะนี้</div>


        let sliderBoxStyle = {
            height: "360px",
            width: "95vw",
            background: "transparent",
            border: "0"
        };

        let dotsSetting = {
            style: {
                dotSize: "5px",
                currDotColor: "rgba(248, 125, 13, 0.75)",
                marginTop: "-5px"
            }
        };


        let itemsStyle = {
            padding: '0',
            margin: "0px 1px",
            height: "100%",
            background: "transparent",
            borderRadius: "10px",
            overflow: "hidden"
        };

        let buttonSetting = {
            placeOn: "middle-inside",
            hoverEvent: true,
            style: {
                left: {
                    height: "40px",
                    width: "40px",
                    color: "#fff",
                    background: "rgba(248, 125, 13, 0.75)",
                    borderRadius: "0"
                },
                right: {
                    height: "40px",
                    width: "40px",
                    color: "#fff",
                    background: "rgba(248, 125, 13, 0.75)",
                    borderRadius: "0"
                }
            }
        };


        let bannerItems = []
        let banners = this.state.banners.map((item, index) => {
            return { imgSrc: `https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(item.image)}?alt=media` }
        })
        bannerItems = this.state.banners.map((item, index) =>
            <Link to={item.link}><img src={`https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(item.image)}?alt=media`} className="thumbnail" /></Link>
        );

        // console.log("RENDER", order)

        if (this.state.state === 'complete') {
            return <div className="ordering">
                <div className="complete">
                    <div className="has-text-centered mt20">
                        <h1 className="title is-3">ทางร้านได้รับข้อมูลของท่านแล้ว</h1>

                        ขอบคุณที่ใช้บริการ
                        {order.paymentMethod === 'transfer' && <div>
                            กรุณาโอนเงินตามรายละเอียดด้านล่าง และ แจ้งการโอนเงินผ่านไลน์<br />
                            <img src={`https://${AppConfig.firebase.projectId}.firebaseapp.com/${this.props.current_store.replace(".", "_")}_transfer.jpg`} />
                        </div>}
                    </div>
                </div>
            </div>
        }

        let { products, showProductType } = this.state
        let productMenus = [], productProducts = []
        if (!products) {
            return <div className="has-text-centered p20">
                {loading_message ? loading_message : 'กำลังโหลดข้อมูล'}<br />
                <img src="/loading.gif" width="80" /></div>
        }
        else {
            productProducts = products.filter(x => x.type === 'product')
            productMenus = products.filter(x => x.type !== 'product')

            if (showProductType !== 'product') {
                products = productMenus
            }
            else {
                products = productProducts
            }

            products = this.applySearch(products)
        }

        products = this.applyTime(products)

        let inTime = true;
        for (let c in order.carts) {
            if (!this.checkInTimeCart(order.carts[c], products)) {
                inTime = false;
            }
        }

        // console.log(categories)
        let filterdCategories = categories ? categories.filter(x => products.filter(p => p.category === x.id && p.inTime).length > 0) : []

        return <div className={`ordering ${isNative() && 'is-native'}`}>
            {!this.state.showSummary &&
                <div className="product-wrapper">
                    {/* <h1>อาหารแนะนำ</h1> */}
                    {<div className="header-nav">
                        {AppConfig.build !== 'locall' && <img className="logo" src="/logo.png" />}
                        {
                            AppConfig.build === 'locall' && <div style={{ padding: '10px' }}><a onClick={() => {
                                if (isNative()) {
                                    sendRN({ action: "pop", params: {} })
                                }
                                else {
                                    this.props.history.push('/');
                                    this.props.setCurrentStore(null)
                                }
                            }}><i className="fas fa-chevron-left"></i> {this.state.storeData.name}</a></div>
                        }
                        {
                            this.getCartsCount() > 0 && <a className="carts-button" onClick={() => {
                                // this.getDistance(); 
                                this.calculateDeliverCost()
                                this.showSummary()
                            }}>
                                &#3647; {order.totalPrice}
                                <i className="fa fa-shopping-bag ml5"></i>
                                <div className="carts-quantity">{this.getCartsCount()}</div>
                            </a>
                        }
                    </div >}

                    <div className="search-box" ref={ref => this.menuOffset = ref ? (ref.offsetTop + ref.clientHeight) : 0}>
                        <div className="columns mb0">
                            <div className="column">
                                {AppConfig.build !== 'singha' && (!this.state.isFixCustomer && !order.discount_fix_time) && <div className="deliver-time-wrapper columns is-mobile">
                                    <div className="column">
                                        <label>วันที่จัดส่ง</label>
                                        <div className="select mt5 is-fullwidth">
                                            <select
                                                name="deliverDate"
                                                value={order.deliverDate}
                                                onChange={this.handleDescriptionChange}
                                                disabled={this.state.isFixCustomer || order.discount_fix_date}>
                                                <option value={""} disabled>กรุณาเลือกวัน</option>
                                                {
                                                    [...Array(15)].map((x, i) => {
                                                        let today = moment();
                                                        let closeTime = moment(this.getCloseTime(), "HH:mm")
                                                        let tomorrow = moment().add(1, "days");
                                                        let date = today.isBefore(closeTime) ? moment() : tomorrow
                                                        date.add(i + 1, 'days')
                                                        let dateName = date.format('dddd D MMM YYYY')

                                                        if (this.isDayoff(date)) {
                                                            return false;
                                                        }
                                                        if (date.date() === today.date()) {
                                                            dateName = "วันนี้"
                                                        }
                                                        else {
                                                            let dayName = {
                                                                Monday: "จันทร์",
                                                                Tuesday: "อังคาร",
                                                                Wednesday: "พุธ",
                                                                Thursday: "พฤหัส",
                                                                Friday: "ศุกร์",
                                                                Saturday: "เสาร์",
                                                                Sunday: "อาทิตย์",
                                                            }

                                                            for (let d in dayName) {
                                                                dateName = dateName.replace(d, dayName[d])
                                                            }
                                                        }

                                                        let monthEn = ["Jan", "Feb", "Mar", "Api", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                                                        let monthTh = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]
                                                        for (let m in monthEn) {
                                                            if (dateName.includes(monthEn[m])) {
                                                                dateName = dateName.replace(monthEn[m], monthTh[m])
                                                            }
                                                        }

                                                        return <option key={i} value={date.format('YYYY-MM-DD')}>{dateName}</option>
                                                    }

                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/*<div className="column">
                                        <label>เวลาจัดส่ง</label>
                                        <div className="select mt5 is-fullwidth">
                                            <select
                                                name="deliverTime"
                                                value={order.deliverTime}
                                                onChange={this.handleDescriptionChange}
                                                disabled={this.state.isFixCustomer || order.discount_fix_time}>
                                                <option value={""} disabled>กรุณาเลือกเวลา</option>
                                                {this.getDeliverTimes(order.deliverDate)}
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                }
                            </div>
                            <div className="column pt5 pb0">
                                <label className="is-hidden-mobile">ค้นหา</label>
                                <div className="quicksearch">

                                    <input type="text" className="input" value={this.state.query} onChange={(e) => {
                                        if (e.target.value.length >= 4) {
                                            mpTrackEvent("Search", {
                                                "Search Term": e.target.value,
                                            });
                                        }

                                        this.setState({ query: e.target.value })
                                    }} placeholder="ค้นหาจากชื่อร้าน/ชื่ออาหาร" />
                                    <i className="fa fa-search" />
                                    {this.state.query && <button className="search-reset-button" onClick={(e) => { this.setState({ query: null }) }}><i className="fa fa-times" /></button>}
                                </div>
                            </div>
                        </div>
                        {
                            filterdCategories.length > 0 && <div className="tabs is-centered categories-wrapper is-clearfix">
                                <ul>
                                    {filterdCategories.map(x => <li key={x.id}><a className={`category-link ${this.state.selectedCategory === x.id && 'selected'}`} onClick={() => { this.setState({ selectedCategory: x.id }); window.scrollTo(0, this.categories[x.id].offsetTop - this.menuOffset) }} >{x.name}</a></li>)}
                                    {filterdCategories.length > 0 && products.filter(p => !p.category).length > 0 && <li><a className={`category-link ${this.state.selectedCategory === 'noncategory' && 'selected'}`} onClick={() => { this.setState({ selectedCategory: 'noncategory' }); window.scrollTo(0, this.categories['noncategory'].offsetTop - this.menuOffset) }} >อื่น ๆ</a></li>}
                                </ul>
                            </div>
                        }
                    </div >

                    {AppConfig.firebase.projectId === 'locall-bkk' && <CloudImage src={storeData.image || `hubs/${this.props.current_store}.jpg`} className="hub-banner" />}
                    {
                        this.state.banners.length > 1 &&
                        <CarouselSlider
                            // slideItems={banners}
                            slideCpnts={bannerItems}
                            sliderBoxStyle={sliderBoxStyle}
                            buttonSetting={buttonSetting}
                            itemsStyle={itemsStyle}
                            dotsSetting={dotsSetting}
                        />
                    }
                    {this.state.banners.length === 1 && <a href={this.state.banners[0].link} ><CloudImage src={this.state.banners[0].image} className="thumbnail" /></a>}
                    {
                        (productMenus.length > 0 && productProducts.length > 0) && <div>
                            <div className="product-togggle-tabs tabs is-toggle is-toggle-rounded" style={{ width: '100%' }}>
                                <ul>
                                    <li className={`${showProductType === 'menu' && 'is-active'}`}><a onClick={() => this.setState({ showProductType: 'menu' })}>อาหาร</a></li>
                                    <li className={`${showProductType === 'product' && 'is-active'}`}><a onClick={() => this.setState({ showProductType: 'product' })}>สินค้า</a></li>
                                </ul>
                            </div>
                        </div>
                    }
                    <div >
                        {products && products.length == 0 && <div className="has-text-centered m20 not_found">ไม่มีรายการในสั่งนี้</div>}
                        {filterdCategories.length > 0 && <>
                            {filterdCategories.map(c => <>
                                <a name={c.id} ref={ref => this.categories[c.id] = ref}><div className="category-header">{c.name}</div></a>
                                <div className="columns is-1 is-multiline is-mobile">
                                    {products && products.filter(x => x.inTime && x.category === c.id).map((item, index) => this.renderProductItem(item, index))}
                                    {products && products.filter(x => !x.inTime && x.category === c.id).map((item, index) => this.renderProductItem(item, index))}
                                </div>

                            </>)}
                            <a name="noncategory" ref={ref => this.categories['noncategory'] = ref}><div className="category-header">อื่นๆ</div></a>
                            <div className="columns is-1 is-multiline is-mobile">
                                {products && products.filter(x => x.inTime && !x.category).map((item, index) => this.renderProductItem(item, index))}
                                {products && products.filter(x => !x.inTime && !x.category).map((item, index) => this.renderProductItem(item, index))}
                            </div>

                        </>}

                        {
                            filterdCategories.length === 0 && <div className="columns is-1 is-multiline is-mobile">
                                {products && products.filter(x => x.inTime).map((item, index) => this.renderProductItem(item, index))}
                                {products && products.filter(x => !x.inTime).map((item, index) => this.renderProductItem(item, index))}
                            </div >}
                        {/* 
{ products.filter(x => !x.inTime).length > 0 && <div className="out-time-seperate">สินค้าที่ไม่จัดส่งในช่วงเวลาที่เลือก</div> }

{
    filterdCategories.length > 0 && <>
        {filterdCategories.map(c => <>
            <a name={c.id} ><div className="category-header">{c.name}</div></a>
            <div className="columns is-1 is-multiline is-mobile">
            {/* {products && products.filter(x => x.inTime && x.category === c.id).map((item, index) => this.renderProductItem(item, index))} * /}
            {products && products.filter(x => !x.inTime && x.category === c.id).map((item, index) => this.renderProductItem(item, index))}
        </div>

                            </>)
}
                            <a name="noncategory" ><div className="category-header">อื่นๆ</div></a>
                            <div className="columns is-1 is-multiline is-mobile">
{/* {products && products.filter(x => x.inTime && !x.category).map((item, index) => this.renderProductItem(item, index))} * /}
{ products && products.filter(x => !x.inTime && !x.category).map((item, index) => this.renderProductItem(item, index)) }
                            </div >

                        </>}

{
    filterdCategories.length === 0 && <div className="columns is-1 is-multiline is-mobile">
    {/* {products && products.filter(x => x.inTime).map((item, index) => this.renderProductItem(item, index))} * /}
                            {products && products.filter(x => !x.inTime).map((item, index) => this.renderProductItem(item, index))}
                        </div>} */}
                    </div >
                    {
                        AppConfig.build === 'locall' && <>
                            <div className={`footer-zone-wrapper ${!this.state.showHubSelect && 'hide'}`}>
                                <div className="footer-zone-list">
                                    {sortByDistance(Object.keys(this.props.stores_map), this.props.stores_map, this.state.currentLocation).map(x => this.props.stores_map[x].is_active &&
                                        <div className="footer-zone-item" onClick={() => {
                                            if (window.confirm('เปลี่ยนไปย่านอื่น รายการที่คุณเลือกไว้ในตะกร้าจะถูกเปลี่ยนแปลง คุณแน่ในหรือไม่?')) {
                                                this.props.setCurrentStore(null)
                                                setTimeout(() => this.props.setCurrentStore(x), 50)
                                            }
                                        }}>
                                            <i className="fa fa-map-pin mr10"></i>{this.props.stores_map[x].name}
                                        </div>
                                    )}
                                </div>
                            </div>
                            < div className="selected-item level is-mobile" style={{ borderRadius: 0, padding: 10 }} onClick={() => { this.setState({ showHubSelect: !this.state.showHubSelect }) }}>
                                <div className="level-left">
                                    <div className="level-item">
                                        <i className={`fa ${this.state.showHubSelect ? 'fa-chevron-down' : 'fa-chevron-up'} mr10`}></i>
                                        <div className="level-item" style={{ fontSize: '1.2em' }}>เลือกย่านอื่น</div>
                                    </div>
                                </div>
                                {/* <div className="level-item">เลือกย่านอื่น</div>
                            <div className="level-right">
                                <div className="level-item">{order.totalPrice}</div>
                            </div> */}
                            </div>
                        </>
                    }
                    {
                        AppConfig.build !== 'locall' &&
                        order.carts.length > 0 &&
                        <div className="selected-item level is-mobile" onClick={() => {
                            // this.getDistance(); 
                            this.calculateDeliverCost()
                            this.showSummary()
                        }}>
                            <div className="level-left">
                                <div className="level-item">
                                    <i className="fa fa-shopping-bag"></i>
                                    <div className="carts-quantity">{this.getCartsCount()}</div>
                                </div>
                            </div>
                            <div className="level-item">แสดงรายการที่เลือก</div>
                            <div className="level-right">
                                <div className="level-item">{order.totalPrice}</div>
                            </div>
                        </div>
                    }
                </div >
            }

            {
                this.state.showSummary && <div>
                    {<div className="header-nav">
                        <div style={{ padding: '10px' }}><a onClick={() => this.setState({ showSummary: false })}><i className="fas fa-chevron-left"></i>ตะกร้าสินค้า</a></div>
                    </div>}

                    <div className="summary-dialogs" style={{ paddingBottom: "80px" }}>
                        <div className="summary-wrapper">
                            <h2 className="title">รายการ</h2>
                            {order.carts.map((item, index) =>
                                this.renderCartItem(item, index))}
                            {order.promotions && order.promotions.length > 0 && <div>
                                <div className="promotions animated fadeIn">
                                    <label>โปรโมชั่น</label>
                                    {order.promotions.map(item =>
                                        <li className={`${(item.id === order.activePromotion || item.id === order.activeDeliverPromotion) ? 'active' : 'inactive'}`}>&nbsp;{item.name} {item.applyTime > 0 ? " x " + item.applyTime : ''}</li>
                                    )}

                                </div>
                            </div>
                            }

                            {AppConfig.build !== 'singha' && <div className={`level is-mobile checkbox ${order.utensils ? 'active ' : ''}`}>
                                <div className={`level-left `}>
                                    <i className="fa fa-utensils" style={{ padding: '0 5px 5px' }} />
                                    <div className="level-item">&nbsp;รับช้อนส้อม</div>
                                </div>
                                <div className="level-right ">
                                    <i />
                                </div>
                                <input type="checkbox"
                                    name="utensils"
                                    checked={order.utensils}
                                    onChange={this.handleDescriptionChange}
                                    className={`input`} />
                            </div>}

                            {AppConfig.build !== 'singha' && <div className={`level is-mobile checkbox ${order.flavoring ? 'active ' : ''}`}>
                                <div className={`level-left `}>
                                    <i className="fa fa-pepper-hot" style={{ padding: '0 5px 5px' }} />
                                    <div className="level-item">&nbsp;ไม่รับเครื่องปรุง</div>
                                </div>
                                <div className="level-right ">
                                    <i />
                                </div>
                                <input type="checkbox"
                                    name="flavoring"
                                    checked={order.flavoring}
                                    onChange={this.handleDescriptionChange}
                                    className={`input`} />
                            </div>}

                            {/* <div className="level is-mobile mb5">
                            <div className="level-left">รวมค่าอาหาร</div>

                            <div className="level-right">
                                <div className="level-item">{order.sumPrice}</div>
                            </div>
                        </div> */}
                            <button className="button is-primary is-outlined is-fullwidth" onClick={() => this.setState({ showSummary: false })}>เลือกเมนูเพิ่มเติม</button>
                            <hr />
                            <div className="columns is-mobile">
                                <div className="column p5">
                                    <label >ชื่อผู้รับ</label>
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                name="customer.name"
                                                value={order.customer.name}
                                                onChange={this.handleDescriptionChange}
                                                className="input"
                                                type="text"
                                                placeholder=""
                                                disabled={this.state.isFixCustomer} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column p5">
                                    <label >เบอร์ติดต่อ</label>
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                name="customer.tel"
                                                value={order.customer.tel}
                                                onChange={this.handleDescriptionChange}
                                                className="input"
                                                type="text"
                                                placeholder=""
                                                disabled={this.state.isFixCustomer} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.renderDeliverOption()}

                            <hr />


                            {AppConfig.build !== 'singha' && (!this.state.isFixCustomer && !order.discount_fix_time) && <div className="columns is-mobile">
                                <div className="column">
                                    <label>วันที่จัดส่ง</label>
                                    <div className="select mt5 is-fullwidth">
                                        <select
                                            name="deliverDate"
                                            value={order.deliverDate}
                                            onChange={this.handleDescriptionChange}
                                            disabled={this.state.isFixCustomer || order.discount_fix_date}>
                                            <option value={""} disabled>กรุณาเลือกวัน</option>
                                            {
                                                [...Array(15)].map((x, i) => {
                                                    let today = moment();
                                                    let closeTime = moment(this.getCloseTime(), "HH:mm")
                                                    let tomorrow = moment().add(1, "days");
                                                    let date = today.isBefore(closeTime) ? moment() : tomorrow
                                                    date.add(i + 1, 'days')
                                                    let dateName = date.format('dddd D MMM YYYY')

                                                    if (this.isDayoff(date)) {
                                                        return false;
                                                    }
                                                    if (date.date() === today.date()) {
                                                        dateName = "วันนี้"
                                                    }
                                                    else {
                                                        let dayName = {
                                                            Monday: "จันทร์",
                                                            Tuesday: "อังคาร",
                                                            Wednesday: "พุธ",
                                                            Thursday: "พฤหัส",
                                                            Friday: "ศุกร์",
                                                            Saturday: "เสาร์",
                                                            Sunday: "อาทิตย์",
                                                        }

                                                        for (let d in dayName) {
                                                            dateName = dateName.replace(d, dayName[d])
                                                        }
                                                    }

                                                    let monthEn = ["Jan", "Feb", "Mar", "Api", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                                                    let monthTh = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]
                                                    for (let m in monthEn) {
                                                        if (dateName.includes(monthEn[m])) {
                                                            dateName = dateName.replace(monthEn[m], monthTh[m])
                                                        }
                                                    }

                                                    return <option key={i} value={date.format('YYYY-MM-DD')}>{dateName}</option>
                                                }

                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="column">
                                    <label>เวลาจัดส่ง</label>
                                    <div className="select mt5 is-fullwidth">
                                        <select
                                            name="deliverTime"
                                            value={order.deliverTime}
                                            onChange={this.handleDescriptionChange}
                                            disabled={this.state.isFixCustomer || order.discount_fix_time}>
                                            <option value={""} disabled>กรุณาเลือกเวลา</option>
                                            {this.getDeliverTimes(order.deliverDate)}
                                        </select>
                                    </div>
                                </div> */}
                            </div>}

                            {(this.state.isFixCustomer || order.discount_fix_time) && <div className="columns is-mobile">
                                <div className="column">
                                    <label>วันที่จัดส่ง</label>
                                    <div className="mt5 is-fullwidth">
                                        {dateFormat(order.deliverDate, false)}
                                    </div>
                                </div>
                                <div className="column">
                                    <label>เวลาจัดส่งประมาณ</label>
                                    <div className="mt5 is-fullwidth">
                                        {order.deliverTime}
                                    </div>
                                </div>
                            </div>}


                            <hr />
                            <label>วิธีการชำระเงิน</label>
                            <div className="payment-method">
                                <button className={`button is-fullwidth mt10 is-large ${order.paymentMethod === 'transfer' ? 'active' : 'inactive'}`} onClick={() => this.setState({ order: { ...order, paymentMethod: "transfer" }, showPayment: false })} style={{ justifyContent: 'left' }}><i className="fa fa-circle" />&nbsp;โอนเงิน</button>
                                {order.paymentMethod === 'transfer' && this.state.storeData && this.state.storeData.bank_accounts && this.state.storeData.bank_accounts.length > 0 && <div className="bank-accounts-list">
                                    {this.state.storeData.bank_accounts.map(x => x.account_no && <div className="bank-account">
                                        <div className="bank">ธนาคาร{x.bank}</div>
                                        <div className="account-name">ชื่อบัญชี: {x.account_name}</div>
                                        <div className="account-no">เลขที่:{x.account_no}</div>
                                        <div className="account-branch">สาขา:{x.account_branch}</div>
                                    </div>)}
                                </div>}
                                {order.deliverType === 'pickup' && <button className={`button is-fullwidth mt10 is-large ${order.paymentMethod === 'cod' ? 'active' : 'inactive'}`} onClick={() => this.setState({ order: { ...order, paymentMethod: "cod" }, showPayment: false })} style={{ justifyContent: 'left' }}><i className="fa fa-circle" />&nbsp;ชำระเงินปลายทาง/เงินสด</button>}
                                {/* <button className={`button is-fullwidth mt10 is-large ${order.paymentMethod === 'linepay' ? 'active' : 'inactive'}`} onClick={() => this.setState({ order: { ...order, paymentMethod: "linepay" }, showPayment: false })} style={{ justifyContent: 'left' }}><i className="fa fa-circle" />&nbsp;Rabbit Line Pay</button> */}
                            </div>
                            <hr />
                            <label>คูปองโค้ด</label>
                            {!order.coupon && <div className="coupon-wrapper">
                                <input
                                    name="coupon_code"
                                    value={order.coupon_code}
                                    onChange={this.handleDescriptionChange}
                                    className="input"
                                    type="text"
                                    placeholder="" />
                                <button className="coupon-button" onClick={() => this.useCoupon()}>ใช้โค้ด</button>

                            </div>
                            }
                            {order.coupon && <div className="coupon-wrapper">
                                <div className="input">{order.coupon_promotion.name}</div>
                                <button className="coupon-button" onClick={() => this.cancelCoupon()}>ยกเลิก</button>

                            </div>}
                            {promotionError && <div className="order-notice has-text-danger">{promotionError}</div>}
                            {/* 
                        <label>หมายเหตุเพิ่มเติม</label>

                        <textarea
                            name="remark"
                            value={order.remark}
                            onChange={this.handleDescriptionChange}
                            className="input" placeholder="">

                        </textarea> */}

                            <div className="summary-panel mt20 has-text-centered">
                                {!inTime && <div className="order-notice has-text-danger">มีบางรายการไม่สามารถสั่งได้ในช่วงเวลาที่เลือก กรุณาตรวจสอบรายการ และ ช่วงเวลาจัดส่งอีกครั้ง</div>}
                                {!order.customer.name && <div className="order-notice has-text-danger">กรุณากรอกชื่อผู้รับ</div>}
                                {!order.customer.tel && <div className="order-notice has-text-danger">กรุณากรอกเบอร์โทร</div>}
                                <div className="level is-mobile" style={{ color: '#AAA' }}>
                                    <div className="level-left">
                                        <div className="level-item">รวม</div>
                                    </div>
                                    <div className="level-right">
                                        <div className="level-item">{order.sumProductPrice}</div>
                                    </div>
                                </div>

                                {
                                    order.deliverType !== 'pickup' && !order.distance && <div className="level is-mobile" style={{ color: '#AAA' }}>
                                        <div className="level-left">
                                            <div className="level-item">ค่าจัดส่ง
                                            </div>
                                        </div>
                                        <div className="level-right">
                                            <div className="level-item" style={{ color: 'red' }}>กรุณาเลือกที่อยู่จัดส่ง</div>
                                        </div>
                                    </div>
                                }

                                {
                                    (order.deliverType !== 'pickup' && !(!this.state.deliverAddress || this.state.deliverAddress.address === "")) && <div className="level is-mobile" style={{ color: '#AAA' }}>
                                        <div className="level-left">
                                            <div className="level-item">ค่าจัดส่ง
                                            </div>
                                        </div>
                                        <div className="level-right">
                                            <div className="level-item" style={{ color: 'red' }}>{this.state.cannotDeliver ? (deliverRemark || "อยู่นอกพื้นที่จัดส่ง") : order.deliverCost}</div>
                                        </div>
                                    </div>
                                }


                                {
                                    order.deliverType === 'pickup' && <div className="level is-mobile" style={{ color: '#AAA' }}>
                                        <div className="level-left">
                                            <div className="level-item">ค่ารวบรวมออเดอร์
                                            </div>
                                        </div>
                                        <div className="level-right">
                                            <div className="level-item" style={{ color: 'red' }}>{this.state.cannotDeliver ? (deliverRemark || "อยู่นอกพื้นที่จัดส่ง") : order.deliverCost}</div>
                                        </div>
                                    </div>
                                }


                                {order.discount > 0 && <div className="level is-mobile" style={{ color: '#AAA' }}>
                                    <div className="level-left">
                                        <div className="level-item">ส่วนลด</div>
                                    </div>
                                    <div className="level-right">
                                        <div className="level-item">(-) {order.discount}</div>
                                    </div>
                                </div>
                                }

                                <div className="level is-mobile">
                                    <div className="level-left">
                                        <div className="level-item">รวมทั้งสิ้น</div>
                                    </div>
                                    <div className="level-right">
                                        <div className="level-item">{order.totalPrice} บาท</div>
                                    </div>
                                </div>

                                <div className="mt0 has-text-centered columns is-mobile">
                                    <div className="column">
                                        <button className="button is-primary is-outlined is-fullwidth" onClick={() => this.hideSummary()}><i className="fa fa-plus"></i>เลือกเพิ่ม</button>
                                    </div>
                                    {order.carts.length > 0 && <div className="column">
                                        <button className="button is-primary is-fullwidth" onClick={() => this.summaryOrder()} disabled={!this.state.isFixCustomer && (!inTime || this.formValidate())}>สั่ง</button>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            }

            {
                currentItem &&
                <div className={`modal is-active description-dialogs animated animate__faster ${currentItem ? 'slideInRight' : 'slideOutLeft'} ${currentItem.isSoldout && 'is-soldout'}`} style={{ display: this.state.showDescription ? "" : "none" }}>
                    <div className="modal-background"></div>
                    {
                        <div className="header-nav">
                            <div style={{ padding: '10px' }}><a onClick={() => { this.hideDescription(); mpTrackEvent('Menu Back Button', { "cart size": order.carts.length, "cart value": order.sumProductPrice }) }}><i className="fas fa-chevron-left"></i>&nbsp; เมนูอื่น</a></div>
                            {this.getCartsCount() > 0 && <a className="carts-button" onClick={() => {
                                // this.getDistance(); 
                                this.calculateDeliverCost()
                                this.showSummary()
                            }}>
                                &#3647; {order.totalPrice}
                                <i className="fa fa-shopping-bag ml5"></i>
                                <div className="carts-quantity">{this.getCartsCount()}</div>
                            </a >}
                        </div >}
                    <div className="modal-content">
                        <div className="columns is-gapless">
                            <div className="column">
                                <div className="thumbnail-image" style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(currentItem.image)}?alt=media)` }}></div>
                                {/* <img src={`https://firebasestorage.googleapis.com/v0/b/${AppConfig.firebase.projectId}.appspot.com/o/${encodeURIComponent(currentItem.image)}?alt=media`} /> */}
                            </div>
                            <div className="column">
                                <div className="name">{currentItem.name}</div>

                                <small style={{ color: "#888" }}>{hubStoresById && hubStoresById[currentItem.store_id] && hubStoresById[currentItem.store_id].name}</small>
                                <div className="price">{this.getPriceRange(currentItem)} บาท</div>
                                <div className="description">{currentItem.description}</div>
                                <ProductOptions product={currentItem} cartOptions={this.state.cartOptions} onOptionChange={(option) => this.setState({ selectedOption: option })} onExtraChange={extras => this.setState({ selectedExtra: extras })} />
                                {/* <div className="product-note">
                                    <label>หมายเหตุ</label>
                                    <span>คุณสามารถใส่ข้อมูลเพิ่มเติมได้ที่นี่ เช่น อาหารที่แพ้ ไม่กินเผ็ด ไม่ใส่ผัก เป็นต้น</span>
                                    <textarea placeholder="ตัวอย่าง: ไม่เผ็ด ไม่ใส่ผัก" onChange={(e) => { this.setState({ selectedNote: e.currentTarget.value }) }} value={selectedNote}></textarea>
                                </div> */}
                                <div className="mt20 has-text-centered dialog-footer">
                                    {!currentItem.isSoldout && <div className="amount-wrapper">
                                        <button className="add-button" onClick={() => this.setState({ selectedAmount: selectedAmount - 1 <= 0 ? 1 : selectedAmount - 1 })} disabled={!this.state.selectedOption && this.state.cartOptions && this.state.cartOptions.length > 0}><i className="fa fa-minus" /></button>
                                        <input type="text" value={selectedAmount} onChange={(e) => this.setState({ selectedAmount: e.target.value })} disabled={!this.state.selectedOption && this.state.cartOptions && this.state.cartOptions.length > 0} />
                                        <button className="add-button" onClick={() => this.setState({ selectedAmount: selectedAmount + 1 })} disabled={!this.state.selectedOption && this.state.cartOptions && this.state.cartOptions.length > 0}><i className="fa fa-plus" /></button>
                                    </div>}
                                    <div className="columns is-gapless is-mobile ">
                                        <div className="column">
                                            <button className="button is-primary is-outlined is-fullwidth" onClick={() => { this.setState({ selectedOption: null }); this.hideDescription(); mpTrackEvent('Menu Back Button', { "cart size": order.carts.length, "cart value": order.sumProductPrice }) }}>เมนูอื่น</button>
                                        </div>
                                        {!currentItem.isSoldout &&
                                            <div className="column">
                                                <button className="button is-success  is-fullwidth" onClick={() => this.addToCartWithOptions()} disabled={!this.state.selectedOption && this.state.cartOptions && this.state.cartOptions.length > 0}><i className="fa fa-plus" />&nbsp;เพิ่มลงตะกร้า</button>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>




                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={() => { this.setState({ selectedOption: null }); this.hideDescription() }}></button>
                </div>
            }

            {
                this.state.showCartAdded && <div className={`notice-cart-added animated ${this.state.showCartAdded ? 'fadeInDown' : 'fadeOutUp'}`}>
                    <a onClick={() => {
                        // this.getDistance(); 
                        this.hideDescription()
                        this.calculateDeliverCost()
                        this.showSummary();
                        window.scrollTo(0, 0); firebase.analytics().logEvent('page_view', { page_title: `Checkout-${this.props.current_store}` })
                    }}><i className="fa fa-shopping-bag"></i>
                        เพิ่มลงตะกร้าเรียบร้อยแล้ว</a>
                </div>
            }

            {
                this.state.showPayment && <div className="modal is-active payment-dialogs">
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        <h2 className="title">วิธีการชำระเงิน</h2>

                        <div className=" has-text-centered">
                            <button className="button is-success is-fullwidth mt10" onClick={() => this.setState({ order: { ...order, paymentMethod: "transfer" }, showPayment: false })} style={{ justifyContent: 'left' }}><i className="fa fa-university" />&nbsp;โอนเงิน</button>
                            <button className="button is-success is-fullwidth mt10" onClick={() => this.setState({ order: { ...order, paymentMethod: "cod" }, showPayment: false })} style={{ justifyContent: 'left' }}><i className="fa fa-money" />&nbsp;ชำระเงินปลายทาง/เงินสด</button>
                        </div>
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({ showOption: false })}></button>
                </div>
            }

            {
                this.state.showOption && <div className="modal is-active option-dialogs">
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        <h2 className="title">ตัวเลือก</h2>
                        {this.state.cartOptions.map((item) =>
                            <div key={item.option.name} className={`item-option ${item.option.isSoldout && 'is-soldout'}`}>
                                <div className="level is-mobile">
                                    <div className="level-left">
                                        {/* <button className="add-button" onClick={() => this.updateOption(item.option, item.amount - 1)} disabled={item.option.isSoldout}><i className="fa fa-minus" /></button>
                                        <input type="text" value={item.amount} onChange={(e) => this.updateOption(item, e.target.value)} disabled={item.option.isSoldout} />
                                        <button className="add-button" onClick={() => this.updateOption(item.option, item.amount + 1)} disabled={item.option.isSoldout}><i className="fa fa-plus" /></button> */}
                                        <input type="radio" name="options" checked={item.option.name === this.state.selectedOption} onClick={() => this.setState({ selectedOption: item.option.name })} disabled={item.option.isSoldout} />
                                    </div>
                                    <div className="level-left" style={{ width: "calc(100% - 110px)" }}>
                                        {item.option.name}{item.option.price ? ` (${item.price + item.option.price})` : ''} {item.option.isSoldout && "(หมด)"}
                                    </div>
                                </div>
                            </div>)}
                        {!currentItem.isSoldout && <div className="mt20 has-text-centered">
                            <button className="button is-success" onClick={() => this.addToCartWithOptions()} disabled={!this.state.selectedOption && this.state.cartOptions && this.state.cartOptions.length > 0}><i className="fa fa-plus" />&nbsp;เพิ่มลงตะกร้า</button>
                        </div>}
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({ selectedOption: null, showOption: false })}></button>
                </div>
            }

            {
                this.state.showDeliverMap &&
                <div className="modal is-active map-dialogs">
                    <div className="modal-background"></div>
                    <div className="modal-content" style={{ overflow: 'hidden' }}>
                        <AsyncMap
                            google={this.props.google}
                            zoom={this.state.zoom}
                            mapPosition={this.state.mapPosition}
                            markerPosition={this.state.markerPosition}
                            onPlaceSelected={this.onPlaceSelected}
                            googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + googleAPIKey + "&libraries=geometry,drawing,places"}
                            onCenterChanged={this.onCenterChanged}
                            onMapDragEnd={this.onMarkerDragEnd}
                            mapRef={(mapRef) => { this.mapRef = mapRef }}
                            deliverAddress={this.state.deliverAddress}
                            loadingElement={
                                <div style={{ height: `100%` }} />
                            }
                            containerElement={
                                <div style={{ height: '100vh' }} />
                            }
                            mapElement={
                                <div style={{ height: `100%` }} />
                            }
                        />
                        <div className="map-deliver-address">
                            <span style={{ padding: 0, margin: 0 }}>{this.state.deliverAddress.address}</span><br />
                            <button className="button is-small is-success is-fullwidth mt5" onClick={() => this.confirmAddress(true)}>ส่งที่นี่</button>
                        </div>
                        <button className="button my-location is-success" onClick={() => this.updateLocation(this.state.currentLocation)}><i className="fa fa-location-arrow"></i></button>
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={() => this.setState({ showDeliverMap: false })}></button>
                </div>
            }

            {this.state.showNoteDialog && this.renderNoteDialog()}
        </div >
    }
}
const mapStateToProps = ({ users, line_user, current_store, app_params, stores_map }) => ({ users, line_user, current_store, app_params, stores_map });
export default withRouter(connect(mapStateToProps, actions)(Ordering))
