import React, { useState } from 'react';
import { extraHint } from '../Helper';

export function ProductOptions(props) {
    let [selectedOption, setSelectedOption] = useState(null)
    let [selectedExtra, setSelectedExtra] = useState({})
    let { product, cartOptions, onOptionChange, onExtraChange } = props

    function toggleSelectedExtra(extra, item) {
        if (!selectedExtra) selectedExtra = {}
        selectedExtra[extra.title] = selectedExtra[extra.title] || []
        if (!selectedExtra[extra.title].includes(item)) {
            if (extra.maximum && selectedExtra[extra.title].length >= extra.maximum)
                selectedExtra[extra.title] = selectedExtra[extra.title].filter(x => x !== selectedExtra[extra.title][0])
            selectedExtra[extra.title].push(item)
        }
        else
            selectedExtra[extra.title] = selectedExtra[extra.title].filter(x => x !== item)

        setSelectedExtra(selectedExtra)

        onExtraChange && onExtraChange(selectedExtra)
    }

    function isSelectedExtra(extra, item) {
        if (!selectedExtra) selectedExtra = {}
        selectedExtra[extra.title] = selectedExtra[extra.title] || []

        return selectedExtra[extra.title].includes(item)
    }

    return <>
        {cartOptions && cartOptions.length > 1 && <div className="item-options">
            <div>ตัวเลือก</div>
            {Array.isArray(cartOptions) && cartOptions.map((item, index) =>
                <div key={index + item.option.name} className={`item-option ${item.option.isSoldout && 'is-soldout'}`}>
                    {/* <button className="add-button" onClick={() => this.updateOption(item.option, item.amount - 1)} disabled={item.option.isSoldout}><i className="fa fa-minus" /></button>
    <input type="text" value={item.amount} onChange={(e) => this.updateOption(item, e.target.value)} disabled={item.option.isSoldout} />
    <button className="add-button" onClick={() => this.updateOption(item.option, item.amount + 1)} disabled={item.option.isSoldout}><i className="fa fa-plus" /></button> */}
                    <div onClick={() => {
                        if (!item.option.isSoldout) {
                            setSelectedOption(item.option.name)
                            if (onOptionChange) onOptionChange(item.option.name)
                        }
                    }}>
                        <input type="radio" name="options" checked={item.option.name === selectedOption} disabled={item.option.isSoldout} readOnly />
                        <div className="option-name">
                            {item.option.name} {item.option.isSoldout && "(หมด)"}
                        </div>
                        <div className="price">
                            {`${product.price + (item.option.price || 0)}`} บาท
                    </div>
                    </div>
                </div>)}
        </div>}
        {product.extra_options && product.extra_options.map(extra => <div className="item-options" key={extra.title}>
            {extra.options && extra.options.length > 1 && <div className="extra-title">{extra.title} <span className="extra-hint">{extraHint(extra)}</span></div>}

            {extra.options && extra.options.length > 1 && Array.isArray(extra.options) && extra.options.map((item, index) =>
                <div key={index + item.name} className={`item-option ${item.isSoldout && 'is-soldout'}`}>
                    {/* <button className="add-button" onClick={() => this.updateOption(item, item.amount - 1)} disabled={item.isSoldout}><i className="fa fa-minus" /></button>
    <input type="text" value={item.amount} onChange={(e) => this.updateOption(item, e.target.value)} disabled={item.isSoldout} />
    <button className="add-button" onClick={() => this.updateOption(item, item.amount + 1)} disabled={item.isSoldout}><i className="fa fa-plus" /></button> */}
                    <div onClick={() => !item.isSoldout && toggleSelectedExtra(extra, item)}>
                        <input type="checkbox" name="options" checked={isSelectedExtra(extra, item)} disabled={item.isSoldout} readOnly />
                        <div className="option-name">
                            {item.name} {item.isSoldout && "(หมด)"}
                        </div>
                        {item.price > 0 && <div className="price">
                            {`+${item.price}`} บาท
                    </div>}
                        {item.price < 0 && <div className="price">
                            {`${item.price}`} บาท
                    </div>}
                    </div>
                </div>)}
        </div>)}
    </>
}