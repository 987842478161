import FirebaseModel from './FirebaseModel';

export default class Store extends FirebaseModel {

}

Store.collection = 'stores';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();