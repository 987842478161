import StoreFirebaseModel from './StoreFirebaseModel';

export default class StoreDayoff extends StoreFirebaseModel {

}

StoreDayoff.collection = 'dayoffs';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();