import React, { Component } from 'react';

class DeliveryTier extends Component {

    updateKm(km) {
        let { data } = this.props
        data.km = km;
        this.props.onChange && this.props.onChange(data)
    }

    validateKm() {
        let { data } = this.props
        if (data.km < this.props.startkm) {
            this.updateKm(this.props.startkm + 1)
        }
    }

    render() {
        let { data } = this.props
        return (
            <>
                <span className="km-text"><span className="startkm">{this.props.startkm > 0 ? `${this.props.startkm}.0` : 0}</span> กม. ถึง</span>
                <input className="input" type="number" value={data.km} onChange={e => { this.updateKm(e.currentTarget.value) }} onBlur={() => this.validateKm()} />
                กม.ละ
                <input className="input" type="number" value={data.price} onChange={e => { data.price = e.currentTarget.value; this.props.onChange && this.props.onChange(data) }} />
                บาท
                <label className="checkbox"><input type="checkbox" checked={data.useLalamove} onChange={e => { data.useLalamove = !data.useLalamove; this.props.onChange && this.props.onChange(data) }} />ใช้ Lalamove</label>
            </>
        );
    }
}

export default DeliveryTier;