import * as Sentry from '@sentry/browser';
import React from "react"
import { withRouter } from "react-router";
import * as firebase from 'firebase';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as AppConfig from '../AppConfig.json';
import { withCookies } from 'react-cookie';
import { CloudImage } from './CloudImage';
import StoreDayoff from '../models/StoreDayoff';
import moment from 'moment'
import LineAddNotify from './LineAddNotify';
import { isNative, sendRN, mpTrackEvent } from '../Helper';
import * as mixpanel from 'mixpanel-browser';

class StoreListChiangMai extends React.Component {
    constructor() {
        super()
        this.state = { storeDayOff: {}, loading: true }
        let storeList = ['locall.lamchang']
        this.storeList = storeList
    }

    async componentDidMount() {

        let storeData = this.props.stores_map
        if (!storeData) {
            var db = firebase.firestore();
            let qs = await db.collection('stores').get();
            qs.forEach(x => {
                let data = x.data();
                data.id = x.id;
                storeData[x.id] = data
            })

        }
        this.setState({ storeData })

        let storeDayOff = {}
        for (let s in this.storeList) {
            let day = moment().isoWeekday() % 7;
            storeDayOff[this.storeList[s]] = (storeData[this.storeList[s]].openday && !storeData[this.storeList[s]].openday[day])
            let dayoffSnap = await StoreDayoff.setStore(this.storeList[s]).getCollection().get()
            let today = moment();
            dayoffSnap.forEach((doc) => {

                var childKey = doc.id;
                var childData = doc.data();
                childData.id = childKey;

                if (moment(childData.startDate).isSame(today, 'day')) {
                    storeDayOff[this.storeList[s]] = true;
                }
            });

        }



        let storeRaining = {}
        // storeRaining['locall'] = true;
        // storeRaining['locall.yaowarat'] = true;

        this.setState({ storeDayOff, storeRaining, loading: false })

        firebase.analytics().logEvent('page_view', { page_title: `Hub List` })


    }

    loadStore(store) {
        if (AppConfig.build === 'locall') {
            mixpanel.track("Select restaurant hub", { "Restaurant Hub": store });
        }

        if (isNative()) {
            sendRN({ action: "loadStore", params: { store } })
        }
        else {
            this.props.setCurrentStore(store)
        }
    }

    render() {


        let { storeDayOff, loading, storeRaining, storeData } = this.state
        if (loading || !storeData) return <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /></div>

        // 
        return <div>
            <div className="header-nav">
                <div style={{ padding: '10px' }}><a onClick={() => { this.props.history.push('/'); this.props.setCurrentCity(null) }}><i className="fas fa-chevron-left"></i>&nbsp;เลือกเมืองอื่น</a></div>
            </div>
            <div className="store-application store-list" style={{ padding: '70px 15px' }}>
                <LineAddNotify lineAccount="locall.chiangmai" />

                <div>
                    <div className="quicksearch front-search" style={{ width: 'calc(100% - 80px)', display: 'inline-block' }}>
                        <input type="text" className="input" value={this.state.query} onChange={(e) => {
                            if (e.target.value.length >= 4) {
                                mpTrackEvent("Search", {
                                    "Search Term": e.target.value,
                                });
                            }

                            this.setState({ query: e.target.value })
                        }} placeholder="ค้นหาจากชื่อร้าน/ชื่ออาหาร" />
                        <i className="fa fa-search" />
                        {this.state.query && <button className="search-reset-button" onClick={(e) => { this.setState({ query: null }) }}><i className="fa fa-times" /></button>}
                    </div>
                    <button className="button is-small is-primary" style={{ width: 70, height: 30, borderRadius: 20, paddingLeft: 5 }} onClick={() => this.props.history.push(`/search/${this.state.query}`)}>ค้นหา</button>
                </div>
                {/* <div><a onClick={() => this.props.setCurrentStore('locall')}><CloudImage src="hubs/locall.jpg" /></a></div>
            <div><a onClick={() => this.props.setCurrentStore('locall.yaowarat')}><CloudImage src="hubs/locall.yaowarat.jpg" /></a></div> */}
                {this.storeList.filter(x => !(storeDayOff[x] || (storeData[x].openday && !storeData[x].openday[moment().isoWeekday()])) && storeData[x].is_active).map(x => <div key={x} className={`${(storeDayOff[x] || (storeData[x].openday && !storeData[x].openday[moment().isoWeekday()])) && 'closed'} store-${x} ${(storeRaining[x] || storeData[x].show_raining) && 'raining'}`}><a onClick={() => this.loadStore(x)}><CloudImage src={storeData[x].image || `hubs/${x}.jpg`} /></a></div>)}
                {this.storeList.filter(x => (storeDayOff[x] || (storeData[x].openday && !storeData[x].openday[moment().isoWeekday()])) && storeData[x].is_active).map(x => <div key={x} className={`${(storeDayOff[x] || (storeData[x].openday && !storeData[x].openday[moment().isoWeekday()])) && 'closed'} store-${x} ${(storeRaining[x] || storeData[x].show_raining) && 'raining'}`}><a onClick={() => this.loadStore(x)}><CloudImage src={storeData[x].image || `hubs/${x}.jpg`} /></a></div>)}
                <div><a ><CloudImage src={`hubs/locall.wualai.jpg`} /></a></div>

            </div>
        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store, app_params }) => ({ current_entity, current_store, app_params });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(StoreListChiangMai)))
