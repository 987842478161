import FirebaseModel from './FirebaseModel';

export default class City extends FirebaseModel {

}

City.collection = 'cities';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();