import * as Sentry from '@sentry/browser';
import React from "react"
import { withRouter } from "react-router";
import * as firebase from 'firebase';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import * as AppConfig from '../AppConfig.json';
import { withCookies } from 'react-cookie';
import { CloudImage } from './CloudImage';
import { sendRN, isNative, mpTrackEvent, sort } from '../Helper';
import StoreDayoff from '../models/StoreDayoff';
import moment from 'moment'
import LineAddNotify from './LineAddNotify';
import * as mixpanel from 'mixpanel-browser';
// mixpanel.init("b651ba333baa36378e4387e74c1587fc"); mixpanel.track("Hello World");

class DriverView extends React.Component {
    constructor() {
        super()
        this.state = { loading: true }
    }

    async componentDidMount() {
        firebase.analytics().logEvent('page_view', { page_title: `Driver View` })

        let { order_id, store } = this.props.match.params

        if (order_id) {
            let qs = await firebase.firestore().collection('stores').doc(store).collection('orders').doc(order_id).get()

            if (qs.exists) {
                let order = qs.data()
                this.setState({ order, loading: false })
            }
        }
    }


    render() {
        let { order, loading } = this.state
        let { order_id, store } = this.props.match.params

        if (loading) return <div className="has-text-centered p20"><img src="/loading.gif" style={{ "width": "60px" }} /></div>

        return <div>
            <div className="store-application store-list" style={{ padding: '30px 15px' }}>
                <b>{order.customer.name}</b><br />
                <b>{order.customer.tel}</b><hr />
                {order.markerPosition && <div>
                    <a className="location-item" href={`http://maps.google.com/maps?daddr=${order.markerPosition.lat},${order.markerPosition.lng}&amp;ll=`} target="_blank">
                        {order.deliverAddress.route} {order.deliverAddress.city} {order.deliverAddress.area} {order.deliverAddress.state} {order.deliverAddress.postcode}
                        {/* {JSON.stringify(order.deliverAddress)} */}
                    </a><br />
                    <a className="location-item" href={`http://maps.google.com/maps?daddr=${order.markerPosition.lat},${order.markerPosition.lng}&amp;ll=`} target="_blank">
                        {`http://maps.google.com/maps?daddr=${order.markerPosition.lat},${order.markerPosition.lng}&amp;ll=`}
                    </a><br />
                    {`${order.markerPosition.lat},${order.markerPosition.lng}`}
                </div>}
                <hr />
                {order.status === 'ontheway' && <button className="button is-success" onClick={() => {
                    this.setState({ loading: true })
                    firebase.firestore().collection('stores').doc(store).collection('orders').doc(order_id).update({ status: "delivered" })
                    this.setState({ loading: false })
                }}>ส่งเสร็จแล้ว</button>}
            </div>
        </div>
    }
}

const mapStateToProps = ({ current_entity, current_store }) => ({ current_entity, current_store });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(DriverView)))
