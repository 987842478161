import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import moment from 'moment'

import * as firebase from 'firebase';
import BaseEditor from './BaseEditor';
import FormField from './FormField';
import StoreCategory from '../models/StoreCategory'
import { CloudImage } from './CloudImage';

// const databaseRef = firebase.database().ref();
// const itemRef = databaseRef.child("items")
var db = firebase.firestore();

class StoreCategoryEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "หมวดหมู่สินค้า"
        this.canCreate = true;
        this.listFields = ['name', 'sort_order']
        this.editFields = ['name', 'sort_order']
        this.entityInfo = {
            name: 'String',
            sort_order: 'Float',
        }
    }

    componentDidMount() {
        this.dataRef = StoreCategory.getCollection(this.props.current_store);
        super.componentDidMount()
    }

    renderEditor() {
        let { current_entity } = this.props
        return <div>
            <div className="field">
                <label className="label is-small">ชื่อ</label>
                <div className="control">
                    <input className="input is-small"
                        name="name"
                        type="text"
                        placeholder=""
                        onChange={this.handleDescriptionChange}
                        value={current_entity.name} />
                </div>
            </div>
            <div className="field">
                <label className="label is-small">เรียงลำดับ</label>
                <div className="control">
                    <input className="input is-small"
                        name="sort_order"
                        type="number"
                        placeholder=""
                        onChange={this.handleDescriptionChange}
                        value={current_entity.sort_order} />
                </div>
            </div>
            {/* 
            <div className="field">
                <label className="label is-small">ภาพปก</label>
                <div className="control">
                    <input type="file" onChange={this.handleFileChange} />
                    <CloudImage src={current_entity.image} className="thumbnail" />
                </div>
            </div> */}

            <div className="mt10">
                <button className="button is-primary is-fullwidth  " onClick={() => this.save(current_entity)} disabled={!current_entity.name}>SAVE</button>
            </div>
        </div >
    }
}


const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(StoreCategoryEditor))