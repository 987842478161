
import React from "react"
import ReactTags from 'react-tag-autocomplete'
import decamelize from 'decamelize'
import * as firebase from 'firebase';

export default class TagsInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: [

            ], suggestions: [
            ]
        }
        this.fetchData()
    }

    fetchData() {
        let db = firebase.firestore()

        let entity = decamelize(this.props.entity)

        fetch(`/api/v2/` + entity + "?filters=" + JSON.stringify(this.props.dependences)).then(response => response.json()).then(data => {
            let prepareData = []
            data.forEach(element => {
                prepareData.push({ id: element._id, name: element.label || element.name || element.title })
            });
            this.setState({ suggestions: prepareData })
        });
    }

    handleDelete(i) {
        const datas = this.state.datas.slice(0)
        datas.splice(i, 1)
        this.setState({ datas })
        if (this.props.onChange) this.props.onChange(null, this.props.name, datas)
    }

    handleAddition(tag) {
        const datas = [].concat(this.state.datas, tag)
        this.setState({ datas })
        console.log(datas)

        // let keys = Object.keys(datas);
        // let values = keys.map(function (v) { return datas[v]["id"] });
        if (this.props.onChange) this.props.onChange(null, this.props.name, datas)
    }

    render() {
        let datas = []
        if (this.props.value) datas = this.props.value.map((v) => { return { id: v.id || v._id, name: v.label || v.name || v.title || "" } })
        // if (this.props.value) datas = this.props.value;
        this.state.datas = datas;

        console.log(this.state.datas, this.props.value, datas)
        return ([
            <ReactTags
                tags={datas}
                suggestions={this.state.suggestions}
                handleDelete={this.handleDelete.bind(this)}
                handleAddition={this.handleAddition.bind(this)}
                allowNew={false}
                minQueryLength={1} />,
        ]
        )
    }
}
