import StoreFirebaseModel from './StoreFirebaseModel';

export default class StoreBanner extends StoreFirebaseModel {

}

StoreBanner.collection = 'banners';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();