import StoreFirebaseModel from './StoreFirebaseModel';

export default class StoreCategory extends StoreFirebaseModel {

}

StoreCategory.collection = 'category';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();