import React from "react"
import DatePicker from "react-datepicker";
import { Editor as TinyMCE } from '@tinymce/tinymce-react';
import FieldMultipleInput from "./FieldMultipleInput";
import TemplateConditionChoicesInput from "./TemplateConditionChoicesInput";
import TagsInput from "./TagsInput";

import moment from "moment-timezone"
moment().tz("Asia/Bangkok").format();

export default class FieldInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = { fileOrUrlType: "url" }

        this.onChange = this.onChange.bind(this)

        this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
    }

    async componentDidMount() {

        let { value, type } = this.props;
        if (type === 'Text' && value) {
            this.updateTextInput(value)
        }
    }


    componentDidUpdate() {
        let { value, type } = this.props;
        if (type === 'Text' && value) {
            this.updateTextInput(value)
        }

    }

    onChange(e, name, value) {

        if (e && e.target.type === "file") {
            this.setState({
                selectedFilesValue: e.target.value,
                selectedFiles: e.target.files,
                selectedPreviewUrl: URL.createObjectURL(e.target.files[0])
            })

            this.props.onFileChange(e, name, value)
        }

        if (e && e.target.type === "checkbox") {
            // console.log(value, e.target.checked)
            this.props.onChange(e, name, e.target.checked)
        }
        else if (this.props.onChange) {
            this.props.onChange(e, name, value)
        }
    }


    _uploadImageCallBack(file) {
        // long story short, every time we upload an image, we
        // need to save it to the state so we can get it's data
        // later when we decide what to do with it.

        // Make sure you have a uploadImages: [] as your default state
        let uploadedImages = this.state.uploadedImages || [];

        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }

        uploadedImages.push(imageObject);

        this.setState({ uploadedImages: uploadedImages })

        // We need to return a promise with the image src
        // the img src we will use here will be what's needed
        // to preview it in the browser. This will be different than what
        // we will see in the index.md file we generate.
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: imageObject.localSrc } });
            }
        );
    }


    render() {
        let { type, value, className, name, dependences } = this.props;
        let type_info = type;
        let form = <input type="text" className={className + " field input " + name} name={name} value={value} onChange={this.onChange} placeholder="" />
        value = value || ""

        try {
            if (typeof type === 'object') {
                if (type.type === 'List') {
                    form = <FieldMultipleInput type={type} className="form-control" name={name} value={value} onChange={this.onChange} dependences={dependences} />
                }
                else if (type.type === "Choices") {
                    if (Array.isArray(type.choices)) {
                        form = <select className={className + " field input " + name} name={name} value={value} onChange={this.onChange} placeholder="">
                            {type.choices.map(item => <option key={item} value={item}>{item}</option>)}
                        </select>
                    }
                    else {
                        let choices = [];
                        for (let c in type.choices) {
                            choices.push(<option value={c}>{type.choices[c]}</option>)
                        }
                        form = <select className={className + " field input " + name} name={name} value={value} onChange={this.onChange} placeholder="">
                            {choices}
                        </select>
                    }
                }
                else if (type.type === "TemplateConditionChoices") {


                    form = <TemplateConditionChoicesInput type={type} className="form-control" name={name} value={value} onChange={this.onChange} dependences={dependences} />
                }
                else {
                    type = type.type;
                }
            }

            if (type === 'Date') {
                form = <input type="date" className={className + " field input " + name} name={name} value={moment(value).format('YYYY-MM-DD')} onChange={this.onChange} placeholder="" />
            }
            if (type === 'Boolean') {
                form = <input type="checkbox" className={className + " field checkbox " + name} name={name} value={true} checked={value} onChange={this.onChange} placeholder="" />
            }
            if (type === 'DateTime') {
                let dateValue = value;
                // form = <input type="date" className={className + " field input " + name} name={name} value={moment(value).format('YYYY-MM-DD')} onChange={this.onChange} placeholder="" />
                if (value && typeof value === 'string') {
                    dateValue = moment(value).toDate()
                }

                form = <DatePicker
                    className={className + " field input " + name}
                    name={name}
                    selected={dateValue}
                    onChange={(v) => { this.onChange(null, name, v) }
                    }
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
            }
            if (type === 'Objects') {
                if (type_info.entity) {
                    form = <TagsInput className={className + " field input " + name} name={name} value={value} onChange={this.onChange} entity={type_info.entity} dependences={dependences} />
                }
            }
            if (type === 'FileUrl') {
                form = [
                    <div>
                        <input type="radio" name={name + "__type"} onChange={this.onChange} value="url" onClick={() => this.setState({ fileOrUrlType: "url" })} checked={this.state.fileOrUrlType === "url"} />&nbsp;Url&nbsp;&nbsp;
                        <input type="radio" name={name + "__type"} onChange={this.onChange} value="file" onClick={() => this.setState({ fileOrUrlType: "file" })} checked={this.state.fileOrUrlType === "file"} />&nbsp;File Upload
                    </div>
                ]
                if (this.state.fileOrUrlType === "url") {
                    form.push(<input type="text" className={className + " field input " + name} name={name} value={value} onChange={this.onChange} placeholder="" />)
                }
                else {
                    form.push(<div className="upload-button">
                        <input type="file" className={className + " field input " + name} name={name + "__file"} value={this.state.selectedFilesValue} onChange={this.onChange} placeholder="" />
                    </div>)
                    // if (this.state.selectedFilesValue) {
                    //     form.push(<img src={this.state.selectedPreviewUrl} />)
                    // }
                }
            }
            if (type === 'ImageFile') {
                form = <div className="upload-button">
                    {!this.state.selectedPreviewUrl && <div style={{ backgroundImage: `url(${value})` }} className="preview-image"></div>}
                    {this.state.selectedPreviewUrl && <div style={{ backgroundImage: `url(${this.state.selectedPreviewUrl})` }} className="preview-image"></div>}
                    <input type="file" name={name + "__file"} value={this.state.selectedFilesValue} onChange={this.onChange} placeholder="" />
                </div>

            }
            if (type === 'Textarea') {
                form = <textarea className={className + " field input " + name} name={name} value={value} onChange={this.onChange} placeholder="" />
            }
            if (type === 'TinyMCE') {
                form = <TinyMCE
                    apiKey="6csaz0aupip8c5vk6na3zhcwbm5rnoauk3h1jaapgtfg65mu"
                    initialValue={value}
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify table image | bullist numlist outdent indent | removeformat fullscreen '
                    }}
                    onChange={(e) => { this.onChange(null, name, e.target.getContent()) }}
                />
            }
            if (type === 'Float' || type === 'Integer') {
                form = <input type="number" className={className + " field input " + name} name={name} value={value} onChange={this.onChange} placeholder="" />
            }


        } catch (e) { console.log(e) }

        return form
    }
}