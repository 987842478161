import StoreFirebaseModel from './StoreFirebaseModel';

export default class StoreOrder extends StoreFirebaseModel {

}

StoreOrder.collection = 'orders';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();