import React from "react"
import moment from "../moment-tz"
import { withRouter } from "react-router";

import { connect } from 'react-redux';
import * as firebase from 'firebase';
import * as actions from '../actions/index'
import { withCookies } from 'react-cookie';
import Store from '../models/Store';
import { CloudImage } from './CloudImage';
import * as AppConfig from '../AppConfig.json';
import { isStaff, isAdmin, isSuperAdmin } from "../Helper";

let storageRef = firebase.storage().ref();

class StoreConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.packages = {
            'FREE': { price: 0, order_count: 30, branch_count: 1, admin_count: 1 },
            'SEED': { price: 330, order_count: 1000, branch_count: 1, admin_count: 1 },
            'STANDARD': { price: 1600, order_count: 5000, branch_count: 2, admin_count: 5 },
            'PRO': { price: 4800, order_count: 15000, branch_count: 5, admin_count: 10 },
            'SME': { price: 12800, order_count: 30000, branch_count: 10, admin_count: 20 }
        }

        this.handleFileChange = this.handleFileChange.bind(this)
    }

    async componentDidMount() {

        let { current_store } = this.props
        if (this.props.store) {
            current_store = this.props.store
        }

        if (current_store) {
            let store = await Store.get(current_store)
            if (store.exists) {
                console.log("Store data", store.data())
                let storeData = store.data()
                storeData.id = store.id
                if (!storeData.branches) {
                    storeData.branches = [{ name: "สาขาที่ 1", lat: "", lng: "" }]
                }
                if (!storeData.bank_accounts) {
                    storeData.bank_accounts = [{ bank: "", branch: "", account_name: "", account_no: "" }]
                }
                if (!storeData.package) {
                    storeData.package = [{ ...this.packages['FREE'], name: "FREE" }]
                }
                storeData.preorder_open_time = storeData.preorder_open_time || storeData.open_time;
                storeData.preorder_close_time = storeData.preorder_close_time || storeData.close_time;

                this.setState({ storeData })
            }

            else {
                this.setState({ storeData: false })
            }
        }
    }

    componentDidUpdate() {

    }


    handleDescriptionChange = (e, name, value) => {
        let updateState = this.state.storeData;
        if (!name) {
            name = e.target.name
        }
        if (!value) {
            value = e.target.value
        }
        if (e && e.target.type === "number") {
            eval(`updateState.${name} = Number.parseFloat(value)`)
        }
        else if (e && e.target.type === 'checkbox') {
            eval(`updateState.${name} = !updateState.${name}`)
        }
        else {
            eval(`updateState.${name} = value`)
        }
        this.setState({ storeData: updateState })
    }

    toggleAdmin(email) {
        let updateState = this.state.storeData;
        if (updateState.admin && updateState.admin.includes(email)) {
            updateState.admin = updateState.admin.filter(x => x != email)
        }
        else {
            if (!updateState.admin) updateState.admin = []
            updateState.admin.push(email)
        }
        this.setState({ storeData: updateState })
    }


    async submit() {
        let { storeData } = this.state
        let { current_store } = this.props
        if (this.props.store) {
            current_store = this.props.store
        }

        let permitted_params = ['name', 'branches', 'open_time', 'close_time', 'open_day', 'bank_accounts', 'packages', 'owner', 'preorder_open_time', 'preorder_close_time', 'image', 'openday', 'admin']
        let updataData = {}
        for (let p in permitted_params) {
            if (storeData[permitted_params[p]]) {
                updataData[permitted_params[p]] = storeData[permitted_params[p]]
            }
        }

        // console.log(updataData)

        await Store.update(current_store, updataData)
        alert("บันทึกการตั้งค่าเรียบร้อยแล้ว")


    }

    formValidate() {
        return true
        let state = this.state
        return state.store_name && state.store_id && state.firstname && state.lastname && state.email && state.tel && state.open_time && state.close_time && state.package
    }

    getDeliverTimes(date) {
        let dateList = []

        let currentTime = moment('00:00', "HH:mm")
        let currentEndTime = currentTime.clone().add(30, "minutes");
        let endTime = moment('23:59', "HH:mm")
        // console.log("getDeliverTime", currentTime.format(), endTime.format(), currentTime < endTime)
        while (currentTime < endTime) {
            // console.log(currentTime.format());
            dateList.push(`${currentTime.format("HH:mm")}`);
            currentTime = currentEndTime
            currentEndTime = currentTime.clone().add(30, "minutes");
        }

        return dateList.map(x => <option key={x} value={x}>{x}</option>)
    }

    async handleFileChange(event) {
        // this.setState({
        //     file: event.target.files[0],
        //     fileObjURL: URL.createObjectURL(event.target.files[0]),
        //     loading: true
        // })

        // var fr = new FileReader();
        // fr.onload = function (e) {
        //     let base64Image = this.result.replace(/^data:image\/(png|jpeg);base64,/, "");
        //     $this.setState({ 'base64Image': base64Image, loading: false })
        // };
        // fr.readAsDataURL(event.target.files[0]);
        let fileInput = event.target
        let { current_store } = this.props
        let file = event.target.files[0];
        // let ext = file.name.split('.').pop();
        let path = `hubs/${current_store}/store/${moment().format('YYYYMMDDHHMMSS')}_${file.name}`

        let fileRef = storageRef.child(path);
        let uploadTask = fileRef.put(file)
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, (error) => {

                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;

                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                }
            }, () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    this.handleDescriptionChange(null, fileInput.name, downloadURL)
                });
            });
        // this.setState({
        //     loading: true,
        //     loading_message: "Start upload item image to database...."
        // })

    }

    render() {
        let { current_store } = this.props

        if (this.props.store) {
            current_store = this.props.store
        }
        if (!this.props.current_user) return <div className="title p20 has-text-centered"></div>;
        // 
        if (this.props.current_user && this.props.owner === false) return <div className="title p20 has-text-centered">คุณไม่มีสิทธิในการเข้าถึงข้อมูลส่วนนี้!<br />({this.props.current_user.email})</div>;

        let { storeData } = this.state
        if (!storeData) return <div className="has-text-centered p20"> <img src="/loading.gif" width="80" /></div>

        if (!storeData.openday) storeData.openday = {}

        let isHubAdmin = isSuperAdmin(this.props.current_user.email) //|| ['peangploy@satarana.com', 'sanon@satarana.com', 'ping@satarana.com', 'mekku.nk@gmail.com', 'klinsanity@gmail.com', 'yada@satarana.com'].includes(this.props.current_user.email)


        return <div className="store-config p15">
            <div className="container">
                <h1 className="title">ข้อมุลร้านค้า</h1>

                <hr />
                <div className="columns">
                    <div className="column">
                        <h3 className="subtitle">รายละเอียดร้าน</h3>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">ชื่อร้าน</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input
                                            name="name"
                                            value={storeData.name}
                                            onChange={this.handleDescriptionChange}
                                            className="input"
                                            type="text"
                                            placeholder=""
                                        />
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Store ID</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input
                                            name="store_id"
                                            value={storeData.id}
                                            onChange={this.handleDescriptionChange}
                                            className="input"
                                            type="text"
                                            placeholder=""
                                            readOnly
                                            disabled
                                        />
                                    </p>
                                    <p className="mt10">

                                        ลิงค์สำหรับใส่ ใน Rich menu ของ Line&nbsp;&nbsp;&nbsp;&nbsp;
<span className="hilight">{`${AppConfig.liffUrl}/${current_store}`}</span>
                                    &nbsp;&nbsp;<button className="button is-success is-small" onClick={() => { navigator.clipboard.writeText(`${AppConfig.liffUrl}/${current_store}`) }}> COPY</button>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">วันเปิดบริการ</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control is-expanded">
                                        <label><input className="checkbox" name="openday[0]" type="checkbox" onChange={this.handleDescriptionChange} checked={storeData.openday[0]} /> อา    </label>
                                        <label><input className="checkbox" name="openday[1]" type="checkbox" onChange={this.handleDescriptionChange} checked={storeData.openday[1]} /> จ    </label>
                                        <label><input className="checkbox" name="openday[2]" type="checkbox" onChange={this.handleDescriptionChange} checked={storeData.openday[2]} /> อ    </label>
                                        <label><input className="checkbox" name="openday[3]" type="checkbox" onChange={this.handleDescriptionChange} checked={storeData.openday[3]} /> พ    </label>
                                        <label><input className="checkbox" name="openday[4]" type="checkbox" onChange={this.handleDescriptionChange} checked={storeData.openday[4]} /> พฤ    </label>
                                        <label><input className="checkbox" name="openday[5]" type="checkbox" onChange={this.handleDescriptionChange} checked={storeData.openday[5]} /> ศ    </label>
                                        <label><input className="checkbox" name="openday[6]" type="checkbox" onChange={this.handleDescriptionChange} checked={storeData.openday[6]} /> ส    </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label"></label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control is-expanded">
                                        <label>
                                            <input
                                                name={`show_raining`}
                                                checked={storeData.show_raining}
                                                onChange={this.handleDescriptionChange}
                                                className="checkbox"
                                                type="checkbox"
                                                placeholder=""
                                            />
                                            &nbsp; แสดงข้อความช่วงเวลาฝนตก</label>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">เวลาเปิดร้าน</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control is-expanded">
                                        <div className="select is-fullwidth">
                                            <select
                                                name="open_time"
                                                value={storeData.open_time}
                                                onChange={this.handleDescriptionChange}>
                                                <option value={null}>ไม่ระบุ</option>
                                                {this.getDeliverTimes()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control is-expanded">
                                        <div className="select is-fullwidth">
                                            <select
                                                name="close_time"
                                                value={storeData.close_time}
                                                onChange={this.handleDescriptionChange}>
                                                <option value={null}>ไม่ระบุ</option>
                                                {this.getDeliverTimes()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">ช่วงเวลาส่งอาหาร Pre-Order</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control is-expanded">
                                        <div className="select is-fullwidth">
                                            <select
                                                name="preorder_open_time"
                                                value={storeData.preorder_open_time}
                                                onChange={this.handleDescriptionChange}>
                                                <option value={null} disabled>กรุณาเลือกเวลา</option>
                                                {this.getDeliverTimes()}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control is-expanded">
                                        <div className="select is-fullwidth">
                                            <select
                                                name="preorder_close_time"
                                                value={storeData.preorder_close_time}
                                                onChange={this.handleDescriptionChange}>
                                                <option value={null} disabled>กรุณาเลือกเวลา</option>
                                                {this.getDeliverTimes()}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="column">
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">รูปภาพ</label>
                            </div>
                            <div className="field-body">
                                <div>
                                    <input type="file" name={`image`} onChange={this.handleFileChange} /><br />
                                    {/* {storeData.image && <CloudImage src={storeData.image} className="thumbnail" style={{ maxHeight: '150px', width: 'auto' }} />} */}
                                    {storeData.image && <img src={storeData.image} className="thumbnail" style={{ maxHeight: '150px', width: 'auto' }} />}
                                </div>

                                {/* {this.state.file && <img src={this.state.fileObjURL} />} */}

                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <h3 className="subtitle is-5">รายละเอียดบัญชีสำหรับโอนเงิน</h3>
                {storeData.bank_accounts && storeData.bank_accounts.map((branch, index) => <div><div className="columns">
                    <div className="column">
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">ธนาคาร</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input
                                            name={`bank_accounts[${index}].bank`}
                                            value={branch.bank}
                                            onChange={this.handleDescriptionChange}
                                            className="input"
                                            type="text"
                                            placeholder=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">ชื่อบัญชี</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input
                                            name={`bank_accounts[${index}].account_name`}
                                            value={branch.account_name}
                                            onChange={this.handleDescriptionChange}
                                            className="input"
                                            type="text"
                                            placeholder=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">เลขที่</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input
                                            name={`bank_accounts[${index}].account_no`}
                                            value={branch.account_no}
                                            onChange={this.handleDescriptionChange}
                                            className="input"
                                            type="text"
                                            placeholder=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">สาขา</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input
                                            name={`bank_accounts[${index}].account_branch`}
                                            value={branch.account_branch}
                                            onChange={this.handleDescriptionChange}
                                            className="input"
                                            type="text"
                                            placeholder=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="column">
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">รูปภาพ QR</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input type="file" name={`bank_accounts[${index}].image`} onChange={this.handleFileChange} /><br />
                                        {/* {this.state.file && <img src={this.state.fileObjURL} />} */}
                                        {/* {branch.image && <CloudImage src={branch.image} className="thumbnail" style={{ maxHeight: '150px', width: 'auto' }} />} */}
                                        {branch.image && <img src={branch.image} className="thumbnail" style={{ maxHeight: '150px', width: 'auto' }} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                </div>)}
                <hr />

                <div className="branch-setting">
                    {storeData.branches && storeData.branches.map((branch, index) =>
                        <div>
                            <h3 className="subtitle is-5">สาขาที่ {index + 1}</h3>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">ชื่อสถานที่</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control is-expanded">
                                            <input
                                                name={`branches[${index}].name`}
                                                value={branch.name}
                                                onChange={this.handleDescriptionChange}
                                                className="input"
                                                type="text"
                                                placeholder=""
                                            />
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">ที่อยู่</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control is-expanded">
                                            <input
                                                name={`branches[${index}].address`}
                                                value={branch.address}
                                                onChange={this.handleDescriptionChange}
                                                className="input"
                                                type="text"
                                                placeholder=""
                                            />
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label"></label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control is-expanded">
                                            <input
                                                name={`branches[${index}].can_pickup`}
                                                checked={branch.can_pickup}
                                                onChange={this.handleDescriptionChange}
                                                className="checkbox"
                                                type="checkbox"
                                                placeholder=""
                                            />
                                            <label>&nbsp; เปิดให้มารับด้วยตนเองได้</label>
                                        </p>
                                    </div>


                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">พิกัดที่ตั้ง</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control is-expanded">
                                            <p className="control is-expanded">
                                                <input
                                                    name={`branches[${index}].lat`}
                                                    value={branch.lat}
                                                    onChange={this.handleDescriptionChange}
                                                    className="input"
                                                    type="text"
                                                    placeholder="latitude"
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control is-expanded">
                                            <p className="control is-expanded">
                                                <input
                                                    name={`branches[${index}].lng`}
                                                    value={branch.lng}
                                                    onChange={this.handleDescriptionChange}
                                                    className="input"
                                                    type="text"
                                                    placeholder="longitude"
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {storeData.package.branch_count <= storeData.branches.length &&
                        <div className="has-text-right mt10">
                            <button className="button is-info" onClick={() => { storeData.branches.push({}); this.forceUpdate() }}><i className="fa fa-plus"></i>&nbsp;เพิ่มสาขา</button>
                        </div>
                    }
                </div>
                <hr />

                {isHubAdmin &&
                    <div className="owner-setting">
                        <h3 className="subtitle is-5">ผู้ดูแลร้านค้า</h3>
                        <div className="table-container is-full">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th><label className="label">อีเมล</label></th>
                                        {/* <th><label className="label">Staff</label></th> */}
                                        <th><label className="label">Admin</label></th>
                                        <th><label className="label">ลบ</label></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {storeData.owner && storeData.owner.map((owner, index) =>
                                        <tr >

                                            <td className="control">
                                                <input
                                                    name={`owner[${index}]`}
                                                    value={owner}
                                                    onChange={this.handleDescriptionChange}
                                                    className="input"
                                                    type="text"
                                                    placeholder=""
                                                    style={{ maxWidth: 'calc(100vw - 150px)', width: '600px' }}
                                                />
                                            </td>
                                            {/* <td className="control">
                                                <label className="checkbox-container"><input type="checkbox" onChange={() => { this.toggleStaff(owner) }} checked={isStaff(storeData, owner)} /><span className="checkmark"></span></label>
                                            </td> */}
                                            <td className="control">
                                                <label className="checkbox-container"><input type="checkbox" onChange={() => { this.toggleAdmin(owner) }} checked={isAdmin(storeData, owner)} /><span className="checkmark"></span></label>
                                            </td>
                                            <td className="control">
                                                <button className="button is-small is-danger" onClick={() => { storeData.owner.splice(index, 1); this.forceUpdate() }}><i className="fa fa-times"></i>
                                                &nbsp;ลบ</button>
                                            </td>
                                        </tr>

                                    )}
                                </tbody>
                            </table>
                        </div>
                        {(storeData.package.admin_count <= storeData.owner.length || current_store.indexOf('locall') >= 0 || current_store.indexOf('singha') >= 0 || current_store.indexOf('imperial') >= 0) &&
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label"></label>
                                </div>
                                <div className="field-body">
                                    <button className="button is-info" onClick={() => { storeData.owner.push(""); this.forceUpdate() }}><i className="fa fa-plus"></i>&nbsp;เพิ่มผู้ดูแล</button>
                                </div>
                            </div>}
                    </div>
                }


                <hr />
                {storeData.contact && <div>
                    <h3 className="subtitle">ผู้ติดต่อ</h3>

                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">ชื่อผู้ติดต่อ</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control is-expanded">
                                    <input
                                        name="firstname"
                                        value={storeData.contact.firstname}
                                        onChange={this.handleDescriptionChange}
                                        className="input"
                                        type="text"
                                        placeholder=""
                                    />
                                </p>
                            </div>
                            <div className="field">
                                <p className="control is-expanded">
                                    <input
                                        name="lastname"
                                        value={storeData.contact.lastname}
                                        onChange={this.handleDescriptionChange}
                                        className="input"
                                        type="text"
                                        placeholder=""
                                    />
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">อีเมล</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control is-expanded">
                                    <input
                                        name="email"
                                        value={storeData.contact.email}
                                        onChange={this.handleDescriptionChange}
                                        className="input"
                                        type="text"
                                        placeholder=""
                                    />
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">เบอร์ติดต่อ</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control is-expanded">
                                    <input
                                        name="tel"
                                        value={storeData.contact.tel}
                                        onChange={this.handleDescriptionChange}
                                        className="input"
                                        type="text"
                                        placeholder=""
                                    />
                                </p>
                            </div>

                        </div>
                    </div>

                    <hr />


                    <h3 className="subtitle">ข้อมูลสำหรับใบเสร็จ</h3>

                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">ชื่อบริษัท</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control is-expanded">
                                    <input
                                        name="company_name"
                                        value={storeData.contact.company_name}
                                        onChange={this.handleDescriptionChange}
                                        className="input"
                                        type="text"
                                        placeholder=""
                                    />
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">เลขประจำตัวผู้เสียภาษี</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control is-expanded">
                                    <input
                                        name="tax"
                                        value={storeData.contact.tax}
                                        onChange={this.handleDescriptionChange}
                                        className="input"
                                        type="text"
                                        placeholder="เลขผู้เสียภาษี"
                                    />
                                </p>
                            </div>
                            <div className="field">
                                <p className="control is-expanded">
                                    <input
                                        name="tax_branch"
                                        value={storeData.contact.tax_branch}
                                        onChange={this.handleDescriptionChange}
                                        className="input"
                                        type="text"
                                        placeholder="สาขา เช่น 00000 หมายถึง สำนักงานใหญ่"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">ที่อยู่บริษัท</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="control is-expanded">
                                    <input
                                        name="company_address"
                                        value={storeData.contact.company_address}
                                        onChange={this.handleDescriptionChange}
                                        className="input"
                                        type="text"
                                        placeholder=""
                                    />
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr />
                </div>}

                {/* {JSON.stringify(storeData)} */}
                <button className="button is-success is-large is-fullwidth" onClick={() => this.submit()} disabled={!this.formValidate()}>บันทึกข้อมูล</button>
                {/* <Payment2c2pForm total_price={'3333'} order_no={'dddd'} order_id={'tttt'} email={'dddd'} description={'Rine Service Fee'} /> */}

            </div>
        </div >
    }
}

const mapStateToProps = ({ current_entity, current_store, current_user }) => ({ current_entity, current_store, current_user });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(StoreConfig)))
