import StoreFirebaseModel from './StoreFirebaseModel';

export default class HubStoreProduct extends StoreFirebaseModel {

}

HubStoreProduct.collection = 'hub_stores';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();