import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../actions/index'
import { CloudImage } from './CloudImage';
import BaseEditor from './BaseEditor';
import FormField from './FormField';
import StoreBanner from '../models/StoreBanner';
import { dateFormat } from '../Helper';

class BannerEditor extends BaseEditor {
    constructor() {
        super()
        this.title = "Banners"
        this.canCreate = true;
        this.defaultValue = { name: "", startDate: null, endDate: null, startTime: null, endTime: null, options: [] };
        this.listFields = ['image', 'name', 'date', 'time', 'active']
        // this.editFields = [['firstname', 'lastname'], ['mobile', 'email'], 'gender', 'grade']
        this.entityInfo = {
            name: 'String',
        }
    }

    componentDidMount() {
        this.dataRef = StoreBanner.getCollection(this.props.current_store);
        super.componentDidMount()
    }

    fieldData(item, name) {
        let result = super.fieldData(item, name)
        if (name === 'date') {
            if (item.startDate && item.endDate) {
                return `${dateFormat(item.startDate, false)} - ${dateFormat(item.endDate, false)}`
            }
            return 'All date'
        }

        if (name === 'time') {
            if (item.startTime && item.endTime) {
                return `${item.startTime} - ${item.endTime}`
            }
            return 'All time'
        }

        if (name === 'image') {
            if (item.image) {
                return <CloudImage src={item.image} style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }} />
            }
        }

        if (name === 'active') {
            return <button className={"button is-small " + (item.is_active ? "is-success" : "is-warning")} onClick={() => { this.toggleActive(item.id, !item.is_active) }}>{(item.is_active ? "ACTIVE" : "INACTIVE")}</button>
        }

        return result
    }


    renderEditor() {
        let { current_entity } = this.props
        return <div>
            <FormField label="Name" name="name" type="text" onChange={this.handleDescriptionChange} value={current_entity.name} />
            <FormField label="Link" name="link" type="text" onChange={this.handleDescriptionChange} value={current_entity.link} />
            <div className="field">
                <div className="columns">
                    <div className="column">
                        <label className="label is-small">From date</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input is-small"
                                name="startDate"
                                type="date"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.startDate} />
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>
                    <div className="column">
                        <label className="label is-small">To</label>
                        <div className="control has-icons-left has-icons-right">
                            <input className="input is-small"
                                name="endDate"
                                type="date"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.endDate} />
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>

                    <div className="column">
                        <label className="label is-small">Time</label>
                        <div className="control has-icons-left has-icons-right">
                            <select className="input is-small"
                                name="startTime"
                                type="text"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.startTime}>
                                {[...Array(48)].map((x, i) =>
                                    <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                )}
                            </select>
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>
                    <div className="column">
                        <label className="label is-small">&nbsp;</label>
                        <div className="control has-icons-left has-icons-right">
                            <select className="input is-small"
                                name="endTime"
                                type="text"
                                placeholder=""
                                onChange={this.handleDescriptionChange}
                                value={current_entity.endTime}>
                                {[...Array(48)].map((x, i) =>
                                    <option key={i / 2} value={Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}>{Math.floor(i / 2) + ":" + (i % 2 ? "30" : "00")}</option>
                                )}
                            </select>
                            <span className="icon is-small is-left">
                                <i className="fa fa-clock"></i>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            <div className="field">
                <label className="label is-small">Images</label>
                <div className="control has-icons-left has-icons-right">
                    <input type="file" onChange={this.handleFileChange} />
                    <CloudImage src={current_entity.image} className="thumbnail" />
                </div>
            </div>

            <div className="mt10">
                <button className="button is-primary" onClick={() => this.save(current_entity)} disabled={!this.state.fileObjURL}>SAVE</button>
            </div>
        </div>
    }
}


const mapStateToProps = ({ current_user, current_entity, current_store }) => ({ current_user, current_entity, current_store });
export default withRouter(connect(mapStateToProps, actions)(BannerEditor))