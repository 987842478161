import React from "react"

export default class TemplateConditionChoices extends React.Component {
    constructor(props) {
        super(props)
        this.state = { checkins: [], surveys: [] }

        this.onChange = this.onChange.bind(this)
    }

    async componentDidMount() {
        let response = await fetch("/api/v2/checkins?filters=" + JSON.stringify(this.props.dependences));
        let data = await response.json();
        this.setState({ checkins: data })

        response = await fetch("/api/v2/surveys?filters=" + JSON.stringify({ ...this.props.dependences }));
        data = await response.json();
        this.setState({ surveys: data })
    }

    onChange(e, name, value) {

        if (this.props.onChange) {
            this.props.onChange(e, name, value)
        }
    }

    render() {
        let { value, className, name } = this.props;
        let form = <select className={className + " field " + name} name={name} value={value} onChange={this.onChange} placeholder="">
            {/* {type.choices.map(item => <option value={item}>{item}</option>)} */}
            {this.state.checkins.map(x => <option value={`checkin_${x._id}`}>{('checkins')} {x.title}</option>)}
            {this.state.surveys.map(x => <option value={`survey_${x._id}`}>{('survey')} {x.title}</option>)}
        </select>

        return form
    }
}