import React from "react"
import moment from "../moment-tz"
import { withRouter } from "react-router";
import XLSX from 'xlsx';

import { connect } from 'react-redux';
import * as firebase from 'firebase';
import * as actions from '../actions/index'
import { withCookies } from 'react-cookie';
import Store from '../models/Store';
import { CloudImage } from './CloudImage';
import * as AppConfig from '../AppConfig.json';
import { isStaff, isAdmin, isSuperAdmin } from "../Helper";
import StoreOrder from "../models/StoreOrder";

let storageRef = firebase.storage().ref();

class StoreBilling extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: '2020-09-11',//moment().subtract(10, 'days').format('YYYY-MM-DD'),
            endDate: null//moment().format('YYYY-MM-DD'),
        }
        this.packages = {
            'FREE': { price: 0, order_count: 30, branch_count: 1, admin_count: 1 },
            'SEED': { price: 330, order_count: 1000, branch_count: 1, admin_count: 1 },
            'STANDARD': { price: 1600, order_count: 5000, branch_count: 2, admin_count: 5 },
            'PRO': { price: 4800, order_count: 15000, branch_count: 5, admin_count: 10 },
            'SME': { price: 12800, order_count: 30000, branch_count: 10, admin_count: 20 }
        }
    }

    async componentDidMount() {

        let { current_store } = this.props

        this.dataRef = StoreOrder.getCollection(this.props.current_store);

        if (this.props.store) {
            current_store = this.props.store
        }

        if (current_store) {
            let store = await Store.get(current_store)
            if (store.exists) {
                console.log("Store data", store.data())
                let storeData = store.data()
                storeData.id = store.id
                this.setState({ storeData })
            }

            else {
                this.setState({ storeData: false })
            }
        }

        this.loadData()
    }

    async loadData() {
        let { startDate, endDate } = this.state

        let querySnapshot = await this.dataRef.where('status', 'in', ['submitted', 'paid', 'ontheway', 'delivered', 'cancel'])
            .where('deliverDate', '>=', moment(startDate).format('YYYY-MM-DD'))// moment().subtract(10, 'days').format('YYYY-MM-DD'))
            // .where('deliverDate', '<=', moment(endDate).format('YYYY-MM-DD'))// moment().format('YYYY-MM-DD'))
            .get()

        var children = [];
        querySnapshot.forEach((doc) => {
            var childKey = doc.id;
            var childData = doc.data();
            childData.id = childKey;
            children.push(childData)
        });

        let date = moment('2020-09-11', 'YYYY-MM-DD')
        let monthlyCount = {}
        do {
            let endDate = date.clone().add(1, 'month')
            console.log(date.format(), endDate.format())
            let monthlyData = children.filter(x => moment(x.created_at.toDate()).isBetween(date, endDate, undefined, "[]"))
            monthlyCount[date.format('YYYY-MM')] = { start: date, end: endDate, datas: monthlyData }
            date = endDate
        } while (date.isBefore(moment()))

        // console.log(monthlyCount)
        this.setState({ monthlyCount })
    }

    export(orders) {
        var filename = "exportOrder.xlsx";
        var data = ["NO", "Name", "Tel", "Total", "ส่วนลด", "ค่าอาหาร", "ต้นทุนอาหาร", "ค่าส่ง", "ต้นทุนค่าส่ง", "ระยะทาง", "Status", "Address", "Payment", "Submit Date", "Deliver Date"];
        if (orders) {

            data = [[...data], ...orders.map((item, key) => {
                let cost = 0;
                cost = item.carts.reduce((acc, cart_item) => {
                    return acc + (cart_item.product.cost + (cart_item.option ? cart_item.option.cost : 0)) * cart_item.quantity
                }, 0
                )

                return [item.order_no, item.customer.name, item.customer.tel, item.totalPrice, item.discount, item.sumProductPrice, cost, item.deliverCost, item.deliverType !== 'pickup' ? (30 + (7 * ((item.distance - 3) > 0 ? (item.distance - 3) : 0))) : 9, item.distance, item.status.humanize, item.deliverAddress.address, item.paymentMethod, item.orderDate && item.orderDate.toString(), item.deliverDate && item.deliverDate.toString()]
            })]
        }
        var ws_name = "SheetJS";

        if (typeof console !== 'undefined') console.log(new Date());
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        ws['!autofilter'] = { ref: "A1:H1" };
        /* write workbook */
        if (typeof console !== 'undefined') console.log(new Date());
        XLSX.writeFile(wb, filename);
        if (typeof console !== 'undefined') console.log(new Date());
    }

    render() {
        let { current_store } = this.props
        let { monthlyCount } = this.state

        if (this.props.store) {
            current_store = this.props.store
        }
        if (!this.props.current_user) return <div className="title p20 has-text-centered"></div>;
        // 
        if (this.props.current_user && this.props.owner === false) return <div className="title p20 has-text-centered">คุณไม่มีสิทธิในการเข้าถึงข้อมูลส่วนนี้!<br />({this.props.current_user.email})</div>;

        let { storeData } = this.state
        if (!storeData || !monthlyCount) return <div className="has-text-centered p20"> <img src="/loading.gif" width="80" /></div>

        let isHubAdmin = isSuperAdmin(this.props.current_user.email) //|| ['peangploy@satarana.com', 'sanon@satarana.com', 'ping@satarana.com', 'mekku.nk@gmail.com', 'klinsanity@gmail.com', 'yada@satarana.com'].includes(this.props.current_user.email)


        return <div className="store-config p15">
            <h1 className="title">ปริมาณการใช้งาน</h1>
            <div className="columns">
                <div className="column">
                    {/* <div style={{ lineHeight: '36px' }}>
                        ตั้งแต่ <input type="date" className="input is-inline" value={this.state.startDate} onChange={e => { this.setState({ startDate: e.target.value }); }} /> ถึง <input type="date" className="input is-inline" value={this.state.endDate} onChange={e => { this.setState({ endDate: e.target.value }); }} /> <button className="button is-info" onClick={() => this.loadData()}>แสดง</button>
                    </div> */}
                    <div className="table">
                        <table className="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>รอบบิล (เดือน)</th>
                                    <th>จำนวนออเดอร์</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(monthlyCount).map(x => <tr>
                                    <td>{monthlyCount[x].start.format("DD MMMM YYYY")} - {monthlyCount[x].end.format("DD MMMM YYYY")}</td>
                                    <td>{monthlyCount[x].datas.length}</td>
                                    <td><button className="button is-small is-info" onClick={() => this.export(monthlyCount[x].datas)}>ดาวน์โหลด</button></td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    }
}

const mapStateToProps = ({ current_entity, current_store, current_user }) => ({ current_entity, current_store, current_user });
export default withCookies(withRouter(connect(mapStateToProps, actions, null, { forwardRef: true })(StoreBilling)))
