import FirebaseModel from './FirebaseModel';

export default class StoreCategory extends FirebaseModel {

}

StoreCategory.collection = 'category';

// Round.addListener((datas) => { alert(`update ${datas.length}`) })
// Round.add({ test: true })
// Round.getItems();